.task_assign_modal {
    &.modal {
        .modal-dialog {
            &.modal-dialog-centered {
                .modal-content {
                    .modal-header {
                        border-bottom: rem(1) solid $grey;

                        .modal-title {
                            &.h4 {
                                font-size: rem(22);
                                color: $form_label_color;
                                font-weight: 600;
                            }
                        }
                    }

                    .modal-body {
                        padding: 0;

                        .form_area {
                            margin: 0 rem(15);
                            margin-top: rem(45);

                            .form-group {
                                position: relative;

                                .form-label {
                                    span {
                                        color: $critical_color;
                                    }
                                }

                                .icon_cal {
                                    font-size: rem(25);
                                    color: $designation;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    right: 0;
                                    margin-top: rem(52);
                                    margin-right: rem(15);
                                }

                                .icon_clock {
                                    color: $designation;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    right: 0;
                                    margin-top: rem(56);
                                    margin-right: rem(12);

                                    .img_area {
                                        width: rem(25);

                                        img {
                                            height: 100%;
                                            width: 100%;
                                            object-fit: cover;
                                        }
                                    }
                                }
                            }

                            .text_area {
                                h5 {
                                    font-size: rem(22);
                                    font-weight: 600;
                                    color: $form_label_color;
                                    margin-bottom: rem(12);

                                    span {
                                        color: $critical_color;
                                    }
                                }

                                textarea {
                                    width: 100%;
                                    // height: 60px;
                                    font-size: 22px;
                                    font-weight: 400;
                                    padding-left: 20px;
                                    border: 1.5px solid #373780;
                                    border-radius: 10px;
                                    color: #7A7A7A;
                                    padding-top: rem(15);
                                    padding-bottom: rem(15);
                                    min-height: rem(115);
                                    margin-bottom: rem(30);
                                }
                            }

                            .btn_area {
                                margin: 0 rem(15) rem(30) rem(15);
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .task_assign_modal {
        &.modal {
            .modal-dialog {
                &.modal-dialog-centered {
                    .modal-content {
                        .modal-header {
                            .modal-title {
                                &.h4 {
                                    font-size: rem(20);
                                }
                            }
                        }

                        .modal-body {
                            .form_area {
                                margin-top: rem(30);

                                .form-group {
                                    margin-bottom: rem(25);

                                    .form-label {
                                        font-size: rem(20);
                                    }

                                    .icon_cal {
                                        margin-top: rem(46);
                                    }

                                    .icon_clock {
                                        margin-top: rem(50);
                                    }

                                }

                                .text_area {
                                    h5 {
                                        font-size: rem(20);
                                    }

                                    textarea {
                                        margin-bottom: rem(25);
                                    }
                                }

                                .btn_area {

                                    .btn {
                                        font-size: rem(22);
                                        margin-top: rem(20);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .task_assign_modal {
        &.modal {
            .modal-dialog {
                &.modal-dialog-centered {
                    .modal-content {
                        .modal-header {
                            .modal-title {
                                &.h4 {
                                    font-size: rem(20);
                                }
                            }
                        }

                        .modal-body {
                            .form_area {
                                margin-top: rem(25);

                                .form-group {
                                    margin-bottom: rem(20);

                                    .form-label {
                                        font-size: rem(18);
                                        margin-bottom: rem(10);
                                    }

                                    .icon_cal {
                                        margin-top: rem(44);
                                        font-size: rem(22);
                                    }

                                    .icon_clock {
                                        margin-top: rem(48);

                                        .img_area {
                                            width: rem(22);
                                        }
                                    }

                                }

                                .text_area {
                                    h5 {
                                        font-size: rem(18);
                                        margin-bottom: rem(10);
                                    }

                                    textarea {
                                        margin-bottom: rem(20);
                                        font-size: rem(20);
                                        min-height: rem(105);
                                    }
                                }


                                .btn_area {
                                    margin-left: 0;
                                    margin-right: 0;
                                    margin-bottom: rem(20);

                                    .btn {
                                        font-size: rem(20);
                                        margin-top: rem(20);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Smaller phones */
@media (max-width: rem(360)) {
    .task_assign_modal {
        &.modal {
            .modal-dialog {
                &.modal-dialog-centered {
                    .modal-content {
                        .modal-header {
                            .modal-title {
                                &.h4 {
                                    font-size: rem(20);
                                }
                            }
                        }

                        .modal-body {
                            .form_area {
                                margin-top: rem(25);

                                .form-group {
                                    margin-bottom: rem(20);

                                    .form-label {
                                        font-size: rem(18);
                                        margin-bottom: rem(10);
                                    }

                                    .icon_cal {
                                        margin-top: rem(44);
                                        font-size: rem(22);
                                    }

                                    .icon_clock {
                                        margin-top: rem(48);

                                        .img_area {
                                            width: rem(22);
                                        }
                                    }

                                }

                                .text_area {
                                    h5 {
                                        font-size: rem(18);
                                        margin-bottom: rem(10);
                                    }

                                    textarea {
                                        margin-bottom: rem(20);
                                        font-size: rem(20);
                                        min-height: rem(105);
                                    }
                                }


                                .btn_area {
                                    margin-left: 0;
                                    margin-right: 0;
                                    margin-bottom: rem(20);

                                    .btn {
                                        font-size: rem(20);
                                        margin-top: rem(20);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}