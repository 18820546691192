.reset_password_screen {
    .form {
        padding: rem(50) rem(30);

        .reset_password {
            position: relative;

            .confirm_password {
                border: rem(1) solid $critical_color;
            }

            .password_icon {
                position: absolute;
                top: 0;
                right: 0;
                padding: rem(19);
                font-size: rem(25);
                color: $critical_color;
                cursor: pointer;
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .reset_password_screen {
        .form {
            padding: rem(20) rem(0);

            .btn_area {
                .btn {
                    font-size: rem(20);
                    height: rem(50);
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .reset_password_screen {
        .form {
            padding: rem(20) rem(0);

            .btn_area {
                .btn {
                    font-size: rem(20);
                    height: rem(50);
                }
            }
        }
    }
}