.auth_header {
    background-color: $white;

    .header_area {
        background-image: url("../../../assets/images/authBackground.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: rem(320);
        position: relative;

        .left_area {
            padding: rem(80) rem(135) rem(80) rem(25);
            color: $white;

            h3 {
                font-size: rem(60);
                font-weight: 600;
            }

            p {
                font-size: rem(24);
                font-weight: 400;
                margin-top: rem(15);
                width: 90%;
            }
        }

        .right_area {
            position: absolute;
            top: 0;
            right: 0;

            .img_one {
                height: rem(275);
                overflow: hidden;
                width: rem(135);

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}


@media (min-width: 768px) and (max-width: 991px) {
    .auth_header {
        background-color: $white;

        .header_area {
            background-image: url("../../../assets/images/authBackground.png");
            background-repeat: no-repeat;
            background-size: cover;
            height: rem(475);
            position: relative;

            .left_area {
                padding: rem(80) rem(135) rem(80) rem(25);
                color: $white;

                h3 {
                    font-size: rem(60);
                    font-weight: 600;
                }

                p {
                    font-size: rem(24);
                    font-weight: 400;
                    margin-top: rem(15);
                    width: 90%;
                }
            }

            .right_area {
                position: absolute;
                top: 0;
                right: 0;

                .img_one {
                    height: rem(275);
                    overflow: hidden;
                    width: rem(135);

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }

}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .auth_header {
        .header_area {
            height: rem(355);

            .left_area {
                padding: rem(45) rem(10);

                h3 {
                    font-size: rem(45);
                }

                p {
                    font-size: rem(20);
                }
            }

            .right_area {
                .img_one {
                    height: rem(230);
                    width: rem(110);
                }
            }

            &.header_responsive {
                height: rem(415);
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .auth_header {
        .header_area {
            height: rem(232);

            .left_area {
                padding: rem(20) rem(11);

                h3 {
                    font-size: rem(28);
                }

                p {
                    font-size: rem(15);
                }
            }

            .right_area {
                .img_one {
                    height: rem(130);
                    width: rem(70);
                }
            }

            &.header_responsive {}
        }
    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .auth_header {
        .header_area {
            height: rem(232);

            .left_area {
                padding: rem(20) rem(11);

                h3 {
                    font-size: rem(28);
                }

                p {
                    font-size: rem(15);
                }
            }

            .right_area {
                .img_one {
                    height: rem(130);
                    width: rem(70);
                }
            }
        }
    }
}