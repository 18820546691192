.patient_list_screen {
    .patient_details {
        padding: 0 rem(25) rem(25) rem(25);
        margin-top: rem(-30);

        .search_section {
            @include clearfix();
            margin-bottom: rem(45);
            display: flex;
            align-items: center;
            position: relative;
            z-index: 2;

            .search_area {
                width: calc(100% - 50px);
                float: left;

                .form-group {
                    position: relative;
                    margin-bottom: 0;

                    .form-control {
                        height: rem(60);
                        padding-right: rem(50);
                        width: 86%;
                    }

                    .icon {
                        position: absolute;
                        right: rem(80);
                        top: 0;
                        line-height: rem(60);
                        font-size: rem(24);
                        margin-right: rem(23);
                        color: $designation;
                    }
                }
            }

            // .filter_area {
            //     width: rem(30);
            //     margin-left: rem(20);
            //     float: left;

            //     .img_area {
            //         img {
            //             width: 100%;
            //             height: 100%;
            //             object-fit: cover;
            //         }
            //     }

            // }
            .filter_area {
                position: relative;
                width: rem(30);
                margin-left: rem(20);
                float: right;

                .btn-primary {
                    float: right;
                    width: rem(124);
                    height: rem(60);
                    font-size: rem(22);
                    font-weight: 400;
                    border-radius: rem(10);
                    background: rgba(255, 0, 0, 0.15);
                    border: rem(1.5) solid rgba(255, 0, 0, 0.4);
                    color: #140745;

                    .image_area {
                        margin-right: rem(5);
                    }
                }

                .btn-border {
                    background-color: $white;
                    border: rem(1.5) solid $form_border_color;
                }

                .filter_icon {
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin-top: 10px;
                    margin-right: 10px;
                    font-size: 7px;
                    color: $dark_red_2;
                }
            }
        }

        .prt_area {
            @include clearfix();
            display: flex;
            align-items: center;
            margin-bottom: rem(21);

            .active_area {
                float: left;

                p {
                    font-weight: 400;
                    font-size: rem(16);
                    color: #373780;

                }
            }

            .right_area {
                float: right;
                margin-left: auto;

                .btn {
                    float: right;
                    font-size: rem(24);
                    font-weight: 600;
                    background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                    width: rem(211);
                    height: rem(50);
                    border: none;

                    &:hover,
                    &:focus {
                        color: $white;
                        height: rem(50);
                        border: none;
                        opacity: 0.8;
                    }
                }


            }
        }

        .card {
            background: $card_color;
            border: rem(1) solid $grey;
            border-radius: rem(25);
            margin-bottom: rem(15);

            .card-body {
                text-transform: capitalize;
                @include clearfix();

                .card_left {
                    float: left;
                    width: rem(136);

                    .img_area {
                        width: rem(136);
                        height: rem(137);
                        border-radius: rem(10);
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .card_right {
                    float: right;
                    width: calc(100% - 136px);
                    padding-left: rem(25);

                    .personal_data {
                        .name_number_area {
                            @include clearfix();

                            h3 {
                                float: left;
                                font-size: rem(22);
                                font-weight: 600;
                                background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                                cursor: pointer;
                                margin-bottom: 10px;
                            }

                            span {
                                float: right;
                                color: $card_text_color;
                                font-weight: 400;
                                font-size: rem(16);
                                display: flex;
                                gap: rem(10);

                                .dropdown-menu {
                                    border-radius: rem(18) 0 rem(18) rem(18);
                                    background: $white;
                                    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
                                    transform: translate(rem(-160), rem(30)) !important;

                                    .dropdown-item {
                                        padding: rem(10) rem(22);
                                        font-size: rem(16);
                                    }

                                }

                                .btn {
                                    color: #989898;
                                    padding-top: 0;

                                    &:hover,
                                    &:focus {
                                        background-color: $white;
                                    }
                                }

                                .ellipsis_icon {
                                    font-size: rem(27);
                                    cursor: pointer;
                                }

                                .dropdown-toggle::after {
                                    display: none !important;
                                }
                            }
                        }

                        h4 {
                            font-weight: 400;
                            font-size: rem(20);
                            color: $form_border_color;
                            padding-top: rem(10);
                            padding-bottom: rem(10);
                            word-spacing: rem(2);
                        }

                        p {
                            color: $designation;
                            font-size: rem(16);
                            font-weight: 400;

                            span {
                                background: rgba(55, 55, 128, 0.2);
                                border-radius: rem(5);
                                color: #373780;
                                padding: rem(3) rem(11);

                            }

                        }

                        .gender_area {

                            margin-top: rem(15);
                            margin-bottom: rem(15);
                            @include clearfix();

                            .left {
                                float: left;
                                display: flex;

                                .date_icon {
                                    margin-right: rem(5);
                                    color: #D81B23;
                                }

                                .gender_icon {
                                    margin-right: rem(5);
                                    color: #D81B23;
                                }
                            }

                            .uhid {
                                float: right;
                                font-size: rem(14);
                                font-weight: 400;
                                color: $light_grey;
                                padding: rem(3) rem(10);
                                background: rgba(154, 154, 154, 0.2);
                                border-radius: rem(5);
                                display: inline-block;
                            }

                        }

                        .date_information {
                            position: relative;
                            @include clearfix();

                            .date {
                                display: flex;
                                gap: rem(10);
                                align-items: center;
                                float: left;
                                position: absolute;
                                bottom: 0;
                                margin-bottom: rem(15);

                                .date_icon {
                                    color: #D81B23;
                                }

                                .icon_area {
                                    height: rem(30);
                                    overflow: hidden;

                                    Image {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }

                            .btn {
                                float: right;
                                border-radius: rem(30);
                                font-weight: 600;
                                font-size: rem(16);
                                width: auto;
                                margin: 0;
                                padding: rem(12);
                                line-height: initial;

                                &:hover,
                                &:focus {
                                    opacity: 0.6;
                                    color: $white;
                                }
                            }

                            // .btn_warning {
                            //     background: $treatment_color;
                            //     &:hover,
                            //     &:focus {
                            //         // opacity: 0.6;
                            //         color: $white;
                            //     }
                            // }

                            // .btn_danger {
                            //     background: $critical_color;
                            // }

                            // .btn_success {
                            //     background-color: $success_color;
                            // }
                            .badge {
                                float: right;
                                background: $treatment_color;
                                padding: rem(17) rem(10);
                                border-radius: rem(25);
                                cursor: pointer;

                                &:hover {
                                    background: darken($treatment_color, 4%);
                                }

                                p {
                                    font-weight: 600;
                                    font-size: rem(16);
                                    color: $white;
                                }

                                &.critical {
                                    background: $critical_color;
                                    padding: rem(17) rem(13);

                                    &:hover {
                                        background: darken($critical_color, 4%);
                                    }
                                }

                                &.success {
                                    background: $success_color;
                                    padding: rem(17) rem(13);

                                    &:hover {
                                        background: darken($success_color, 4%);
                                    }
                                }
                            }


                        }
                    }
                }
            }
        }
    }

    .without_image_header {
        .header_area {
            .details {
                .icon_area {
                    display: none;
                }
            }
        }
    }
}


/* Landscape tablets and ipad */
@media (min-width: 768px) and (max-width: 991px) {
    .patient_list_screen {
        .patient_details {
            .card {
                .card-body {
                    .card_right {
                        .personal_data {
                            .name_number_area {
                                span {
                                    .dropdown-menu {
                                        transform: translate(rem(-20), rem(30)) !important;
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }

}



/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .patient_list_screen {
        .patient_details {
            .card {
                .card-body {
                    .card_right {
                        .personal_data {
                            .name_number_area {
                                h3 {
                                    float: left;
                                    font-size: rem(18);
                                }

                                span {
                                    font-size: rem(13);

                                    .dropdown-menu {
                                        transform: translate(rem(-20), rem(30)) !important;
                                    }

                                    .ellipsis_icon {
                                        font-size: rem(23);
                                    }
                                }
                            }

                            h4 {
                                font-size: rem(17);
                            }

                            .date_information {
                                .btn {
                                    font-size: rem(14);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .patient_list_screen {
        .patient_details {
            margin-top: rem(10);
            padding: 0;

            .search_section {
                margin-bottom: rem(25);

                .search_area {
                    .form-group {
                        .form-control {
                            height: rem(45);
                        }
                    }

                    .icon {
                        line-height: rem(45);
                    }
                }
            }

            .card {
                .card-body {
                    .card_left {
                        width: rem(65);

                        .img_area {
                            width: rem(65);
                            height: rem(70);
                        }
                    }

                    .card_right {
                        width: calc(100% - 65px);
                        padding-left: rem(20);

                        .personal_data {
                            .name_number_area {
                                h3 {
                                    font-size: rem(17);
                                }

                                span {
                                    font-size: rem(15);
                                    margin-top: rem(4);

                                    .dropdown-menu {
                                        transform: translate(rem(-20), rem(30)) !important;
                                    }

                                    .ellipsis_icon {
                                        font-size: rem(23);
                                    }
                                }
                            }

                            h4 {
                                font-size: rem(14);
                                padding-top: rem(6);
                                padding-bottom: rem(5);
                                word-spacing: 0;
                            }

                            .date_information {
                                .date {
                                    position: relative;
                                    top: 0;
                                    margin-top: rem(5);
                                }

                                .btn {
                                    float: left;
                                    font-size: rem(14);
                                    margin-top: rem(15);
                                }

                                .badge {
                                    float: unset;
                                    background: #FF8717;
                                    padding: 10px 10px;

                                    p {
                                        font-size: 14px;
                                    }

                                    &.critical {
                                        padding: rem(10) rem(16);
                                    }

                                    &.success {
                                        padding: rem(10) rem(16);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Smaller phones */
@media (max-width: rem(360)) {
    .patient_list_screen {
        .patient_details {
            margin-top: rem(10);
            padding: 0;

            .search_section {
                margin-bottom: rem(25);

                .search_area {
                    .form-group {
                        .form-control {
                            height: rem(45);
                        }
                    }

                    .icon {
                        line-height: rem(45);
                    }
                }
            }

            .card {
                .card-body {
                    .card_left {
                        width: rem(65);

                        .img_area {
                            width: rem(65);
                            height: rem(70);
                        }
                    }

                    .card_right {
                        width: calc(100% - 65px);
                        padding-left: rem(10);

                        .personal_data {
                            .name_number_area {
                                h3 {
                                    font-size: rem(17);

                                }

                                span {
                                    font-size: rem(15);
                                    margin-top: rem(4);

                                    .dropdown-menu {
                                        transform: translate(rem(-20), rem(30)) !important;
                                    }

                                    .ellipsis_icon {
                                        font-size: rem(23);
                                    }
                                }
                            }

                            h4 {
                                font-size: rem(14);
                                padding-top: rem(6);
                                padding-bottom: rem(5);
                                word-spacing: 0;
                            }

                            .date_information {
                                .date {
                                    position: relative;
                                    top: 0;
                                    margin-top: rem(5);
                                }

                                .btn {
                                    float: left;
                                    font-size: rem(14);
                                    margin-top: rem(15);
                                }

                                .badge {
                                    float: unset;
                                    background: #FF8717;
                                    padding: 10px 10px;

                                    p {
                                        font-size: 14px;
                                    }

                                    &.critical {
                                        padding: rem(10) rem(13);
                                    }

                                    &.success {
                                        padding: rem(10) rem(13);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}