.task_section {
    .task_area {
        padding: 0 rem(15);
        padding-top: rem(90);
        position: relative;

        .date_num_area {
            @include clearfix();
            margin-top: rem(40);
            margin-bottom: rem(27);

            .left_date_area {
                float: left;
                width: rem(200);
                display: flex;
                align-items: center;

                .primary {
                    background: transparent;
                }

                .btn {
                    border: 0;
                    width: rem(45);
                    box-shadow: none;
                    text-decoration: none;
                    outline: none;
                    border-radius: 0;
                    color: $cancel_btn_color;
                    font-size: rem(24);
                    font-weight: 600;
                    padding-right: 35px;

                    &:hover {
                        color: $cancel_btn_color;
                    }
                }
                .icon {
                    width: rem(30);

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                p {
                    font-size: rem(24);
                    font-weight: 600;
                    color: $card_text_color;
                    margin-left: rem(10);

                    .date_icon {
                        font-size: rem(24);
                        color: $card_text_color;
                        margin-right: rem(10);
                    }
                }
            }

            .right_num_area {
                float: right;
                width: calc(100% - 200px);
                text-align: end;

                .dropdown {
                    .dropdown-toggle {
                        padding: 0;

                        &:after {
                            content: none;
                        }

                        &:focus {
                            background-color: $white;
                        }
                    }
                }

                p {
                    font-weight: 600;
                    font-size: rem(24);
                    color: $card_text_color;
                }

                .primary {
                    // width: 110px;

                    background: transparent;
                }

                .btn {
                    border: 0;
                    width: rem(45);
                    box-shadow: none;
                    text-decoration: none;
                    outline: none;
                    border-radius: 0;
                    color: $cancel_btn_color;
                    font-size: rem(24);
                    font-weight: 600;
                    padding-right: 35px;

                    &:hover {
                        color: $cancel_btn_color;
                    }
                }
            }
        }

        .assign_task {
            float: right;
            margin-top: rem(80);
            margin-bottom: -95px;

            .circle_area {
                display: flex;
                align-items: center;
                justify-content: center;
                height: rem(100);
                width: rem(100);
                text-align: center;
                border-radius: 50%;
                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                box-shadow: 0px 10px 27px rgba(227, 30, 36, 0.59);

                &:hover {
                    background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                }
            }

            .add_icon_area {
                color: $white;
                font-size: rem(20);

                p {
                    font-weight: 500;
                    font-size: 14px;
                    color: $white;
                }

            }


        }

        .pagination_space {
            margin-top: rem(25);
        }
    }
}




/* Above 1920 desktop */
@media (min-width: 1920px) {}

/* 1680 desktop */
@media (min-width: 1680px) and (max-width: 1919px) {}

/* 1600 desktop */
@media (min-width: 1600px) and (max-width: 1679px) {}

/* Above 1400 and Below 1599 Desktop*/
@media (min-width: 1400px) and (max-width: 1599px) {}

/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {}

/* Landscape tablets and ipad */
@media (min-width: 768px) and (max-width: 991px) {
    .task_section {
        .task_area {
            padding: 0 rem(15);
            position: relative;

            .date_num_area {
                @include clearfix();
                margin-bottom: rem(27);
                // margin-top: rem(-35);



                .left_date_area {
                    float: left;
                    width: rem(200);
                    display: flex;
                    align-items: center;

                    // .button_area{
                    .primary {
                        // width: 110px;

                        background: transparent;
                    }

                    .btn {
                        border: 0;
                        width: rem(45);
                        box-shadow: none;
                        text-decoration: none;
                        outline: none;
                        border-radius: 0;
                        color: $cancel_btn_color;
                        font-size: rem(24);
                        font-weight: 600;
                        padding-right: 35px;

                        &:hover {
                            color: $cancel_btn_color;
                        }
                    }

                    // }

                    .icon {
                        width: rem(30);

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    p {
                        font-size: rem(24);
                        font-weight: 600;
                        color: $card_text_color;
                        margin-left: rem(10);

                        .date_icon {
                            font-size: rem(24);
                            color: $card_text_color;
                            margin-right: rem(10);
                        }
                    }
                }

                .right_num_area {
                    float: right;
                    width: calc(100% - 200px);
                    text-align: end;

                    .dropdown {
                        .dropdown-toggle {
                            padding: 0;

                            &:after {
                                content: none;
                            }

                            &:focus {
                                background-color: $white;
                            }
                        }
                    }

                    p {
                        font-weight: 600;
                        font-size: rem(24);
                        color: $card_text_color;
                    }

                    .primary {
                        // width: 110px;

                        background: transparent;
                    }

                    .btn {
                        border: 0;
                        width: rem(45);
                        box-shadow: none;
                        text-decoration: none;
                        outline: none;
                        border-radius: 0;
                        color: $cancel_btn_color;
                        font-size: rem(24);
                        font-weight: 600;
                        padding-right: 35px;

                        &:hover {
                            color: $cancel_btn_color;
                        }
                    }
                }
            }

            .assign_task {
                float: right;
                margin-top: rem(80);
                // margin-bottom: rem(50);
                margin-bottom: -95px;

                .circle_area {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: rem(100);
                    width: rem(100);
                    text-align: center;
                    border-radius: 50%;
                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                    box-shadow: 0px 10px 27px rgba(227, 30, 36, 0.59);

                    &:hover {
                        background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                    }
                }

                .add_icon_area {
                    color: $white;
                    font-size: rem(20);

                    p {
                        font-weight: 500;
                        font-size: 14px;
                        color: $white;
                    }

                }


            }

            .pagination_space {
                margin-top: rem(25);
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .task_section {
        .task_area {
            padding: 0;

            .date_num_area {
                margin-top: rem(20);
            }
        }

    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .task_section {
        .task_area {
            padding: 0;

            .date_num_area {
                margin: 33px 0;
            }
        }

    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .task_section {
        .task_area {
            padding: 0;

            .date_num_area {
                margin: 33px 0;
            }
        }

    }
}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}