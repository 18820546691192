.login_screen {
    .form {
        padding: rem(50) rem(30);

        a {
            &.forget_password {
                width: auto;
                // width: 27%;
                font-size: rem(22);
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                float: right;
                margin-top: rem(-7);
                gap: rem(5);
                color: $cancel_btn_color;
                border-bottom: 1px solid $cancel_btn_color;
                cursor: pointer;
            }
        }

        .btn {
            font-size: rem(24);
            font-weight: 600;
            width: 100%;
            height: rem(60);
            margin-top: rem(35);
            background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
            border: 0px solid transparent;

            &:hover {
                background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                color: $white;
                border: 0px solid transparent;
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .login_screen {
        .form {
            padding: rem(30) rem(10);

            a {
                &.forget_password {
                    width: 37%;
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .login_screen {

        .form {
            padding: rem(30) rem(10);

            a {
                &.forget_password {
                    width: auto;
                    font-size: 18px;
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .login_screen {
        .form {
            padding: rem(30) rem(10);

            a {
                &.forget_password {
                    width: auto;
                    font-size: 18px;
                }
            }
        }
    }
}