.hospital_detail_area {

    .hospital_area {
        padding: 0 rem(30);
        padding-top: 60px;
        background-color: $white;

        .img_banner {
            width: 79%;
            max-width: 100%;
            height: 470px;
            border-radius: 30px;
            overflow: hidden;
            margin: 0 auto;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .content_section {
            margin: rem(60) rem(200) rem(0) rem(200);
            background-color: $white;
            padding-bottom: rem(20);

            .content_area {
                @include clearfix();
                display: flex;
                align-items: center;
                border-bottom: 2px solid $designation;
                padding-bottom: rem(25);
                margin-bottom: rem(25);

                &.cursor {
                    cursor: pointer;
                }

                .left_area {
                    width: rem(24);

                    .icon_user {
                        height: rem(24);
                        width: rem(24);
                        border-radius: rem(4);

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: contain;
                        }
                    }
                }

                .right_area {
                    width: calc(100% - 24px);
                    padding-left: rem(25);

                    p {
                        font-size: rem(20);
                        font-weight: 400;
                        color: $black_2;
                    }
                }
            }
        }
    }
}




/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {
    .hospital_detail_area {
        .hospital_area {
            .img_banner {
                width: 900px;
                height: 420px;
                border-radius: 25px;
            }

            .content_section {
                margin: 90px 90px 0px 90px;
            }

        }
    }
}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {
    .hospital_detail_area {
        .hospital_area {
            .img_banner {
                width: 900px;
                height: 420px;
                border-radius: 25px;

            }

            .content_section {
                margin: 70px 70px 0px 70px;
            }
        }
    }
}

@media (min-width: 1023px) and (max-width: 1199px) {
    .hospital_detail_area {
        .hospital_area {
            .img_banner {
                width: 830px;
                height: 380px;
                border-radius: 20px;
            }

            .content_section {
                margin: 70px 70px 0px 70px;
            }

        }
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .hospital_detail_area {

        .hospital_area {
            padding: 0 rem(30);
            background-color: $white;

            .img_banner {
                width: rem(695);
                max-width: 100%;
                height: 320px;
                border-radius: 0 rem(95) 0 0;
                margin-left: rem(-30);
                margin-top: -25px;
                overflow: hidden;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .content_section {
                margin: rem(50) rem(45) rem(0) rem(45);
                background-color: $white;
                padding-bottom: rem(20);

                .content_area {
                    @include clearfix();
                    display: flex;
                    align-items: center;
                    border-bottom: 2px solid $designation;
                    padding-bottom: rem(30);
                    margin-bottom: rem(30);

                    &.cursor {
                        cursor: pointer;
                    }

                    .left_area {
                        width: rem(24);

                        .icon_user {
                            height: rem(24);
                            width: rem(24);
                            border-radius: rem(4);

                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: contain;
                            }
                        }

                        // .icon_area {
                        //     font-size: rem(24);
                        //     color: $dark_red_gradient_color;
                        // }
                    }

                    .right_area {
                        width: calc(100% - 24px);
                        padding-left: rem(25);

                        p {
                            font-size: rem(20);
                            font-weight: 400;
                            color: $black_2;
                        }
                    }
                }
            }
        }
    }
}



/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .hospital_detail_area {
        .hospital_area {
            padding: 0 rem(30);

            .img_banner {
                height: 170px;
                border-radius: 15px;
                margin-left: 0;
            }

            .content_section {
                margin: rem(40) rem(35) 0 rem(35);
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .hospital_detail_area {
        .hospital_area {
            padding: 0 rem(20);



            .img_banner {
                height: 170px;
                border-radius: 15px;
                margin-left: 0;
            }

            .content_section {
                margin: rem(30) rem(0) 0 rem(0);
                padding-bottom: rem(10);

                .content_area {
                    padding-bottom: rem(20);
                    margin-bottom: rem(20);

                    .left_area {
                        .icon_user {
                            height: rem(20);
                            width: rem(20);
                        }
                    }

                    .right_area {
                        padding-left: rem(15);

                        p {
                            font-size: rem(16);
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .hospital_detail_area {
        .hospital_area {
            padding: 0 rem(20);


            .img_banner {
                height: 170px;
                border-radius: 15px;
                margin-left: 0;
            }

            .content_section {
                margin: rem(30) rem(0) 0 rem(0);
                padding-bottom: rem(10);

                .content_area {
                    padding-bottom: rem(20);
                    margin-bottom: rem(20);

                    .left_area {
                        .icon_user {
                            height: rem(20);
                            width: rem(20);
                        }
                    }

                    .right_area {
                        padding-left: rem(15);

                        p {
                            font-size: rem(16);
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}