.add_medicine_form {
    margin-top: rem(50);
    margin-bottom: rem(59);

    form {
        .form-group {
            .form-label {
                span {
                    color: red;
                }
            }

            .form-control {
                background: transparent;
            }
        }
    }
}