.staff_detail_section {
    .staff_detail_area {
        margin: 0 rem(15);
        margin-top: rem(80);

        .content_img_area {
            @include clearfix();
            display: flex;
            align-items: center;
            margin-top: rem(0);
            // margin-top: rem(-15);
            margin-bottom: rem(20);

            .left_area {
                float: left;
                width: rem(115);

                .img_area {
                    height: rem(114);
                    width: rem(114);
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }

            .right_area {
                float: right;
                width: calc(100% - 115px);
                padding-left: rem(15);

                h5 {
                    font-size: rem(36);
                    font-weight: 400;
                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    margin-bottom: rem(5);
                }

                .text_box {
                    font-size: rem(16);
                    font-weight: 400;
                    color: $blue;
                    padding: rem(3) rem(10);
                    border-radius: rem(5);
                    background-color: $light_blue;
                    display: inline-block;
                }
            }
        }

        .info_area {
            border: 1px solid $grey;
            box-shadow: 0px 4px 25px rgba(55, 55, 128, 0.12);
            border-radius: 25px;
            padding: rem(45) rem(50) rem(18) rem(50);
            background-color: $accordin_body;
            position: relative;
            margin-bottom: rem(80);
            overflow: hidden;

            .inner_info_area {
                @include clearfix();
                margin-bottom: rem(20);

                .left_inner_area {
                    float: left;
                    width: 30%;
                    position: relative;

                    p {
                        font-size: rem(20);
                        font-weight: 600;
                        color: $black_2;
                        word-break: break-all;

                        &::before {
                            content: ":";
                            font-size: rem(20);
                            color: $black_2;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                        }
                    }
                }

                .right_inner_area {
                    float: right;
                    width: calc(100% - 30%);
                    padding-left: rem(5);

                    p {
                        font-size: rem(20);
                        font-weight: 400;
                        color: $black_2;
                        word-break: break-word;
                    }
                }
            }

            .circle_img {
                width: rem(228);
                overflow: hidden;
                position: absolute;
                bottom: 0;
                right: 0;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        .tab_details {
            padding-bottom: rem(30);

            .sorting_data {
                @include clearfix();
                padding-top: rem(50);
                display: flex;
                align-items: center;

                .left_area {
                    float: left;
                    width: rem(250);
                    padding-top: rem(8);

                    p {
                        font-weight: 600;
                        font-size: rem(24);
                        color: $designation;
                    }
                }

                .right_area {
                    float: right;
                    width: calc(100% - 250px);

                    .sort_button {
                        float: right;

                        .btn-primary {
                            // float: right;
                            border: rem(2) solid $designation;
                            color: $designation;
                            //  width: 30%;
                            width: rem(117);
                            background: $white;
                            font-size: rem(20);
                            font-weight: 400;
                        }

                        .image_area {
                            height: rem(22);
                            padding-left: rem(15);
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }

            .custom_tab_data {
                position: relative;

                .card_section {
                    .card_area {
                        .card_inner {
                            .card_inner_detail {
                                padding: 0;
                            }

                            .bottom_area {
                                .inner_area {
                                    .right_area {
                                        .dropdown {
                                            .dropdown-menu {
                                                transform: translate(210px, 7px) !important;

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .custom_tabs_area {
            .custom_tabs {
                overflow-x: hidden;
            }
        }
    }
}


/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {
    .staff_detail_section {
        .staff_detail_area {
            .tab_details {
                .custom_tab_data {
                    .card_section {
                        .card_area {
                            .card_inner {
                                .bottom_area {
                                    .inner_area {
                                        .right_area {
                                            .dropdown {
                                                .dropdown-menu {
                                                    transform: translate(70px, 7px) !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {
    .staff_detail_section {
        .staff_detail_area {
            .tab_details {
                .custom_tab_data {
                    .card_section {
                        .card_area {
                            .card_inner {
                                .bottom_area {
                                    .inner_area {
                                        .right_area {
                                            .dropdown {
                                                .dropdown-menu {
                                                    transform: translate(50px, 7px) !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media(min-width:1024px) and (max-width:1199px) {
    .staff_detail_section {
        .staff_detail_area {
            .tab_details {
                .custom_tab_data {
                    .card_section {
                        .card_area {
                            .card_inner {
                                .bottom_area {
                                    .inner_area {
                                        .right_area {
                                            .dropdown {
                                                .dropdown-menu {
                                                    transform: translate(0, 7px) !important;

                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .staff_detail_section {
        .staff_detail_area {
            margin: 0 rem(15);

            .content_img_area {
                @include clearfix();
                display: flex;
                align-items: center;
                margin-top: rem(-15);
                margin-bottom: rem(20);

                .left_area {
                    float: left;
                    width: rem(115);

                    .img_area {
                        height: rem(114);
                        width: rem(114);
                        border-radius: 50%;
                        overflow: hidden;

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .right_area {
                    float: right;
                    width: calc(100% - 115px);
                    padding-left: rem(15);

                    h5 {
                        font-size: rem(36);
                        font-weight: 400;
                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        margin-bottom: rem(5);
                    }

                    .text_box {
                        font-size: rem(16);
                        font-weight: 400;
                        color: $blue;
                        padding: rem(3) rem(10);
                        border-radius: rem(5);
                        background-color: $light_blue;
                        display: inline-block;
                    }
                }
            }

            .info_area {
                border: 1px solid $grey;
                box-shadow: 0px 4px 25px rgba(55, 55, 128, 0.12);
                border-radius: 25px;
                padding: rem(45) rem(50) rem(18) rem(50);
                background-color: $accordin_body;
                position: relative;
                margin-bottom: rem(80);
                overflow: hidden;

                .inner_info_area {
                    @include clearfix();
                    margin-bottom: rem(20);

                    .left_inner_area {
                        float: left;
                        width: 30%;
                        position: relative;

                        p {
                            font-size: rem(20);
                            font-weight: 600;
                            color: $black_2;
                            word-break: break-all;

                            &::before {
                                content: ":";
                                font-size: rem(20);
                                color: $black_2;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                right: 0;
                            }
                        }
                    }

                    .right_inner_area {
                        float: right;
                        width: calc(100% - 30%);
                        padding-left: rem(5);

                        p {
                            font-size: rem(20);
                            font-weight: 400;
                            color: $black_2;
                            word-break: break-word;
                        }
                    }
                }

                .circle_img {
                    width: rem(228);
                    overflow: hidden;
                    position: absolute;
                    bottom: 0;
                    right: 0;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }

            .tab_details {
                padding-bottom: rem(30);



                .sorting_data {
                    @include clearfix();
                    padding-top: rem(50);
                    display: flex;
                    align-items: center;

                    .left_area {
                        float: left;
                        width: rem(250);
                        padding-top: rem(8);

                        p {
                            font-weight: 600;
                            font-size: rem(24);
                            color: $designation;
                        }
                    }

                    .right_area {
                        float: right;
                        width: calc(100% - 250px);

                        .sort_button {
                            float: right;

                            .btn-primary {
                                // float: right;
                                border: rem(2) solid $designation;
                                color: $designation;
                                //  width: 30%;
                                width: rem(117);
                                background: $white;
                                font-size: rem(20);
                                font-weight: 400;
                            }

                            .image_area {
                                height: rem(22);
                                padding-left: rem(15);
                                overflow: hidden;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                    }
                }

                .custom_tab_data {
                    position: relative;

                    .card_section {
                        .card_area {
                            .card_inner {
                                .bottom_area {
                                    .inner_area {
                                        .right_area {
                                            .dropdown {
                                                .dropdown-menu {
                                                    transform: translate(112px, 7px) !important;

                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .custom_tabs_area {
                .custom_tabs {
                    overflow-x: hidden;
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .staff_detail_section {
        .staff_detail_area {
            .content_img_area {
                .left_area {
                    width: rem(100);

                    .img_area {
                        height: rem(100);
                        width: rem(100);
                    }
                }

                .right_area {
                    width: calc(100% - 100px);

                    h5 {
                        font-size: rem(30);
                    }

                    .text_box {
                        font-size: rem(15);
                    }
                }
            }

            .info_area {
                padding: rem(25);
                margin-bottom: rem(60);

                .inner_info_area {
                    margin-bottom: rem(18);

                    &.space {
                        margin-bottom: 0;
                    }

                    .left_inner_area {
                        p {
                            font-size: rem(18);

                            &::before {
                                font-size: rem(18);
                                margin-top: rem(-2);
                            }
                        }
                    }

                    .right_inner_area {
                        p {
                            font-size: rem(18);
                        }
                    }
                }

                .circle_img {
                    width: rem(200);
                }
            }

            .tab_details {
                .custom_tab_data {
                    .card_section {
                        .card_area {
                            .card_inner {
                                .bottom_area {
                                    .inner_area {
                                        .right_area {
                                            .dropdown {
                                                .dropdown-menu {
                                                    transform: translate(60px, 7px) !important;

                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .sorting_data {
                    padding-top: rem(40);
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .staff_detail_section {
        .staff_detail_area {
            margin: 0;

            .content_img_area {
                margin-top: rem(-25);
                margin-bottom: rem(10);

                .left_area {
                    width: rem(90);

                    .img_area {
                        height: rem(90);
                        width: rem(90);
                    }
                }

                .right_area {
                    width: calc(100% - 90px);
                    padding-left: rem(10);

                    h5 {
                        font-size: rem(25);
                    }

                    .text_box {
                        font-size: rem(14);
                    }
                }
            }

            .info_area {
                padding: rem(15);
                border-radius: rem(12);
                margin-bottom: rem(40);

                .circle_img {
                    width: rem(120);
                }

                .inner_info_area {
                    margin-bottom: rem(15);

                    &.space {
                        margin-bottom: 0;
                    }



                    .left_inner_area {
                        width: 42%;

                        p {
                            font-size: rem(16);

                            &::before {
                                font-size: rem(16);
                                margin-top: rem(-1);
                            }
                        }
                    }

                    .right_inner_area {
                        width: calc(100% - 42%);

                        p {
                            font-size: rem(16);
                        }
                    }
                }
            }

            .tab_details {
                .custom_tab_data {
                    .card_section {
                        .card_area {
                            .card_inner {
                                .bottom_area {
                                    .inner_area {
                                        .right_area {
                                            .dropdown {
                                                .dropdown-menu {
                                                    transform: translate(0, 7px) !important;

                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .sorting_data {
                    padding-top: rem(30);

                    .left_area {
                        width: rem(220);

                        p {
                            font-size: rem(18);
                        }
                    }

                    .right_area {
                        width: calc(100% - 220px);

                        .sort_button {
                            .btn-primary {
                                width: 100%;
                                font-size: rem(18);
                                height: rem(45);
                            }

                            .image_area {
                                height: rem(19);
                                padding-left: rem(10);
                            }
                        }
                    }
                }
            }

            .logs_tab_screen {
                padding: rem(30) 0;
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .staff_detail_section {
        .staff_detail_area {
            margin: 0;

            .content_img_area {
                margin-top: rem(-25);
                margin-bottom: rem(10);

                .left_area {
                    width: rem(90);

                    .img_area {
                        height: rem(90);
                        width: rem(90);
                    }
                }

                .right_area {
                    width: calc(100% - 90px);
                    padding-left: rem(10);

                    h5 {
                        font-size: rem(25);
                    }

                    .text_box {
                        font-size: rem(14);
                    }
                }
            }

            .info_area {
                padding: rem(15);
                border-radius: rem(12);
                margin-bottom: rem(40);

                .circle_img {
                    width: rem(120);
                }

                .inner_info_area {
                    margin-bottom: rem(15);

                    &.space {
                        margin-bottom: 0;
                    }



                    .left_inner_area {
                        width: 42%;

                        p {
                            font-size: rem(16);

                            &::before {
                                font-size: rem(16);
                                margin-top: rem(-1);
                            }
                        }
                    }

                    .right_inner_area {
                        width: calc(100% - 42%);

                        p {
                            font-size: rem(16);
                        }
                    }
                }
            }

            .tab_details {
                .custom_tab_data {
                    .card_section {
                        .card_area {
                            .card_inner {
                                .bottom_area {
                                    .inner_area {
                                        .right_area {
                                            .dropdown {
                                                .dropdown-menu {
                                                    transform: translate(0, 7px) !important;

                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .sorting_data {
                    padding-top: rem(30);

                    .left_area {
                        width: rem(220);

                        p {
                            font-size: rem(18);
                        }
                    }

                    .right_area {
                        width: calc(100% - 220px);

                        .sort_button {
                            .btn-primary {
                                width: 100%;
                                font-size: rem(18);
                                height: rem(45);
                            }

                            .image_area {
                                height: rem(19);
                                padding-left: rem(10);
                            }
                        }
                    }
                }
            }

            .logs_tab_screen {
                padding: rem(30) 0;
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}