.modal-dialog {
    &.modal-dialog-centered {
        width: 100%;
        max-width: rem(680);

        .modal-content {
            background: $card_color;
            border: rem(1) solid $grey;
            box-shadow: 0 rem(4) rem(25) rgba(55, 55, 128, 0.12);
            border-radius: 25px;

            .modal_area {
                .modal_data {
                    background: linear-gradient(141.32deg, $notification -16.17%, #A8DB18 94.82%);
                    box-shadow: 0 rem(4) rem(33) #A3D417;
                    border-radius: 0 0 rem(94.5) rem(94.5);
                    width: rem(189);
                    height: rem(199);
                    display: block;
                    margin: 0 auto;

                    .modal_img {
                        position: relative;

                        .img_one {
                            background: $notification;
                            width: rem(100);
                            height: rem(100);
                            display: flex;
                            border-radius: 50%;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            margin-top: rem(59);
                            margin-left: rem(43);
                        }
                    }
                }
            }

            .modal-body {
                padding-left: rem(30);
                padding-right: rem(30);

                .message {
                    text-align: center;

                    h3 {
                        font-weight: 600;
                        font-size: rem(24);
                        color: $black_2;
                        padding-top: rem(50);
                        padding-bottom: rem(50);
                    }

                    .card {
                        border: rem(1) solid $grey;
                        box-shadow: 0 rem(4) rem(25) rgba(55, 55, 128, 0.12);
                        border-radius: rem(25);
                        background: $card_color;

                        .card-body {
                            .card_data {
                                @include clearfix();

                                .left_area {
                                    width: rem(250);
                                    float: left;

                                    .details {
                                        padding-left: rem(20);

                                        h5 {
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                            font-weight: 600;
                                            font-size: rem(20);
                                            color: $black_2;
                                        }
                                    }
                                }

                                .right_area {
                                    width: calc(100% - 250px);
                                    float: right;

                                    p {
                                        font-weight: 400;
                                        font-size: rem(20);
                                        text-align: left;
                                        color: $black_2;
                                    }
                                }

                                li {
                                    padding: rem(10);
                                }
                            }
                        }
                    }
                }

                .modal_btn {
                    padding: rem(10) 0 rem(40) 0;
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .modal-dialog {
        &.modal-dialog-centered {
            max-width: rem(450);

            .modal-content {

                .modal_area {
                    .modal_data {
                        width: rem(160);
                        height: rem(160);

                        .modal_img {
                            .img_one {
                                width: rem(80);
                                height: rem(80);
                                margin-top: rem(55);
                                margin-left: rem(40);
                            }
                        }
                    }
                }

                .modal-body {
                    padding-left: rem(20);
                    padding-right: rem(20);

                    .message {
                        h3 {
                            font-size: rem(20);
                            padding-top: rem(25);
                            padding-bottom: rem(30);
                        }

                        .card {
                            .card-body {
                                .card_data {
                                    .left_area {
                                        width: rem(125);

                                        .details {
                                            padding-left: 0;

                                            h5 {
                                                font-size: rem(15);
                                            }

                                        }
                                    }

                                    li {
                                        padding: rem(7);
                                    }

                                    .right_area {
                                        width: calc(100% - 125px);

                                        p {
                                            font-size: rem(15);
                                        }
                                    }

                                }
                            }
                        }
                    }

                    .modal_btn {
                        padding: 0 0 rem(15) 0;

                        .btn_area {
                            .btn {
                                font-size: rem(19);
                            }
                        }
                    }
                }
            }
        }
    }

}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .modal-dialog {
        &.modal-dialog-centered {
            max-width: 96%;

            .modal-content {

                .modal_area {
                    .modal_data {
                        width: rem(160);
                        height: rem(160);

                        .modal_img {
                            .img_one {
                                width: rem(80);
                                height: rem(80);
                                margin-top: rem(55);
                                margin-left: rem(40);
                            }
                        }
                    }
                }

                .modal-body {
                    padding-left: rem(5);
                    padding-right: rem(5);

                    .message {
                        h3 {
                            font-size: rem(20);
                            padding-top: rem(25);
                            padding-bottom: rem(30);
                        }

                        .card {
                            .card-body {
                                .card_data {
                                    .left_area {
                                        width: rem(105);

                                        .details {
                                            padding-left: 0;

                                            h5 {
                                                font-size: rem(13);
                                            }

                                        }
                                    }

                                    li {
                                        padding: rem(5);
                                    }

                                    .right_area {
                                        width: calc(100% - 105px);

                                        p {
                                            font-size: rem(12);
                                        }
                                    }

                                }
                            }
                        }
                    }

                    .modal_btn {
                        padding: 0 0 rem(15) 0;

                        .btn_area {
                            .btn {
                                font-size: rem(15);
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .modal-dialog {
        &.modal-dialog-centered {
            // max-width: rem(345);
            max-width: 96%;

            .modal-content {

                .modal_area {
                    .modal_data {
                        width: rem(160);
                        height: rem(160);

                        .modal_img {
                            .img_one {
                                width: rem(80);
                                height: rem(80);
                                margin-top: rem(55);
                                margin-left: rem(40);
                            }
                        }
                    }
                }

                .modal-body {
                    padding-left: rem(5);
                    padding-right: rem(5);

                    .message {
                        h3 {
                            font-size: rem(20);
                            padding-top: rem(25);
                            padding-bottom: rem(30);
                        }

                        .card {
                            .card-body {
                                .card_data {
                                    .left_area {
                                        width: rem(105);

                                        .details {
                                            padding-left: 0;

                                            h5 {
                                                font-size: rem(13);
                                            }

                                        }
                                    }

                                    li {
                                        padding: rem(5);
                                    }

                                    .right_area {
                                        width: calc(100% - 105px);

                                        p {
                                            font-size: rem(12);
                                        }
                                    }

                                }
                            }
                        }
                    }

                    .modal_btn {
                        padding: 0 0 rem(15) 0;

                        .btn_area {
                            .btn {
                                font-size: rem(15);
                            }
                        }
                    }
                }
            }
        }
    }
}