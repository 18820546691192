.role_list_screen {
    .role_list_details {
        padding: rem(60) rem(30) rem(30);
        // padding: rem(90) rem(30) rem(30);
        position: relative;
        z-index: 111;

        .title_btn_area {
            display: flex;
            align-items: center;
            margin-bottom: rem(25);
            margin-top: rem(0);

            .left_area {
                float: left;
                width: 70%;

                p {
                    font-weight: 600;
                    font-size: rem(24);
                    color: $form_label_color;
                }
            }

            .right_area {
                float: right;
                width: calc(100% - 70%);

                .btn-primary {
                    float: right;
                    font-size: rem(24);
                    font-weight: 600;
                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                    border-radius: rem(41);
                    width: rem(153);
                    height: rem(61);
                    line-height: rem(45);
                    border: 0px solid transparent;

                    &:hover,
                    &:focus {
                        color: $white;
                        height: rem(61);
                        border: 0px solid transparent;
                        background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                    }
                }


            }
        }

        .search_section {
            @include clearfix();
            margin-bottom: rem(30);
            display: flex;
            align-items: center;
            position: relative;
            z-index: 2;

            .filter_button {
                .btn-primary {
                    float: right;
                    width: rem(124);
                    height: rem(55);
                    margin-left: rem(20);
                    border: rem(1) solid $form_label_color;
                    color: $form_label_color;
                    background: $white;
                    font-size: rem(22);
                    font-weight: 400;
                    border-radius: rem(10);
                }

                .image_area {
                    height: rem(22);
                    padding-right: rem(10);
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .search_area {
                width: calc(100% - 50px);
                float: left;

                .form-group {
                    position: relative;
                    margin-bottom: 0;

                    .form-control {
                        padding-left: rem(50);
                    }
                }

                .icon {
                    margin-top: 4px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    line-height: rem(55);
                    font-size: rem(20);
                    margin-left: rem(18);
                    color: $form_label_color;
                }
            }

            .pagination_list {
                padding-top: 30px;
                margin-left: 20px;

                .form-group {
                    .form-select {
                        width: 90px;
                        color: $designation;
                        font-size: rem(20);

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
            }

            .filter_area {
                width: rem(30);
                margin-left: rem(20);
                float: left;
                color: $form_label_color;
                border: rem(1) solid $form_label_color;
                padding: rem(5);

                .img_area {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

            }
        }

        .list_detail {
            background: #FFFFFF;
            border: rem(1) solid #E0E0E0;
            box-shadow: 0px 0px 8px rgba(17, 17, 17, 0.12);
            border-radius: rem(25);
            text-transform: capitalize;
            display: flex;
            align-items: center;
            @include clearfix();
            overflow: hidden;

            .list_data {
                width: 100%;
            }

            .table-responsive {
                min-height: rem(250);

                table {
                    width: 100%;
                    height: auto;
                    margin-bottom: 0px;
                    overflow: auto;

                    tr {
                        th {
                            border-bottom: rem(1) solid rgba(133, 133, 133, 0.8);
                            padding: rem(25) 0;
                            text-align: center;
                            color: $form_label_color;
                            font-weight: 600;
                            font-size: rem(21);


                            &.role_text {
                                text-align: center;
                                padding-left: rem(33);

                                &.hospital {
                                    min-width: rem(250) !important;
                                }
                            }

                            &:nth-child(1) {
                                min-width: rem(150);
                            }

                            &:nth-child(2) {
                                min-width: rem(160);
                            }

                            &:nth-child(3) {
                                min-width: rem(160);
                            }

                            &:nth-child(4) {
                                min-width: rem(160);
                            }

                        }

                        td {
                            text-align: center;
                            padding: rem(15) 0;
                            border-top: rem(1) solid rgba(133, 133, 133, 0.6);
                            font-weight: 400;
                            font-size: rem(20);
                            color: $designation;
                            padding-right: rem(10);
                            background-color: transparent;

                            &:first-child {
                                padding-left: rem(30);
                            }

                            &.test {
                                padding-left: 20px;
                                width: 360px !important;

                                .hospital_head {
                                    display: flex;
                                    align-items: center;
                                    column-gap: 10px;

                                    .test_bed {
                                        height: rem(50);
                                        width: rem(50);
                                        border-radius: 50%;
                                        margin: 0 auto;
                                        overflow: hidden;
                                        flex-shrink: 0;

                                        img {
                                            height: 100%;
                                            width: 100%;
                                            object-fit: cover;
                                        }

                                        .h_name {
                                            font-size: 16px;
                                            font-weight: 500;
                                            color: $designation;
                                        }
                                    }

                                }

                            }

                            &.text {
                                font-size: 16px;
                                font-weight: 500;
                                color: $designation;
                            }

                            .icon {
                                background: transparent;

                                .dropdown-menu {
                                    padding: 0;
                                    border-radius: rem(12) 0 rem(12) rem(12);
                                    background: $white;
                                    transform: translate(rem(70), rem(35)) !important;
                                    margin-bottom: 10px;

                                    .dropdown-item {
                                        padding: rem(8) rem(20);
                                        font-size: rem(16);
                                        font-weight: 400;
                                        color: $designation;
                                        padding-left: rem(45);
                                        position: relative;
                                        border-bottom: rem(1) solid #858585;

                                        &:last-child {
                                            border-bottom: none;
                                        }

                                        .image_area {
                                            padding-left: rem(8);
                                            overflow: hidden;

                                            img {
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                            }

                                            &.delete_icon {
                                                margin-top: rem(-4);
                                            }
                                        }

                                    }

                                }

                                .btn {
                                    color: $form_label_color;
                                    padding-top: 0;
                                    position: relative;
                                    top: rem(3);

                                    &:hover,
                                    &:focus {
                                        background-color: transparent;
                                    }
                                }

                                .ellipsis_icon {
                                    font-size: rem(22);
                                    cursor: pointer;
                                }

                                .dropdown-toggle::after {
                                    display: none !important;
                                }
                            }

                            h4 {
                                font-weight: 500;
                                font-size: rem(16);
                                color: $designation;
                                background-color: transparent;
                            }

                            .badge_yes {
                                padding: rem(5) rem(10);
                                background: #E1EDC0;
                                border-radius: rem(20);
                                width: rem(73);
                                height: rem(29);
                                display: block;
                                margin: 0 auto;

                                p {
                                    font-weight: 500;
                                    font-size: rem(14);
                                    color: $notification;
                                }

                            }

                            .badge_no {
                                padding: rem(5) rem(10);
                                background: rgba(233, 97, 97, 0.3);
                                border-radius: rem(20);
                                width: rem(73);
                                height: rem(29);
                                display: block;
                                margin: 0 auto;

                                p {
                                    font-weight: 500;
                                    font-size: rem(14);
                                    color: $fade_pink;
                                }
                            }
                        }
                    }

                    tr {
                        position: relative;

                        td {
                            padding: rem(23) 0;
                            background-color: transparent;

                            &::before {
                                content: "";
                                height: auto;
                                width: rem(8);
                                background-color: transparent;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                            }
                        }

                        &:hover {
                            td {
                                background: rgba(232, 232, 232, 0.21);

                                &::before {
                                    background-color: $dark_red_2;
                                }
                            }
                        }

                        &.active {
                            td {
                                background: rgba(232, 232, 232, 0.21);

                                &::before {
                                    background-color: $critical_color;
                                }
                            }
                        }
                    }
                }
            }

            .data_table {
                text-align: inherit;
            }

            .left_area {
                float: left;
                @include clearfix();

                .inner_left {
                    float: left;
                    width: rem(106);

                    .img_area {
                        width: rem(106);
                        height: rem(106);
                        border-radius: rem(100);
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .inner_right {
                    float: left;
                    width: calc(100% - 106px);
                    padding-left: rem(15);

                    .name {
                        h3 {
                            font-size: rem(36);
                            font-weight: 600;
                            background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }
                    }

                    .gender {
                        display: flex;
                        margin-top: rem(15);

                        p {
                            font-size: rem(16);
                            font-weight: 400;
                            color: $designation;

                            .gender_icon {
                                margin-right: rem(5);
                                color: #D81B23;
                            }

                            span {
                                margin-left: rem(30);
                                background: rgba(55, 55, 128, 0.2);
                                border-radius: rem(5);
                                color: #373780;
                                padding: rem(3) rem(11);
                            }
                        }
                    }

                    .date {
                        display: flex;
                        margin-top: rem(15);

                        .date_icon {
                            margin-right: rem(5);
                            color: #D81B23;
                        }

                        p {
                            color: $designation;
                            font-size: rem(16);
                            font-weight: 400;
                        }
                    }
                }
            }

            .right_area {
                float: right;
                width: calc(100% - 500px);
                margin-top: rem(20);

                .id_area {
                    float: right;
                    background: rgba(154, 154, 154, 0.2);
                    border-radius: rem(5);
                    padding: rem(2) rem(16);
                    margin-bottom: rem(10);

                    p {
                        font-weight: 400;
                        font-size: rem(14);
                        line-height: rem(17);
                        color: #666666;
                    }
                }

                .batch {
                    float: right;
                    background: $treatment_color;
                    width: rem(160);
                    height: rem(43);
                    line-height: rem(43);
                    border-radius: rem(22);

                    &:hover {
                        background: darken($treatment_color, 4%);
                    }

                    p {
                        font-size: rem(16);
                        font-weight: 600;
                        color: $white;
                        text-align: center;
                    }
                }
            }
        }
    }
}


/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {
    .role_list_screen {
        .role_list_details {
            .list_detail {
                .table-responsive {
                    table {
                        tr {
                            td {
                                .icon {
                                    .dropdown-menu {
                                        transform: translate(0px, 30px) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {
    .role_list_screen {
        .role_list_details {
            .list_detail {
                .table-responsive {
                    table {
                        tr {
                            td {
                                .icon {
                                    .dropdown-menu {
                                        transform: translate(-130px, 30px) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (min-width: 1024px) and (max-width: 1199px) {
    .role_list_screen {
        .role_list_details {
            .list_detail {
                .table-responsive {
                    table {
                        tr {
                            th {
                                padding: rem(20) 0;
                            }

                            td {
                                .icon {
                                    .dropdown-menu {
                                        transform: translate(-110px, 30px) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .role_list_screen {
        .role_list_details {
            padding: 0 rem(20) rem(20);
            position: relative;
            z-index: 111;

            .title_btn_area {
                display: flex;
                align-items: center;
                margin-bottom: rem(40);
                margin-top: rem(-40);

                .left_area {
                    float: left;
                    width: 70%;

                    p {
                        font-weight: 600;
                        font-size: rem(24);
                        color: $form_label_color;
                    }
                }

                .right_area {
                    float: right;
                    width: calc(100% - 70%);

                    .btn-primary {
                        float: right;
                        font-size: rem(24);
                        font-weight: 600;
                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                        border-radius: rem(41);
                        width: rem(153);
                        height: rem(61);
                        line-height: rem(45);
                        border: 0px solid transparent;

                        &:hover,
                        &:focus {
                            color: $white;
                            height: rem(61);
                            border: 0px solid transparent;
                            background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                        }
                    }


                }
            }

            .search_section {
                @include clearfix();
                margin-bottom: rem(45);
                display: flex;
                align-items: center;
                position: relative;
                z-index: 2;

                .filter_button {
                    .btn-primary {
                        float: right;
                        width: rem(124);
                        height: rem(55);
                        margin-left: rem(20);
                        border: rem(1) solid $form_label_color;
                        color: $form_label_color;
                        background: $white;
                        font-size: rem(22);
                        font-weight: 400;
                        border-radius: rem(10);
                    }

                    .image_area {
                        height: rem(22);
                        padding-right: rem(10);
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .search_area {
                    width: calc(100% - 50px);
                    // width: 100%;
                    float: left;

                    .form-group {
                        position: relative;
                        margin-bottom: 0;

                        .form-control {
                            // padding-right: rem(50);
                            padding-left: rem(50);

                        }
                    }

                    .icon {
                        margin-top: 4px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        line-height: rem(55);
                        font-size: rem(20);
                        margin-left: rem(18);
                        color: $form_label_color;
                    }
                }

                .pagination_list {
                    padding-top: 30px;
                    margin-left: 20px;

                    .form-group {
                        .form-select {
                            width: 90px;
                            color: $designation;
                            font-size: rem(20);

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }

                .filter_area {
                    width: rem(30);
                    margin-left: rem(20);
                    float: left;
                    color: $form_label_color;
                    // display: flex;
                    border: rem(1) solid $form_label_color;
                    padding: rem(5);

                    .img_area {
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                }
            }

            .list_detail {
                background: #FFFFFF;
                border: rem(1) solid #E0E0E0;
                box-shadow: 0px 0px 8px rgba(17, 17, 17, 0.12);
                border-radius: rem(25);
                text-transform: capitalize;
                display: flex;
                align-items: center;
                @include clearfix();
                overflow: hidden;

                .list_data {
                    width: 100%;
                    // display: flex;
                    // justify-content: center;
                    // align-items: center;
                    min-height: rem(290);
                }

                .table-responsive {
                    table {
                        width: 100%;
                        height: auto;
                        margin-bottom: 0px;
                        overflow: hidden;
                        // max-height: rem(250);

                        tr {
                            // &:active {
                            //     background: rgba(232, 232, 232, 0.21);
                            //     border-left: rem(6) solid #FF0000;
                            // }

                            th {
                                border-bottom: rem(1) solid rgba(133, 133, 133, 0.8);
                                padding: rem(15) 0;
                                text-align: center;
                                color: $form_label_color;
                                font-weight: 600;
                                font-size: rem(22);


                                &.role_text {
                                    text-align: start;
                                    padding-left: rem(33);

                                    &.hospital {
                                        min-width: rem(250) !important;
                                    }
                                }

                                &:nth-child(1) {
                                    min-width: rem(150);
                                }

                                &:nth-child(2) {
                                    min-width: rem(160);
                                }

                                &:nth-child(3) {
                                    min-width: rem(160);
                                }

                                &:nth-child(4) {
                                    min-width: rem(160);
                                }

                            }

                            td {
                                text-align: center;
                                padding: rem(15) 0;
                                border-top: rem(1) solid rgba(133, 133, 133, 0.6);
                                font-weight: 400;
                                font-size: rem(20);
                                color: $designation;
                                padding-right: rem(10);

                                &:first-child {
                                    padding-left: rem(30);
                                }

                                &.test {
                                    padding-left: 20px;
                                    width: auto !important;

                                    .hospital_head {
                                        display: flex;
                                        align-items: center;

                                        .test_bed {
                                            height: rem(45);
                                            width: rem(45);
                                            border-radius: 50%;
                                            margin: 0 auto;
                                            overflow: hidden;

                                            img {
                                                height: 100%;
                                                width: 100%;
                                                object-fit: cover;
                                            }

                                            .h_name {
                                                font-size: 16px;
                                                font-weight: 500;
                                                color: $designation;
                                            }
                                        }

                                    }

                                }



                                &.text {
                                    font-size: 16px;
                                    font-weight: 500;
                                    color: $designation;
                                }

                                .icon {

                                    .dropdown-menu {
                                        padding: 0;
                                        border-radius: rem(12) 0 rem(12) rem(12);
                                        background: $white;
                                        transform: translate(rem(-80), rem(30)) !important;
                                        margin-bottom: 10px;

                                        .dropdown-item {
                                            padding: rem(8) rem(20);
                                            font-size: rem(16);
                                            font-weight: 400;
                                            color: $designation;
                                            padding-left: rem(45);
                                            position: relative;
                                            border-bottom: rem(1) solid #858585;

                                            &:last-child {
                                                border-bottom: none;
                                            }

                                            .image_area {
                                                padding-left: rem(8);
                                                overflow: hidden;

                                                img {
                                                    width: 100%;
                                                    height: 100%;
                                                    object-fit: cover;
                                                }

                                                &.delete_icon {
                                                    margin-top: rem(-4);
                                                }
                                            }

                                        }

                                    }

                                    .btn {
                                        color: $form_label_color;
                                        padding-top: 0;
                                        position: relative;
                                        top: rem(3);

                                        &:hover,
                                        &:focus {
                                            // background-color: $white;
                                            background-color: transparent;
                                        }
                                    }

                                    .ellipsis_icon {
                                        font-size: rem(22);
                                        cursor: pointer;
                                    }

                                    .dropdown-toggle::after {
                                        display: none !important;
                                    }
                                }

                                h4 {
                                    font-weight: 500;
                                    font-size: rem(16);
                                    color: $designation;
                                    // margin-left: rem(25);

                                }

                                .badge_yes {
                                    padding: rem(5) rem(10);
                                    background: #E1EDC0;
                                    border-radius: rem(20);
                                    width: rem(73);
                                    height: rem(29);
                                    display: block;
                                    margin: 0 auto;

                                    p {
                                        font-weight: 500;
                                        font-size: rem(14);
                                        color: $notification;
                                    }

                                }

                                .badge_no {
                                    padding: rem(5) rem(10);
                                    background: rgba(233, 97, 97, 0.3);
                                    border-radius: rem(20);
                                    width: rem(73);
                                    height: rem(29);
                                    display: block;
                                    margin: 0 auto;

                                    p {
                                        font-weight: 500;
                                        font-size: rem(14);
                                        color: $fade_pink;
                                    }
                                }



                                // .left_area{
                                //     padding: 0px;
                                //     margin-left: rem(20);
                                //     .img_area{
                                //         width: rem(50);
                                //         height: rem(50);
                                //         border-radius: rem(50);
                                //         margin-top: rem(10);
                                //         Image {
                                //             width: 100%;
                                //             height: 100%;
                                //             object-fit: cover;
                                //         }
                                //     }  
                                // }
                                // .right_areaa{
                                //     width: 100%;
                                //     h4{
                                //         font-weight: 500;
                                //         font-size: rem(16);
                                //         color: $designation;
                                //     }
                                //     p{
                                //         margin-top: rem(5);
                                //         font-weight: 500;
                                //         font-size: rem(14);
                                //         span {
                                //             // margin-left: rem(30);
                                //             background: rgba(55, 55, 128, 0.2);
                                //             border-radius: rem(5);
                                //             color: #1C709E;
                                //             background: #DDF0FB;
                                //             padding: rem(3) rem(11);
                                //         }

                                //     }
                                // }

                            }
                        }

                        tr {
                            position: relative;

                            td {
                                padding: rem(23) 0;

                                &::before {
                                    content: "";
                                    height: auto;
                                    width: rem(8);
                                    background-color: transparent;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                }
                            }

                            &:hover {
                                td {
                                    background: rgba(232, 232, 232, 0.21);

                                    &::before {
                                        background-color: $dark_red_2;
                                    }
                                }
                            }

                            &.active {
                                td {
                                    background: rgba(232, 232, 232, 0.21);

                                    &::before {
                                        background-color: $critical_color;
                                    }
                                }
                            }
                        }
                    }
                }

                // .active{
                //     background: rgba(232, 232, 232, 0.21);
                //     border-left: rem(6) solid #FF0000;

                // }
                .data_table {
                    text-align: inherit;
                }

                .left_area {
                    float: left;
                    // width: rem(500);
                    @include clearfix();

                    .inner_left {
                        float: left;
                        width: rem(106);

                        .img_area {
                            width: rem(106);
                            height: rem(106);
                            border-radius: rem(100);
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .inner_right {
                        float: left;
                        width: calc(100% - 106px);
                        padding-left: rem(15);

                        .name {

                            // padding-top: rem(25);
                            h3 {
                                font-size: rem(36);
                                font-weight: 600;
                                background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }
                        }

                        .gender {
                            display: flex;
                            margin-top: rem(15);

                            p {
                                font-size: rem(16);
                                font-weight: 400;
                                color: $designation;

                                .gender_icon {
                                    margin-right: rem(5);
                                    color: #D81B23;
                                }

                                span {
                                    margin-left: rem(30);
                                    background: rgba(55, 55, 128, 0.2);
                                    border-radius: rem(5);
                                    color: #373780;
                                    padding: rem(3) rem(11);
                                }
                            }
                        }

                        .date {
                            display: flex;
                            margin-top: rem(15);

                            .date_icon {
                                margin-right: rem(5);
                                color: #D81B23;
                            }

                            p {
                                color: $designation;
                                font-size: rem(16);
                                font-weight: 400;
                            }
                        }
                    }
                }

                .right_area {
                    float: right;
                    width: calc(100% - 500px);
                    margin-top: rem(20);

                    .id_area {
                        float: right;
                        background: rgba(154, 154, 154, 0.2);
                        border-radius: rem(5);
                        padding: rem(2) rem(16);
                        margin-bottom: rem(10);

                        p {
                            font-weight: 400;
                            font-size: rem(14);
                            line-height: rem(17);
                            color: #666666;
                        }
                    }

                    .batch {
                        float: right;
                        background: $treatment_color;
                        width: rem(160);
                        height: rem(43);
                        line-height: rem(43);
                        border-radius: rem(22);

                        &:hover {
                            background: darken($treatment_color, 4%);
                        }

                        p {
                            font-size: rem(16);
                            font-weight: 600;
                            color: $white;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .role_list_screen {
        .role_list_details {
            padding: 0 rem(20) rem(20);

            .title_btn_area {
                margin-bottom: 20px;
                margin-top: rem(-40);
            }

            .search_section {
                margin-bottom: rem(10);
            }

            .list_detail {
                .table-responsive {
                    table {
                        tr {
                            th {
                                padding: rem(15) 0;
                            }

                            td {
                                .icon {
                                    .dropdown-menu {
                                        transform: translate(-70px, 30px) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }

}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .role_list_screen {
        .role_list_details {
            padding: 0;

            .title_btn_area {
                margin-bottom: 20px;
                margin-top: rem(-40);

                .left_area {
                    width: 62%;

                    p {
                        font-size: 20px;
                    }
                }

                .right_area {
                    width: calc(100% - 62%);

                    .btn-primary {
                        font-size: 20px;
                        width: 130px;
                        height: 45px;
                        line-height: 32px;
                    }
                }
            }

            .search_section {
                display: block;
                margin-bottom: 20px;

                .search_area {
                    float: unset;
                    width: 100%;

                    .icon {
                        margin-top: -2px;
                    }
                }

                .pagination_list {
                    padding-top: 10px;
                    margin-left: 0;
                }
            }

            .list_detail {
                border-radius: 15px;

                .table-responsive {
                    table {
                        tr {
                            th {
                                font-size: 20px;
                            }

                            td {
                                .icon {
                                    .dropdown-menu {
                                        transform: translate(-75px, 30px) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .role_list_screen {
        .role_list_details {
            padding: 0;

            .title_btn_area {
                margin-bottom: 20px;
                margin-top: rem(-40);

                .left_area {
                    width: 62%;

                    p {
                        font-size: 20px;
                    }
                }

                .right_area {
                    width: calc(100% - 62%);

                    .btn-primary {
                        font-size: 20px;
                        width: 130px;
                        height: 45px;
                        line-height: 32px;
                    }
                }
            }

            .search_section {
                display: block;
                margin-bottom: 20px;

                .search_area {
                    float: unset;
                    width: 100%;

                    .icon {
                        margin-top: -2px;
                    }
                }

                .pagination_list {
                    padding-top: 10px;
                    margin-left: 0;
                }
            }

            .list_detail {
                border-radius: 15px;

                .table-responsive {
                    table {
                        tr {
                            th {
                                font-size: 20px;
                            }

                            td {
                                .icon {
                                    .dropdown-menu {
                                        transform: translate(-75px, 30px) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}