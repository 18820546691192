.patient_details_screen {
    .patient_details {
        padding: rem(130) rem(20) rem(20) rem(20);
        margin-top: rem(-35);

        .main_detail {
            text-transform: capitalize;
            display: flex;
            align-items: center;
            @include clearfix();
            position: relative;
            z-index: 1;

            .left_area {
                float: left;
                width: rem(600);
                @include clearfix();

                .inner_left {
                    float: left;
                    width: rem(106);

                    .img_area {
                        width: rem(106);
                        height: rem(106);
                        border-radius: rem(100);
                        overflow: hidden;
                        cursor: pointer;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .inner_right {
                    float: left;
                    width: calc(100% - 106px);
                    padding-left: rem(15);

                    .name {
                        display: flex;
                        align-items: center;

                        h3 {
                            font-size: rem(36);
                            font-weight: 600;
                            background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            cursor: pointer;
                        }

                        .img_icon {
                            width: rem(28);
                            margin-left: rem(10);
                            cursor: pointer;
                            // height: rem(23);

                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .gender {
                        display: flex;
                        margin-top: rem(15);
                        align-items: flex-start;

                        p {
                            font-size: rem(16);
                            font-weight: 400;
                            color: $designation;
                            width: auto;
                            display: contents;

                            .gender_icon {
                                margin-right: rem(5);
                                color: #D81B23;
                            }

                            &.general {
                                color: #00954D;
                                margin-left: 15px;
                                display: flex;
                                align-items: start;
                                gap: 4px;
                                white-space: nowrap;

                                &.emergency {
                                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    background-clip: text;
                                    text-fill-color: transparent
                                }

                                .general_img {
                                    position: relative;
                                    top: rem(4);
                                }
                            }

                            span {
                                // margin-left: rem(15);
                                background: rgba(55, 55, 128, 0.2);
                                border-radius: rem(5);
                                color: #373780;
                                padding: rem(3) rem(11);
                                display: inline-block;
                            }
                        }
                    }

                    .aged {
                        display: flex;
                        margin-top: rem(13);

                        .date_icon {
                            margin-right: rem(5);
                            color: #D81B23;
                        }

                        p {
                            color: $designation;
                            font-size: rem(16);
                            font-weight: 400;
                            width: auto;
                            display: contents;

                            &.bed_space {
                                white-space: nowrap;
                            }
                        }
                    }

                    .date {
                        display: flex;
                        margin-top: rem(15);

                        .date_icon {
                            margin-right: rem(5);
                            color: #D81B23;
                        }

                        p {
                            color: $designation;
                            font-size: rem(16);
                            font-weight: 400;
                            width: auto;
                            display: contents;

                            &.bed_space {
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }

            .right_area {
                float: right;
                width: calc(100% - 600px);
                margin-top: rem(20);
                display: flex;
                flex-direction: column;
                align-items: end;

                .id_area {
                    float: right;
                    background: rgba(154, 154, 154, 0.2);
                    border-radius: rem(5);
                    padding: rem(2) rem(16);
                    margin-bottom: rem(10);

                    p {
                        font-weight: 400;
                        font-size: rem(14);
                        line-height: rem(17);
                        color: #666666;
                    }
                }

                .batch {
                    float: right;
                    background: $treatment_color;
                    width: rem(160);
                    height: rem(43);
                    line-height: rem(43);
                    border-radius: rem(22);

                    &:hover {
                        background: darken($treatment_color, 4%);
                    }

                    p {
                        font-size: rem(16);
                        font-weight: 600;
                        color: $white;
                        text-align: center;
                    }
                }

                .dropdown {
                    float: right;

                    .dropdown-toggle {
                        padding: 0;

                        &::after {
                            display: none;
                        }

                        &:focus,
                        &:hover {
                            background-color: transparent;
                        }

                        .batch {
                            float: right;
                            width: rem(160);
                            height: rem(43);
                            line-height: rem(43);
                            border-radius: rem(22);

                            &.orange_bg {
                                background: $treatment_color;

                                &:hover {
                                    background: darken($treatment_color, 4%);
                                }
                            }

                            &.danger {
                                background: $critical_color;

                                &:hover {
                                    background: darken($critical_color, 4%);
                                }
                            }

                            &.success {
                                background: $green;

                                &:hover {
                                    background: darken($green, 4%);
                                }
                            }

                            &.discharges {
                                background: #FFEDAD;

                                &:hover {
                                    background: darken(#FFEDAD, 4%);
                                }
                            }

                            p {
                                font-size: rem(16);
                                font-weight: 600;
                                color: $white;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }

        .health_area {
            background: $card_color;
            border: rem(1) solid $grey;
            border-radius: rem(25);
            padding: rem(25);
            margin-top: rem(30);

            .title_area {
                @include clearfix();

                h5 {
                    float: left;
                    font-weight: 600;
                    font-size: rem(22);
                    color: $form_label_color;
                }

                p {
                    float: right;
                    font-weight: 400;
                    font-size: rem(16);
                    color: $designation;
                }
            }

            .cards_area {
                .card_inner_area {
                    background: $white;
                    border: rem(1) solid $border_color;
                    box-shadow: 0 rem(1) rem(50) rgba(0, 0, 0, 0.08);
                    border-radius: rem(12);
                    padding: rem(16);
                    margin-top: rem(30);

                    .sub_area {
                        display: flex;
                        align-items: center;
                        @include clearfix();

                        .left_area {
                            float: left;
                            height: rem(34);
                            width: rem(34);
                            line-height: rem(32);
                            text-align: center;
                            background: #D0FBFF;
                            border-radius: rem(6);

                            &.heart {
                                background: rgba(233, 97, 97, 0.1);
                            }

                            &.blood {
                                background: #F8DEBD;
                            }

                            &.oxygen {
                                background: #DDEAF6;
                            }

                            &.sugar {
                                background: #EAFFBD;
                            }

                            .img_area {
                                max-width: 100%;

                                Image {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                        }

                        .right_area {
                            float: left;
                            width: calc(100% - 34px);
                            margin-left: rem(12);

                            h5 {
                                font-weight: 600;
                                font-size: rem(16);
                            }
                        }
                    }

                    .bottom_area {
                        margin-top: rem(12);
                        display: flex;


                        span {
                            font-weight: 400;
                            font-size: rem(20);
                            color: $black;
                            padding-right: rem(2);

                            &.circle {
                                margin-right: rem(4);

                                &:after {
                                    content: '';
                                    position: absolute;
                                    border-radius: rem(50);
                                    border: rem(1) solid $text_color;
                                    padding: rem(1);
                                    margin-top: rem(5);
                                }
                            }

                            &.oxygen {

                                margin-left: rem(20);
                            }
                        }

                        p {
                            font-weight: 500;
                            font-size: rem(14);
                            color: $text_color;
                        }
                    }
                }
            }

            .last_update {
                font-size: rem(16);
                font-weight: 400;
                color: $designation;
                text-decoration: underline;
                margin-top: rem(20);
                display: flex;
                justify-content: end;
            }

            .update_area {
                @include clearfix();
                margin-top: rem(20);

                .update_info {
                    float: left;
                    padding-top: rem(15);

                    p {
                        color: $card_text_color;
                        font-weight: 400;
                        font-size: rem(16);

                        a {
                            color: #D81B23;

                        }
                    }

                }

                .button_area {
                    float: right;

                    .btn {
                        float: right;
                        font-size: rem(16);
                        font-weight: 600;
                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                        width: rem(216);
                        height: rem(50);
                        border: 0px solid transparent;

                        &:hover,
                        &:focus {
                            color: $white;
                            height: rem(50);
                            border: 0px solid transparent;
                            background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                            opacity: unset;
                        }
                    }
                }

                &.center_area {
                    margin-top: 0;

                    .button_area {
                        float: unset;
                        text-align: center;

                        .btn {
                            float: unset;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .accordin_area {
            margin-top: rem(30);
            overflow: hidden;

            .accordion {
                --bs-accordion-border-width: 1px;
                --bs-accordion-border-radius: 1.5rem;
                --bs-accordion-inner-border-radius: 42px;
                --bs-accordion-btn-color: #140745;
                --bs-accordion-btn-focus-border-color: grey;
                --bs-accordion-btn-focus-box-shadow: grey;
                --bs-accordion-btn-padding-y: 1.25rem;
                --bs-accordion-btn-icon-width: 1.75rem;
                --bs-accordion-active-color: #140745;
                --bs-accordion-active-bg: #F9F9F9;
                --bs-accordion-bg: #F9F9F9;
                // overflow: hidden;
            }

            .accordion-header {
                // &::after {
                //     border-bottom: 1px solid #C4C4C4;
                //     content: "";
                //     display: block;
                //     width: 94%;
                //     margin-left: 20px;
                // }
            }

            .accordion-button:not(.collapsed)>.accordion-header::after {
                display: none;
            }

            .accordion-button {
                font-size: rem(22);
                font-weight: 600;
                // padding: 5px 0;
            }

            .accordion-button:not(.collapsed) {
                box-shadow: none;

                &::after {
                    background-image: var(--bs-accordion-btn-icon);
                }
            }

            .accordion-body {
                // border-top: rem(1) solid #E7E7E7;
                font-size: rem(16);
                color: $black;
                position: relative;

                // background: $accordin_body;
                // overflow: hidden;
                &::after {
                    border-top: 1px solid #C4C4C4;
                    content: "";
                    display: block;
                    width: 98%;
                    // margin-left: 20px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                .title {
                    h5 {
                        font-weight: 600;
                        font-size: rem(20);
                        color: $form_label_color;
                        margin-bottom: rem(25);
                    }
                }

                .inner_area {


                    h5 {
                        font-weight: 400;
                        font-size: rem(20);
                        line-height: rem(24);
                        color: $form_border_color;
                        margin-top: rem(15);
                    }

                    .category {
                        display: inline-block;
                        padding: rem(4) rem(10);
                        background: #FFD3D3;
                        border-radius: rem(3);
                        margin-right: rem(11);
                        margin-top: rem(15);

                        p {
                            font-weight: 400;
                            font-size: rem(16);
                            color: #FF4B4B;
                        }

                        &.color {
                            background: #FFF5D3;

                            p {
                                color: #B89E17;
                            }
                        }
                    }

                    .description {
                        margin-top: rem(28);

                        p {
                            font-weight: 400;
                            font-size: rem(16);
                            line-height: rem(26);
                            color: $black_2;
                        }
                    }



                    .btn_active_area {
                        margin-top: rem(20);
                        display: flex;
                        justify-content: space-between;

                        p {
                            a {
                                color: #D81B23;
                            }

                            color: #989898;
                            font-weight: 400;
                            font-size: 16px;
                            padding-top: rem(10);
                            padding-bottom: rem(10);
                        }

                        a {
                            font-size: rem(14);
                            font-weight: 500;
                            padding: rem(10);
                            border-radius: rem(40);

                            &.active {
                                background: rgba(120, 182, 18, 0.18);
                                color: $green;
                            }

                            &.inactive {
                                background-color: $inactive_bg;
                                color: $inactive_txt;
                            }
                        }
                    }
                }

            }



        }

        .btn_area {
            float: right;
            margin-bottom: rem(20);

            .submitBtn {
                float: right;
                font-size: rem(16);
                font-weight: 600;
                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                width: rem(216);
                height: rem(50);
                border: 0px solid transparent;

                &:hover,
                &:focus {
                    color: $white;
                    height: rem(50);
                    border: 0px solid transparent;
                    background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                    opacity: unset;
                }
            }
        }


        .task_btn_area {
            @include clearfix();
            align-items: center;
            border-top: rem(1) solid $grey;
            padding: rem(10);
            margin-top: rem(30);

            .main_area {
                position: relative;

                .btn_area {
                    width: 25%;

                    .btn {
                        font-size: rem(20);
                        font-weight: 600;
                        width: 100%;
                        height: rem(45active_btn);
                        margin-top: 0;
                    }
                }

                .btn-primary {
                    border-radius: rem(10);
                }

                .report_area {
                    width: calc(100% - 25%);
                    float: right;

                    p {
                        font-weight: 600;
                        font-size: rem(24);
                        line-height: rem(29);
                        color: $card_text_color;
                        margin-top: rem(-38);
                        margin-left: rem(40);
                    }
                }
            }
        }
    }

    .tab_details {
        padding: rem(20) rem(22) rem(60) rem(22);

        .sorting_data {
            @include clearfix();

            .left_area {
                float: left;
                width: rem(250);
                padding-top: rem(8);

                p {
                    font-weight: 600;
                    font-size: rem(24);
                    color: $designation;
                }
            }

            .right_area {
                float: right;
                width: calc(100% - 250px);

                .sort_button {
                    .btn-primary {
                        float: right;
                        border: rem(2) solid $designation;
                        color: $designation;
                        width: 10%;
                        background: $white;
                        font-size: rem(20);
                    }

                    .image_area {
                        height: rem(22);
                        padding-left: rem(15);
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }

    .custom_tab_data {
        position: relative;

        .card_section {
            .card_area {
                .mytask_area {
                    display: none;
                }

                .card_inner {
                    .card_inner_detail {
                        .sub_title {
                            .task_right_area {
                                display: none;

                                &.pending {
                                    display: none;
                                }
                            }
                        }
                    }

                    .bottom_area {
                        .inner_area {
                            @include clearfix();
                            display: flex;
                            align-items: center;

                            .right_area {
                                padding-left: rem(10);

                                .name_area {
                                    margin-left: 0;

                                    .ellipsis_icon {
                                        margin-right: 0;
                                        margin-top: rem(-27);
                                    }

                                    .dropdown {
                                        .dropdown-menu.show {
                                            transform: translate(210px, 10px) !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {
    .patient_details_screen {
        .patient_details {
            .accordin_area {
                .accordion-body {
                    &::after {
                        width: 97%;
                    }
                }
            }
        }

        .custom_tab_data {

            .card_section {
                .card_area {
                    .card_inner {
                        .bottom_area {
                            .inner_area {
                                .right_area {
                                    .name_area {
                                        .dropdown {
                                            .dropdown-menu.show {
                                                transform: translate(75px, 7px) !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {
    .patient_details_screen {
        .patient_details {
            .accordin_area {
                .accordion-body {
                    &::after {
                        width: 97%;
                    }
                }
            }
        }

        .custom_tab_data {

            .card_section {
                .card_area {
                    .card_inner {
                        .bottom_area {
                            .inner_area {
                                .right_area {
                                    .name_area {
                                        .dropdown {
                                            .dropdown-menu.show {
                                                transform: translate(55px, 7px) !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .tab_details {
            .sorting_data {
                .right_area {
                    .sort_button {
                        .btn-primary {
                            width: 13%;
                        }
                    }
                }
            }
        }
    }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    .patient_details_screen {
        .patient_details {
            .accordin_area {
                .accordion-body {
                    &::after {
                        width: 96%;
                    }
                }
            }
        }

        .custom_tab_data {

            .card_section {
                .card_area {
                    .card_inner {
                        .bottom_area {
                            .inner_area {
                                .right_area {
                                    .name_area {
                                        .dropdown {
                                            .dropdown-menu.show {
                                                transform: translate(0, 7px) !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .tab_details {
            .sorting_data {
                .right_area {
                    .sort_button {
                        .btn-primary {
                            width: 18%;
                        }
                    }
                }
            }
        }
    }
}

/* Landscape tablets and ipad */
@media (min-width: 768px) and (max-width: 991px) {
    .patient_details_screen {
        .patient_details {
            padding: 0 rem(20) rem(20) rem(20);
            margin-top: rem(-35);

            .main_detail {
                text-transform: capitalize;
                display: flex;
                align-items: center;
                @include clearfix();
                position: relative;
                z-index: 1;

                .left_area {
                    float: left;
                    width: rem(500);
                    @include clearfix();

                    .inner_left {
                        float: left;
                        width: rem(106);

                        .img_area {
                            width: rem(106);
                            height: rem(106);
                            border-radius: rem(100);
                            overflow: hidden;
                            cursor: pointer;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .inner_right {
                        float: left;
                        width: calc(100% - 106px);
                        padding-left: rem(15);

                        .name {
                            display: flex;
                            align-items: center;

                            h3 {
                                font-size: rem(36);
                                font-weight: 600;
                                background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                                cursor: pointer;
                            }

                            .img_icon {
                                width: rem(28);
                                margin-left: rem(10);
                                cursor: pointer;
                                // height: rem(23);

                                img {
                                    height: 100%;
                                    width: 100%;
                                    object-fit: cover;
                                }
                            }
                        }

                        .gender {
                            display: flex;
                            margin-top: rem(15);
                            align-items: flex-start;

                            p {
                                font-size: rem(16);
                                font-weight: 400;
                                color: $designation;
                                width: auto;
                                display: contents;

                                .gender_icon {
                                    margin-right: rem(5);
                                    color: #D81B23;
                                }

                                &.general {
                                    color: #00954D;
                                    margin-left: 15px;
                                    display: flex;
                                    align-items: start;
                                    gap: 4px;
                                    white-space: nowrap;

                                    &.emergency {
                                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                        background-clip: text;
                                        text-fill-color: transparent
                                    }

                                    .general_img {
                                        position: relative;
                                        top: rem(4);
                                    }
                                }

                                span {
                                    // margin-left: rem(15);
                                    background: rgba(55, 55, 128, 0.2);
                                    border-radius: rem(5);
                                    color: #373780;
                                    padding: rem(3) rem(11);
                                    display: inline-block;
                                }
                            }
                        }

                        .aged {
                            display: flex;
                            margin-top: rem(13);

                            .date_icon {
                                margin-right: rem(5);
                                color: #D81B23;
                            }

                            p {
                                color: $designation;
                                font-size: rem(16);
                                font-weight: 400;
                                width: auto;
                                display: contents;

                                &.bed_space {
                                    white-space: nowrap;
                                }
                            }
                        }

                        .date {
                            display: flex;
                            margin-top: rem(15);

                            .date_icon {
                                margin-right: rem(5);
                                color: #D81B23;
                            }

                            p {
                                color: $designation;
                                font-size: rem(16);
                                font-weight: 400;
                                width: auto;
                                display: contents;

                                &.bed_space {
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }

                .right_area {
                    float: right;
                    width: calc(100% - 500px);
                    margin-top: rem(20);

                    .id_area {
                        float: right;
                        background: rgba(154, 154, 154, 0.2);
                        border-radius: rem(5);
                        padding: rem(2) rem(16);
                        margin-bottom: rem(10);

                        p {
                            font-weight: 400;
                            font-size: rem(14);
                            line-height: rem(17);
                            color: #666666;
                        }
                    }

                    .batch {
                        float: right;
                        background: $treatment_color;
                        width: rem(160);
                        height: rem(43);
                        line-height: rem(43);
                        border-radius: rem(22);

                        &:hover {
                            background: darken($treatment_color, 4%);
                        }

                        p {
                            font-size: rem(16);
                            font-weight: 600;
                            color: $white;
                            text-align: center;
                        }
                    }

                    .dropdown {
                        float: right;

                        .dropdown-toggle {
                            padding: 0;

                            &::after {
                                display: none;
                            }

                            &:focus,
                            &:hover {
                                background-color: transparent;
                            }

                            .batch {
                                float: right;
                                width: rem(160);
                                height: rem(43);
                                line-height: rem(43);
                                border-radius: rem(22);

                                &.orange_bg {
                                    background: $treatment_color;

                                    &:hover {
                                        background: darken($treatment_color, 4%);
                                    }
                                }

                                &.danger {
                                    background: $critical_color;

                                    &:hover {
                                        background: darken($critical_color, 4%);
                                    }
                                }

                                &.success {
                                    background: $green;

                                    &:hover {
                                        background: darken($green, 4%);
                                    }
                                }

                                &.discharges {
                                    background: #FFEDAD;

                                    &:hover {
                                        background: darken(#FFEDAD, 4%);
                                    }
                                }

                                p {
                                    font-size: rem(16);
                                    font-weight: 600;
                                    color: $white;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }

            .health_area {
                background: $card_color;
                border: rem(1) solid $grey;
                border-radius: rem(25);
                padding: rem(25);
                margin-top: rem(30);

                .title_area {
                    @include clearfix();

                    h5 {
                        float: left;
                        font-weight: 600;
                        font-size: rem(22);
                        color: $form_label_color;
                    }

                    p {
                        float: right;
                        font-weight: 400;
                        font-size: rem(16);
                        color: $designation;
                    }
                }

                .cards_area {
                    .card_inner_area {
                        background: $white;
                        border: rem(1) solid $border_color;
                        box-shadow: 0 rem(1) rem(50) rgba(0, 0, 0, 0.08);
                        border-radius: rem(12);
                        padding: rem(16);
                        margin-top: rem(30);

                        .sub_area {
                            display: flex;
                            align-items: center;
                            @include clearfix();

                            .left_area {
                                float: left;
                                height: rem(34);
                                width: rem(34);
                                line-height: rem(32);
                                text-align: center;
                                background: #D0FBFF;
                                border-radius: rem(6);

                                &.heart {
                                    background: rgba(233, 97, 97, 0.1);
                                }

                                &.blood {
                                    background: #F8DEBD;
                                }

                                &.oxygen {
                                    background: #DDEAF6;
                                }

                                &.sugar {
                                    background: #EAFFBD;
                                }

                                .img_area {
                                    max-width: 100%;

                                    Image {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }

                            .right_area {
                                float: left;
                                width: calc(100% - 34px);
                                margin-left: rem(12);

                                h5 {
                                    font-weight: 600;
                                    font-size: rem(16);
                                }
                            }
                        }

                        .bottom_area {
                            margin-top: rem(12);
                            display: flex;


                            span {
                                font-weight: 400;
                                font-size: rem(20);
                                color: $black;
                                padding-right: rem(2);

                                &.circle {
                                    margin-right: rem(4);

                                    &:after {
                                        content: '';
                                        position: absolute;
                                        border-radius: rem(50);
                                        border: rem(1) solid $text_color;
                                        padding: rem(1);
                                        margin-top: rem(5);
                                    }
                                }

                                &.oxygen {

                                    margin-left: rem(20);
                                }
                            }

                            p {
                                font-weight: 500;
                                font-size: rem(14);
                                color: $text_color;
                            }
                        }
                    }
                }

                .last_update {
                    font-size: rem(16);
                    font-weight: 400;
                    color: $designation;
                    text-decoration: underline;
                    margin-top: rem(20);
                    display: flex;
                    justify-content: end;
                }

                .update_area {
                    @include clearfix();
                    margin-top: rem(20);

                    .update_info {
                        float: left;
                        padding-top: rem(15);

                        p {
                            color: $card_text_color;
                            font-weight: 400;
                            font-size: rem(16);

                            a {
                                color: #D81B23;

                            }
                        }

                    }

                    .button_area {
                        float: right;

                        .btn {
                            float: right;
                            font-size: rem(16);
                            font-weight: 600;
                            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                            width: rem(216);
                            height: rem(50);
                            border: 0px solid transparent;

                            &:hover,
                            &:focus {
                                color: $white;
                                height: rem(50);
                                border: 0px solid transparent;
                                background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                                opacity: unset;
                            }
                        }
                    }

                    &.center_area {
                        margin-top: 0;

                        .button_area {
                            float: unset;
                            text-align: center;

                            .btn {
                                float: unset;
                                text-align: center;
                            }
                        }
                    }
                }
            }

            .accordin_area {
                margin-top: rem(30);
                overflow: hidden;

                .accordion {
                    --bs-accordion-border-width: 1px;
                    --bs-accordion-border-radius: 1.5rem;
                    --bs-accordion-inner-border-radius: 42px;
                    --bs-accordion-btn-color: #140745;
                    --bs-accordion-btn-focus-border-color: grey;
                    --bs-accordion-btn-focus-box-shadow: grey;
                    --bs-accordion-btn-padding-y: 1.25rem;
                    --bs-accordion-btn-icon-width: 1.75rem;
                    --bs-accordion-active-color: #140745;
                    --bs-accordion-active-bg: #F9F9F9;
                    --bs-accordion-bg: #F9F9F9;
                    // overflow: hidden;
                }

                .accordion-header {
                    // &::after {
                    //     border-bottom: 1px solid #C4C4C4;
                    //     content: "";
                    //     display: block;
                    //     width: 94%;
                    //     margin-left: 20px;
                    // }
                }

                .accordion-button:not(.collapsed)>.accordion-header::after {
                    display: none;
                }

                .accordion-button {
                    font-size: rem(22);
                    font-weight: 600;
                    // padding: 5px 0;
                }

                .accordion-button:not(.collapsed) {
                    box-shadow: none;

                    &::after {
                        background-image: var(--bs-accordion-btn-icon);
                    }
                }

                .accordion-body {
                    // border-top: rem(1) solid #E7E7E7;
                    font-size: rem(16);
                    color: $black;
                    position: relative;

                    // background: $accordin_body;
                    // overflow: hidden;
                    &::after {
                        border-top: 1px solid #C4C4C4;
                        content: "";
                        display: block;
                        width: 94%;
                        // margin-left: 20px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                    }

                    .title {
                        h5 {
                            font-weight: 600;
                            font-size: rem(20);
                            color: $form_label_color;
                            margin-bottom: rem(25);
                        }
                    }

                    .inner_area {


                        h5 {
                            font-weight: 400;
                            font-size: rem(20);
                            line-height: rem(24);
                            color: $form_border_color;
                            margin-top: rem(15);
                        }

                        .category {
                            display: inline-block;
                            padding: rem(4) rem(10);
                            background: #FFD3D3;
                            border-radius: rem(3);
                            margin-right: rem(11);
                            margin-top: rem(15);

                            p {
                                font-weight: 400;
                                font-size: rem(16);
                                color: #FF4B4B;
                            }

                            &.color {
                                background: #FFF5D3;

                                p {
                                    color: #B89E17;
                                }
                            }
                        }

                        .description {
                            margin-top: rem(28);

                            p {
                                font-weight: 400;
                                font-size: rem(16);
                                line-height: rem(26);
                                color: $black_2;
                            }
                        }



                        .btn_active_area {
                            margin-top: rem(20);
                            display: flex;
                            justify-content: space-between;

                            p {
                                a {
                                    color: #D81B23;
                                }

                                color: #989898;
                                font-weight: 400;
                                font-size: 16px;
                                padding-top: rem(10);
                                padding-bottom: rem(10);
                            }

                            a {
                                font-size: rem(14);
                                font-weight: 500;
                                padding: rem(10);
                                border-radius: rem(40);

                                &.active {
                                    background: rgba(120, 182, 18, 0.18);
                                    color: $green;
                                }

                                &.inactive {
                                    background-color: $inactive_bg;
                                    color: $inactive_txt;
                                }
                            }
                        }
                    }

                }



            }

            .btn_area {
                float: right;
                margin-bottom: rem(20);

                .submitBtn {
                    float: right;
                    font-size: rem(16);
                    font-weight: 600;
                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                    width: rem(216);
                    height: rem(50);
                    border: 0px solid transparent;

                    &:hover,
                    &:focus {
                        color: $white;
                        height: rem(50);
                        border: 0px solid transparent;
                        background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                        opacity: unset;
                    }
                }
            }


            .task_btn_area {
                @include clearfix();
                align-items: center;
                border-top: rem(1) solid $grey;
                padding: rem(10);
                margin-top: rem(30);

                .main_area {
                    position: relative;

                    .btn_area {
                        width: 25%;

                        .btn {
                            font-size: rem(20);
                            font-weight: 600;
                            width: 100%;
                            height: rem(45active_btn);
                            margin-top: 0;
                        }
                    }

                    .btn-primary {
                        border-radius: rem(10);
                    }

                    .report_area {
                        width: calc(100% - 25%);
                        float: right;

                        p {
                            font-weight: 600;
                            font-size: rem(24);
                            line-height: rem(29);
                            color: $card_text_color;
                            margin-top: rem(-38);
                            margin-left: rem(40);
                        }
                    }
                }
            }
        }

        .tab_details {
            padding: rem(20) rem(22) rem(60) rem(22);

            .sorting_data {
                @include clearfix();

                .left_area {
                    float: left;
                    width: rem(250);
                    padding-top: rem(8);

                    p {
                        font-weight: 600;
                        font-size: rem(24);
                        color: $designation;
                    }
                }

                .right_area {
                    float: right;
                    width: calc(100% - 250px);

                    .sort_button {
                        .btn-primary {
                            float: right;
                            border: rem(2) solid $designation;
                            color: $designation;
                            width: 25%;
                            background: $white;
                            font-size: rem(20);
                        }

                        .image_area {
                            height: rem(22);
                            padding-left: rem(15);
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }

        .custom_tab_data {
            position: relative;

            .card_section {
                .card_area {
                    .mytask_area {
                        display: none;
                    }

                    .card_inner {
                        .card_inner_detail {
                            .sub_title {
                                .task_right_area {
                                    display: none;

                                    &.pending {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .bottom_area {
                            .inner_area {
                                @include clearfix();
                                display: flex;
                                align-items: center;

                                .right_area {
                                    padding-left: rem(10);

                                    .name_area {
                                        margin-left: 0;

                                        .ellipsis_icon {
                                            margin-right: 0;
                                            margin-top: rem(-27);
                                        }

                                        .dropdown {
                                            .dropdown-menu.show {
                                                transform: translate(115px, 7px) !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .patient_details_screen {
        .patient_details {
            padding: 30px 20px 20px 20px;

            .accordin_area {
                .accordion-body {
                    &::after {
                        width: 94%;
                    }
                }
            }


            .main_detail {
                display: block;
                width: 100%;

                .left_area {
                    float: none;
                    width: 100%;

                    .inner_right {
                        .name {
                            h3 {
                                font-size: rem(20);
                            }

                            .img_icon {
                                width: rem(16);
                                margin-left: rem(6);
                            }
                        }

                        .date {
                            p {
                                font-size: rem(14);
                            }
                        }

                    }
                }

                .right_area {
                    float: none;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: end;

                    .id_area {
                        margin-bottom: 0;
                        margin-right: rem(15);
                    }

                    .button_area {
                        margin-right: rem(-15);

                        .btn {
                            font-size: rem(13);
                            width: rem(130);
                            height: rem(40);
                        }
                    }
                }
            }

            .health_area {
                padding: rem(20);

                .cards_area {
                    .card_inner_area {
                        padding: rem(14);


                        .sub_area {
                            .right_area {
                                h5 {
                                    font-size: rem(13);
                                }
                            }
                        }

                        .bottom_area {
                            p {
                                font-size: rem(12);
                            }
                        }
                    }
                }


            }
        }

        .tab_details {
            .sorting_data {
                .right_area {
                    .sort_button {
                        .btn-primary {
                            width: 50%;
                        }
                    }
                }
            }
        }

        .custom_tab_data {

            .card_section {
                .card_area {
                    .card_inner {
                        .bottom_area {
                            .inner_area {
                                .right_area {
                                    .name_area {
                                        .dropdown {
                                            .dropdown-menu.show {
                                                transform: translate(70px, 7px) !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .patient_details_screen {
        .patient_details {
            padding: 0 rem(10) rem(20) rem(10);

            .main_detail {
                margin-top: rem(28);
                display: block;

                .left_area {
                    float: unset;
                    width: 100%;

                    .inner_left {
                        width: rem(60);

                        .img_area {
                            width: rem(60);
                            height: rem(60);
                            border-radius: 50%;
                        }
                    }

                    .inner_right {
                        width: calc(100% - 60px);
                        padding-left: rem(10);

                        .name {
                            h3 {
                                font-size: rem(22);
                            }

                            .img_icon {
                                width: rem(20);
                                margin-left: rem(6);
                            }
                        }

                        .gender {
                            margin-top: rem(10);
                            display: inline-block;

                            p {
                                margin-top: rem(4);

                                &.general {
                                    margin-left: 0;
                                }
                            }
                        }

                        .date {
                            display: inline-block;
                            margin-top: rem(10);

                            p {
                                margin-right: 10px;
                                display: inline-block;
                                margin-top: rem(4);
                            }

                            .ps-4 {
                                padding-left: 0 !important;
                            }
                        }
                    }
                }

                .right_area {
                    float: none;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: rem(15);
                    row-gap: 15px;

                    .id_area {
                        margin-bottom: 0;
                        margin-right: rem(15);
                    }

                    .button_area {
                        .btn {
                            font-size: rem(10);
                            width: rem(106);
                            height: rem(35);
                            margin-top: rem(40);
                        }
                    }

                    .batch {
                        float: unset;
                        height: rem(35);
                        line-height: rem(35);
                    }

                    .dropdown {
                        .batch {
                            float: unset;
                            height: rem(35);
                            line-height: rem(35);
                        }
                    }
                }
            }

            .card_section {
                .card_area {
                    margin-bottom: rem(10);
                }
            }

            .health_area {
                padding: rem(15);
                border-radius: rem(15);

                .title_area {
                    p {
                        float: left;
                        margin-top: rem(10);
                        margin-bottom: rem(15);
                    }
                }

                .cards_area {
                    margin-left: 0;
                    margin-right: 0;

                    .card_inner_area {
                        margin-bottom: rem(12);
                        margin-top: 0;
                    }
                }

                .update_area {
                    .button_area {
                        float: unset;

                        .btn {
                            float: unset;
                            margin-top: rem(15);
                        }
                    }
                }

            }

            .accordin_area {
                margin-top: rem(20);

                .accordion {
                    --bs-accordion-btn-padding-y: 1rem;
                    --bs-accordion-btn-icon-width: 1.35rem;

                    .accordion-item {
                        border-radius: rem(15);
                    }
                }

                .accordion-button {
                    font-size: rem(19);
                }

                .accordion-body {
                    display: flow-root;

                    &::after {
                        width: 89%;
                    }

                    .inner_area {
                        .btn_active_area {
                            display: block;

                            a {
                                &.active {
                                    float: right;
                                    display: block;
                                }

                                &.inactive {
                                    float: right;
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }

            // .btn_area {
            //     .btn {
            //         font-size: rem(20);
            //         height: rem(50);
            //         margin-top: rem(20);
            //     }
            // }
            .btn_area {
                .submitBtn {
                    width: rem(185);
                }
            }
        }

        .tab_details {
            .sorting_data {
                .left_area {
                    width: rem(125);

                    p {
                        font-size: rem(20);
                    }

                }

                .right_area {
                    width: calc(100% - 125px);

                    .sort_button {
                        .btn-primary {
                            width: 55%;
                        }
                    }
                }
            }

            .sort_button {
                .btn-primary {
                    width: 40%;
                }
            }
        }

        .custom_tab_data {

            .card_section {
                .card_area {
                    .card_inner {
                        .bottom_area {
                            .inner_area {
                                .right_area {
                                    .name_area {
                                        .dropdown {
                                            .dropdown-menu.show {
                                                transform: translate(10px, 7px) !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .patient_details_screen {
        .patient_details {
            padding: 0 rem(10) rem(20) rem(10);

            .main_detail {
                margin-top: rem(28);
                display: block;

                .left_area {
                    float: unset;
                    width: 100%;

                    .inner_left {
                        width: rem(60);

                        .img_area {
                            width: rem(60);
                            height: rem(60);
                            border-radius: 50%;
                        }
                    }

                    .inner_right {
                        width: calc(100% - 60px);
                        padding-left: rem(10);

                        .name {
                            h3 {
                                font-size: rem(24);
                            }

                            .img_icon {
                                width: rem(20);
                                margin-left: rem(6);
                            }
                        }

                        .gender {
                            margin-top: rem(10);
                            display: inline-block;

                            p {
                                margin-top: rem(4);

                                &.general {
                                    margin-left: 0;
                                }
                            }
                        }

                        .date {
                            display: inline-block;
                            margin-top: rem(10);

                            p {
                                margin-right: 10px;
                                display: inline-block;
                                margin-top: rem(4);
                            }

                            .ps-4 {
                                padding-left: 0 !important;
                            }
                        }
                    }
                }

                .right_area {
                    float: none;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: rem(15);
                    row-gap: 15px;

                    .id_area {
                        margin-bottom: 0;
                        margin-right: rem(15);
                    }

                    .button_area {
                        .btn {
                            font-size: rem(10);
                            width: rem(106);
                            height: rem(35);
                            margin-top: rem(40);
                        }
                    }

                    .batch {
                        float: unset;
                        height: rem(35);
                        line-height: rem(35);
                    }

                    .dropdown {
                        .batch {
                            float: unset;
                            height: rem(35);
                            line-height: rem(35);
                        }
                    }
                }
            }

            .card_section {
                .card_area {
                    margin-bottom: rem(10);
                }
            }

            .health_area {
                .title_area {
                    p {
                        float: left;
                        margin-top: rem(10);
                    }
                }

                .cards_area {
                    margin-left: 0;
                    margin-right: 0;

                    .card_inner_area {
                        margin-bottom: rem(10);
                    }
                }

                .update_area {
                    .button_area {
                        float: unset;

                        .btn {
                            float: unset;
                            margin-top: rem(15);
                        }
                    }
                }
            }

            .accordin_area {
                .accordion {
                    --bs-accordion-btn-padding-y: 1rem;
                    --bs-accordion-btn-icon-width: 1.35rem;
                }

                .accordion-button {
                    font-size: rem(19);
                }

                .accordion-body {
                    display: flow-root;

                    &::after {
                        width: 89%;
                    }

                    .inner_area {
                        .btn_active_area {
                            display: block;

                            a {
                                &.active {
                                    float: right;
                                    display: block;
                                }

                                &.inactive {
                                    float: right;
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }

        .tab_details {
            .sorting_data {
                .left_area {
                    width: rem(125);

                    p {
                        font-size: rem(20);
                    }
                }

                .right_area {
                    width: calc(100% - 125px);

                    .sort_button {
                        .btn-primary {
                            width: 60%;
                        }
                    }
                }
            }
        }

        .btn_area {

            .submitBtn {
                width: rem(185);
            }
        }

        .custom_tab_data {

            .card_section {
                .card_area {
                    .card_inner {
                        .bottom_area {
                            .inner_area {
                                .right_area {
                                    .name_area {
                                        .dropdown {
                                            .dropdown-menu.show {
                                                transform: translate(10px, 7px) !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}