.add_role_screen {

    .add_role_details {
        padding: rem(90) rem(30) rem(30);


        .btn {
            display: block;
            margin: 0 auto;
            margin-top: rem(60);
            font-size: rem(24);
            font-weight: 600;
            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
            border-radius: rem(41);
            width: rem(153);
            height: rem(61);
            border: 0px solid transparent;

            &:hover,
            &:focus {
                color: $white;
                height: rem(61);
                border: 0px solid transparent;
                background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
            }
        }

        .role_area_title {
            display: flex;
            align-items: center;
            margin-bottom: rem(20);

            .role_area {
                display: flex;
                align-items: center;

                p {
                    font-weight: 600;
                    font-size: rem(22);
                    color: $form_label_color;
                    padding-right: rem(10);

                    span {
                        color: $red_gradient_color;
                        margin-left: rem(5);

                    }
                }
            }
        }

        .search_section {
            @include clearfix();
            margin-bottom: rem(45);
            display: flex;
            align-items: center;
            position: relative;
            z-index: 2;


            .search_area {
                width: calc(100% - 50px);
                float: left;

                .form-group {
                    position: relative;
                    margin-bottom: 0;

                    .form-control {
                        height: rem(55);
                    }
                }

                .icon {
                    position: absolute;
                    left: 0;
                    top: 0;
                    line-height: rem(55);
                    font-size: rem(24);
                    margin-left: rem(18);
                    color: $form_label_color;
                }
            }

            &.input_width {
                width: 100%;

                .search_area {
                    float: none;
                    width: 100%;
                }
            }

        }

        .title_btn_area {
            display: flex;
            align-items: center;
            margin-bottom: rem(20);

            .form-check-input {
                background-color: $white;
                border: 1px solid $designation;

                &:checked {
                    border-color: $red_gradient_color;
                    background-image: url('../../../assets/images/vector.png');
                }

                &:focus {
                    box-shadow: none;
                }
            }

            .left_area {
                display: flex;
                align-items: center;
                width: 87%;

                .form-check {

                    label {
                        font-size: rem(16);
                        font-weight: 400;
                        color: $black_2;
                    }
                }


            }

            .right_area {
                float: right;
                width: calc(100% - 87%);
                display: flex;
                align-items: center;

                .form-check {

                    label {
                        font-size: rem(16);
                        font-weight: 400;
                        color: $black_2;
                    }
                }

            }
        }

        .list_detail {
            background: #FFFFFF;
            border: rem(1) solid #E0E0E0;
            box-shadow: 0px 0px 8px rgba(17, 17, 17, 0.12);
            border-radius: rem(25);
            text-transform: capitalize;
            display: flex;
            align-items: center;
            @include clearfix();

            .list_data {
                width: 100%;

            }

            .card {
                background: #F9F9F9;
                border: 1px solid #E7E7E7;
                box-shadow: 0px 4px 25px rgba(55, 55, 128, 0.12);
                border-radius: rem(25);

                .card-body {
                    h4 {
                        width: 100%;
                        padding: rem(15) 0;
                        text-align: center;
                        color: $black_2;
                        font-weight: 600;
                        font-size: rem(22);
                        border-bottom: rem(1) solid $black_2;
                    }

                    table {
                        width: 100%;
                        height: auto;
                        overflow-x: auto;

                        &::-webkit-scrollbar {
                            width: 0;
                            background-color: transparent;
                        }

                        th {
                            .list-inline {
                                display: flex;
                                padding: rem(16) 0;

                                li {
                                    font-size: rem(16);
                                    font-weight: 600;
                                    color: $black_2;
                                    width: rem(350);

                                    &.list-gap {
                                        margin-left: rem(0);
                                    }

                                    &:last-child {
                                        width: rem(56);
                                    }

                                    &:first-child {
                                        width: rem(150);
                                    }
                                }
                            }
                        }

                        td {
                            width: 20%;
                            padding: rem(15) 0;
                            border-top: rem(1) solid #E3E3E3;
                            font-weight: 400;
                            font-size: rem(16);
                            color: $black_2;
                            word-break: break-word;

                            .list-inline {
                                display: flex;

                                li {
                                    width: rem(350);

                                    .form-check-input {
                                        background-color: $white;
                                        text-align: start;

                                        &:checked {
                                            border-color: $red_gradient_color;
                                            background-image: url('../../../assets/images/vector.png');
                                        }

                                        &:focus {
                                            box-shadow: none;
                                        }
                                    }

                                    &.list-gap {
                                        margin-left: rem(5);
                                    }

                                    &:last-child {
                                        width: rem(56);
                                    }

                                    &:first-child {
                                        width: rem(150);
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}


/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {}


@media (min-width: 1024px) and (max-width: 1199px) {}

@media(min-width:768px) and (max-width:1023px) {
    .add_role_screen {

        .add_role_details {
            padding: 0 rem(20) rem(20);


            .btn {
                display: block;
                margin: 0 auto;
                margin-top: rem(60);
                font-size: rem(24);
                font-weight: 600;
                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                border-radius: rem(41);
                width: rem(153);
                height: rem(61);
                border: 0px solid transparent;

                &:hover,
                &:focus {
                    color: $white;
                    height: rem(61);
                    border: 0px solid transparent;
                    background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                }
            }

            .role_area_title {
                display: flex;
                align-items: center;
                margin-bottom: rem(20);

                .role_area {
                    display: flex;
                    align-items: center;

                    p {
                        font-weight: 600;
                        font-size: rem(22);
                        color: $form_label_color;
                        padding-right: rem(10);

                        span {
                            color: $red_gradient_color;
                            margin-left: rem(5);

                        }
                    }
                }
            }

            .search_section {
                @include clearfix();
                margin-bottom: rem(45);
                display: flex;
                align-items: center;
                position: relative;
                z-index: 2;


                .search_area {
                    width: calc(100% - 50px);
                    float: left;

                    .form-group {
                        position: relative;
                        margin-bottom: 0;

                        .form-control {
                            height: rem(55);
                        }
                    }

                    .icon {
                        position: absolute;
                        left: 0;
                        top: 0;
                        line-height: rem(55);
                        font-size: rem(24);
                        margin-left: rem(18);
                        color: $form_label_color;
                    }
                }

                &.input_width {
                    width: 100%;

                    .search_area {
                        float: none;
                        width: 100%;
                    }
                }

            }

            .title_btn_area {
                display: flex;
                align-items: center;
                margin-bottom: rem(20);

                .form-check-input {
                    background-color: $white;
                    border: 1px solid $designation;

                    &:checked {
                        border-color: $red_gradient_color;
                        background-image: url('../../../assets/images/vector.png');
                    }

                    &:focus {
                        box-shadow: none;
                    }
                }

                .left_area {
                    display: flex;
                    align-items: center;
                    width: 87%;

                    .form-check {

                        label {
                            font-size: rem(16);
                            font-weight: 400;
                            color: $black_2;
                        }
                    }


                }

                .right_area {
                    float: right;
                    width: calc(100% - 87%);
                    display: flex;
                    align-items: center;

                    .form-check {

                        label {
                            font-size: rem(16);
                            font-weight: 400;
                            color: $black_2;
                        }
                    }

                }
            }

            .list_detail {
                background: #FFFFFF;
                border: rem(1) solid #E0E0E0;
                box-shadow: 0px 0px 8px rgba(17, 17, 17, 0.12);
                border-radius: rem(25);
                text-transform: capitalize;
                display: flex;
                align-items: center;
                @include clearfix();

                .list_data {
                    width: 100%;

                }

                .card {
                    background: #F9F9F9;
                    border: 1px solid #E7E7E7;
                    box-shadow: 0px 4px 25px rgba(55, 55, 128, 0.12);
                    border-radius: rem(25);

                    .card-body {
                        h4 {
                            width: 100%;
                            padding: rem(15) 0;
                            text-align: center;
                            color: $black_2;
                            font-weight: 600;
                            font-size: rem(22);
                            border-bottom: rem(1) solid $black_2;
                        }

                        table {
                            width: 100%;
                            height: auto;
                            overflow-x: auto;

                            &::-webkit-scrollbar {
                                width: 0;
                                background-color: transparent;
                            }

                            th {
                                .list-inline {
                                    display: flex;
                                    padding: rem(16) 0;

                                    li {
                                        font-size: rem(16);
                                        font-weight: 600;
                                        color: $black_2;
                                        width: rem(133);

                                        &.list-gap {
                                            margin-left: rem(0);
                                        }

                                        &:last-child {
                                            width: rem(56);
                                        }

                                        &:first-child {
                                            width: rem(185);
                                        }
                                    }
                                }
                            }

                            td {
                                width: 20%;
                                padding: rem(15) 0;
                                border-top: rem(1) solid #E3E3E3;
                                font-weight: 400;
                                font-size: rem(16);
                                color: $black_2;
                                word-break: break-word;

                                .list-inline {
                                    display: flex;

                                    li {
                                        width: rem(130);

                                        .form-check-input {
                                            background-color: $white;
                                            text-align: start;

                                            &:checked {
                                                border-color: $red_gradient_color;
                                                background-image: url('../../../assets/images/vector.png');
                                            }

                                            &:focus {
                                                box-shadow: none;
                                            }
                                        }

                                        &.list-gap {
                                            margin-left: rem(5);
                                        }

                                        &:last-child {
                                            width: rem(56);
                                        }

                                        &:first-child {
                                            width: rem(185);
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}



/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .add_role_screen {
        .add_role_details {
            padding: 0 rem(20) rem(20);
            .list_detail {
                .card {
                    .card-body {
                        table {
                            th {
                                .list-inline {
                                    li {
                                        width: rem(130);
                                    }
                                }
                            }
                            td {
                                .list-inline {
                                    li {
                                        width: rem(130);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .add_role_screen {
        .add_role_details {
            padding: 0 rem(20) rem(20);

            .title_btn_area {
                .left_area {
                    width: 75%;
                }

                .right_area {
                    width: calc(100% - 75%);
                }
            }
            .list_detail {
                .card {
                    .card-body {
                        table {
                            th {
                                .list-inline {
                                    li {
                                        width: rem(130);
                                    }
                                }
                            }
                            td {
                                .list-inline {
                                    li {
                                        width: rem(130);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .add_role_screen {
        .add_role_details {
            padding: 0 rem(20) rem(20);

            .title_btn_area {
                .left_area {
                    width: 75%;
                }

                .right_area {
                    width: calc(100% - 75%);
                }
            }
            .list_detail {
                .card {
                    .card-body {
                        table {
                            th {
                                .list-inline {
                                    li {
                                        width: rem(130);
                                    }
                                }
                            }
                            td {
                                .list-inline {
                                    li {
                                        width: rem(130);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}