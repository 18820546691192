.assigned_member_modal {
    &.modal {
        .modal-dialog {
            &.modal-dialog-centered {
                .modal-content {
                    .modal-header {
                        .modal-title {
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 29px;
                            color: $black_2;
                        }
                    }

                    .modal-body {
                        padding-top: 40px;
                        overflow-y: auto;
                        height: 486px;

                        .assigned_member_area {
                            .item {
                                margin: 0 auto;
                                text-align: center;
                                padding-bottom: 40px;


                                .member_img {
                                    width: 105px;
                                    height: 109px;
                                    overflow: hidden;
                                    margin: 0 auto;
                                    border-radius: 50%;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }

                                h5 {
                                    font-weight: 600;
                                    font-size: 20px;
                                    line-height: 24px;
                                    color: $black_2;
                                    margin-top: 11px;
                                }

                                .tag_area {
                                    display: flex;
                                    justify-content: center;
                                    margin-top: 6px;

                                    p {
                                        // width: 77px;
                                        // height: 25px;
                                        padding: rem(2) rem(12);
                                        background: rgba(75, 75, 100, 0.14);
                                        border-radius: 5px;
                                        font-weight: 400;
                                        font-size: 20px;
                                        line-height: 24px;
                                        cursor: pointer;
                                        color: $form_border_color;
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .assigned_member_modal {
        &.modal {
            .modal-dialog {
                &.modal-dialog-centered {
                    .modal-content {
                        .modal-body {
                            padding-top: 35px;
                            height: 400px;

                            .assigned_member_area {
                                .item {
                                    .member_img {
                                        width: 75px;
                                        height: 75px;
                                    }

                                    h5 {
                                        font-size: 16px;
                                    }

                                    .tag_area {
                                        p {
                                            width: 60px;
                                            height: 25px;
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .assigned_member_modal {
        &.modal {
            .modal-dialog {
                &.modal-dialog-centered {
                    .modal-content {
                        .modal-header {
                            .modal-title {
                                font-size: 20px;
                            }
                        }

                        .modal-body {
                            padding-top: 35px;
                            height: 400px;

                            .assigned_member_area {
                                .item {
                                    .member_img {
                                        width: 75px;
                                        height: 75px;
                                    }

                                    h5 {
                                        font-size: 16px;
                                    }

                                    .tag_area {
                                        p {
                                            width: 60px;
                                            height: 25px;
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Smaller phones */
@media (max-width: rem(360)) {
    .assigned_member_modal {
        &.modal {
            .modal-dialog {
                &.modal-dialog-centered {
                    .modal-content {
                        .modal-header {
                            .modal-title {
                                font-size: 20px;
                            }
                        }

                        .modal-body {
                            padding-top: 35px;
                            height: 400px;

                            .assigned_member_area {
                                .item {
                                    .member_img {
                                        width: 75px;
                                        height: 75px;
                                    }

                                    h5 {
                                        font-size: 14px;
                                    }

                                    .tag_area {
                                        p {
                                            width: 60px;
                                            height: 25px;
                                            font-size: 14px;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}