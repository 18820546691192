.card_area {
    padding: rem(45) rem(20);

    .card {
        background: $card_color;
        border: rem(1) solid $grey;
        border-radius: rem(25);
        padding: rem(15) rem(30) rem(25) rem(30);

        .card-body {
            .card_details {
                h5 {
                    font-weight: 600;
                    font-size: rem(22);
                    color: $form_label_color;
                    padding-bottom: rem(25);
                    border-bottom: rem(1) solid #C4C4C4;
                }

                h6 {
                    padding-top: rem(30);
                    padding-bottom: rem(19);
                    font-size: rem(20);
                    font-weight: 400;
                    color: $form_border_color;
                }

                .btns_area {
                    margin-bottom: rem(25);

                    ul {
                        display: flex;
                        gap: rem(15);

                        li {
                            width: rem(66);
                            height: rem(23);
                            border-radius: rem(3);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: rem(16);
                            font-weight: 400;

                            &.btn_danger {
                                background: #FFD3D3;

                                a {
                                    color: #FF4B4B;
                                }
                            }

                            &.btn_warning {
                                background: #FFF5D3;

                                a {
                                    color: #B89E17;
                                }
                            }
                        }
                    }
                }

                p {
                    font-weight: 400;
                    font-size: rem(16);
                    line-height: rem(26);
                    color: $black_2;
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .card_area {
        .card {
            padding: rem(5) rem(10) rem(10) rem(10);

            .card-body {
                .card_details {
                    h5 {
                        font-size: rem(20);
                        padding-bottom: rem(15);
                    }

                    h6 {
                        padding-top: rem(20);
                        padding-bottom: rem(15);
                        font-size: rem(18);
                    }

                    p {
                        font-size: rem(14);
                        line-height: rem(23);
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .card_area {
        .card {
            padding: rem(5) rem(10) rem(10) rem(10);

            .card-body {
                .card_details {
                    h5 {
                        font-size: rem(20);
                        padding-bottom: rem(15);
                    }

                    h6 {
                        padding-top: rem(20);
                        padding-bottom: rem(15);
                        font-size: rem(18);
                    }

                    p {
                        font-size: rem(14);
                        line-height: rem(23);
                    }
                }
            }
        }
    }
}