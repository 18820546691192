.my_profile_screen {

    .parent_area {
        // margin-bottom: rem(50);
        // background: linear-gradient(0deg, #FAFAFA, #FAFAFA), #2041C6;
        // // border-bottom: 1px solid #989898;
        // box-shadow: 0px 10px 60px rgba(55, 55, 128, 0.06);
        // border-radius: 0 0 rem(80) rem(80);
        position: relative;
        background: linear-gradient(0deg, #FAFAFA, #FAFAFA), #2041C6;
        // border-bottom: 1px solid #7A7A7A;
        box-shadow: 0px 10px 60px rgba(55, 55, 128, 0.06);
        border-radius: 0px;

        &.details {
            // border-radius:rem(25);
            padding-top: rem(28);
            // border-top: 1px solid #989898;

            background: #F9F9F9;
            /* bg light grey */

            // border-top: 0px solid #E7E7E7;
            box-shadow: 0px 4px 25px rgba(55, 55, 128, 0.12);
            border-radius: 25px;
            // transform: matrix(1, 0, 0, -1, 0, 0);
        }

        .edit_btn_area {
            margin: rem(50) 0 rem(350) 0;
            text-align: center;

            .icon {
                padding-left: rem(10);
            }

            .btn {
                width: 30%;
                font-size: rem(16);
                font-weight: 600;
                height: rem(60);
                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                border: none;

                &:hover {
                    background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                    color: $white;
                    border: none;
                }
            }
        }

        .circle_area {
            position: absolute;
            bottom: 0;
            right: 0;
            // margin-bottom: rem(-70);
            width: rem(190);
            height: rem(160);
            overflow: hidden;

            Image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .profile_area {

            // margin-bottom: rem(50);
            // background: linear-gradient(0deg, #FAFAFA, #FAFAFA), #2041C6;
            // border-bottom: 1px solid #989898;
            // box-shadow: 0px 10px 60px rgba(55, 55, 128, 0.06);

            .image_area {
                margin: 0 auto;
                text-align: center;
                width: rem(180);
                height: rem(180);
                border-radius: 50%;
                overflow: hidden;
                margin-top: rem(-40);

                Image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .name_area {
                text-align: center;
                margin-top: rem(25);
                margin-bottom: rem(50);

                h5 {
                    font-size: rem(24);
                    font-weight: 600;
                    color: $profile_info;
                }

                p {

                    font-weight: 400;
                    font-size: rem(20);
                    color: $dashboard;
                    margin-top: rem(10);

                    span {
                        background: rgba(55, 55, 128, 0.2);
                        border-radius: rem(5);
                        padding: rem(3) rem(11);
                    }

                }
            }

            .card_area {
                margin-left: rem(110);
                margin-right: rem(110);
                margin-top: rem(45);

                .card_inner_area {
                    text-align: center;
                    border-radius: rem(20);
                    border: 0.5px solid #E7E7E7;
                    box-shadow: 0px 10px 25px rgba(107, 119, 154, 0.05);
                    padding: 0px 0 35px 0;

                    .top_area {
                        height: rem(63);
                        width: rem(49);
                        background: #FEF6EC;
                        border-radius: 0 0 rem(15) rem(15);
                        margin: 0 auto;
                        position: relative;

                        &.experience {
                            background: #FDF1F3;
                        }

                        &.task {
                            background: #7ACEFA26;
                        }

                        .img_area {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            margin-bottom: rem(10);

                            Image {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    h5 {
                        padding-top: rem(20);
                        font-weight: 600;
                        font-size: rem(22);
                    }

                    p {
                        font-weight: 400;
                        font-size: rem(16);
                        color: $designation;

                    }
                }

            }
        }

        .info_area {
            .inner_area {
                margin-left: rem(120);
                margin-right: rem(80);
                margin-top: rem(26);

                .left_area {
                    width: 45%;
                    float: left;
                    display: flex;

                    .details_area {
                        @include clearfix();
                        width: 100%;

                        .icon_area,
                        p {
                            float: left;
                        }

                        span {
                            float: right;
                            font-size: rem(22);
                            font-weight: 600;
                        }


                        .icon_area {
                            height: rem(30);
                            overflow: hidden;

                            Image {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        p {
                            color: $profile_info;
                            font-size: rem(22);
                            font-weight: 600;
                            padding-left: rem(15);
                        }
                    }

                }

                .right_area {
                    width: calc(100% - 45%);
                    float: right;

                    p {

                        font-size: rem(22);
                        font-weight: 600;
                        padding-left: rem(13);
                    }
                }
            }

        }
    }

    // .my_profile_bottom {
    //     padding-bottom: 25px;
    // }
}


/* Above 1920 desktop */
@media (min-width: 1920px) {}

/* 1680 desktop */
@media (min-width: 1680px) and (max-width: 1919px) {}

/* 1600 desktop */
@media (min-width: 1600px) and (max-width: 1679px) {}

/* Above 1400 and Below 1599 Desktop*/
@media (min-width: 1400px) and (max-width: 1599px) {}

/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {}

/* Landscape tablets and ipad */
@media (min-width: 768px) and (max-width: 991px) {}

/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {

    .my_profile_screen {
        .parent_area {
            .profile_area {
                .card_area {
                    margin-left: rem(20);
                    margin-right: rem(20);

                    .card_inner_area {
                        margin-bottom: rem(15);
                    }
                }
            }

            .info_area {
                .inner_area {
                    margin-left: rem(20);
                    margin-right: rem(20);
                    margin-top: rem(15);

                    .left_area {
                        width: 100%;

                        .details_area {
                            span {
                                float: left;
                                margin-left: 20px;
                            }
                        }

                        p {
                            font-size: rem(20);

                            .test {
                                color: black;
                                text-align: right;
                                float: inline-end;
                            }
                        }
                    }

                    .right_area {
                        width: 100%;
                        margin-top: rem(6);

                        p {
                            font-size: rem(17);
                            padding-left: rem(40);
                        }
                    }
                }
            }

            .edit_btn_area {
                .btn {
                    width: 50%;
                }
            }
        }
    }

}

/* Smaller phones */
@media (max-width: 360px) {
    .my_profile_screen {
        .parent_area {
            .profile_area {
                .details {
                    margin-left: 40px;
                }

                .card_area {
                    margin-left: rem(20);
                    margin-right: rem(20);

                    .card_inner_area {
                        margin-bottom: rem(15);
                    }
                }
            }

            .info_area {
                .inner_area {
                    margin-left: rem(20);
                    margin-right: 0;
                    margin-top: rem(15);

                    .left_area {
                        width: 100%;

                        p {
                            font-size: rem(20);
                        }
                    }

                    .right_area {
                        width: 100%;
                        margin-top: rem(6);

                        p {
                            font-size: rem(20);
                            padding-left: rem(22);
                        }
                    }
                }
            }

            .edit_btn_area {
                .btn {
                    width: 50%;
                }
            }
        }
    }

}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}