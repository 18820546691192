body {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: inherit;
    background-color: #d9d9d9;
}

.btn-primary {
    width: rem(190);
    height: rem(50);
    border: rem(2) solid $white;
    border-radius: rem(30);
    font-size: rem(15);
    font-weight: 600;
    background: transparent;

    &:hover,
    &:focus {
        background: $white;
        color: $red_gradient_color;
        border-radius: rem(30);
        opacity: 0.7;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

.no_event {
    pointer-events: none;
}

.scroll_off {
    overflow: hidden;
    @include ioshidden();
}

.form-control {
    font-size: rem(14);
}

.asterisk {
    color: #f00 !important;
}

label {
    &.error {
        display: block;
        color: #f00 !important;
        font-weight: 500 !important;
        font-size: 14px !important;
    }
}

.table>:not(caption)>*>* {
    padding: 0;
}

.text-success {
    color: #25d366 !important;
}

.callout {
    border-radius: 0.25rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background-color: #fff;
    border-left: 5px solid #e9ecef;
    margin-bottom: 1rem;
    padding: 13px;

    &.callout-success {
        border-left-color: #1e7e34;
        background-color: #28a745;
        color: #fff;
    }

    &.callout-danger {
        border-left-color: #bd2130;
        background-color: #d72d3e;
        color: #fff;
    }
}

.alert {
    &.alert-success {
        border-left-color: #1e7e34;
        background-color: #28a745;
        color: #fff;
    }

    &.alert-danger {
        border-left-color: #bd2130;
        background-color: #d72d3e;
        color: #fff;
    }
}

.popover {
    max-width: 360px;
    padding: 20px;
    border: 1px solid #e8e8eb;
    box-shadow: 0px 12px 20px 0px rgba(0, 0, 50, 0.12);

    .popover-body {
        padding: 0;

        p {
            font-weight: 600;
            font-size: 14px;
            color: $black;
            padding-bottom: 10px;

            a {
                color: $black;
                display: inline-block;
            }
        }

        a {
            color: $black;
            font-weight: 600;
            display: block;
        }
    }
}

.pagination_area {
    text-align: center;
    display: table;
    margin: 0 auto;
    @include clearfix();

    .list-inline {
        li {
            a {
                display: block;
                font-size: 16px;
                color: $black;
                background-color: $primary_color;
                color: $white;
                padding: 0px 0px;
                width: 30px;
                height: 30px;
                margin: 0px 1.2px;
                line-height: 30px;
                font-size: 14px;
                text-transform: uppercase;
                letter-spacing: 0.5px;

                &:hover {
                    background-color: darken($primary_color, 10%);
                }
            }

            &.page_np {
                a {
                    width: auto;
                    padding: 0px 8px;
                }
            }

            &.disable {
                cursor: no-drop;

                a {
                    pointer-events: none;
                }
            }
        }
    }
}

.custom_pagination {
    padding-top: 15px;
    overflow: auto;

    .pagination {
        white-space: nowrap;

        //justify-content: flex-end;
        .paginate_button {
            &.page-item {
                a {
                    display: block;
                    position: relative;
                    padding: 0.5rem 0.75rem;
                    margin-left: -1px;
                    line-height: 1.25;
                    color: $black;
                    background-color: #fff;
                    border: 1px solid #dee2e6;

                    &.active {
                        color: #fff;
                        background-color: $primary_color;
                        border-color: $primary_color;
                    }
                }
            }
        }
    }
}

#pb_scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
    background-color: none;
}

#pb_scroll::-webkit-scrollbar {
    width: 3px;
    background-color: none;
}

#pb_scroll::-webkit-scrollbar-thumb {
    background-color: $black;
    border: 1px solid $black;
}

.spinner-border {
    display: block;
    position: fixed;
    top: calc(50% - (58px / 2));
    right: calc(50% - (58px / 2));
    height: 80px;
    width: 80px;
    z-index: 9;
}

.img_icon_area {
    position: relative;
    margin-top: -15px;

    .img_area {
        height: rem(200);
        width: rem(200);
        border-radius: 50%;
        margin: 0 auto;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .icon_area {
        height: rem(42);
        width: rem(42);
        line-height: rem(42);
        border-radius: 50%;
        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
        text-align: center;
        font-size: 20px;
        color: $white;
        position: absolute;
        bottom: 0;
        right: 0;
        margin-right: 832px;
        cursor: pointer;
    }
}


//Toaster--
.Toastify__toast-container--top-center {
    width: 768px;
    right: 0 !important;
    margin: 0 auto;
}

.progress {
    // height: 15px;
    margin-top: 10px;

    width: 100%;

}

// .Toastify__toast .Toastify__toast-theme--light .Toastify__toast--success {
//     margin-left: calc(calc(100% - 368px)/2);
//     width: 100%;
// }

/* Extra Huge large desktops */
@media (min-width: 1920px) {}

/* Extra Huge large desktops */
@media (min-width: 1680px) and (max-width: 1919px) {}

/* Extra Huge large desktops */
@media (min-width: 1600px) and (max-width: 1679px) {}

/* Extra large desktops and mac laptops*/
@media (min-width: 1400px) and (max-width: 1599px) {}

@media (min-width: 1300px) and (max-width: 1399px) {
    .img_icon_area {
        .icon_area {
            margin-right: 560px;
        }
    }
}

@media (min-width: 1200px) and (max-width: 1299px) {
    .img_icon_area {

        .icon_area {
            margin-right: 515px;
        }
    }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    .btn-primary {
        font-size: 14px;
    }

    .img_icon_area {

        .icon_area {
            margin-right: 400px;
        }
    }
}

/* Landscape tablets and ipad */
@media (min-width: 768px) and (max-width: 991px) {
    .btn-primary {
        font-size: 14px;
    }

    .btn-primary-1 {
        font-size: 14px;
    }

    .img_icon_area {
        position: relative;
        margin-top: -15px;

        .img_area {
            height: rem(175);
            width: rem(175);
            border-radius: 50%;
            margin: 0 auto;
            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .icon_area {
            height: rem(42);
            width: rem(42);
            line-height: rem(42);
            border-radius: 50%;
            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
            text-align: center;
            font-size: 20px;
            color: $white;
            position: absolute;
            bottom: 0;
            right: 0;
            margin-right: 276px;
            cursor: pointer;
        }
    }

}

/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .btn-primary {
        font-size: 14px;
    }

    .btn-primary-1 {
        font-size: 14px;
    }

    .img_icon_area {
        .img_area {
            height: rem(140);
            width: rem(140);
        }

        .icon_area {
            height: rem(35);
            width: rem(35);
            line-height: rem(36);
            font-size: rem(17);
            margin-right: rem(248);
        }
    }

    .Toastify__toast-container--top-center {
        width: 100%;
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .btn-primary {
        font-size: 14px;
    }

    .btn-primary-1 {
        font-size: 14px;
    }

    .img_icon_area {
        .img_area {
            height: rem(120);
            width: rem(120);
        }

        .icon_area {
            height: rem(30);
            width: rem(30);
            line-height: rem(30);
            font-size: rem(15);
            margin-right: rem(125);
        }
    }

    .Toastify__toast-container--top-center {
        width: 100%;
    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .btn-primary {
        font-size: 14px;
    }

    .btn-primary-1 {
        font-size: 14px;
    }

    .img_icon_area {
        .img_area {
            height: rem(120);
            width: rem(120);
        }

        .icon_area {
            height: rem(30);
            width: rem(30);
            line-height: rem(30);
            font-size: rem(15);
            margin-right: rem(115);
        }
    }

    .Toastify__toast-container--top-center {
        width: 100%;
    }
}

/* Smaller phones */
@media (max-width: 320px) {}