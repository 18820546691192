.welcome_screen {
    .splash_bottom {
        .bottom_area {
            .details {
                .img_three {
                    width: rem(405);
                    height: rem(356);
                    display: block;
                    margin: 0 auto;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .welcome_screen {
        .splash_bottom {
            .bottom_area {
                .details {
                    .img_three {
                        width: rem(305);
                        height: rem(265);
                        display: block;
                        margin: 0 auto;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .welcome_screen {
        .splash_bottom {
            .bottom_area {
                .details {
                    .img_three {
                        width: rem(245);
                        height: rem(214);
                        display: block;
                        margin: 0 auto;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .welcome_screen {
        .splash_bottom {
            .bottom_area {
                .details {
                    .img_three {
                        width: rem(305);
                        height: rem(265);
                        display: block;
                        margin: 0 auto;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}