.health_tab_screen {
    padding: rem(40) 0;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;

    .card {
        border: rem(1) solid $designation;
        border-radius: rem(25);
        margin-bottom: rem(20);
        background-color: $accordin_body;
        width: 49%;

        .card-body {
            padding: rem(25) rem(38) rem(30) rem(38);

            .card_details {
                @include clearfix();

                .left_area {
                    width: rem(400);
                    float: left;
                    @include clearfix();

                    .inner_left_area {
                        width: rem(203);
                        float: left;

                        ul {
                            li {
                                padding: rem(7) 0;


                                .person_health_details {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;

                                    .details {
                                        display: flex;
                                        gap: rem(18);

                                        .img_heart {
                                            width: rem(16);
                                            height: rem(19);
                                            overflow: hidden;

                                            img {
                                                width: 100%;
                                                height: 100%;
                                                object-fit: contain;
                                            }
                                        }

                                        h6 {
                                            font-weight: 600;
                                            font-size: rem(16);
                                            color: $black_2;
                                        }
                                    }

                                    span {
                                        font-weight: 600;
                                        font-size: rem(16);
                                        color: $black;
                                    }
                                }
                            }
                        }
                    }

                    .inner_right_area {
                        width: calc(100% - 203px);
                        float: right;
                        padding-left: rem(15);

                        ul {
                            li {
                                padding: rem(7) 0;

                                .details_list {
                                    display: flex;
                                    align-items: center;

                                    p {
                                        font-weight: 600;
                                        font-size: rem(16);
                                        color: $designation;
                                    }
                                }
                            }
                        }
                    }
                }

                .right_area {
                    width: calc(100% - 400px);
                    float: right;

                    .person_details {
                        text-align: center;
                        padding-top: rem(10);

                        .img_person {
                            width: rem(100);
                            height: rem(100);
                            overflow: hidden;
                            border-radius: 50%;
                            margin: 0 auto;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        h5 {

                            font-size: rem(16);
                            font-weight: 400;
                            padding-top: rem(11);
                            background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }

                        p {
                            font-size: rem(16);
                            font-weight: 400;
                            color: $cancel_btn_color;
                            padding-top: rem(6);
                        }
                    }
                }
            }
        }

    }
}



/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {}

@media (min-width: 1024px) and (max-width: 1199px) {
    .health_tab_screen {
        .card {
            width: 100%;

            .card-body {
                .card_details {
                    .left_area {
                        width: 50%;
                    }

                    .right_area {
                        width: calc(100% - 50%);
                    }
                }
            }
        }
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .health_tab_screen {
        padding: rem(40) 0;
        gap: 0;

        .card {
            border: rem(1) solid $designation;
            border-radius: rem(25);
            margin-bottom: rem(20);
            background-color: $accordin_body;
            width: 100%;

            .card-body {
                padding: rem(25) rem(38) rem(30) rem(38);

                .card_details {
                    @include clearfix();

                    .left_area {
                        width: rem(400);
                        float: left;
                        @include clearfix();

                        .inner_left_area {
                            width: rem(203);
                            float: left;

                            ul {
                                li {
                                    padding: rem(7) 0;


                                    .person_health_details {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;

                                        .details {
                                            display: flex;
                                            gap: rem(18);

                                            .img_heart {
                                                width: rem(16);
                                                height: rem(19);
                                                overflow: hidden;

                                                img {
                                                    width: 100%;
                                                    height: 100%;
                                                    object-fit: contain;
                                                }
                                            }

                                            h6 {
                                                font-weight: 600;
                                                font-size: rem(16);
                                                color: $black_2;
                                            }
                                        }

                                        span {
                                            font-weight: 600;
                                            font-size: rem(16);
                                            color: $black;
                                        }
                                    }
                                }
                            }
                        }

                        .inner_right_area {
                            width: calc(100% - 203px);
                            float: right;
                            padding-left: rem(15);

                            ul {
                                li {
                                    padding: rem(7) 0;

                                    .details_list {
                                        display: flex;
                                        align-items: center;

                                        p {
                                            font-weight: 600;
                                            font-size: rem(16);
                                            color: $designation;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .right_area {
                        width: calc(100% - 400px);
                        float: right;

                        .person_details {
                            text-align: center;
                            padding-top: rem(10);

                            .img_person {
                                width: rem(100);
                                height: rem(100);
                                overflow: hidden;
                                border-radius: 50%;
                                margin: 0 auto;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            h5 {

                                font-size: rem(16);
                                font-weight: 400;
                                padding-top: rem(11);
                                background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }

                            p {
                                font-size: rem(16);
                                font-weight: 400;
                                color: $cancel_btn_color;
                                padding-top: rem(6);
                            }
                        }
                    }
                }
            }

        }
    }
}

@media (min-width: rem(576)) and (max-width: rem(767)) {
    .health_tab_screen {
        gap: 0;

        .card {
            width: 100%;

            .card-body {
                .card_details {
                    .right_area {
                        width: 100%;
                    }
                }
            }
        }
    }
}


@media (min-width: rem(361)) and (max-width: rem(575)) {
    .health_tab_screen {
        gap: 0;

        .card {
            width: 100%;

            .card-body {
                padding: rem(10);

                .card_details {
                    .left_area {
                        width: rem(280);

                        .inner_left_area {
                            width: rem(155);

                            ul {
                                li {
                                    padding: rem(5) 0;

                                    .person_health_details {
                                        .details {
                                            gap: rem(12);

                                            .img_heart {
                                                width: rem(14);
                                                height: rem(18);
                                            }

                                            h6 {
                                                font-size: rem(12);
                                                padding-top: rem(2);
                                            }
                                        }

                                        span {
                                            font-size: rem(12);
                                        }
                                    }
                                }
                            }
                        }

                        .inner_right_area {
                            width: calc(100% - 155px);
                            padding-left: rem(5);

                            ul {
                                li {
                                    padding: rem(5) 0;

                                    .details_list {
                                        p {
                                            font-size: rem(12);
                                            padding-top: rem(3);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .right_area {
                        width: 100%;

                        .person_details {
                            h5 {
                                font-size: rem(14);
                                padding-top: rem(10);
                            }

                            p {
                                font-size: rem(14);
                                padding-top: rem(5);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: rem(360)) {
    .health_tab_screen {
        gap: 0;

        .card {
            width: 100%;

            .card-body {
                padding: rem(10);

                .card_details {
                    .left_area {
                        width: rem(280);

                        .inner_left_area {
                            width: rem(155);

                            ul {
                                li {
                                    padding: rem(5) 0;

                                    .person_health_details {
                                        .details {
                                            gap: rem(12);

                                            .img_heart {
                                                width: rem(14);
                                                height: rem(18);
                                            }

                                            h6 {
                                                font-size: rem(12);
                                                padding-top: rem(2);
                                            }
                                        }

                                        span {
                                            font-size: rem(12);
                                        }
                                    }
                                }
                            }
                        }

                        .inner_right_area {
                            width: calc(100% - 155px);
                            padding-left: rem(5);

                            ul {
                                li {
                                    padding: rem(5) 0;

                                    .details_list {
                                        p {
                                            font-size: rem(12);
                                            padding-top: rem(3);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .right_area {
                        width: 100%;

                        .person_details {
                            h5 {
                                font-size: rem(14);
                                padding-top: rem(10);
                            }

                            p {
                                font-size: rem(14);
                                padding-top: rem(5);
                            }
                        }
                    }
                }
            }
        }
    }
}