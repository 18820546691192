.add_case_section {

    .case_area {
        padding: rem(90) rem(250);

        .form-group {
            .form-label {
                span {
                    color: $critical_color;
                }
            }

            textarea {
                height: rem(122);
            }
        }

        .dropdown_box {
            position: relative;
            text-transform: capitalize;

            p {
                &.text-danger {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    margin-bottom: rem(-20);
                }
            }

            h6 {
                font-size: rem(22);
                font-weight: 600;
                color: $form_label_color;

                span {
                    color: $critical_color;
                }
            }

            .dropdown_area {
                margin-top: rem(12);
                // margin-right: rem(45);
                background: transparent;
                border: 1px solid transparent;
                border-radius: rem(10);
                color: $white;
                width: 100%;
                // width: rem(342);
                margin-bottom: rem(30);
                text-transform: capitalize;
            }
        }

        .check_box {
            .form-check {
                display: flex;
                align-items: center;
                margin-bottom: rem(30);

                .form-check-label {
                    font-size: rem(22);
                    font-weight: 600;
                    color: $critical_color;
                    margin-top: rem(3);
                }

                .form-check-input {
                    width: rem(26);
                    height: rem(26);
                    margin-right: rem(10);
                    background-color: $white;
                    border: 1px solid $critical_color;

                    &:checked {
                        border-color: $critical_color;
                        background-image: url('../../../assets/images/vector.png');
                        outline: none;
                    }

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }

        .btn_area {
            margin-bottom: rem(30);
            justify-content: center;

            .btn {
                // width: 15%;
            }
        }

        .tag_area {
            h6 {
                font-size: rem(22);
                font-weight: 600;
                color: $form_label_color;

                span {
                    color: $critical_color;
                }
            }

            .react-tagsinput {
                margin-top: rem(12);
                margin-bottom: rem(30);

                .react-tagsinput-input {
                    border: 1px solid $form_border_color;
                    min-height: 60px;
                    height: auto;
                    width: 100%;
                    border-radius: rem(10);
                    font-size: rem(22);
                    font-weight: 400;
                    padding: 10px;
                    padding-left: rem(20);
                    color: $designation;
                }
            }
        }
    }
}

/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {
    .add_case_section {

        .case_area {
            padding: rem(90) rem(150);
        }
    }
}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {
    .add_case_section {

        .case_area {
            padding: rem(90) rem(130);
        }
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .add_case_section {
        .case_area {
            padding: rem(90) rem(100);
        }
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .add_case_section {
        .case_area {
            padding: 0 rem(15);

            .form-group {
                .form-label {
                    span {
                        color: $critical_color;
                    }
                }

                textarea {
                    height: rem(122);
                }
            }

            .dropdown_box {
                position: relative;
                text-transform: capitalize;

                p {
                    &.text-danger {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        margin-bottom: rem(-20);
                    }
                }

                h6 {
                    font-size: rem(22);
                    font-weight: 600;
                    color: $form_label_color;

                    span {
                        color: $critical_color;
                    }
                }

                .dropdown_area {
                    margin-top: rem(12);
                    // margin-right: rem(45);
                    background: transparent;
                    border: 1px solid transparent;
                    border-radius: rem(10);
                    color: $white;
                    width: 100%;
                    // width: rem(342);
                    margin-bottom: rem(30);
                    text-transform: capitalize;
                }
            }

            .check_box {
                .form-check {
                    display: flex;
                    align-items: center;
                    margin-bottom: rem(30);

                    .form-check-label {
                        font-size: rem(22);
                        font-weight: 600;
                        color: $critical_color;
                        margin-top: rem(3);
                    }

                    .form-check-input {
                        width: rem(26);
                        height: rem(26);
                        margin-right: rem(10);
                        background-color: $white;
                        border: 1px solid $critical_color;

                        &:checked {
                            border-color: $critical_color;
                            background-image: url('../../../assets/images/vector.png');
                            outline: none;
                        }

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }
                }
            }

            .btn_area {
                margin-bottom: rem(30);

                .btn {
                    width: 50%;
                }
            }

            .tag_area {
                h6 {
                    font-size: rem(22);
                    font-weight: 600;
                    color: $form_label_color;

                    span {
                        color: $critical_color;
                    }
                }

                .react-tagsinput {
                    margin-top: rem(12);
                    margin-bottom: rem(30);

                    .react-tagsinput-input {
                        border: 1px solid $form_border_color;
                        min-height: 60px;
                        height: auto;
                        width: 100%;
                        border-radius: rem(10);
                        font-size: rem(22);
                        font-weight: 400;
                        padding: 10px;
                        padding-left: rem(20);
                        color: $designation;
                    }
                }
            }
        }
    }
}



/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .add_case_section {

        .case_area {
            padding: 0 rem(15);

            .img_section {
                margin-bottom: rem(40);

                .img_profile {
                    height: rem(140);
                    width: rem(140);
                }

                .img_upload {
                    height: rem(35);
                    width: rem(35);
                    margin-right: rem(250);
                }
            }

            .btn_area {
                margin-bottom: rem(20);
                    .btn {
                        width: 50%;
                }
            }

            .check_box {
                .form-check {
                    margin-bottom: rem(20);

                    .form-check-label {
                        font-size: rem(20);
                    }

                    .form-check-input {
                        width: rem(22);
                        height: rem(22);
                    }
                }
            }

            .form-group {
                textarea {
                    height: rem(105);
                }
            }

            .tag_area {
                .react-tagsinput {
                    .react-tagsinput-input {
                        height: rem(53);
                        font-size: rem(20);
                    }
                }
            }
        }
    }

    .css-b62m3t-container {
        .css-13cymwt-control {
            height: auto;
            min-height: rem(53);
            font-size: rem(20);
        }

        .css-t3ipsp-control {
            height: auto;
            min-height: rem(53);
            font-size: rem(20);
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .add_case_section {
        .case_area {
            padding: 0;

            .img_section {
                margin-bottom: rem(30);

                .img_profile {
                    height: rem(120);
                    width: rem(120);
                }

                .img_upload {
                    height: rem(30);
                    width: rem(30);
                    margin-right: rem(128);
                }
            }

            .btn_area {
                margin-bottom: rem(20);

                .btn {
                    font-size: rem(20);
                    height: rem(50);
                    width: 50%;
                }
            }

            .form-group {
                margin-bottom: rem(25);
            }

            .dropdown_box {
                h6 {
                    font-size: rem(18);
                }

                .dropdown_area {
                    margin-bottom: rem(25);
                }
            }

            .form-group {
                textarea {
                    height: rem(102);
                }
            }

            .check_box {
                .form-check {
                    margin-bottom: rem(15);

                    .form-check-label {
                        font-size: rem(18);
                    }

                    .form-check-input {
                        width: rem(20);
                        height: rem(20);
                    }
                }
            }

            .tag_area {
                h6 {
                    font-size: rem(18);
                }

                .react-tagsinput {
                    margin-bottom: rem(25);

                    .react-tagsinput-input {
                        height: rem(53);
                        font-size: rem(20);
                    }
                }
            }
        }
    }

    .css-b62m3t-container {
        .css-13cymwt-control {
            height: auto;
            min-height: rem(53);
            font-size: rem(20);
        }

        .css-t3ipsp-control {
            height: auto;
            min-height: rem(53);
            font-size: rem(20);
        }
    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .add_case_section {

        .case_area {
            padding: 0;

            .img_section {
                margin-bottom: rem(30);

                .img_profile {
                    height: rem(120);
                    width: rem(120);
                }

                .img_upload {
                    height: rem(30);
                    width: rem(30);
                    margin-right: rem(128);
                }
            }

            .btn_area {
                margin-bottom: rem(20);

                .btn {
                    font-size: rem(20);
                    height: rem(50);
                    width: 50%;
                }
            }

            .form-group {
                margin-bottom: rem(25);
            }

            .dropdown_box {
                h6 {
                    font-size: rem(18);
                }

                .dropdown_area {
                    margin-bottom: rem(25);
                }
            }

            .form-group {
                textarea {
                    height: rem(102);
                }
            }

            .check_box {
                .form-check {
                    margin-bottom: rem(15);

                    .form-check-label {
                        font-size: rem(18);
                    }

                    .form-check-input {
                        width: rem(20);
                        height: rem(20);
                    }
                }
            }

            .tag_area {
                h6 {
                    font-size: rem(18);
                }

                .react-tagsinput {
                    margin-bottom: rem(25);

                    .react-tagsinput-input {
                        height: rem(53);
                        font-size: rem(20);
                    }
                }
            }
        }
    }

    .css-b62m3t-container {
        .css-13cymwt-control {
            height: rem(53);
            font-size: rem(20);
        }

        .css-t3ipsp-control {
            height: rem(53);
            font-size: rem(20);
        }
    }
}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}