.task_list_screen {
    &.staff_list_section {
        .task_list_details {
            padding: 80px 30px 20px 30px;

            .title_btn_area {
                margin-bottom: rem(10);
                margin-top: 0;

                .right_area {
                    .btn {
                        width: rem(200);
                    }
                }
            }

            .search_section {
                margin-bottom: 0;

                .pagination_list {
                    .form-group {
                        .form-select {
                            border: rem(1.5) solid $form_border_color;
                        }
                    }
                }

                .right_area {
                    float: right;
                    width: calc(100% - 650px);

                    .sort_button {
                        .btn-primary {
                            float: right;
                            border: rem(2) solid $designation;
                            color: $designation;
                            width: 100%;
                            background: $white;
                            font-size: rem(20);
                        }

                        .image_area {
                            height: rem(22);
                            padding-left: rem(15);
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }

            .staff_table_area {
                background: $white;
                border: 1px solid $light_grey_2;
                box-shadow: 0px 0px 8px rgba(17, 17, 17, 0.06);
                border-radius: 25px;
                overflow: hidden;

                .table-responsive {
                    overflow-x: auto;
                    min-height: rem(265);

                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                        -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                        background-color: $grey;
                    }

                    &::-webkit-scrollbar {
                        width: 2px;
                        height: 6px;
                        background-color: $dark_red_2;
                        margin: 0 rem(20);
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: $dark_red_2;
                    }

                    table {
                        margin-bottom: 0;

                        tr {
                            padding: 0 rem(30);

                            th {
                                font-size: rem(21);
                                font-weight: 600;
                                color: $form_label_color;
                                border-bottom: 1.5px solid rgba(133, 133, 133, 0.6);
                                padding: rem(25) rem(20);
                                text-align: center;

                                &:nth-child(1) {
                                    min-width: rem(205);
                                    padding-left: rem(20);
                                    cursor: pointer;
                                }

                                &:nth-child(2) {
                                    min-width: rem(170);
                                }

                                &:nth-child(3) {
                                    min-width: rem(160);
                                }

                                &:nth-child(4) {
                                    min-width: rem(180);
                                    cursor: pointer;
                                }

                                &:nth-child(5) {
                                    min-width: rem(120);
                                }

                                &:nth-child(6) {
                                    min-width: rem(175);
                                }

                                &:nth-child(7) {
                                    min-width: rem(100);
                                }
                            }
                        }

                        tr {
                            position: relative;
                            border-bottom: 1px solid rgba(128, 128, 128, 0.8);

                            &:last-child {
                                border-bottom: 0;
                                border-color: transparent;
                            }

                            td {
                                text-align: center;
                                padding: rem(18) 0;
                                vertical-align: middle;

                                .switch_area {
                                    padding-left: rem(22);

                                    .form-switch {
                                        display: flex;
                                        align-items: center;

                                        .form-check-input {
                                            background-color: rgba(233, 97, 97, 0.4);
                                            border: 1px solid;
                                            border-color: rgba(233, 97, 97, 0.4);
                                            background-image: url('../../images/c_red.png');

                                            &:focus {
                                                box-shadow: none;
                                                border-color: $grey;
                                            }

                                            &:checked {
                                                border: 1px solid;
                                                background-color: rgba(120, 182, 18, 0.2);
                                                border-color: rgba(120, 182, 18, 0.2);
                                                background-image: url('../../images/c_green.png');
                                            }
                                        }

                                        .form-check-label {
                                            color: #E96161;
                                            font-size: 14px;
                                            font-weight: 500;
                                            margin-left: rem(5);
                                            margin-top: rem(2);
                                        }

                                        .form-check-input:checked~.form-check-label {
                                            color: #8BB612;
                                        }
                                    }
                                }

                                .profile_area {
                                    @include clearfix();
                                    display: flex;
                                    align-items: center;
                                    padding-left: rem(20);
                                    cursor: pointer;

                                    .left_area {
                                        float: left;
                                        width: rem(50);

                                        .img_area {
                                            height: rem(50);
                                            width: rem(50);
                                            border-radius: rem(50);
                                            overflow: hidden;

                                            img {
                                                height: 100%;
                                                width: 100%;
                                                object-fit: cover;
                                            }
                                        }
                                    }

                                    .right_area {
                                        float: right;
                                        width: calc(100% - 50px);
                                        padding-left: rem(12);
                                        text-align: start;

                                        a {
                                            font-size: rem(16);
                                            font-weight: 600;
                                            color: $designation;
                                        }
                                    }
                                }

                                .text_box {
                                    font-size: rem(14);
                                    font-weight: 500;
                                    color: $form_border_color;
                                    padding: rem(3) rem(8);
                                    background: rgba(55, 55, 128, 0.2);
                                    border-radius: rem(6);
                                    display: inline-block;
                                }

                                .under_line {
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }

                                p {
                                    font-size: rem(16);
                                    font-weight: 600;
                                    color: $designation;
                                    cursor: pointer;
                                }

                                .deactive {
                                    font-size: rem(14);
                                    font-weight: 500;
                                    color: $white;
                                    padding: rem(10);
                                    background-color: $critical_color;
                                    border-radius: rem(8);
                                    display: inline-block;
                                }

                                .active {
                                    font-size: rem(14);
                                    font-weight: 500;
                                    color: $white;
                                    padding: rem(10);
                                    background-color: $notification;
                                    border-radius: rem(8);
                                    display: inline-block;
                                }

                                .icon {
                                    .dropdown-menu {
                                        padding: 0;
                                        border-radius: rem(12) 0 rem(12) rem(12);
                                        background: $fade_white;
                                        inset: auto !important;
                                        // transform: translate(rem(-40), rem(30)) !important;
                                        // transform: translate(rem(-66px), rem(35px)) !important;
                                        transform: translate(-66px, 0px) !important;


                                        .dropdown-item {
                                            padding: rem(8) rem(20);
                                            font-size: rem(16);
                                            font-weight: 400;
                                            color: $designation;
                                            padding-left: rem(45);
                                            position: relative;
                                            border-bottom: rem(0.2) solid $border_line;

                                            &:last-child {
                                                border-bottom: none;
                                            }

                                            .image_area {
                                                padding-left: rem(8);
                                                overflow: hidden;

                                                img {
                                                    width: 100%;
                                                    height: 100%;
                                                    object-fit: cover;
                                                }

                                                &.delete_icon {
                                                    margin-top: rem(-4);
                                                }
                                            }

                                        }

                                    }

                                    .btn {
                                        color: $form_label_color;
                                        padding-top: 0;
                                        position: relative;
                                        top: 5px;

                                        &:hover,
                                        &:focus {
                                            background-color: transparent;
                                        }
                                    }

                                    .ellipsis_icon {
                                        font-size: rem(27);
                                        cursor: pointer;
                                    }

                                    .dropdown-toggle::after {
                                        display: none !important;
                                    }
                                }

                                background:transparent;

                                &::before {
                                    content: "";
                                    width: rem(6);
                                    // height: rem(86);
                                    background-color: transparent;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    bottom: 0;
                                    @include transition(all .5s ease);
                                }
                            }

                            &:hover {
                                td {
                                    background: rgba(232, 232, 232, 0.21);

                                    &::before {
                                        background-color: $dark_red_2;
                                    }
                                }
                            }

                            // &.active {
                            //     td {
                            //         background: rgba(232, 232, 232, 0.21);

                            //         &::before {
                            //             background-color: $critical_color;
                            //         }
                            //     }
                            // }
                        }
                    }
                }
            }
        }
    }
}






/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {
    .task_list_screen {
        &.staff_list_section {
            .task_list_details {
                .staff_table_area {
                    .table-responsive {
                        table {
                            tr {
                                td {
                                    .icon {
                                        .dropdown-menu {
                                            transform: translate(-100px, 0px) !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {
    .task_list_screen {
        &.staff_list_section {
            .task_list_details {
                .staff_table_area {
                    .table-responsive {
                        table {
                            tr {
                                td {
                                    .icon {
                                        .dropdown-menu {
                                            transform: translate(-100px, 0px) !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (min-width: 1024px) and (max-width: 1199px) {
    .task_list_screen {
        &.staff_list_section {
            .task_list_details {
                .staff_table_area {
                    .table-responsive {
                        table {
                            tr {
                                td {
                                    .icon {
                                        .dropdown-menu {
                                            transform: translate(-100px, 0px) !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



/* Landscape tablets and ipad */
@media (min-width: 768px) and (max-width: 1023px) {
    .task_list_screen {
        &.staff_list_section {
            .task_list_details {
                padding: rem(20);
                padding-top: 0;

                .title_btn_area {
                    margin-bottom: rem(10);

                    .right_area {
                        .btn {
                            width: rem(200);
                        }
                    }
                }

                .search_section {
                    margin-bottom: 0;

                    .pagination_list {
                        .form-group {
                            .form-select {
                                border: rem(1.5) solid $form_border_color;
                            }
                        }
                    }

                    .right_area {
                        float: right;
                        width: calc(100% - 650px);

                        .sort_button {
                            .btn-primary {
                                float: right;
                                border: rem(2) solid $designation;
                                color: $designation;
                                width: 100%;
                                background: $white;
                                font-size: rem(20);
                            }

                            .image_area {
                                height: rem(22);
                                padding-left: rem(15);
                                overflow: hidden;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                    }
                }

                .staff_table_area {
                    background: $white;
                    border: 1px solid $light_grey_2;
                    box-shadow: 0px 0px 8px rgba(17, 17, 17, 0.06);
                    border-radius: 25px;
                    overflow: hidden;

                    .table-responsive {
                        overflow-x: auto;
                        min-height: rem(250);

                        &::-webkit-scrollbar-track {
                            box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                            -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                            background-color: $grey;
                        }

                        &::-webkit-scrollbar {
                            width: 2px;
                            height: 6px;
                            background-color: $dark_red_2;
                            margin: 0 rem(20);
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $dark_red_2;
                        }

                        table {
                            margin-bottom: 0;

                            tr {
                                padding: 0 rem(30);

                                th {
                                    font-size: rem(22);
                                    font-weight: 600;
                                    color: $form_label_color;
                                    border-bottom: 1.5px solid rgba(133, 133, 133, 0.6);
                                    padding: rem(18) 0;
                                    text-align: center;

                                    &:nth-child(1) {
                                        min-width: rem(205);
                                        padding-left: rem(20);
                                        cursor: pointer;
                                    }

                                    &:nth-child(2) {
                                        min-width: rem(170);
                                    }

                                    &:nth-child(3) {
                                        min-width: rem(160);
                                    }

                                    &:nth-child(4) {
                                        min-width: rem(180);
                                        cursor: pointer;
                                    }

                                    &:nth-child(5) {
                                        min-width: rem(120);
                                    }

                                    &:nth-child(6) {
                                        min-width: rem(175);
                                    }

                                    &:nth-child(7) {
                                        min-width: rem(100);
                                    }
                                }
                            }

                            tr {
                                position: relative;
                                border-bottom: 1px solid rgba(128, 128, 128, 0.8);

                                &:last-child {
                                    border-bottom: 0;
                                }


                                td {
                                    text-align: center;
                                    padding: rem(18) 0;
                                    vertical-align: middle;

                                    .switch_area {
                                        padding-left: rem(22);

                                        .form-switch {
                                            display: flex;
                                            align-items: center;

                                            .form-check-input {
                                                background-color: rgba(233, 97, 97, 0.4);
                                                border: 1px solid;
                                                border-color: rgba(233, 97, 97, 0.4);
                                                background-image: url('../../images/c_red.png');

                                                &:focus {
                                                    box-shadow: none;
                                                    border-color: $grey;
                                                }

                                                &:checked {
                                                    border: 1px solid;
                                                    background-color: rgba(120, 182, 18, 0.2);
                                                    border-color: rgba(120, 182, 18, 0.2);
                                                    background-image: url('../../images/c_green.png');
                                                }
                                            }

                                            .form-check-label {
                                                color: #E96161;
                                                font-size: 14px;
                                                font-weight: 500;
                                                margin-left: rem(5);
                                                margin-top: rem(2);
                                            }

                                            .form-check-input:checked~.form-check-label {
                                                color: #8BB612;
                                            }
                                        }
                                    }

                                    .profile_area {
                                        @include clearfix();
                                        display: flex;
                                        align-items: center;
                                        padding-left: rem(20);
                                        cursor: pointer;

                                        .left_area {
                                            float: left;
                                            width: rem(50);

                                            .img_area {
                                                height: rem(50);
                                                width: rem(50);
                                                border-radius: rem(50);
                                                overflow: hidden;

                                                img {
                                                    height: 100%;
                                                    width: 100%;
                                                    object-fit: cover;
                                                }
                                            }
                                        }

                                        .right_area {
                                            float: right;
                                            width: calc(100% - 50px);
                                            padding-left: rem(12);
                                            text-align: start;

                                            a {
                                                font-size: rem(16);
                                                font-weight: 600;
                                                color: $designation;
                                            }
                                        }
                                    }

                                    .text_box {
                                        font-size: rem(14);
                                        font-weight: 500;
                                        color: $form_border_color;
                                        padding: rem(3) rem(8);
                                        background: rgba(55, 55, 128, 0.2);
                                        border-radius: rem(6);
                                        display: inline-block;
                                    }

                                    .under_line {
                                        &:hover {
                                            text-decoration: underline;
                                        }
                                    }

                                    p {
                                        font-size: rem(16);
                                        font-weight: 600;
                                        color: $designation;
                                        cursor: pointer;
                                    }

                                    .deactive {
                                        font-size: rem(14);
                                        font-weight: 500;
                                        color: $white;
                                        padding: rem(10);
                                        background-color: $critical_color;
                                        border-radius: rem(8);
                                        display: inline-block;
                                    }

                                    .active {
                                        font-size: rem(14);
                                        font-weight: 500;
                                        color: $white;
                                        padding: rem(10);
                                        background-color: $notification;
                                        border-radius: rem(8);
                                        display: inline-block;
                                    }

                                    .icon {
                                        .dropdown-menu {
                                            padding: 0;
                                            border-radius: rem(12) 0 rem(12) rem(12);
                                            background: $fade_white;
                                            transform: translate(rem(-100), rem(0)) !important;

                                            .dropdown-item {
                                                padding: rem(8) rem(20);
                                                font-size: rem(16);
                                                font-weight: 400;
                                                color: $designation;
                                                padding-left: rem(45);
                                                position: relative;
                                                border-bottom: rem(0.2) solid $border_line;

                                                &:last-child {
                                                    border-bottom: none;
                                                }

                                                .image_area {
                                                    padding-left: rem(8);
                                                    overflow: hidden;

                                                    img {
                                                        width: 100%;
                                                        height: 100%;
                                                        object-fit: cover;
                                                    }

                                                    &.delete_icon {
                                                        margin-top: rem(-4);
                                                    }
                                                }

                                            }

                                        }

                                        .btn {
                                            color: $form_label_color;
                                            padding-top: 0;
                                            position: relative;
                                            top: 5px;

                                            &:hover,
                                            &:focus {
                                                background-color: $white;
                                            }
                                        }

                                        .ellipsis_icon {
                                            font-size: rem(27);
                                            cursor: pointer;
                                        }

                                        .dropdown-toggle::after {
                                            display: none !important;
                                        }
                                    }

                                    background:transparent;

                                    &::before {
                                        content: "";
                                        width: rem(6);
                                        // height: rem(86);
                                        background-color: transparent;
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        bottom: 0;
                                        @include transition(all .5s ease);
                                    }
                                }

                                &:hover {
                                    td {
                                        background: rgba(232, 232, 232, 0.21);

                                        &::before {
                                            background-color: $dark_red_2;
                                        }
                                    }
                                }

                                // &.active {
                                //     td {
                                //         background: rgba(232, 232, 232, 0.21);

                                //         &::before {
                                //             background-color: $critical_color;
                                //         }
                                //     }
                                // }
                            }
                        }
                    }
                }
            }
        }
    }
}



/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .task_list_screen {
        &.staff_list_section {
            .task_list_details {
                padding: 0 30px 20px 30px;

                .staff_table_area {
                    .table-responsive {
                        table {
                            tr {
                                td {
                                    .icon {
                                        .dropdown-menu {
                                            padding: 0;
                                            border-radius: 12px 0 12px 12px;
                                            background: #FEFEFE;
                                            inset: auto !important;
                                            transform: translate(-100px, 0px) !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .task_list_screen {
        &.staff_list_section {
            .task_list_details {
                padding: 0;

                .title_btn_area {
                    margin-bottom: rem(25);
                    margin-top: rem(-10);

                    .left_area {
                        p {
                            font-size: rem(20);
                        }
                    }

                    .right_area {
                        .btn {
                            width: rem(147);
                            font-size: rem(20);
                        }
                    }
                }

                .search_section {
                    display: inline-block;
                    width: 100%;

                    .pagination_list {
                        width: 50%;
                        display: inline-block;
                        padding-top: rem(0);

                        .form-group {
                            margin-bottom: rem(20);

                            .form-select {
                                width: rem(90);
                            }
                        }
                    }

                    .search_area {
                        .form-group {
                            margin-bottom: rem(20);
                        }
                    }

                    .filter_button {
                        text-align: end;
                        width: 50%;
                        display: inline-block;

                        .btn-primary {
                            width: rem(124);
                            height: rem(53);
                            margin-left: rem(0);
                            font-size: rem(20);
                            border-radius: rem(8);
                            margin-top: 0;
                            margin-bottom: rem(15);
                        }

                        .filter_icon {
                            margin-top: 12px;
                        }
                    }
                }

                .list_detail {
                    .list_data {
                        .card {
                            border-radius: rem(20);
                        }
                    }
                }

                .staff_table_area {
                    border-radius: 15px;

                    .table-responsive {
                        table {
                            tr {
                                th {
                                    font-size: 20px;
                                }

                                td {
                                    .icon {
                                        .dropdown-menu {
                                            padding: 0;
                                            border-radius: 12px 0 12px 12px;
                                            background: #FEFEFE;
                                            inset: auto !important;
                                            transform: translate(-100px, 0px) !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Smaller phones */
@media (max-width: rem(360)) {
    .task_list_screen {
        &.staff_list_section {
            .task_list_details {
                padding: 0;

                .title_btn_area {
                    margin-bottom: rem(25);
                    margin-top: rem(-10);

                    .left_area {
                        width: calc(100% - 64%);

                        p {
                            font-size: rem(20);
                        }
                    }

                    .right_area {
                        width: 64%;

                        .btn {
                            width: rem(147);
                            font-size: rem(20);
                            line-height: rem(30);
                        }
                    }
                }

                .search_section {
                    display: inline-block;
                    width: 100%;

                    .pagination_list {
                        width: 50%;
                        display: inline-block;
                        padding-top: rem(0);

                        .form-group {
                            margin-bottom: rem(20);

                            .form-select {
                                width: rem(90);
                            }
                        }
                    }

                    .search_area {
                        .form-group {
                            margin-bottom: rem(20);
                        }
                    }

                    .filter_button {
                        text-align: end;
                        width: 50%;
                        display: inline-block;

                        .btn-primary {
                            width: rem(124);
                            height: rem(53);
                            margin-left: rem(0);
                            font-size: rem(20);
                            border-radius: rem(8);
                            margin-top: 0;
                            margin-bottom: rem(15);
                        }

                        .filter_icon {
                            margin-top: 12px;
                        }
                    }
                }

                .list_detail {
                    .list_data {
                        .card {
                            border-radius: rem(20);
                        }
                    }
                }

                .staff_table_area {
                    border-radius: 15px;

                    .table-responsive {
                        table {
                            tr {
                                th {
                                    font-size: 20px;
                                }

                                td {
                                    .icon {
                                        .dropdown-menu {
                                            padding: 0;
                                            border-radius: 12px 0 12px 12px;
                                            background: #FEFEFE;
                                            inset: auto !important;
                                            transform: translate(-100px, 0px) !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}