$black: #000000;
$white: #ffffff;
$grey: #E7E7E7;
$black_2: #111111;

$primary_color: #f7f1f0;
$secondary_color: #c16452;
$form_label_color: #140745;
$form_border_color: #373780;

// Social Icons Color
$facebook: #1871e7;
$twitter: #1c99e7;
$linkedin: #0077b5;
$google-plus: #db4437;
$youtube: #fa362c;
$skype: #02b4eb;
$pinterest: #dd0017;
$instagram: #e1376f;
$rss: #e98735;
$notification: #8BB612;
$dashboard: #373780;
$dashboard_ward: #DB1C24;
$designation: #7A7A7A;
$profile_info: #D00809;

// Button colors
$treatment_color: #FF8717;
$critical_color: #FF0000;
$success_color: #8BB612;
$card_color: #FAFAFA;
$card_text_color: #989898;
$cancel_btn_color: #7A7A7A;
$red_gradient_color: #E31E24;
$dark_red_gradient_color: #800020;
$border_color: #E8E7E7;
$text_color: #818181;
$dark_red_2: #D81B23;
$light_grey: #666666;
$treat_color: #FFB057;
$send_comments: #9E9EA8;
$accordin_body: #F9F9F9;
$light_red: #FFF5F5;
$fade_pink: #E96161;
$light_grey_2: #E0E0E0;
$green: #8BB612;
$light_green: #E1ECCF;
$border_line: #858585;
$dark_grey: #808080;
$blue: #1C709E;
$light_blue: #DDF0FB;
$light_white: #E1EDC0;
$off_white: #D7D7E6;
$light_pur: #E4DDFB;
$dark_yellow: #ED7932;
$grey_2: #8E8E8E;
$light_sky: #6284FF;
$light_pur_2: #D2C7FF;
$light_red_2: #FFF9F9;
$fade_white: #FEFEFE;
$dark_green: #00954D;
$light_bg: #D1D1E0;
$border_line2: #E8E8E8;
$grey_3: #727272;
$border_color_2: #E7E6E6;
$text_color2: #99784D;
$label_color: #F8DEBD;
$bg_color: #F8DFC7;
$grey_4: #909090;
$inactive_bg: #FFD3D3;
$inactive_txt: #FF4B4B;
$txt_color_2: #232323;
$black_3: #222B45;
$light_pink: #FFEFEF;
$border_line_3: #9E9E9E;
$file_bg: #D9D9D9;
$border_bottom: #B0B0B0;