.dashboard_screen {
    .dashboardheader_area {
        position: relative;
        background-color: #DB1C24;
        overflow-x: hidden;

        .inner_area {
            @include clearfix();
            position: relative;
            margin-top: rem(0);
            margin: rem(40) rem(30);
            display: flex;
            align-items: center;

            .left_area {
                width: 10%;
                float: left;
                position: relative;
                z-index: 2;

                .icon {
                    margin-left: rem(0);
                    width: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .arrow {
                    color: $white;
                    font-size: rem(25);
                    margin-left: rem(0);
                    margin-top: 0;
                }
            }

            .right_area {
                width: calc(100% - 10%);
                float: right;
                padding-right: rem(20);
                position: relative;
                z-index: 2;

                ul {
                    float: right;

                    li {
                        margin-right: rem(12);
                        display: inline-block;

                        .icon {
                            color: $white;
                            font-size: rem(26);
                            position: relative;
                            font-weight: 400;

                            &.alert_circle {
                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: rem(0);
                                    right: rem(0);
                                    width: rem(10);
                                    height: rem(10);
                                    border-radius: rem(7);
                                    margin-top: rem(2);
                                    background-color: $notification;
                                }
                            }
                        }

                        &:last-child {
                            margin-right: rem(0);
                        }
                    }
                }
            }

            .header_title {
                position: absolute;
                top: rem(-10);
                left: rem(0);
                right: rem(0);
                bottom: rem(0);
                color: $white;
                margin: rem(0) auto;
                text-align: center;
                z-index: 1;

                h5 {
                    font-size: rem(36);
                    font-weight: 600;
                }
            }
        }

        .breadcrum_area {
            position: absolute;
            margin-top: rem(30);
            margin-left: rem(45);
            font-weight: 600;
            font-size: rem(16);
            display: flex;
            align-items: center;

            a {
                color: $form_border_color;
            }

            .icon {
                margin-left: rem(10);
                margin-right: rem(15);
                font-size: rem(10);
                color: $form_border_color;
                margin-bottom: rem(2);
            }

            span {
                color: #DB1C24;
            }
        }

        .linking_area {
            margin-top: rem(100);
            margin-left: rem(22);

            .inner_area {

                a {
                    font-size: rem(16);
                    font-weight: 600;
                    color: $dashboard;
                }

                span {
                    padding-left: rem(10);
                    font-size: rem(16);
                    font-weight: 600;
                    color: $dashboard_ward;
                }

                .task_area {
                    padding-left: 10px;
                }

            }

            .dropdown_area {
                float: right;
                margin-top: rem(15);
                margin-right: rem(45);
                background: $dashboard;
                border-radius: rem(6);
                color: $white;

                button {
                    &.btn-primary {
                        border-radius: rem(6);
                        border: none;
                        height: rem(40);
                        width: rem(271);
                        font-size: rem(16);

                        &:hover {
                            background-color: $dashboard;
                            border-radius: rem(6);
                            color: $white;
                        }

                        &:focus {
                            background: unset;
                            color: unset;
                            border-radius: rem(6);
                        }
                    }

                    .icon {
                        display: inline-block;
                        margin-right: rem(10);
                        @include transition(0.3s ease-in-out);
                    }

                    &.dropdown-toggle {
                        &:after {
                            content: none;
                        }
                    }

                    &:focus {
                        .down_icon {
                            @include rotate(180deg);
                        }
                    }
                }

                .dropdown-menu {
                    &.show {
                        left: 50% !important;
                        right: auto !important;
                        transform: translate(calc(-50% + 3px), 42px) !important;
                    }
                }
            }
        }
    }
}







/* Above 1920 desktop */
@media (min-width: 1920px) {}

/* 1680 desktop */
@media (min-width: 1680px) and (max-width: 1919px) {}

/* 1600 desktop */
@media (min-width: 1600px) and (max-width: 1679px) {}

/* Above 1400 and Below 1599 Desktop*/
@media (min-width: 1400px) and (max-width: 1599px) {}

/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {}

/* Landscape tablets and ipad */
@media (min-width: 768px) and (max-width: 991px) {
    .dashboard_screen {

        .dashboardheader_area {
            background-color: unset;
            background-image: url("../../../assets/images/dashboard_header.png");
            background-repeat: no-repeat;
            background-size: cover;
            height: rem(272);

            .inner_area {
                @include clearfix();
                position: relative;
                margin-top: rem(50);
                display: flex;
                align-items: center;

                .left_area {
                    width: 10%;
                    float: left;
                    position: relative;
                    z-index: 2;

                    .icon {
                        margin-left: rem(20);
                        width: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .arrow {
                        color: $white;
                        font-size: rem(25);
                        margin-left: rem(35);
                        margin-top: 8px;
                    }
                }

                .right_area {
                    width: calc(100% - 10%);
                    float: right;
                    padding-right: rem(20);
                    position: relative;
                    z-index: 2;

                    ul {
                        float: right;

                        li {
                            margin-right: rem(12);
                            display: inline-block;

                            .icon {
                                color: $white;
                                font-size: rem(26);
                                position: relative;
                                font-weight: 400;

                                &.alert_circle {
                                    &:before {
                                        content: '';
                                        position: absolute;
                                        top: rem(0);
                                        right: rem(0);
                                        width: rem(10);
                                        height: rem(10);
                                        border-radius: rem(7);
                                        margin-top: rem(2);
                                        background-color: $notification;
                                    }
                                }
                            }

                            &:last-child {
                                margin-right: rem(0);
                            }
                        }
                    }
                }

                .header_title {
                    position: absolute;
                    top: rem(0);
                    left: rem(0);
                    right: rem(0);
                    bottom: rem(0);
                    color: $white;
                    margin: rem(0) auto;
                    text-align: center;
                    z-index: 1;

                    h5 {
                        font-size: rem(36);
                        font-weight: 600;
                    }
                }
            }

            .breadcrum_area {
                position: absolute;
                margin-top: rem(105);
                margin-left: rem(30);
                font-weight: 600;
                font-size: rem(16);
                display: flex;
                align-items: center;

                a {
                    color: $form_border_color;
                }

                .icon {
                    margin-left: rem(10);
                    margin-right: rem(15);
                    font-size: rem(10);
                    color: $form_border_color;
                    margin-bottom: rem(2);
                }

                span {
                    color: #DB1C24;
                }
            }

            .linking_area {
                margin-top: rem(100);
                margin-left: rem(22);

                .inner_area {

                    a {
                        font-size: rem(16);
                        font-weight: 600;
                        color: $dashboard;
                    }

                    span {
                        padding-left: rem(10);
                        font-size: rem(16);
                        font-weight: 600;
                        color: $dashboard_ward;
                    }

                    .task_area {
                        padding-left: 10px;
                    }

                }

                .dropdown_area {
                    float: right;
                    margin-top: rem(15);
                    margin-right: rem(45);
                    background: $dashboard;
                    border-radius: rem(6);
                    color: $white;

                    button {
                        &.btn-primary {
                            border-radius: rem(6);
                            border: none;
                            height: rem(40);
                            width: rem(271);
                            font-size: rem(16);

                            &:hover {
                                background-color: $dashboard;
                                border-radius: rem(6);
                                color: $white;
                            }

                            &:focus {
                                background: unset;
                                color: unset;
                                border-radius: rem(6);
                            }
                        }

                        .icon {
                            display: inline-block;
                            margin-right: rem(10);
                            @include transition(0.3s ease-in-out);
                        }

                        &.dropdown-toggle {
                            &:after {
                                content: none;
                            }



                            // &:before{
                            //     content:'\f078';
                            //     font-family:"Font Awesome 5";
                            //     position: absolute;
                            //     top: 0;
                            //     right: 0;
                            //     margin-top: rem(8);
                            //     margin-right: rem(27);
                            //     font-size: rem(18);
                            //     @include transition(all 0.5s ease-in-out);
                            //     &:focus{
                            //         @include rotate(180deg);
                            //     }
                            // }


                        }

                        &:focus {
                            .down_icon {
                                @include rotate(180deg);
                            }
                        }
                    }

                    .dropdown-menu {
                        &.show {
                            left: 50% !important;
                            right: auto !important;
                            transform: translate(calc(-50% + 3px), 42px) !important;
                        }
                    }
                }
            }
        }
    }

}

/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .dashboard_screen {
        .dashboardheader_area {
            background-color: unset;
            background-image: url("../../../assets/images/dashboard_header.png");
            background-repeat: no-repeat;
            background-size: cover;
            height: rem(272);

            .breadcrum_area {
                position: absolute;
                margin-top: rem(50);
                margin-left: rem(30);
                font-weight: 600;
                font-size: rem(16);
                display: flex;
                align-items: center;
            }
        }
    }

}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .dashboard_screen {
        .dashboardheader_area {
            background-color: unset;
            height: rem(130);
            background-image: url("../../../assets/images/dashboard_header.png");
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: rem(25);

            .breadcrum_area {
                margin-top: rem(10);
                margin-left: rem(12);

                a,
                span {
                    font-size: rem(14);
                }
            }

            .linking_area {
                margin-top: rem(35);

                .dropdown_area {
                    float: unset;
                    margin-right: rem(0);

                    button {
                        .btn-primary {
                            width: 100%;
                        }
                    }
                }
            }

            .inner_area {
                margin-top: rem(17);

                .header_title {
                    h5 {
                        font-size: rem(22);
                        font-weight: 500;
                    }
                }

                .left_area {
                    .arrow {
                        font-size: 20px;
                        margin-left: 25px;
                        margin-top: 5px;
                    }
                }
            }


        }
    }


}


/* Smaller phones */
@media (max-width: 360px) {
    .dashboard_screen {
        .dashboardheader_area {
            background-color: unset;
            height: rem(130);
            background-image: url("../../../assets/images/dashboard_header.png");
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: rem(25);

            .breadcrum_area {
                margin-top: rem(10);
                margin-left: rem(12);

                a,
                span {
                    font-size: rem(14);
                }
            }

            .linking_area {
                margin-top: rem(35);

                .dropdown_area {
                    float: unset;
                    margin-right: rem(25);

                    button {
                        .btn-primary {
                            width: 100%;
                        }
                    }
                }
            }

            .inner_area {
                margin-top: rem(17);

                .header_title {
                    h5 {
                        font-size: rem(22);
                        font-weight: 500;
                    }
                }

                .left_area {
                    .arrow {
                        font-size: 18px;
                        margin-left: 25px;
                        margin-top: 5px;
                    }
                }
            }
        }
    }

}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}