.search_screen {
    .without_image_header {
        .header_area {
            .details {
                .icon_area {
                    display: none;
                }
            }
        }
    }

    form {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 40px;

        .form-group {
            position: relative;

            .icon {
                position: absolute;
                top: 0;
                right: 0;
                margin-top: 15px;
                margin-right: 15px;
                color: $cancel_btn_color;
                font-size: 28px;
            }
        }

    }

    .task_btn_area {
        .custom_tabs_area {
            .custom_tabs {
                overflow-x: hidden;
            }
        }
    }

    .search_data_details {
        padding-left: 30px;
        padding-top: 18px;
        padding-bottom: 15px;

        h4 {
            font-size: 25px;
            font-weight: 500;
            line-height: 30px;
            color: $black_2;
        }
    }

    .custom_tab_data {
        .noImg {
            width: 40% !important;
        }
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .search_screen {
        .without_image_header {
            .header_area {
                .details {
                    .icon_area {
                        display: none;
                    }
                }
            }
        }

        form {
            padding-left: 20px;
            padding-right: 20px;

            .form-group {
                position: relative;

                .icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-top: 15px;
                    margin-right: 15px;
                    color: $cancel_btn_color;
                    font-size: 28px;
                }
            }

        }

        .task_btn_area {
            .custom_tabs_area {
                .custom_tabs {
                    overflow-x: hidden;
                }
            }
        }

        .search_data_details {
            padding-left: 30px;
            padding-top: 18px;
            padding-bottom: 15px;

            h4 {
                font-size: 25px;
                font-weight: 500;
                line-height: 30px;
                color: $black_2;
            }
        }

        .custom_tab_data {
            .noImg {
                width: 75% !important;
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .search_screen {
        form {
            padding-left: 0;
            padding-right: 0;

            .form-group {
                .icon {
                    font-size: 20px;
                }
            }
        }

        .task_btn_area {
            .custom_tabs_area {
                .custom_tabs {
                    overflow-x: scroll;
                }
            }
        }

    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .search_screen {
        form {
            padding-left: 0;
            padding-right: 0;

            .form-group {
                .icon {
                    font-size: 20px;
                }
            }
        }

        .task_btn_area {
            .custom_tabs_area {
                .custom_tabs {
                    overflow-x: scroll;
                }
            }
        }

        .search_data_details {
            padding: 10px;

            h4 {
                font-size: 20px;
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .search_screen {
        form {
            padding-left: 0;
            padding-right: 0;

            .form-group {
                .icon {
                    font-size: 20px;
                }
            }
        }

        .task_btn_area {
            .custom_tabs_area {
                .custom_tabs {
                    overflow-x: scroll;
                }
            }
        }

        .search_data_details {
            padding: 10px;

            h4 {
                font-size: 18px;
            }
        }
    }
}