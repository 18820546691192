.add_staff_section {
    &.profile_lab_area {
        .add_staff_area {
            margin-top: rem(-75);

            .image_section {
                .gallery_img {
                    margin-right: rem(280);
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .add_staff_section {
        &.profile_lab_area {
            .add_staff_area {
                .image_section {
                    .gallery_img {
                        margin-right: 124px;
                    }
                }

                .btn_area {
                    .btn {
                        height: rem(50);
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .add_staff_section {
        &.profile_lab_area {
            .add_staff_area {
                .image_section {
                    .gallery_img {
                        margin-right: 118px;
                    }
                }

                .btn_area {
                    .btn {
                        height: rem(50);
                    }
                }
            }
        }
    }
}