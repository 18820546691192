.without_image_header {
    background-color: $white;

    .header_area {
        position: relative;
        background-color: #DB1C24;
        // background: linear-gradient(135.83deg, #E31E24 8.45%, #7F001F 115.01%);

        .details {
            padding: rem(35) rem(30);
            position: relative;
            color: $white;

            .arrow {
                position: absolute;
                margin-top: rem(12);
                font-size: rem(25);
            }

            .menu_icon {
                position: absolute;
                margin-top: rem(11);

                .img_menu {
                    width: rem(28);

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }

            h3 {
                font-size: rem(36);
                font-weight: 600;
                text-align: center;
            }

            .icon_area {
                position: absolute;
                right: 0;
                margin-top: rem(-40);
                margin-right: 30px;
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
            }

            .icon_user {
                position: absolute;
                right: 0;
                margin-top: rem(-37);
                margin-right: 25px;
                font-size: rem(24);
            }

            .search_area {
                position: absolute;
                right: 0;
                margin-top: rem(-40);
                margin-right: 25px;

                .img_search {
                    width: rem(35);

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }

            .add_area {
                position: absolute;
                right: 0;
                margin-top: rem(-36);
                margin-right: rem(30);

                a {
                    font-size: rem(20);
                    font-weight: 400;
                    color: $white;

                    .add_icon {
                        font-size: rem(18);
                        color: $white;
                        margin-right: rem(5);
                    }
                }
            }
        }

        .breadcrum_area {
            position: absolute;
            margin-top: rem(30);
            margin-left: rem(45);
            font-weight: 600;
            font-size: rem(16);
            display: block;

            .left_area {
                float: left;

                a {
                    color: $form_border_color;
                    cursor: pointer;
                }

                .icon {
                    margin-left: rem(10);
                    margin-right: rem(15);
                    font-size: rem(10);
                    color: $form_border_color;
                }

                span {
                    color: #DB1C24;
                }
            }
        }
    }
}


@media(min-width:768px) and (max-width:1023px) {
    .without_image_header {
        background-color: $white;

        .header_area {
            background-image: url("../../../assets/images/headerImg.png");
            background-repeat: no-repeat;
            background-size: cover;
            height: rem(270);
            position: relative;
            background-color: unset;

            .details {
                padding: rem(45) rem(30);
                position: relative;
                color: $white;

                .arrow {
                    position: absolute;
                    margin-top: rem(12);
                    font-size: rem(25);
                }

                .menu_icon {
                    position: absolute;
                    margin-top: rem(11);

                    .img_menu {
                        width: rem(28);

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }

                h3 {
                    font-size: rem(36);
                    font-weight: 600;
                    text-align: center;
                }

                .icon_area {
                    position: absolute;
                    right: 0;
                    margin-top: rem(-40);
                    margin-right: 25px;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;
                }

                .icon_user {
                    position: absolute;
                    right: 0;
                    margin-top: rem(-37);
                    margin-right: 25px;
                    font-size: rem(24);
                }

                .search_area {
                    position: absolute;
                    right: 0;
                    margin-top: rem(-40);
                    margin-right: 25px;

                    .img_search {
                        width: rem(35);

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .add_area {
                    position: absolute;
                    right: 0;
                    margin-top: rem(-36);
                    margin-right: rem(30);

                    a {
                        font-size: rem(20);
                        font-weight: 400;
                        color: $white;

                        .add_icon {
                            font-size: rem(18);
                            color: $white;
                            margin-right: rem(5);
                        }
                    }
                }
            }

            .breadcrum_area {
                position: absolute;
                margin-top: rem(50);
                margin-left: rem(30);
                font-weight: 600;
                font-size: rem(16);
                // display: flex;
                // align-items: center;
                display: block;

                .left_area {
                    float: left;

                    a {
                        color: $form_border_color;
                        cursor: pointer;
                    }

                    .icon {
                        margin-left: rem(10);
                        margin-right: rem(15);
                        font-size: rem(10);
                        color: $form_border_color;
                    }

                    span {
                        color: #DB1C24;
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .without_image_header {
        .header_area {
            background-image: url("../../../assets/images/headerImg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-color: unset;
            height: rem(200);

            .details {
                padding: rem(25) rem(20);

            }

            .breadcrum_area {
                margin-top: rem(35);
                margin-left: rem(20);
                font-size: rem(14);
            }
        }
    }
}

@media (min-width: rem(538)) and (max-width: rem(575)) {
    .without_image_header {
        .header_area {
            background-image: url("../../../assets/images/headerImg.png");
            background-repeat: no-repeat;
            background-size: cover;
            height: rem(190) !important;
            background-color: unset;

            .details {
                padding: rem(20);

                h3 {
                    font-size: rem(22);
                }

                .arrow {
                    margin-top: 0;
                }

                .add_area {
                    margin-top: rem(-24);
                    margin-right: rem(20);

                    a {
                        font-size: rem(16);

                        .add_icon {
                            font-size: rem(16);
                        }
                    }
                }

                .search_area {
                    margin-top: rem(-30);
                    margin-right: rem(18);

                    .img_search {
                        width: rem(30);
                    }
                }

                .icon_user {
                    font-size: rem(20);
                    margin-right: rem(20);
                    margin-top: rem(-27);
                }

                .menu_icon {
                    margin-top: rem(3);

                    .img_menu {
                        width: rem(23);
                    }
                }
            }

            .breadcrum_area {
                margin-top: rem(45);
                margin-left: rem(20);
                font-size: rem(13);

                .icon {
                    margin-left: 3px;
                    margin-right: 3px;
                }
            }

            .details {
                .icon_area {
                    margin-top: -27px;
                    margin-right: 17px;

                    .img_one {
                        width: 17px;
                        height: 20px;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .img_two {
                        width: 22px;
                        height: 25px;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }

        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(537)) {
    .without_image_header {
        .header_area {
            background-image: url("../../../assets/images/headerImg.png");
            background-repeat: no-repeat;
            background-size: cover;
            height: rem(133);
            background-color: unset;

            .details {
                padding: rem(20);

                h3 {
                    font-size: rem(22);
                }

                .arrow {
                    margin-top: 0;
                }

                .add_area {
                    margin-top: rem(-24);
                    margin-right: rem(20);

                    a {
                        font-size: rem(16);

                        .add_icon {
                            font-size: rem(16);
                        }
                    }
                }

                .search_area {
                    margin-top: rem(-30);
                    margin-right: rem(18);

                    .img_search {
                        width: rem(30);
                    }
                }

                .icon_user {
                    font-size: rem(20);
                    margin-right: rem(20);
                    margin-top: rem(-27);
                }

                .menu_icon {
                    margin-top: rem(3);

                    .img_menu {
                        width: rem(23);
                    }
                }
            }

            .breadcrum_area {
                margin-top: rem(25);
                margin-left: rem(20);
                font-size: rem(13);

                .icon {
                    margin-left: 3px;
                    margin-right: 3px;
                }
            }

            .details {
                .icon_area {
                    margin-top: -27px;
                    margin-right: 17px;

                    .img_one {
                        width: 17px;
                        height: 20px;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .img_two {
                        width: 22px;
                        height: 25px;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }

        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .without_image_header {
        .header_area {
            height: rem(127);
            background-image: url("../../../assets/images/headerImg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-color: unset;

            .details {
                padding: rem(20);

                h3 {
                    font-size: rem(22);
                }

                .arrow {
                    margin-top: 0;
                }

                .menu_icon {
                    margin-top: rem(3);

                    .img_menu {
                        width: rem(20);
                    }
                }

                .icon_user {
                    margin-top: -27px;
                }
            }

            .breadcrum_area {
                margin-top: rem(25);
                margin-left: rem(20);
                font-size: rem(13);

                .icon {
                    margin-left: 3px;
                    margin-right: 3px;
                }
            }

            .details {
                .icon_area {
                    margin-top: -27px;
                    margin-right: 17px;

                    .img_one {
                        width: 17px;
                        height: 20px;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .img_two {
                        width: 22px;
                        height: 25px;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }
}