.report_detail_screen {
    .report_detail {
        padding: rem(90) rem(20) rem(20) rem(20);
        margin-top: rem(-35);

        .main_detail {
            text-transform: capitalize;
            display: flex;
            align-items: center;
            @include clearfix();
            position: relative;
            z-index: 1;

            .left_area {
                float: left;
                width: rem(600);
                @include clearfix();

                .inner_left {
                    float: left;
                    width: rem(106);

                    .img_area {
                        width: rem(106);
                        height: rem(106);
                        border-radius: rem(100);
                        overflow: hidden;
                        cursor: pointer;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .inner_right {
                    float: left;
                    width: calc(100% - 106px);
                    padding-left: rem(15);

                    .name {
                        display: flex;
                        align-items: center;

                        h3 {
                            font-size: rem(36);
                            font-weight: 600;
                            background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            cursor: pointer;
                        }
                    }

                    .gender {
                        display: flex;
                        margin-top: rem(15);
                        align-items: flex-start;

                        p {
                            font-size: rem(16);
                            font-weight: 400;
                            color: $designation;
                            width: auto;
                            display: contents;

                            .gender_icon {
                                margin-right: rem(5);
                                color: #D81B23;
                            }

                            &.general {
                                color: #00954D;
                                margin-left: 15px;
                                display: flex;
                                align-items: start;
                                gap: 4px;

                                &.emergency {
                                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    background-clip: text;
                                    text-fill-color: transparent
                                }

                                .general_img {
                                    position: relative;
                                    top: rem(4);
                                }
                            }

                            span {
                                margin-left: rem(15);
                                background: rgba(55, 55, 128, 0.2);
                                border-radius: rem(5);
                                color: #373780;
                                padding: rem(3) rem(11);
                                display: inline-block;
                            }
                        }
                    }

                    .date {
                        display: flex;
                        margin-top: rem(15);

                        .date_icon {
                            margin-right: rem(5);
                            color: #D81B23;
                        }

                        p {
                            color: $designation;
                            font-size: rem(16);
                            font-weight: 400;
                            width: auto;
                            display: contents;

                            &.bed_space {
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }

            .right_area {
                float: right;
                width: calc(100% - 600px);
                margin-top: rem(20);
                display: flex;
                flex-direction: column;
                align-items: end;

                .id_area {
                    float: right;
                    background: rgba(154, 154, 154, 0.2);
                    border-radius: rem(5);
                    padding: rem(2) rem(16);
                    margin-bottom: rem(10);

                    p {
                        font-weight: 400;
                        font-size: rem(14);
                        line-height: rem(17);
                        color: #666666;
                    }
                }

                .batch {
                    float: right;
                    background: $treatment_color;
                    width: rem(160);
                    height: rem(43);
                    line-height: rem(43);
                    border-radius: rem(22);

                    &:hover {
                        background: darken($treatment_color, 4%);
                    }

                    p {
                        font-size: rem(16);
                        font-weight: 600;
                        color: $white;
                        text-align: center;
                    }
                }

                .dropdown {
                    float: right;

                    .dropdown-toggle {
                        padding: 0;

                        &::after {
                            display: none;
                        }

                        &:focus,
                        &:hover {
                            background-color: transparent;
                        }

                        .batch {
                            float: right;
                            width: rem(160);
                            height: rem(43);
                            line-height: rem(43);
                            border-radius: rem(22);

                            &.orange_bg {
                                background: $treatment_color;

                                &:hover {
                                    background: darken($treatment_color, 4%);
                                }
                            }

                            &.danger {
                                background: $critical_color;

                                &:hover {
                                    background: darken($critical_color, 4%);
                                }
                            }

                            &.success {
                                background: $green;

                                &:hover {
                                    background: darken($green, 4%);
                                }
                            }

                            &.discharges {
                                background: #FFEDAD;

                                &:hover {
                                    background: darken(#FFEDAD, 4%);
                                }
                            }

                            p {
                                font-size: rem(16);
                                font-weight: 600;
                                color: $white;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }

        .info_detail_area {
            border: rem(1) solid $grey;
            border-radius: rem(25);
            box-shadow: 0px, 4px rgba(55, 55, 128, 0.12);
            padding: 13px 42px 10px 42px;
            background-color: $accordin_body;
            overflow: hidden;
            margin-top: 45px;
            position: relative;

            .top_inner_area {
                // border-bottom: rem(1) solid $border_bottom;
                // padding-bottom: rem(15);
                margin-bottom: rem(28);

                .text_type {
                    font-size: rem(24);
                    font-weight: 600;
                    color: $black_2;
                    padding-top: 10px;

                    &:first-child{
                        padding-top: 0;
                    }
                }
            }

            .border_remove {
                tr {
                    border: none;
                }
            }

            table {
                width: 100%;
                margin-bottom: rem(28);

                tbody {
                    tr {
                        border: 1px solid #b0b0b0;

                        table {
                            width: 100%;
                        }
                    }

                    td {
                        width: 33.3%;
                        font-size: rem(16);
                        padding-left: rem(10);
                        padding-top: rem(5);
                        padding-bottom: rem(5);
                        word-break: break-word;

                        &.bold {
                            font-weight: 600;

                        }

                    }
                }
            }


            .bottom_area {
                .inner_info_area {
                    @include clearfix();
                    display: flex;
                    align-items: center;
                    margin-bottom: rem(20);

                    .left_area {
                        float: left;
                        width: rem(200);

                        p {
                            font-size: rem(20);
                            font-weight: 600;
                            color: $black_2;
                        }
                    }

                    .right_area {
                        float: right;
                        width: calc(100% - 200px);

                        .text_area {
                            display: flex;
                            align-items: center;
                            align-content: space-between;

                            span {
                                font-size: rem(16);
                                font-weight: 400;
                                color: $black_2;
                                margin-right: rem(5);
                            }

                            p {
                                font-size: rem(20);
                                font-weight: 400;
                                color: $black_2;
                            }
                        }
                    }
                }
            }

            .circle_img {
                width: rem(185);
                position: absolute;
                bottom: 0;
                right: 0;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        .report_file_area {
            margin-top: 45px;

            .title_area {
                p {
                    font-size: rem(22);
                    font-weight: 600;
                    color: $form_label_color;
                }
            }

            .main_files_area {
                .inner_file_area {
                    border: rem(1) solid $border_line_3;
                    background-color: $file_bg;
                    height: rem(128);
                    width: rem(128);
                    border-radius: rem(20);
                    display: inline-block;
                    margin: 0 6px;
                    margin-top: rem(18);

                    .file_upload {
                        text-align: center;
                        position: relative;
                        top: 26%;

                        .icon_area {
                            font-size: rem(20);
                            color: $black;
                            margin-bottom: rem(12);
                        }

                        p {
                            font-size: rem(14);
                            font-weight: 500;
                            color: $black;
                            word-break: break-word;
                        }
                    }
                }
            }
        }
    }
}

/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {}


@media (min-width: 1024px) and (max-width: 1199px) {}

@media(min-width:768px) and (max-width:1023px) {
    .report_detail_screen {
        .report_detail {
            padding: 0 rem(20) rem(20) rem(20);
            margin-top: rem(-35);

            .main_detail {
                text-transform: capitalize;
                display: flex;
                align-items: center;
                @include clearfix();
                position: relative;
                z-index: 1;

                .left_area {
                    float: left;
                    width: rem(500);
                    @include clearfix();

                    .inner_left {
                        float: left;
                        width: rem(106);

                        .img_area {
                            width: rem(106);
                            height: rem(106);
                            border-radius: rem(100);
                            overflow: hidden;
                            cursor: pointer;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .inner_right {
                        float: left;
                        width: calc(100% - 106px);
                        padding-left: rem(15);

                        .name {
                            display: flex;
                            align-items: center;

                            h3 {
                                font-size: rem(36);
                                font-weight: 600;
                                background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                                cursor: pointer;
                            }
                        }

                        .gender {
                            display: flex;
                            margin-top: rem(15);
                            align-items: flex-start;

                            p {
                                font-size: rem(16);
                                font-weight: 400;
                                color: $designation;
                                width: auto;
                                display: contents;

                                .gender_icon {
                                    margin-right: rem(5);
                                    color: #D81B23;
                                }

                                &.general {
                                    color: #00954D;
                                    margin-left: 15px;
                                    display: flex;
                                    align-items: start;
                                    gap: 4px;

                                    &.emergency {
                                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                        background-clip: text;
                                        text-fill-color: transparent
                                    }

                                    .general_img {
                                        position: relative;
                                        top: rem(4);
                                    }
                                }

                                span {
                                    margin-left: rem(15);
                                    background: rgba(55, 55, 128, 0.2);
                                    border-radius: rem(5);
                                    color: #373780;
                                    padding: rem(3) rem(11);
                                    display: inline-block;
                                }
                            }
                        }

                        .date {
                            display: flex;
                            margin-top: rem(15);

                            .date_icon {
                                margin-right: rem(5);
                                color: #D81B23;
                            }

                            p {
                                color: $designation;
                                font-size: rem(16);
                                font-weight: 400;
                                width: auto;
                                display: contents;

                                &.bed_space {
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }

                .right_area {
                    float: right;
                    width: calc(100% - 500px);
                    margin-top: rem(20);

                    .id_area {
                        float: right;
                        background: rgba(154, 154, 154, 0.2);
                        border-radius: rem(5);
                        padding: rem(2) rem(16);
                        margin-bottom: rem(10);

                        p {
                            font-weight: 400;
                            font-size: rem(14);
                            line-height: rem(17);
                            color: #666666;
                        }
                    }

                    .batch {
                        float: right;
                        background: $treatment_color;
                        width: rem(160);
                        height: rem(43);
                        line-height: rem(43);
                        border-radius: rem(22);

                        &:hover {
                            background: darken($treatment_color, 4%);
                        }

                        p {
                            font-size: rem(16);
                            font-weight: 600;
                            color: $white;
                            text-align: center;
                        }
                    }

                    .dropdown {
                        float: right;

                        .dropdown-toggle {
                            padding: 0;

                            &::after {
                                display: none;
                            }

                            &:focus,
                            &:hover {
                                background-color: transparent;
                            }

                            .batch {
                                float: right;
                                width: rem(160);
                                height: rem(43);
                                line-height: rem(43);
                                border-radius: rem(22);

                                &.orange_bg {
                                    background: $treatment_color;

                                    &:hover {
                                        background: darken($treatment_color, 4%);
                                    }
                                }

                                &.danger {
                                    background: $critical_color;

                                    &:hover {
                                        background: darken($critical_color, 4%);
                                    }
                                }

                                &.success {
                                    background: $green;

                                    &:hover {
                                        background: darken($green, 4%);
                                    }
                                }

                                &.discharges {
                                    background: #FFEDAD;

                                    &:hover {
                                        background: darken(#FFEDAD, 4%);
                                    }
                                }

                                p {
                                    font-size: rem(16);
                                    font-weight: 600;
                                    color: $white;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }

            .info_detail_area {
                border: rem(1) solid $grey;
                border-radius: rem(25);
                box-shadow: 0px, 4px rgba(55, 55, 128, 0.12);
                padding: 13px 42px 10px 42px;
                background-color: $accordin_body;
                overflow: hidden;
                margin-top: 45px;
                position: relative;

                .top_inner_area {
                    // border-bottom: rem(1) solid $border_bottom;
                    // padding-bottom: rem(15);
                    margin-bottom: rem(28);

                    .text_type {
                        font-size: rem(24);
                        font-weight: 600;
                        color: $black_2;
                    }
                }

                .border_remove {
                    tr {
                        border: none;
                    }
                }

                table {
                    width: 100%;
                    margin-bottom: rem(28);

                    tbody {
                        tr {
                            border: 1px solid #b0b0b0;

                            table {
                                width: 100%;
                            }
                        }

                        td {
                            width: 33.3%;
                            font-size: rem(16);
                            padding-left: rem(10);
                            padding-top: rem(5);
                            padding-bottom: rem(5);
                            word-break: break-word;

                            &.bold {
                                font-weight: 600;

                            }

                        }
                    }
                }


                .bottom_area {
                    .inner_info_area {
                        @include clearfix();
                        display: flex;
                        align-items: center;
                        margin-bottom: rem(20);

                        .left_area {
                            float: left;
                            width: rem(200);

                            p {
                                font-size: rem(20);
                                font-weight: 600;
                                color: $black_2;
                            }
                        }

                        .right_area {
                            float: right;
                            width: calc(100% - 200px);

                            .text_area {
                                display: flex;
                                align-items: center;
                                align-content: space-between;

                                span {
                                    font-size: rem(16);
                                    font-weight: 400;
                                    color: $black_2;
                                    margin-right: rem(5);
                                }

                                p {
                                    font-size: rem(20);
                                    font-weight: 400;
                                    color: $black_2;
                                }
                            }
                        }
                    }
                }

                .circle_img {
                    width: rem(185);
                    position: absolute;
                    bottom: 0;
                    right: 0;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }

            .report_file_area {
                margin-top: 45px;

                .title_area {
                    p {
                        font-size: rem(22);
                        font-weight: 600;
                        color: $form_label_color;
                    }
                }

                .main_files_area {
                    .inner_file_area {
                        border: rem(1) solid $border_line_3;
                        background-color: $file_bg;
                        height: rem(128);
                        width: rem(128);
                        border-radius: rem(20);
                        display: inline-block;
                        margin: 0 6px;
                        margin-top: rem(18);

                        .file_upload {
                            text-align: center;
                            position: relative;
                            top: 26%;

                            .icon_area {
                                font-size: rem(20);
                                color: $black;
                                margin-bottom: rem(12);
                            }

                            p {
                                font-size: rem(14);
                                font-weight: 500;
                                color: $black;
                                word-break: break-word;
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .report_detail_screen {
        .report_detail {
            padding: 0 rem(10) rem(20) rem(10);

            .main_detail {
                display: block;
                width: 100%;

                .left_area {
                    float: none;
                    width: 100%;

                    .inner_right {
                        .name {
                            h3 {
                                font-size: rem(20);
                            }

                            .img_icon {
                                width: rem(16);
                                margin-left: rem(6);
                            }
                        }

                        .date {
                            p {
                                font-size: rem(14);
                            }
                        }

                    }
                }

                .right_area {
                    float: none;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    row-gap: 12px;

                    .id_area {
                        margin-bottom: 0;
                        margin-right: rem(15);
                    }

                    .button_area {
                        margin-right: rem(-15);

                        .btn {
                            font-size: rem(13);
                            width: rem(130);
                            height: rem(40);
                        }
                    }
                }
            }

            .info_detail_area {
                margin-top: 45px;
                padding: rem(13) rem(30) rem(10) rem(30);

                .top_inner_area {
                    padding-bottom: rem(12);
                    margin-bottom: rem(24);

                    .text_type {
                        font-size: rem(22);
                    }
                }

                .bottom_area {
                    .inner_info_area {
                        margin-bottom: rem(18);

                        .left_area {
                            p {
                                font-size: rem(18);
                            }
                        }

                        .right_area {
                            .text_area {
                                p {
                                    font-size: rem(18);
                                }
                            }
                        }
                    }
                }

                .circle_img {
                    width: rem(124);
                }
            }

            .report_file_area {
                margin-top: rem(30);

                .title_area {
                    p {
                        font-size: rem(20);
                    }
                }

                .main_files_area {
                    .inner_file_area {
                        height: rem(90);
                        width: rem(112);
                        margin-top: rem(12);
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .report_detail_screen {
        .report_detail {
            padding: 0 rem(0) rem(20) rem(0);

            .main_detail {
                margin-top: rem(28);
                display: block;

                .left_area {
                    float: unset;
                    width: 100%;

                    .inner_left {
                        width: rem(60);

                        .img_area {
                            width: rem(60);
                            height: rem(60);
                            border-radius: 50%;
                        }
                    }

                    .inner_right {
                        width: calc(100% - 60px);
                        padding-left: rem(10);

                        .name {
                            h3 {
                                font-size: rem(22);
                            }

                            .img_icon {
                                width: rem(20);
                                margin-left: rem(6);
                            }
                        }

                        .gender {
                            margin-top: rem(10);
                            display: inline-block;

                            p {
                                margin-top: rem(4);

                                &.general {
                                    margin-left: 0;
                                }
                            }
                        }

                        .date {
                            display: inline-block;
                            margin-top: rem(10);

                            p {
                                margin-right: 10px;
                                display: inline-block;
                                margin-top: rem(4);
                            }

                            .ps-4 {
                                padding-left: 0 !important;
                            }
                        }
                    }
                }

                .right_area {
                    float: none;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: rem(15);
                    row-gap: 12px;

                    .id_area {
                        margin-bottom: 0;
                        margin-right: rem(15);
                    }

                    .button_area {
                        .btn {
                            font-size: rem(10);
                            width: rem(106);
                            height: rem(35);
                            margin-top: rem(40);
                        }
                    }

                    .batch {
                        float: unset;
                        height: rem(35);
                        line-height: rem(35);
                    }

                    .dropdown {
                        .batch {
                            float: unset;
                            height: rem(35);
                            line-height: rem(35);
                        }
                    }
                }
            }

            .info_detail_area {
                border-radius: rem(15);
                padding: rem(10) rem(15) rem(10) rem(15);
                margin-top: rem(25);

                .top_inner_area {
                    padding-bottom: rem(10);
                    margin-bottom: rem(15);

                    .text_type {
                        font-size: rem(20);
                    }
                }

                .bottom_area {
                    .inner_info_area {
                        .left_area {
                            width: 106px;

                            p {
                                font-size: 16px;
                            }
                        }

                        .right_area {
                            width: calc(100% - 106px);

                            .text_area {
                                p {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }

                .circle_img {
                    width: 90px;
                }
            }

            .report_file_area {
                margin-top: rem(25);

                .title_area {
                    p {
                        font-size: rem(20);
                    }
                }

                .main_files_area {
                    .inner_file_area {
                        height: 80px;
                        width: 105px;
                        margin-top: 12px;
                        border-radius: 15px;

                        .file_upload {
                            top: 23%;

                            .icon_area {
                                font-size: rem(20);
                                margin-bottom: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .report_detail_screen {
        .report_detail {
            padding: 0 rem(0) rem(20) rem(0);

            .main_detail {
                margin-top: rem(28);
                display: block;

                .left_area {
                    float: unset;
                    width: 100%;

                    .inner_left {
                        width: rem(60);

                        .img_area {
                            width: rem(60);
                            height: rem(60);
                            border-radius: 50%;
                        }
                    }

                    .inner_right {
                        width: calc(100% - 60px);
                        padding-left: rem(10);

                        .name {
                            h3 {
                                font-size: rem(22);
                            }

                            .img_icon {
                                width: rem(20);
                                margin-left: rem(6);
                            }
                        }

                        .gender {
                            margin-top: rem(10);
                            display: inline-block;

                            p {
                                margin-top: rem(4);

                                &.general {
                                    margin-left: 0;
                                }
                            }
                        }

                        .date {
                            display: inline-block;
                            margin-top: rem(10);

                            p {
                                margin-right: 10px;
                                display: inline-block;
                                margin-top: rem(4);
                            }

                            .ps-4 {
                                padding-left: 0 !important;
                            }
                        }
                    }
                }

                .right_area {
                    float: none;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: rem(15);
                    row-gap: 12px;

                    .id_area {
                        margin-bottom: 0;
                        margin-right: rem(15);
                    }

                    .button_area {
                        .btn {
                            font-size: rem(10);
                            width: rem(106);
                            height: rem(35);
                            margin-top: rem(40);
                        }
                    }

                    .batch {
                        float: unset;
                        height: rem(35);
                        line-height: rem(35);
                    }

                    .dropdown {
                        .batch {
                            float: unset;
                            height: rem(35);
                            line-height: rem(35);
                        }
                    }
                }
            }

            .info_detail_area {
                border-radius: rem(15);
                padding: rem(10) rem(15) rem(10) rem(15);
                margin-top: rem(25);

                .top_inner_area {
                    padding-bottom: rem(10);
                    margin-bottom: rem(15);

                    .text_type {
                        font-size: rem(20);
                    }
                }

                .bottom_area {
                    .inner_info_area {
                        .left_area {
                            width: 106px;

                            p {
                                font-size: 16px;
                            }
                        }

                        .right_area {
                            width: calc(100% - 106px);

                            .text_area {
                                p {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }

                .circle_img {
                    width: 90px;
                }
            }

            .report_file_area {
                margin-top: rem(25);

                .title_area {
                    p {
                        font-size: rem(20);
                    }
                }

                .main_files_area {
                    .inner_file_area {
                        height: 80px;
                        width: 100px;
                        margin-top: 12px;
                        border-radius: 15px;

                        .file_upload {
                            top: 23%;

                            .icon_area {
                                font-size: rem(20);
                                margin-bottom: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}