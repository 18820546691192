.welcome_screen {
    flex: 1;
    justify-content: center;
    background: #E31E24;
    background: -webkit-linear-gradient(to left, #FFFFFF, #e31e242e, #E31E24);
    background: linear-gradient(to left, #FFFFFF, #e31e242e, #E31E24);
    min-height: 100vh;
    height: 100%;
    padding-left: 140px;
    padding-right: 140px;

    .description {
        // padding: 0 rem(10);
        // margin-top: rem(45);
        // position: relative;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
            font-size: rem(60);
            font-weight: 600;
        }

        p {
            font-size: rem(24);
            font-weight: 400;
            padding-top: rem(15);
        }

        .img_area {
            display: flex;
            gap: rem(20);
            padding-top: rem(30);
        }

        .skip_btn {
            position: absolute;
            right: 0;
            top: 0;
            margin-right: rem(30);
            margin-top: rem(30);

            a {
                color: $dark_red_2;
                font-size: rem(22);
                font-weight: 600;
            }
        }

        .btn_area_bottom {
            position: absolute;
            right: 50px;
            bottom: 60px;
            // margin-bottom: rem(-160);
            // margin-right: rem(30);
            // display: flex;
            // align-items: center;
            // gap: rem(20);
            // float: right;
            // width: rem(210);
            // margin-bottom: rem(20);


            // a {
            //     color: $white;
            //     font-size: rem(22);
            //     font-weight: 600;
            // }

            .btn {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .btn-primary {
                font-size: rem(24);
                background: #ffffff;
                color: #E31E24;
                border-radius: 30px;
                opacity: 0.7;
                border: 1px solid #E31E24;

                &:hover {
                    background: #E31E24;
                    color: #fff;
                }
            }
        }
    }

    .splash_bottom {
        // position: absolute;
        // bottom: 0;
        // height: rem(620);
        // width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        // justify-content: center;

        .bottom_area {
            // background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
            // background-image: url('../../../assets/images/splashBackground.png');
            // background-repeat: no-repeat;
            // background-size: cover;
            // margin-top: rem(40);
            // height: rem(555);
            // width: rem(768);
            // max-height: 100%;
            // max-width: 100%;

            .details {
                position: relative;

                .img_one {
                    width: rem(430);
                    height: rem(430);
                    display: block;
                    margin: 0 auto;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .one_half {
                    @include clearfix();
                    display: flex;
                    align-items: end;

                    .left_area {
                        width: calc(100% - 210px);

                        .img_two {
                            // position: absolute;
                            // bottom: 0;
                            // left: 0;
                            // margin-bottom: rem(-200);
                            // margin-left: rem(-28);
                            width: rem(220);
                            height: rem(225);
                            overflow: hidden;
                            float: left;


                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }


                }

            }
        }
    }
}


@media (min-width: 1280px) and (max-width: 1399px) {
    .welcome_screen {
        padding-left: 40px;
        padding-right: 40px;

        .splash_bottom {
            .bottom_area {
                .details {
                    .img_one {
                        width: 350px;
                        height: 350px;
                    }
                }
            }
        }
    }
}


/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    .welcome_screen {
        padding-left: 40px;
        padding-right: 40px;

        .splash_bottom {

            .bottom_area {
                .details {
                    .img_one {
                        width: 350px;
                        height: 350px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .welcome_screen {
        padding-left: 20px;
        padding-right: 20px;

        .description {
            h3 {
                font-size: 40px;
            }

            p {
                font-size: 20px;
            }
        }

        .splash_bottom {

            .bottom_area {
                .details {
                    .img_one {
                        width: 270px;
                        height: 270px;
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .welcome_screen {
        background: #fff;
        padding-left: 20px;
        padding-right: 20px;

        .description {
            height: 100%;
            margin-top: 40px;

            h3 {
                font-size: rem(30);
            }

            p {
                font-size: rem(18);
                padding-top: rem(5);
            }

            .img_area {
                width: 270px;
                height: 270px;
            }

            .skip_btn {
                a {
                    font-size: rem(20);
                }
            }

            .btn_area_bottom {
                width: rem(150);
                // margin-right: rem(10);
                // margin-bottom: rem(-140);
                // gap: rem(10);

                .btn {
                    width: rem(140);
                    font-size: rem(20);
                }
            }

        }

        .splash_bottom {
            height: rem(275);
            width: 100%;

            .bottom_area {
                background-size: cover;
                // height: rem(416);
                width: rem(667);

                .details {
                    .img_one {
                        width: rem(225);
                        height: rem(216);
                    }

                    .one_half {
                        .left_area {
                            width: calc(100% - 150px);

                            .img_two {
                                // margin-bottom: rem(-182);
                                width: rem(150);
                                height: rem(90);
                            }
                        }


                        &.space_area {
                            .left_area {
                                width: calc(100% - 170px);
                            }

                            .btn_area_bottom {
                                width: rem(170);
                                margin-bottom: rem(15);

                                .btn {
                                    width: rem(155);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .welcome_screen {
        background: #fff;
        padding-left: 20px;
        padding-right: 20px;

        .description {
            height: 100%;
            margin-top: rem(50);

            h3 {
                font-size: rem(28);
            }

            p {
                font-size: rem(16);
            }

            .skip_btn {
                a {
                    font-size: rem(20);
                }
            }

            .btn_area_bottom {

                .btn {
                    font-size: rem(20);
                }
            }
        }


        .splash_bottom {
            margin-bottom: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            margin-top: 40px;

            .bottom_area {
                width: 100%;

                .details {
                    .img_one {
                        width: 245px;
                        height: 210px;
                    }

                    .one_half {
                        .left_area {
                            width: calc(100% - 140px);

                            .img_two {
                                // margin-bottom: rem(-159);
                                width: rem(130);
                                height: rem(130);
                            }
                        }

                        &.space_area {
                            .left_area {
                                width: calc(100% - 170px);
                            }

                            .btn_area_bottom {
                                width: rem(170);
                                margin-bottom: rem(15);
                                margin-right: 0;

                                .btn {
                                    width: rem(155);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .welcome_screen {
        background: #fff;
        padding-left: 20px;
        padding-right: 20px;

        .description {
            height: 100%;
            margin-top: rem(50);

            h3 {
                font-size: rem(28);
            }

            p {
                font-size: rem(16);
            }

            .skip_btn {
                a {
                    font-size: rem(20);
                }
            }

            .btn_area_bottom {

                .btn {
                    font-size: rem(20);
                }
            }
        }


        .splash_bottom {
            margin-bottom: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            margin-top: 40px;

            .bottom_area {
                width: 100%;

                .details {
                    .img_one {
                        width: 245px;
                        height: 210px;
                    }

                    .one_half {
                        .left_area {
                            width: calc(100% - 140px);

                            .img_two {
                                // margin-bottom: rem(-159);
                                width: rem(130);
                                height: rem(130);
                            }
                        }

                        &.space_area {
                            .left_area {
                                width: calc(100% - 170px);
                            }

                            .btn_area_bottom {
                                width: rem(170);
                                margin-bottom: rem(15);
                                margin-right: 0;

                                .btn {
                                    width: rem(155);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}