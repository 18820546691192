.add_hospital_area {
    .hospital_content_area {
        padding: 0 rem(30);
        padding-top: 90px;

        .form_section {
            width: 1330px;
            max-width: 100%;
            margin: 0 auto;
            margin-top: rem(50);

            .form-group {
                .form-label {
                    span {
                        color: $critical_color;
                    }
                }
            }

            .dropdown_box {
                position: relative;

                p {
                    &.text-danger {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        margin-bottom: rem(-20);
                    }
                }

                h6 {
                    font-size: rem(22);
                    font-weight: 600;
                    color: $form_label_color;

                    span {
                        color: $critical_color;
                    }
                }

                .dropdown_area {
                    margin-top: rem(15);
                    // margin-right: rem(45);
                    background: transparent;
                    border: 1px solid transparent;
                    border-radius: rem(10);
                    color: $white;
                    // width: rem(342);
                    margin-bottom: rem(30);

                    button {
                        position: relative;

                        &.btn-primary {
                            border-radius: rem(10);
                            border: 1px solid $dashboard;
                            height: rem(60);
                            width: 100%;
                            font-size: rem(22);
                            font-weight: 400;
                            color: $designation;
                            text-align: start;
                            padding: 0 rem(20);
                            background-color: transparent;
                            overflow: hidden;

                            &::placeholder {
                                opacity: 0.5;
                            }

                            &:hover {
                                background-color: transparent;
                                color: $designation;
                            }

                            &:focus {
                                background: transparent;
                                color: $designation;
                            }
                        }

                        .icon {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            margin-right: 20px;
                            margin-top: 15px;
                            @include transition(0.3s ease-in-out);
                        }

                        &.dropdown-toggle {
                            &:after {
                                content: none;
                            }
                        }

                        &:focus {
                            box-shadow: none;

                            .down_icon {
                                @include rotate(180deg);
                                margin-bottom: rem(15);
                            }
                        }
                    }

                    .dropdown-menu {
                        &.show {
                            left: 50% !important;
                            right: auto !important;
                            transform: translate(calc(-50% + 3px), 60px) !important;
                            max-height: rem(230);
                            overflow-y: auto;
                        }
                    }
                }
            }

            .btn_area {
                margin-bottom: rem(50);
                gap: 30px;
            }
        }
    }
}


/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {}


@media (min-width: 1024px) and (max-width: 1199px) {
    .add_hospital_area {
        .hospital_content_area {
            .form_section {
                .btn_area {
                    gap: 20px;
                }
            }
        }
    }
}


@media(min-width:768px) and (max-width:1023px) {
    .add_hospital_area {
        .hospital_content_area {
            padding: 0 rem(30);

            .form_section {
                margin-top: rem(30);

                .form-group {
                    .form-label {
                        span {
                            color: $critical_color;
                        }
                    }
                }

                .dropdown_box {
                    position: relative;

                    p {
                        &.text-danger {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            margin-bottom: rem(-20);
                        }
                    }

                    h6 {
                        font-size: rem(22);
                        font-weight: 600;
                        color: $form_label_color;

                        span {
                            color: $critical_color;
                        }
                    }

                    .dropdown_area {
                        margin-top: rem(15);
                        margin-right: rem(45);
                        background: transparent;
                        border: 1px solid transparent;
                        border-radius: rem(10);
                        color: $white;
                        width: rem(342);
                        margin-bottom: rem(30);

                        button {
                            position: relative;

                            &.btn-primary {
                                border-radius: rem(10);
                                border: 1px solid $dashboard;
                                height: rem(60);
                                width: 100%;
                                font-size: rem(22);
                                font-weight: 400;
                                color: $designation;
                                text-align: start;
                                padding: 0 rem(20);
                                background-color: transparent;
                                overflow: hidden;

                                &::placeholder {
                                    opacity: 0.5;
                                }

                                &:hover {
                                    background-color: transparent;
                                    color: $designation;
                                }

                                &:focus {
                                    background: transparent;
                                    color: $designation;
                                }
                            }

                            .icon {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                right: 0;
                                margin-right: 20px;
                                margin-top: 15px;
                                @include transition(0.3s ease-in-out);
                            }

                            &.dropdown-toggle {
                                &:after {
                                    content: none;
                                }
                            }

                            &:focus {
                                box-shadow: none;

                                .down_icon {
                                    @include rotate(180deg);
                                    margin-bottom: rem(15);
                                }
                            }
                        }

                        .dropdown-menu {
                            &.show {
                                left: 50% !important;
                                right: auto !important;
                                transform: translate(calc(-50% + 3px), 60px) !important;
                                max-height: rem(230);
                                overflow-y: auto;
                            }
                        }
                    }
                }

                .btn_area {
                    gap: 15px;
                    margin-bottom: rem(50);
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
}



/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .without_image_header {
        .header_area {
            height: rem(235);

            .breadcrum_area {
                margin-top: rem(45);
            }
        }
    }

    .add_hospital_area {
        .hospital_content_area {
            padding: 0 rem(20);

            .form_section {
                margin-top: rem(25);

                .form-group {
                    .form-label {
                        font-size: rem(20);
                    }
                }

                .btn_area {
                    margin-bottom: rem(30);
                    gap: 15px;

                    .btn {
                        font-size: rem(22);
                    }
                }

                .dropdown_box {
                    h6 {
                        font-size: rem(20);
                    }

                    .dropdown_area {
                        width: 100%;

                        button {
                            &.btn-primary {
                                width: 100%;
                            }
                        }

                        .dropdown-menu {
                            &.show {
                                transform: translate(calc(-50% + 3px), 62px) !important;
                            }
                        }
                    }
                }
            }
        }
    }

}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .without_image_header {
        .header_area {
            height: rem(235);
        }
    }

    .add_hospital_area {
        .hospital_content_area {
            padding: 0 rem(15);

            .form_section {
                margin-top: rem(20);

                .form-group {
                    margin-bottom: rem(22);

                    .form-label {
                        font-size: rem(18);
                    }
                }

                .btn_area {
                    margin-bottom: rem(20);
                    gap: 15px;

                    .btn {
                        font-size: rem(20);
                    }
                }

                .dropdown_box {
                    h6 {
                        font-size: rem(18);
                    }

                    .dropdown_area {
                        width: 100%;
                        margin-bottom: rem(22);

                        button {
                            &.btn-primary {
                                width: 100%;
                                height: rem(53);
                                font-size: rem(20);
                            }

                            .icon {
                                margin-top: rem(13);
                            }

                            &:focus {

                                .down_icon {
                                    @include rotate(180deg);
                                    margin-bottom: rem(13);
                                }
                            }
                        }

                        .dropdown-menu {
                            &.show {
                                transform: translate(calc(-50% + 3px), 54px) !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .without_image_header {
        .header_area {
            height: rem(235);
        }
    }

    .add_hospital_area {
        .hospital_content_area {
            padding: 0 rem(15);

            .form_section {
                margin-top: rem(20);

                .form-group {
                    margin-bottom: rem(22);

                    .form-label {
                        font-size: rem(18);
                    }
                }

                .btn_area {
                    margin-bottom: rem(20);
                    gap: 15px;


                    .btn {
                        font-size: rem(20);
                    }
                }

                .dropdown_box {
                    h6 {
                        font-size: rem(18);
                    }

                    .dropdown_area {
                        width: 100%;
                        margin-bottom: rem(22);

                        button {
                            &.btn-primary {
                                width: 100%;
                                height: rem(53);
                                font-size: rem(20);
                            }

                            .icon {
                                margin-top: rem(13);
                            }

                            &:focus {

                                .down_icon {
                                    @include rotate(180deg);
                                    margin-bottom: rem(13);
                                }
                            }
                        }

                        .dropdown-menu {
                            &.show {
                                transform: translate(calc(-50% + 3px), 54px) !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}