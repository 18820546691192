.task_list_screen {
    .task_list_details {
        padding: rem(90) rem(30) rem(30);

        .title_btn_area {
            display: flex;
            align-items: center;
            margin-bottom: rem(40);
            margin-top: rem(0);

            .left_area {
                float: left;
                width: 70%;

                p {
                    font-weight: 600;
                    font-size: rem(24);
                    color: $form_label_color;
                }
            }

            .right_area {
                float: right;
                width: calc(100% - 70%);

                .btn {
                    float: right;
                    font-size: rem(24);
                    font-weight: 600;
                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                    border-radius: rem(41);
                    width: rem(153);
                    height: rem(61);
                    border: 0px solid transparent;
                    color: $white;
                    line-height: rem(48);

                    &:hover,
                    &:focus {
                        color: $white;
                        height: rem(61);
                        border: 0px solid transparent;
                        background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                    }
                }


            }
        }

        .search_section {
            @include clearfix();
            margin-bottom: rem(45);
            display: flex;
            align-items: center;
            position: relative;
            z-index: 2;

            .filter_button {
                position: relative;

                .btn-primary {
                    float: right;
                    width: rem(124);
                    height: rem(60);
                    margin-left: rem(20);
                    font-size: rem(22);
                    font-weight: 400;
                    border-radius: rem(10);
                    background: rgba(255, 0, 0, 0.15);
                    border: rem(1.5) solid rgba(255, 0, 0, 0.4);
                    color: #140745;
                }

                .btn-border {
                    background-color: $white;
                    border: rem(1.5) solid $form_border_color;
                }

                .filter_icon {
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin-top: 10px;
                    margin-right: 10px;
                    font-size: 7px;
                    color: $dark_red_2;
                }

                .image_area {
                    height: rem(22);
                    padding-right: rem(10);
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .search_area {
                width: calc(100% - 250px);
                float: left;

                .form-group {
                    position: relative;
                    margin-bottom: 0;

                    .form-control {
                        // height: rem(55);
                        // padding-right: rem(50);
                        padding-left: rem(45);

                    }
                }

                .icon {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    top: 0;
                    font-size: rem(20);
                    margin-top: rem(18);
                    margin-left: rem(15);
                    color: $form_label_color;
                }
            }

            .pagination_list {
                padding-top: 30px;
                margin-left: 20px;

                .form-group {
                    .form-select {
                        width: 90px;
                        color: $designation;
                        font-size: rem(20);

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
            }

            .filter_area {
                width: rem(30);
                margin-left: rem(20);
                float: left;
                color: $form_label_color;
                // display: flex;
                border: rem(1) solid $form_label_color;
                padding: rem(5);

                .img_area {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

            }

            .dropdown {
                &::after {

                    &:focus,
                    &.active,
                    &:hover {
                        background-color: transparent;
                    }
                }

                .dropdown-toggle {
                    padding: 0;

                    &::after {
                        display: none;
                    }

                    &:focus,
                    &.active,
                    &:hover {
                        background-color: transparent;
                    }
                }

                .sort_button {
                    width: 100%;
                    margin-left: rem(15);

                    .btn-primary {
                        // float: right;
                        border: rem(2) solid $designation;
                        color: $designation;
                        width: 100%;
                        height: rem(60);
                        background: $white;
                        font-size: rem(20);
                        font-weight: 400;
                    }

                    .btn_bg {
                        background: rgba(255, 0, 0, 0.15);
                        border: 1.5px solid rgba(255, 0, 0, 0.4);
                        font-size: 20px;
                    }

                    .image_area {
                        height: rem(22);
                        padding-left: rem(15);
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }

        }

        .list_detail {
            background: $white;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            @include clearfix();

            .list_data {
                display: flex;
                align-items: center;
                width: 100%;

                .card {
                    border: rem(1) solid $light_grey_2;
                    box-shadow: 0px 0px 8px rgba(17, 17, 17, 0.12);
                    border-radius: rem(25);
                    overflow: hidden;
                    width: 100%;

                    .card-body {
                        padding: 0;

                        .table-responsive {
                            min-height: rem(265);

                            .table {
                                width: 100%;
                                overflow: auto;
                                height: auto;
                                margin-bottom: 0;

                                tr {
                                    border-bottom: rem(0.6) solid rgba(133, 133, 133, 0.6);
                                    position: relative;

                                    td {
                                        padding: rem(23) 0;

                                        &::before {
                                            content: "";
                                            height: auto;
                                            width: rem(8);
                                            background-color: transparent;
                                            position: absolute;
                                            top: 0;
                                            bottom: 0;
                                            left: 0;
                                        }

                                        .dropdown-toggle {
                                            &::after {
                                                content: none;
                                            }

                                            &:focus {
                                                background-color: $white ;
                                            }
                                        }

                                        .profile_info {
                                            @include clearfix();
                                            display: flex;
                                            align-items: center;
                                            padding-left: rem(20);
                                            background-color: transparent;

                                            .left_area {
                                                float: left;
                                                width: rem(50);

                                                .img_profile {
                                                    height: rem(50);
                                                    width: rem(50);
                                                    border-radius: 50%;
                                                    overflow: hidden;

                                                    img {
                                                        height: 100%;
                                                        width: 100%;
                                                        object-fit: cover;
                                                    }
                                                }
                                            }

                                            .right_area {
                                                float: right;
                                                width: calc(100% - 50px);
                                                padding-left: rem(15);


                                                p {
                                                    font-size: rem(16);
                                                    font-weight: 600;
                                                    color: $designation;
                                                    text-align: start;
                                                    margin-bottom: 0;
                                                    margin-top: rem(-20);
                                                    cursor: pointer;

                                                    &:hover {
                                                        text-decoration: underline;
                                                    }
                                                }
                                            }
                                        }

                                    }

                                    &:hover {
                                        td {
                                            background: rgba(232, 232, 232, 0.21);

                                            &::before {
                                                background-color: $dark_red_2;
                                            }
                                        }

                                        // &:nth-child(1) {
                                        //     cursor: pointer;
                                        // }

                                        // &:nth-child(3) {
                                        //     cursor: pointer;
                                        // }
                                    }

                                    &.active {
                                        // td {
                                        //     background: rgba(232, 232, 232, 0.21);

                                        //     &::before {
                                        //         background-color: $critical_color;
                                        //     }
                                        // }
                                    }

                                    &:last-child {
                                        border-bottom: none;
                                    }

                                    th {
                                        border-bottom: rem(1.5) solid rgba(133, 133, 133, 0.8);
                                        word-break: break-word;
                                        white-space: nowrap;
                                        margin-right: rem(20);
                                        padding: rem(25) rem(20);
                                        color: $form_label_color;
                                        font-weight: 600;
                                        font-size: rem(21);

                                        &.width_s {
                                            min-width: rem(250);
                                        }
                                    }

                                    td {
                                        text-align: center;
                                        padding: rem(15) 0;
                                        font-weight: 400;
                                        font-size: rem(16);
                                        color: $designation;

                                        &.title {
                                            font-weight: 500;

                                            &.width_s {
                                                min-width: rem(250);
                                                text-align: start;
                                                padding-left: rem(20);

                                                a {
                                                    background-color: transparent;

                                                    &:hover {
                                                        text-decoration: underline;
                                                        cursor: pointer;
                                                    }
                                                }
                                            }
                                        }

                                        .dropdown {
                                            background-color: transparent;
                                        }

                                        .icon {
                                            background-color: transparent;

                                            .dropdown-menu {
                                                padding: 0;
                                                border-radius: rem(12) 0 rem(12) rem(12);
                                                background: $fade_white;
                                                transform: translate(rem(-40), rem(30)) !important;

                                                .dropdown-item {
                                                    padding: rem(8) rem(20);
                                                    font-size: rem(16);
                                                    font-weight: 400;
                                                    color: $designation;
                                                    padding-left: rem(45);
                                                    position: relative;
                                                    border-bottom: rem(0.2) solid $border_line;

                                                    &:last-child {
                                                        border-bottom: none;
                                                    }

                                                    .image_area {
                                                        padding-left: rem(8);
                                                        overflow: hidden;

                                                        img {
                                                            width: 100%;
                                                            height: 100%;
                                                            object-fit: cover;
                                                        }

                                                        &.delete_icon {
                                                            margin-top: rem(-4);
                                                        }
                                                    }

                                                }

                                            }

                                            .btn {
                                                color: $form_label_color;
                                                padding-top: 0;

                                                &:hover,
                                                &:focus {
                                                    background-color: $white;
                                                }
                                            }

                                            .ellipsis_icon {
                                                font-size: rem(27);
                                                cursor: pointer;
                                            }

                                            .dropdown-toggle::after {
                                                display: none !important;
                                            }
                                        }

                                        .badge_low {
                                            padding: rem(5) rem(10);
                                            background: $light_white;
                                            border-radius: rem(20);
                                            width: rem(73);
                                            height: rem(29);
                                            display: block;
                                            margin: 0 auto;

                                            p {
                                                font-weight: 500;
                                                font-size: rem(14);
                                                color: $notification;
                                            }

                                        }

                                        .badge_high {
                                            padding: rem(5) rem(10);
                                            background: rgba(233, 97, 97, 0.3);
                                            border-radius: rem(20);
                                            width: rem(73);
                                            height: rem(29);
                                            display: block;
                                            margin: 0 auto;

                                            p {
                                                font-weight: 500;
                                                font-size: rem(14);
                                                color: $fade_pink;
                                            }
                                        }

                                        .badge_medium {
                                            padding: rem(5) rem(10);
                                            background: rgba(255, 135, 23, 0.21);
                                            border-radius: rem(20);
                                            width: rem(73);
                                            height: rem(29);
                                            display: block;
                                            margin: 0 auto;

                                            p {
                                                font-weight: 500;
                                                font-size: rem(14);
                                                color: $treatment_color;
                                            }
                                        }

                                        .badge_complete {
                                            padding: rem(10);
                                            background: rgba(120, 182, 18, 0.18);
                                            border-radius: rem(8);
                                            width: rem(88);
                                            height: rem(37);
                                            display: block;
                                            margin: 0 auto;

                                            p {
                                                font-weight: 500;
                                                font-size: rem(14);
                                                line-height: rem(14);
                                                color: $notification;
                                            }
                                        }

                                        .bagde_pending {
                                            padding: rem(10);
                                            background: rgba(255, 135, 23, 0.21);
                                            border-radius: rem(8);
                                            width: rem(88);
                                            height: rem(37);
                                            display: block;
                                            margin: 0 auto;

                                            p {
                                                font-weight: 500;
                                                font-size: rem(14);
                                                color: $treatment_color;
                                                line-height: rem(14);
                                            }
                                        }

                                        .data_table {
                                            @include clearfix();
                                            display: flex;
                                            align-items: center;
                                            cursor: pointer;
                                            background-color: transparent;
                                            // width: 230px;

                                            .left_area {
                                                width: 50px;
                                                padding: 0px;
                                                margin-left: rem(20);
                                                margin-right: rem(10);

                                                .img_area {
                                                    width: rem(50);
                                                    height: rem(50);
                                                    border-radius: 50%;
                                                    overflow: hidden;
                                                    margin-top: rem(5);
                                                    margin-right: rem(10);

                                                    img {
                                                        width: 100%;
                                                        height: 100%;
                                                        object-fit: cover;
                                                    }
                                                }
                                            }

                                            .right_areaa {
                                                width: calc(180px - 60px);
                                                text-align: left;

                                                a {
                                                    font-weight: 500;
                                                    font-size: rem(16);
                                                    color: $designation;
                                                    cursor: pointer;
                                                    text-align: start;
                                                    display: block;

                                                    // padding-left: rem(20);
                                                    &:hover {
                                                        text-decoration: underline;
                                                    }

                                                }


                                                p {
                                                    margin-top: rem(5);
                                                    font-weight: 500;
                                                    font-size: rem(14);
                                                    display: inline-flex;
                                                    text-align: start;


                                                    span {
                                                        // margin-left: rem(30);
                                                        background: rgba(55, 55, 128, 0.2);
                                                        border-radius: rem(5);
                                                        color: $blue;
                                                        background: $light_blue;
                                                        padding: rem(3) rem(11);

                                                        &.designation {
                                                            background: $off_white;
                                                            color: $form_border_color;
                                                            margin-right: rem(14);

                                                        }

                                                        &.consultant {
                                                            background: $light_blue;
                                                            color: $blue;
                                                            margin-right: rem(14);

                                                        }
                                                    }

                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &::-webkit-scrollbar-track {
                                box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                                -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                                background-color: $grey;
                            }

                            &::-webkit-scrollbar {
                                width: 2px;
                                height: 6px;
                                background-color: $dark_red_2;
                                margin: 0 rem(20);
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: $dark_red_2;
                            }
                        }

                    }
                }

            }


            // .data_table{
            //     text-align: inherit;
            // }





            .left_area {
                float: left;
                // width: rem(500);
                @include clearfix();

                .inner_left {
                    float: left;
                    width: rem(106);

                    .img_area {
                        width: rem(106);
                        height: rem(106);
                        border-radius: rem(100);
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .inner_right {
                    float: left;
                    width: calc(100% - 106px);
                    padding-left: rem(15);

                    .name {

                        // padding-top: rem(25);
                        h3 {
                            font-size: rem(36);
                            font-weight: 600;
                            background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }
                    }

                    .gender {
                        display: flex;
                        margin-top: rem(15);

                        p {
                            font-size: rem(16);
                            font-weight: 400;
                            color: $designation;

                            .gender_icon {
                                margin-right: rem(5);
                                color: $dark_red_2;
                            }

                            span {
                                margin-left: rem(30);
                                background: rgba(55, 55, 128, 0.2);
                                border-radius: rem(5);
                                color: $form_border_color;
                                padding: rem(3) rem(11);
                            }
                        }
                    }

                    .date {
                        display: flex;
                        margin-top: rem(15);

                        .date_icon {
                            margin-right: rem(5);
                            color: $dark_red_2;
                        }

                        p {
                            color: $designation;
                            font-size: rem(16);
                            font-weight: 400;
                        }
                    }
                }
            }

            .right_area {
                float: right;
                width: calc(100% - 500px);
                margin-top: rem(20);

                .id_area {
                    float: right;
                    background: rgba(154, 154, 154, 0.2);
                    border-radius: rem(5);
                    padding: rem(2) rem(16);
                    margin-bottom: rem(10);

                    p {
                        font-weight: 400;
                        font-size: rem(14);
                        line-height: rem(17);
                        color: $light_grey;
                    }
                }

                .batch {
                    float: right;
                    background: $treatment_color;
                    width: rem(160);
                    height: rem(43);
                    line-height: rem(43);
                    border-radius: rem(22);

                    &:hover {
                        background: darken($treatment_color, 4%);
                    }

                    p {
                        font-size: rem(16);
                        font-weight: 600;
                        color: $white;
                        text-align: center;
                    }
                }
            }
        }






    }
}




/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {}


@media (min-width: 1024px) and (max-width: 1199px) {
    .add_hospital_area {
        .hospital_content_area {
            .form_section {
                .btn_area {
                    gap: 20px;
                }
            }
        }
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .task_list_screen {
        .task_list_details {
            padding: 0 rem(20) rem(20);

            .title_btn_area {
                display: flex;
                align-items: center;
                margin-bottom: rem(40);
                margin-top: rem(-10);

                .left_area {
                    float: left;
                    width: 70%;

                    p {
                        font-weight: 600;
                        font-size: rem(24);
                        color: $form_label_color;
                    }
                }

                .right_area {
                    float: right;
                    width: calc(100% - 70%);

                    .btn {
                        float: right;
                        font-size: rem(24);
                        font-weight: 600;
                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                        border-radius: rem(41);
                        width: rem(153);
                        height: rem(61);
                        border: 0px solid transparent;
                        color: $white;
                        line-height: rem(48);

                        &:hover,
                        &:focus {
                            color: $white;
                            height: rem(61);
                            border: 0px solid transparent;
                            background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                        }
                    }


                }
            }

            .search_section {
                @include clearfix();
                margin-bottom: rem(45);
                display: flex;
                align-items: center;
                position: relative;
                z-index: 2;

                .filter_button {
                    position: relative;

                    .btn-primary {
                        float: right;
                        width: rem(124);
                        height: rem(60);
                        margin-left: rem(20);
                        font-size: rem(22);
                        font-weight: 400;
                        border-radius: rem(10);
                        background: rgba(255, 0, 0, 0.15);
                        border: rem(1.5) solid rgba(255, 0, 0, 0.4);
                        color: #140745;
                    }

                    .btn-border {
                        background-color: $white;
                        border: rem(1.5) solid $form_border_color;
                    }

                    .filter_icon {
                        position: absolute;
                        right: 0;
                        top: 0;
                        margin-top: 10px;
                        margin-right: 10px;
                        font-size: 7px;
                        color: $dark_red_2;
                    }

                    .image_area {
                        height: rem(22);
                        padding-right: rem(10);
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .search_area {
                    width: calc(100% - 250px);
                    float: left;

                    .form-group {
                        position: relative;
                        margin-bottom: 0;

                        .form-control {
                            // height: rem(55);
                            // padding-right: rem(50);
                            padding-left: rem(45);

                        }
                    }

                    .icon {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        top: 0;
                        font-size: rem(20);
                        margin-top: rem(18);
                        margin-left: rem(15);
                        color: $form_label_color;
                    }
                }

                .pagination_list {
                    padding-top: 30px;
                    margin-left: 20px;

                    .form-group {
                        .form-select {
                            width: 90px;
                            color: $designation;
                            font-size: rem(20);

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }

                .filter_area {
                    width: rem(30);
                    margin-left: rem(20);
                    float: left;
                    color: $form_label_color;
                    // display: flex;
                    border: rem(1) solid $form_label_color;
                    padding: rem(5);

                    .img_area {
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                }

                .dropdown {
                    background-color: transparent;

                    &::after {

                        &:focus,
                        &.active,
                        &:hover {
                            background-color: transparent;
                        }
                    }

                    .dropdown-toggle {
                        padding: 0;

                        &::after {
                            display: none;
                        }

                        &:focus,
                        &.active,
                        &:hover {
                            background-color: transparent;
                        }
                    }

                    .sort_button {
                        width: 100%;
                        margin-left: rem(15);

                        .btn-primary {
                            // float: right;
                            border: rem(2) solid $designation;
                            color: $designation;
                            width: 100%;
                            height: rem(60);
                            background: $white;
                            font-size: rem(20);
                            font-weight: 400;
                        }

                        .btn_bg {
                            background: rgba(255, 0, 0, 0.15);
                            border: 1.5px solid rgba(255, 0, 0, 0.4);
                            font-size: 20px;
                        }

                        .image_area {
                            height: rem(22);
                            padding-left: rem(15);
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }

            }

            .list_detail {
                background: $white;
                text-transform: capitalize;
                display: flex;
                align-items: center;
                @include clearfix();

                .list_data {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .card {
                        border: rem(1) solid $light_grey_2;
                        box-shadow: 0px 0px 8px rgba(17, 17, 17, 0.12);
                        border-radius: rem(25);
                        overflow: hidden;

                        .card-body {
                            padding: 0;

                            .table-responsive {
                                min-height: rem(250);

                                .table {
                                    width: 100%;
                                    overflow: auto;
                                    height: auto;
                                    margin-bottom: 0;

                                    tr {
                                        border-bottom: rem(0.6) solid rgba(133, 133, 133, 0.6);
                                        position: relative;

                                        td {
                                            padding: rem(23) 0;

                                            &::before {
                                                content: "";
                                                height: auto;
                                                width: rem(8);
                                                background-color: transparent;
                                                position: absolute;
                                                top: 0;
                                                bottom: 0;
                                                left: 0;
                                            }

                                            .dropdown-toggle {
                                                &::after {
                                                    content: none;
                                                }

                                                &:focus {
                                                    background-color: $white ;
                                                }
                                            }

                                            .profile_info {
                                                @include clearfix();
                                                display: flex;
                                                align-items: center;
                                                padding-left: rem(20);

                                                .left_area {
                                                    float: left;
                                                    width: rem(50);

                                                    .img_profile {
                                                        height: rem(50);
                                                        width: rem(50);
                                                        border-radius: 50%;
                                                        overflow: hidden;

                                                        img {
                                                            height: 100%;
                                                            width: 100%;
                                                            object-fit: cover;
                                                        }
                                                    }
                                                }

                                                .right_area {
                                                    float: right;
                                                    width: calc(100% - 50px);
                                                    padding-left: rem(15);


                                                    p {
                                                        font-size: rem(16);
                                                        font-weight: 600;
                                                        color: $designation;
                                                        text-align: start;
                                                        margin-bottom: 0;
                                                        margin-top: rem(-20);
                                                        cursor: pointer;

                                                        &:hover {
                                                            text-decoration: underline;
                                                        }
                                                    }
                                                }
                                            }

                                        }

                                        &:hover {
                                            td {
                                                background: rgba(232, 232, 232, 0.21);

                                                &::before {
                                                    background-color: $dark_red_2;
                                                }
                                            }

                                            // &:nth-child(1) {
                                            //     cursor: pointer;
                                            // }

                                            // &:nth-child(3) {
                                            //     cursor: pointer;
                                            // }
                                        }

                                        &.active {
                                            // td {
                                            //     background: rgba(232, 232, 232, 0.21);

                                            //     &::before {
                                            //         background-color: $critical_color;
                                            //     }
                                            // }
                                        }

                                        // &:hover {
                                        //     background: rgba(232, 232, 232, 0.21);
                                        //     border-left: rem(6) solid $critical_color;
                                        // }

                                        &:last-child {
                                            border-bottom: none;
                                        }

                                        th {
                                            border-bottom: rem(1.5) solid rgba(133, 133, 133, 0.8);
                                            word-break: break-word;
                                            white-space: nowrap;
                                            margin-right: rem(20);
                                            padding: rem(15) rem(20);
                                            // text-align: center;
                                            color: $form_label_color;
                                            font-weight: 600;
                                            font-size: rem(22);

                                            &.width_s {
                                                min-width: rem(250);
                                            }
                                        }

                                        td {
                                            text-align: center;
                                            padding: rem(15) 0;
                                            font-weight: 400;
                                            font-size: rem(16);
                                            color: $designation;

                                            &.title {
                                                font-weight: 500;

                                                &.width_s {
                                                    min-width: rem(250);
                                                    text-align: start;
                                                    padding-left: rem(20);

                                                    a {
                                                        &:hover {
                                                            text-decoration: underline;
                                                            cursor: pointer;
                                                        }
                                                    }
                                                }
                                            }

                                            .icon {

                                                .dropdown-menu {
                                                    padding: 0;
                                                    border-radius: rem(12) 0 rem(12) rem(12);
                                                    background: $fade_white;
                                                    transform: translate(rem(-40), rem(30)) !important;

                                                    .dropdown-item {
                                                        padding: rem(8) rem(20);
                                                        font-size: rem(16);
                                                        font-weight: 400;
                                                        color: $designation;
                                                        padding-left: rem(45);
                                                        position: relative;
                                                        border-bottom: rem(0.2) solid $border_line;

                                                        &:last-child {
                                                            border-bottom: none;
                                                        }

                                                        .image_area {
                                                            padding-left: rem(8);
                                                            overflow: hidden;

                                                            img {
                                                                width: 100%;
                                                                height: 100%;
                                                                object-fit: cover;
                                                            }

                                                            &.delete_icon {
                                                                margin-top: rem(-4);
                                                            }
                                                        }

                                                    }

                                                }

                                                .btn {
                                                    color: $form_label_color;
                                                    padding-top: 0;

                                                    &:hover,
                                                    &:focus {
                                                        background-color: $white;
                                                    }
                                                }

                                                .ellipsis_icon {
                                                    font-size: rem(27);
                                                    cursor: pointer;
                                                }

                                                .dropdown-toggle::after {
                                                    display: none !important;
                                                }
                                            }

                                            .badge_low {
                                                padding: rem(5) rem(10);
                                                background: $light_white;
                                                border-radius: rem(20);
                                                width: rem(73);
                                                height: rem(29);
                                                display: block;
                                                margin: 0 auto;

                                                p {
                                                    font-weight: 500;
                                                    font-size: rem(14);
                                                    color: $notification;
                                                }

                                            }

                                            .badge_high {
                                                padding: rem(5) rem(10);
                                                background: rgba(233, 97, 97, 0.3);
                                                border-radius: rem(20);
                                                width: rem(73);
                                                height: rem(29);
                                                display: block;
                                                margin: 0 auto;

                                                p {
                                                    font-weight: 500;
                                                    font-size: rem(14);
                                                    color: $fade_pink;
                                                }
                                            }

                                            .badge_medium {
                                                padding: rem(5) rem(10);
                                                background: rgba(255, 135, 23, 0.21);
                                                border-radius: rem(20);
                                                width: rem(73);
                                                height: rem(29);
                                                display: block;
                                                margin: 0 auto;

                                                p {
                                                    font-weight: 500;
                                                    font-size: rem(14);
                                                    color: $treatment_color;
                                                }
                                            }

                                            .badge_complete {
                                                padding: rem(10);
                                                background: rgba(120, 182, 18, 0.18);
                                                border-radius: rem(8);
                                                width: rem(88);
                                                height: rem(37);
                                                display: block;
                                                margin: 0 auto;

                                                p {
                                                    font-weight: 500;
                                                    font-size: rem(14);
                                                    line-height: rem(14);
                                                    color: $notification;
                                                }
                                            }

                                            .bagde_pending {
                                                padding: rem(10);
                                                background: rgba(255, 135, 23, 0.21);
                                                border-radius: rem(8);
                                                width: rem(88);
                                                height: rem(37);
                                                display: block;
                                                margin: 0 auto;

                                                p {
                                                    font-weight: 500;
                                                    font-size: rem(14);
                                                    color: $treatment_color;
                                                    line-height: rem(14);
                                                }
                                            }

                                            .data_table {
                                                @include clearfix();
                                                display: flex;
                                                align-items: center;
                                                cursor: pointer;
                                                // width: 230px;

                                                .left_area {
                                                    width: 50px;
                                                    padding: 0px;
                                                    margin-left: rem(20);
                                                    margin-right: rem(10);

                                                    .img_area {
                                                        width: rem(50);
                                                        height: rem(50);
                                                        border-radius: 50%;
                                                        overflow: hidden;
                                                        margin-top: rem(5);
                                                        margin-right: rem(10);

                                                        img {
                                                            width: 100%;
                                                            height: 100%;
                                                            object-fit: cover;
                                                        }
                                                    }
                                                }

                                                .right_areaa {
                                                    width: calc(180px - 60px);
                                                    text-align: left;

                                                    a {
                                                        font-weight: 500;
                                                        font-size: rem(16);
                                                        color: $designation;
                                                        cursor: pointer;
                                                        text-align: start;
                                                        display: block;

                                                        // padding-left: rem(20);
                                                        &:hover {
                                                            text-decoration: underline;
                                                        }

                                                    }


                                                    p {
                                                        margin-top: rem(5);
                                                        font-weight: 500;
                                                        font-size: rem(14);
                                                        display: inline-flex;
                                                        text-align: start;


                                                        span {
                                                            // margin-left: rem(30);
                                                            background: rgba(55, 55, 128, 0.2);
                                                            border-radius: rem(5);
                                                            color: $blue;
                                                            background: $light_blue;
                                                            padding: rem(3) rem(11);

                                                            &.designation {
                                                                background: $off_white;
                                                                color: $form_border_color;
                                                                margin-right: rem(14);

                                                            }

                                                            &.consultant {
                                                                background: $light_blue;
                                                                color: $blue;
                                                                margin-right: rem(14);

                                                            }
                                                        }

                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &::-webkit-scrollbar-track {
                                    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                                    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                                    background-color: $grey;
                                }

                                &::-webkit-scrollbar {
                                    width: 2px;
                                    height: 6px;
                                    background-color: $dark_red_2;
                                    margin: 0 rem(20);
                                }

                                &::-webkit-scrollbar-thumb {
                                    background-color: $dark_red_2;
                                }
                            }

                        }
                    }

                }


                // .data_table{
                //     text-align: inherit;
                // }





                .left_area {
                    float: left;
                    // width: rem(500);
                    @include clearfix();

                    .inner_left {
                        float: left;
                        width: rem(106);

                        .img_area {
                            width: rem(106);
                            height: rem(106);
                            border-radius: rem(100);
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .inner_right {
                        float: left;
                        width: calc(100% - 106px);
                        padding-left: rem(15);

                        .name {

                            // padding-top: rem(25);
                            h3 {
                                font-size: rem(36);
                                font-weight: 600;
                                background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }
                        }

                        .gender {
                            display: flex;
                            margin-top: rem(15);

                            p {
                                font-size: rem(16);
                                font-weight: 400;
                                color: $designation;

                                .gender_icon {
                                    margin-right: rem(5);
                                    color: $dark_red_2;
                                }

                                span {
                                    margin-left: rem(30);
                                    background: rgba(55, 55, 128, 0.2);
                                    border-radius: rem(5);
                                    color: $form_border_color;
                                    padding: rem(3) rem(11);
                                }
                            }
                        }

                        .date {
                            display: flex;
                            margin-top: rem(15);

                            .date_icon {
                                margin-right: rem(5);
                                color: $dark_red_2;
                            }

                            p {
                                color: $designation;
                                font-size: rem(16);
                                font-weight: 400;
                            }
                        }
                    }
                }

                .right_area {
                    float: right;
                    width: calc(100% - 500px);
                    margin-top: rem(20);

                    .id_area {
                        float: right;
                        background: rgba(154, 154, 154, 0.2);
                        border-radius: rem(5);
                        padding: rem(2) rem(16);
                        margin-bottom: rem(10);

                        p {
                            font-weight: 400;
                            font-size: rem(14);
                            line-height: rem(17);
                            color: $light_grey;
                        }
                    }

                    .batch {
                        float: right;
                        background: $treatment_color;
                        width: rem(160);
                        height: rem(43);
                        line-height: rem(43);
                        border-radius: rem(22);

                        &:hover {
                            background: darken($treatment_color, 4%);
                        }

                        p {
                            font-size: rem(16);
                            font-weight: 600;
                            color: $white;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .task_list_screen {
        .task_list_details {
            padding: 0 rem(20) rem(20);

            .search_section {
                .search_area {
                    .icon {
                        font-size: rem(18);
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .task_list_screen {
        .task_list_details {
            padding: 0;

            .title_btn_area {
                margin-bottom: 30px;

                .left_area {
                    width: 58%;

                    p {
                        font-size: rem(20);
                    }
                }

                .right_area {
                    width: calc(100% - 58%);

                    .btn {
                        font-size: rem(20);
                        height: 45px;
                        line-height: rem(30);
                        width: rem(130);
                    }
                }
            }

            .search_section {
                margin-bottom: 35px;
                display: block;

                .search_area {
                    width: 100%;
                    float: unset;

                    .icon {
                        font-size: rem(18);
                    }
                }

                .filter_button {
                    .btn-primary {
                        float: unset;
                        width: 100%;
                        margin-top: 15px;
                        margin-left: 0;
                        font-size: rem(20);
                    }

                    .filter_icon {
                        margin-top: 25px;
                    }
                }

                .pagination_list {
                    margin-left: 0;
                    padding-top: 20px;

                    .form-group {
                        margin-bottom: 0;

                        .form-select {
                            width: 100%;
                        }
                    }
                }

                .dropdown {
                    display: inline-block;
                    float: right;
                    margin-right: rem(15);

                    .sort_button {
                        .btn-primary {
                            height: rem(53);
                            font-size: rem(18);
                        }

                        .image_area {
                            height: rem(20);
                        }
                    }
                }
            }



            .list_detail {
                .list_data {
                    .card {
                        .card-body {
                            .table {
                                tr {
                                    th {
                                        font-size: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

/* Smaller phones */
@media (max-width: rem(360)) {
    .task_list_screen {
        .task_list_details {
            padding: 0;

            .title_btn_area {
                margin-bottom: 30px;

                .left_area {
                    width: 56%;

                    p {
                        font-size: rem(20);
                    }
                }

                .right_area {
                    width: calc(100% - 56%);

                    .btn {
                        font-size: rem(20);
                        height: 45px;
                        line-height: rem(30);
                        width: rem(130);
                    }
                }
            }

            .search_section {
                margin-bottom: 35px;
                display: block;

                .search_area {
                    width: 100%;
                    float: unset;

                    .icon {
                        font-size: rem(18);
                    }
                }

                .filter_button {
                    .btn-primary {
                        float: unset;
                        width: 100%;
                        margin-top: 15px;
                        margin-left: 0;
                        font-size: rem(20);
                    }

                    .filter_icon {
                        margin-top: 25px;
                    }
                }

                .pagination_list {
                    margin-left: 0;
                    padding-top: 20px;

                    .form-group {
                        margin-bottom: 0;

                        .form-select {
                            width: 100%;
                        }
                    }
                }

                .dropdown {
                    display: inline-block;
                    float: right;
                    margin-right: rem(15);

                    .sort_button {
                        .btn-primary {
                            height: rem(53);
                            font-size: rem(18);
                        }

                        .image_area {
                            height: rem(20);
                        }
                    }
                }
            }



            .list_detail {
                .list_data {
                    .card {
                        .card-body {
                            .table {
                                tr {
                                    th {
                                        font-size: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}