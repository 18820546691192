.update_lab_tab {
    margin: 0 rem(10);

    .top_content_area {
        @include clearfix();
        display: flex;
        align-items: center;
        margin-bottom: rem(15);

        .left_area {
            float: left;
            width: calc(100% - 100px);

            p {
                font-size: rem(24);
                font-weight: 600;
                color: $form_label_color;
            }
        }

        .right_area {
            float: right;
            width: rem(100);
            text-align: end;

            .img_text_area {
                display: flex;
                align-items: center;
                justify-content: end;

                p {
                    font-size: rem(18);
                    font-weight: 400;
                    color: $designation;
                    margin-right: rem(12);
                }

                .img_area {
                    width: rem(32);

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    .container_area {
        background: $accordin_body;
        border: 1px solid $grey;
        box-shadow: 0px 4px 25px rgba(55, 55, 128, 0.12);
        border-radius: rem(25);
        padding: rem(40) rem(38);

        .inner_container_area {
            background: $accordin_body;
            border: 1px solid $grey;
            box-shadow: 0px 4px 25px rgba(55, 55, 128, 0.12);
            border-radius: rem(10);
            padding: rem(15);
            margin-bottom: rem(20);

            &.no_mb {
                margin-bottom: 0;
            }

            .top_area {
                @include clearfix();
                display: flex;
                align-items: center;
                margin-bottom: rem(25);

                .left_top_area {
                    float: left;
                    width: calc(100% - 30px);

                    .name {
                        font-size: rem(20);
                        font-weight: 400;
                        color: $form_border_color;
                        margin-bottom: rem(5);

                        .icon_attach {
                            color: $card_text_color;
                            font-size: rem(18);
                            margin-left: rem(8);
                        }
                    }

                    .txt {
                        font-size: rem(16);
                        font-weight: 400;
                        color: $black_2;
                    }
                }

                .right_top_area {
                    float: right;
                    width: rem(30);
                    text-align: end;
                    margin-right: rem(15);

                    .img_eye {
                        width: rem(23);

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }

            .bottom_area {
                @include clearfix();
                background-color: $light_pink;
                border-radius: rem(40);
                padding: rem(15) rem(18);

                .left_area {
                    float: left;
                    width: calc(100% - 180px);

                    p {
                        font-size: rem(16);
                        font-weight: 400;
                        color: $designation;
                        cursor: pointer;
                    }
                }

                .right_area {
                    float: right;
                    width: rem(180);
                    text-align: end;

                    p {
                        font-size: rem(16);
                        font-weight: 400;
                        color: $designation;
                    }
                }
            }
        }
    }

    .assign_task {
        float: right;
        margin-top: rem(15);
        margin-bottom: rem(50);

        .circle_area {
            display: flex;
            align-items: center;
            justify-content: center;
            height: rem(100);
            width: rem(100);
            text-align: center;
            border-radius: 50%;
            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
            box-shadow: 0px 10px 27px rgba(227, 30, 36, 0.59);

            &:hover {
                background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
            }
        }

        .add_icon_area {
            color: $white;
            font-size: rem(20);

            p {
                font-weight: 500;
                font-size: 14px;
                color: $white;
            }

        }


    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .update_lab_tab {
        .top_content_area {
            .left_area {
                p {
                    font-size: rem(22);
                }
            }

            .right_area {
                .img_text_area {
                    .img_area {
                        width: rem(27);
                    }
                }
            }
        }

        .container_area {
            padding: rem(30);
        }
    }
}

@media (min-width: rem(361)) and (max-width: rem(575)) {
    .update_lab_tab {
        margin: 0;

        .top_content_area {
            .left_area {
                p {
                    font-size: rem(20);
                }
            }

            .right_area {
                .img_text_area {
                    .img_area {
                        width: rem(24);
                    }

                    p {
                        font-size: rem(16);
                    }
                }
            }
        }

        .container_area {
            padding: rem(20);
            border-radius: rem(15);

            .inner_container_area {
                padding: rem(12);

                .top_area {
                    margin-bottom: rem(15);

                    .left_top_area {
                        .name {
                            font-size: rem(18);

                            .icon_attach {
                                font-size: rem(15);
                            }
                        }

                        .txt {
                            font-size: rem(15);
                        }
                    }

                    .right_top_area {
                        .img_eye {
                            width: rem(18);
                        }
                    }
                }

                .bottom_area {
                    width: 100%;
                    padding: rem(12) rem(15);

                    .left_area {
                        float: unset;
                        width: 100%;

                        p {
                            font-size: rem(15);
                            text-align: center;
                        }
                    }

                    .right_area {
                        float: unset;
                        width: 100%;
                        text-align: left;
                        margin-top: rem(5);

                        p {
                            font-size: rem(15);
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: rem(360)) {
    .update_lab_tab {
        margin: 0;

        .top_content_area {
            .left_area {
                p {
                    font-size: rem(20);
                }
            }

            .right_area {
                .img_text_area {
                    .img_area {
                        width: rem(24);
                    }

                    p {
                        font-size: rem(16);
                    }
                }
            }
        }

        .container_area {
            padding: rem(20);
            border-radius: rem(15);

            .inner_container_area {
                padding: rem(12);

                .top_area {
                    margin-bottom: rem(15);

                    .left_top_area {
                        .name {
                            font-size: rem(18);

                            .icon_attach {
                                font-size: rem(15);
                            }
                        }

                        .txt {
                            font-size: rem(15);
                        }
                    }

                    .right_top_area {
                        .img_eye {
                            width: rem(18);
                        }
                    }
                }

                .bottom_area {
                    width: 100%;
                    padding: rem(12) rem(15);

                    .left_area {
                        float: unset;
                        width: 100%;

                        p {
                            font-size: rem(15);
                            text-align: center;
                        }
                    }

                    .right_area {
                        float: unset;
                        width: 100%;
                        text-align: left;
                        margin-top: rem(5);

                        p {
                            font-size: rem(15);
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}