.role_list_screen {
    &.Department_list_area {
        .role_list_details {
            .title_btn_area {
                margin-top: rem(10);

                .left_area {
                    width: 68%;
                }

                .right_area {
                    width: calc(100% - 68%);

                    .btn-primary {
                        width: rem(220);
                    }
                }
            }

            .list_detail {
                .table-responsive {
                    table {
                        tr {
                            th {

                                &:nth-child(2) {
                                    min-width: rem(230);
                                }
                            }


                        }
                    }
                }
            }
        }
    }

}


@media(min-width:768px) and (max-width:1023px) {
    .role_list_screen {
        &.Department_list_area {
            .role_list_details {
                .title_btn_area {
                    margin-top: rem(-18);

                    .left_area {
                        width: 68%;
                    }

                    .right_area {
                        width: calc(100% - 68%);

                        .btn-primary {
                            width: rem(220);
                        }
                    }
                }

                .list_detail {
                    .table-responsive {
                        table {
                            tr {
                                th {

                                    &:nth-child(2) {
                                        min-width: rem(230);
                                    }
                                }


                            }
                        }
                    }
                }
            }
        }

    }
}



/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .role_list_screen {
        &.Department_list_area {
            .role_list_details {
                .title_btn_area {
                    margin-top: rem(-12);
                    margin-bottom: rem(25);

                    .left_area {
                        width: 68%;
                    }

                    .right_area {
                        width: calc(100% - 68%);

                        .btn-primary {
                            width: rem(192);
                            font-size: rem(22);
                        }
                    }
                }

                .search_section {
                    margin-bottom: rem(25);

                    .search_area {
                        .icon {
                            margin-top: rem(-1);
                        }
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .role_list_screen {
        &.Department_list_area {
            .role_list_details {
                padding: rem(0);

                .title_btn_area {
                    margin-top: rem(-10);
                    margin-bottom: rem(20);

                    .left_area {
                        width: 49%;

                        p {
                            font-size: rem(18);
                        }
                    }

                    .right_area {
                        width: calc(100% - 49%);

                        .btn-primary {
                            font-size: 18px;
                            width: 175px;
                            height: 50px;
                            line-height: 36px;
                        }
                    }
                }

                .search_section {
                    margin-bottom: rem(25);

                    .search_area {
                        .icon {
                            margin-top: rem(-1);
                        }
                    }
                }

                // .list_detail {
                //     border-radius: rem(16);
                // }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .role_list_screen {
        &.Department_list_area {
            .role_list_details {
                padding: rem(0);

                .title_btn_area {
                    margin-top: rem(-10);
                    margin-bottom: rem(20);

                    .left_area {
                        width: 52%;

                        p {
                            font-size: rem(18);
                        }
                    }

                    .right_area {
                        width: calc(100% - 52%);

                        .btn-primary {
                            font-size: 18px;
                            width: 160px;
                            height: 50px;
                            line-height: 36px;
                        }
                    }
                }

                .search_section {
                    margin-bottom: rem(25);

                    .search_area {
                        .icon {
                            margin-top: rem(-1);
                        }
                    }
                }

                // .list_detail {
                //     border-radius: rem(16);
                // }
            }
        }
    }
}