.manage_patient_list_screen {
    .patient_list_details {
        padding-bottom: rem(60);

        .title_btn_area {
            @include clearfix();
            padding-left: rem(30);
            padding-right: rem(30);
            padding-bottom: rem(30);
            padding-top: rem(80);
            display: flex;
            align-items: center;

            .left_area {
                width: 50%;
                float: left;

                h3 {
                    font-size: rem(24);
                    font-weight: 600;
                    color: $form_label_color;
                }
            }

            .right_area {
                width: calc(100% - 50%);
                float: right;

                .btn {
                    float: right;
                    font-size: rem(24);
                    font-weight: 600;
                    background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                    width: rem(211);
                    height: rem(50);
                    border: none;

                    &:hover,
                    &:focus {
                        color: $white;
                        height: rem(50);
                        border: none;
                        opacity: 0.8;
                    }
                }
            }
        }

        .search_section {
            @include clearfix();
            padding-left: rem(30);
            padding-right: rem(20);
            margin-bottom: 20px;


            .search_left_area {
                width: 86%;
                float: left;

                form {
                    .form-group {
                        .form-control {
                            padding-left: rem(50);
                            position: relative;
                        }

                        a {
                            .icon {
                                position: absolute;
                                top: 0;
                                left: 0;
                                margin-top: rem(18);
                                margin-left: rem(15);
                                font-size: rem(20);
                                color: $form_label_color;
                            }
                        }
                    }
                }

            }

            .search_right_area {
                width: calc(100% - 86%);
                float: right;
                display: flex;

                form {
                    .form-group {
                        &.select_pagination {
                            margin-left: rem(20);
                            margin-right: rem(20);
                        }

                        .form-select {
                            color: #7A7A7A;
                            padding-left: rem(16);

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }

                .filter_button {
                    position: relative;

                    .btn-primary {
                        float: right;
                        width: rem(124);
                        height: rem(60);
                        font-size: rem(22);
                        font-weight: 400;
                        border-radius: rem(10);
                        background: rgba(255, 0, 0, 0.15);
                        border: rem(1.5) solid rgba(255, 0, 0, 0.4);
                        color: #140745;

                        .image_area {
                            margin-right: rem(5);
                        }
                    }

                    .btn-border {
                        background-color: $white;
                        border: rem(1.5) solid $form_border_color;
                    }

                    .filter_icon {
                        position: absolute;
                        right: 0;
                        top: 0;
                        margin-top: 10px;
                        margin-right: 10px;
                        font-size: 7px;
                        color: $dark_red_2;
                    }
                }
            }
        }

        .list_detail {
            padding-left: rem(24);
            padding-right: rem(24);
            text-transform: capitalize;
            display: flex;
            align-items: center;
            @include clearfix();

            .list_data {
                width: 100%;

                .card {
                    border: rem(1) solid $light_grey_2;
                    box-shadow: 0 0 rem(8) rgba(17, 17, 17, 0.12);
                    border-radius: rem(25);
                    overflow: auto;

                    .card-body {
                        padding: 0;

                        .table-responsive {
                            min-height: rem(250);

                            .table {
                                width: 100%;
                                overflow: auto;
                                height: auto;

                                tr {
                                    border-bottom: 0.6px solid rgba(133, 133, 133, 0.6);

                                    &:last-child {
                                        border-bottom: none;
                                    }

                                    th {
                                        border-bottom: 1.5px solid rgba(128, 128, 128, 0.8);
                                        word-break: break-word;
                                        white-space: nowrap;
                                        margin-right: rem(20);
                                        padding: rem(30) rem(20);
                                        text-align: center;
                                        color: $form_label_color;
                                        font-weight: 600;
                                        font-size: rem(21);

                                        // &:first-child {
                                        //     padding-left: rem(60);
                                        // }

                                        &:last-child {
                                            padding-right: rem(50);
                                        }

                                        &.role_text {
                                            text-align: start;
                                            padding-left: rem(40);
                                        }

                                        &:nth-child(1) {
                                            min-width: rem(215);
                                        }

                                        &:nth-child(2) {
                                            min-width: rem(150);
                                        }

                                        &:nth-child(3) {
                                            min-width: rem(150);
                                        }

                                        &:nth-child(4) {
                                            min-width: rem(150);
                                        }

                                        &:nth-child(5) {
                                            min-width: rem(212);
                                        }

                                        &:nth-child(6) {
                                            min-width: rem(100);
                                        }
                                    }

                                    td {
                                        text-align: center;
                                        padding: rem(20) 0;
                                        font-weight: 600;
                                        font-size: rem(16);
                                        color: $cancel_btn_color;

                                        .dropdown-menu {
                                            padding: 0;
                                            border-radius: rem(12) 0 rem(12) rem(12);
                                            background: #FEFEFE;
                                            transform: translate(rem(-40), rem(30)) !important;

                                            .dropdown-item {
                                                padding: rem(8) rem(20);
                                                font-size: rem(16);
                                                font-weight: 400;
                                                color: $designation;
                                                padding-left: rem(45);
                                                position: relative;
                                                border-bottom: 0.3px solid #808080;


                                                &:focus,
                                                &:hover {

                                                    // color: var(--bs-dropdown-link-hover-color);
                                                    // background-color: var(--bs-dropdown-link-hover-bg);
                                                    &:first-child {
                                                        border-radius: rem(12) 0 rem(0) rem(0);
                                                    }

                                                    &:last-child {
                                                        border-radius: rem(0) 0 rem(12) rem(12);
                                                    }

                                                }


                                                &:last-child {
                                                    border-bottom: none;
                                                }

                                                // .image_area {
                                                //     padding-left: rem(8);
                                                //     overflow: hidden;

                                                //     img {
                                                //         width: 100%;
                                                //         height: 100%;
                                                //         object-fit: cover;
                                                //     }

                                                //     &.delete_icon {
                                                //         margin-top: rem(-4);
                                                //     }
                                                // }

                                            }

                                        }

                                        .dropdown-toggle::after {
                                            display: none !important;
                                        }

                                        .btn {
                                            color: $form_label_color;
                                            padding-top: 0;

                                            &:hover,
                                            &:focus {
                                                background-color: $white;
                                            }
                                        }




                                        &:first-child {
                                            padding-left: rem(20);
                                        }

                                        &:last-child {
                                            padding-right: rem(50);
                                        }

                                        p {
                                            font-weight: 500;
                                            font-size: 14px;

                                            span {
                                                background: rgba(55, 55, 128, 0.2);
                                                border-radius: rem(8);
                                                color: $blue;
                                                background: $light_blue;
                                                padding: 10px;

                                                // padding: rem(3) rem(11);

                                                &.designation {
                                                    // padding: 10px;
                                                    background: #D1D1E0;
                                                    color: $form_border_color;
                                                    white-space: nowrap;
                                                }
                                            }
                                        }

                                        .badge {
                                            width: rem(165);
                                            margin: 0 auto;

                                            p {
                                                padding: rem(5) rem(10);
                                                height: rem(37);
                                                margin: 0 auto;
                                                border-radius: rem(8);
                                                font-weight: 500;
                                                font-size: rem(14);
                                                color: $white;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;

                                                &.warning {
                                                    background: $treatment_color;
                                                    width: rem(124);
                                                }

                                                &.danger {
                                                    background-color: $critical_color;
                                                    width: rem(100);
                                                }

                                                &.success {
                                                    background: $success_color;
                                                    width: rem(78);
                                                }

                                                &.discharge {
                                                    background: #FFEDAD;
                                                    color: #CD7915;
                                                    width: rem(82);
                                                }
                                            }
                                        }

                                        .icon {
                                            .dropdown-menu {
                                                padding: 0;
                                                border-radius: rem(12) 0 rem(12) rem(12);
                                                background: #FEFEFE;
                                                transform: translate(rem(-40), rem(30)) !important;

                                                .dropdown-item {
                                                    padding: rem(8) rem(20);
                                                    font-size: rem(16);
                                                    font-weight: 400;
                                                    color: $designation;
                                                    padding-left: rem(45);
                                                    position: relative;
                                                    border-bottom: 0.3px solid #808080;


                                                    &:focus,
                                                    &:hover {

                                                        // color: var(--bs-dropdown-link-hover-color);
                                                        // background-color: var(--bs-dropdown-link-hover-bg);
                                                        &:first-child {
                                                            border-radius: rem(12) 0 rem(0) rem(0);
                                                        }

                                                        &:last-child {
                                                            border-radius: rem(0) 0 rem(12) rem(12);
                                                        }

                                                    }


                                                    &:last-child {
                                                        border-bottom: none;
                                                    }

                                                    .image_area {
                                                        padding-left: rem(8);
                                                        overflow: hidden;

                                                        img {
                                                            width: 100%;
                                                            height: 100%;
                                                            object-fit: cover;
                                                        }

                                                        &.delete_icon {
                                                            margin-top: rem(-4);
                                                        }
                                                    }

                                                }

                                            }

                                            .btn {
                                                color: $form_label_color;
                                                padding-top: 0;

                                                &:hover,
                                                &:focus {
                                                    background-color: $white;
                                                }
                                            }

                                            .ellipsis_icon {
                                                font-size: rem(27);
                                                cursor: pointer;
                                            }

                                            .dropdown-toggle::after {
                                                display: none !important;
                                            }
                                        }

                                        .badge_low {
                                            padding: rem(5) rem(10);
                                            background: $light_white;
                                            border-radius: rem(20);
                                            width: rem(73);
                                            height: rem(29);
                                            display: block;
                                            margin: 0 auto;

                                            p {
                                                font-weight: 500;
                                                font-size: rem(14);
                                                color: $notification;
                                            }

                                        }

                                        .badge_high {
                                            padding: rem(5) rem(10);
                                            background: rgba(233, 97, 97, 0.3);
                                            border-radius: rem(20);
                                            width: rem(73);
                                            height: rem(29);
                                            display: block;
                                            margin: 0 auto;

                                            p {
                                                font-weight: 500;
                                                font-size: rem(14);
                                                color: $fade_pink;
                                            }
                                        }

                                        .badge_complete {
                                            padding: rem(10);
                                            background: $light_white;
                                            border-radius: rem(20);
                                            width: rem(88);
                                            height: rem(37);
                                            display: block;
                                            margin: 0 auto;

                                            p {
                                                font-weight: 500;
                                                font-size: rem(14);
                                                color: $notification;
                                            }

                                        }

                                        .under_line {
                                            font-weight: 600;
                                            font-size: rem(16);
                                            color: $designation;
                                            cursor: pointer;

                                            &:hover {
                                                text-decoration: underline;
                                            }
                                        }

                                        .data_table {
                                            width: rem(230);
                                            display: flex;
                                            align-items: center;

                                            .left_area {
                                                width: rem(50);
                                                padding: 0;
                                                margin-left: rem(20);

                                                .img_area {
                                                    width: rem(50);
                                                    height: rem(50);
                                                    border-radius: rem(50);
                                                    margin-top: rem(5);
                                                    overflow: hidden;

                                                    img {
                                                        width: 100%;
                                                        height: 100%;
                                                        object-fit: cover;
                                                    }
                                                }
                                            }

                                            .right_areaa {
                                                width: calc(230px - 50px);
                                                text-align: left;
                                                margin-left: 10px;
                                                cursor: pointer;

                                                h4 {
                                                    font-weight: 600;
                                                    font-size: rem(16);
                                                    color: $designation;
                                                }

                                                p {
                                                    margin-top: rem(5);
                                                    font-weight: 500;
                                                    font-size: rem(14);

                                                    span {
                                                        background: rgba(55, 55, 128, 0.2);
                                                        border-radius: rem(5);
                                                        color: $blue;
                                                        background: $light_blue;
                                                        padding: rem(3) rem(11);

                                                        &.designation {
                                                            background: $off_white;
                                                            color: $form_border_color;
                                                            margin-right: rem(14);

                                                        }
                                                    }

                                                    &.patient_number {
                                                        color: $black_2;
                                                        font-weight: 400;
                                                    }

                                                }
                                            }
                                        }

                                    }
                                }
                            }

                            &::-webkit-scrollbar-track {
                                box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                                -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                                background-color: $grey;
                            }

                            &::-webkit-scrollbar {
                                width: 2px;
                                height: 6px;
                                background-color: $dark_red_2;
                                margin: 0 rem(20);
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: $dark_red_2;
                            }
                        }

                    }
                }

            }

            .left_area {
                float: left;
                @include clearfix();

                .inner_left {
                    float: left;
                    width: rem(106);

                    .img_area {
                        width: rem(106);
                        height: rem(106);
                        border-radius: rem(100);
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .inner_right {
                    float: left;
                    width: calc(100% - 106px);
                    padding-left: rem(15);

                    .name {
                        h3 {
                            font-size: rem(36);
                            font-weight: 600;
                            background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }
                    }

                    .gender {
                        display: flex;
                        margin-top: rem(15);

                        p {
                            font-size: rem(16);
                            font-weight: 400;
                            color: $designation;

                            .gender_icon {
                                margin-right: rem(5);
                                color: #D81B23;
                            }

                            span {
                                margin-left: rem(30);
                                background: rgba(55, 55, 128, 0.2);
                                border-radius: rem(5);
                                color: $form_border_color;
                                padding: rem(3) rem(11);
                            }
                        }
                    }

                    .date {
                        display: flex;
                        margin-top: rem(15);

                        .date_icon {
                            margin-right: rem(5);
                            color: $dark_red_2;
                        }

                        p {
                            color: $designation;
                            font-size: rem(16);
                            font-weight: 400;
                        }
                    }
                }
            }

            .right_area {
                float: right;
                width: calc(100% - 500px);
                margin-top: rem(20);
            }
        }
    }

    .without_image_header {
        .header_area {
            .details {
                .icon_area {
                    .img_one {
                        display: none;
                    }
                }
            }
        }
    }
}




/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {
    .manage_patient_list_screen {
        .patient_list_details {
            .search_section {
                .search_left_area {
                    width: 81%;
                }

                .search_right_area {
                    width: calc(100% - 81%);

                }
            }
        }
    }
}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {
    .manage_patient_list_screen {
        .patient_list_details {
            .search_section {
                margin-bottom: 15px;

                .search_left_area {
                    width: 79%;
                }

                .search_right_area {
                    width: calc(100% - 79%);

                }
            }
        }
    }
}

@media (min-width: 1023px) and (max-width: 1199px) {
    .manage_patient_list_screen {
        .patient_list_details {
            .search_section {
                margin-bottom: 15px;

                .search_left_area {
                    width: 74%;
                }

                .search_right_area {
                    width: calc(100% - 74%);
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .manage_patient_list_screen {
        .patient_list_details {
            padding-bottom: rem(60);

            .title_btn_area {
                @include clearfix();
                padding-left: rem(43);
                padding-right: rem(30);
                padding-bottom: rem(40);
                padding-top: rem(0);
                display: flex;
                align-items: center;

                .left_area {
                    width: 50%;
                    float: left;

                    h3 {
                        font-size: rem(24);
                        font-weight: 600;
                        color: $form_label_color;
                    }
                }

                .right_area {
                    width: calc(100% - 50%);
                    float: right;

                    .btn {
                        float: right;
                        font-size: rem(24);
                        font-weight: 600;
                        background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                        width: rem(211);
                        height: rem(50);
                        border: none;

                        &:hover,
                        &:focus {
                            color: $white;
                            height: rem(50);
                            border: none;
                            opacity: 0.8;
                        }
                    }
                }
            }

            .search_section {
                @include clearfix();
                padding-left: rem(20);
                padding-right: rem(20);
                margin-bottom: 12px;

                .search_left_area {
                    width: 65%;
                    float: left;

                    form {
                        .form-group {
                            .form-control {
                                padding-left: rem(50);
                                position: relative;
                            }

                            a {
                                .icon {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    margin-top: rem(18);
                                    margin-left: rem(15);
                                    font-size: rem(20);
                                    color: $form_label_color;
                                }
                            }
                        }
                    }

                }

                .search_right_area {
                    width: calc(100% - 65%);
                    float: right;
                    display: flex;

                    form {
                        .form-group {
                            &.select_pagination {
                                margin-left: rem(20);
                                margin-right: rem(20);
                            }

                            .form-select {
                                color: #7A7A7A;
                                padding-left: rem(16);
                            }
                        }
                    }

                    .filter_button {
                        position: relative;

                        .btn-primary {
                            float: right;
                            width: rem(124);
                            height: rem(60);
                            font-size: rem(22);
                            font-weight: 400;
                            border-radius: rem(10);
                            background: rgba(255, 0, 0, 0.15);
                            border: rem(1.5) solid rgba(255, 0, 0, 0.4);
                            color: #140745;

                            .image_area {
                                margin-right: rem(5);
                            }
                        }

                        .btn-border {
                            background-color: $white;
                            border: rem(1.5) solid $form_border_color;
                        }

                        .filter_icon {
                            position: absolute;
                            right: 0;
                            top: 0;
                            margin-top: 10px;
                            margin-right: 10px;
                            font-size: 7px;
                            color: $dark_red_2;
                        }
                    }
                }
            }

            .list_detail {
                padding-left: rem(20);
                padding-right: rem(20);
                text-transform: capitalize;
                display: flex;
                align-items: center;
                @include clearfix();

                .list_data {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .card {
                        border: rem(1) solid $light_grey_2;
                        box-shadow: 0 0 rem(8) rgba(17, 17, 17, 0.12);
                        border-radius: rem(25);
                        overflow: auto;

                        .card-body {
                            padding: 0;

                            .table-responsive {
                                min-height: rem(250);

                                // height: rem(400);
                                .table {
                                    width: 100%;
                                    overflow: auto;
                                    height: auto;

                                    tr {
                                        border-bottom: 0.6px solid rgba(133, 133, 133, 0.6);

                                        &:last-child {
                                            border-bottom: none;
                                        }

                                        th {
                                            border-bottom: 1.5px solid rgba(128, 128, 128, 0.8);
                                            word-break: break-word;
                                            white-space: nowrap;
                                            margin-right: rem(20);
                                            padding: rem(15) rem(20);
                                            text-align: center;
                                            color: $form_label_color;
                                            font-weight: 600;
                                            font-size: rem(22);

                                            // &:first-child {
                                            //     padding-left: rem(60);
                                            // }

                                            &:last-child {
                                                padding-right: rem(50);
                                            }

                                            &.role_text {
                                                text-align: start;
                                                padding-left: rem(40);
                                            }

                                            &:nth-child(1) {
                                                min-width: rem(215);
                                            }

                                            &:nth-child(2) {
                                                min-width: rem(150);
                                            }

                                            &:nth-child(3) {
                                                min-width: rem(150);
                                            }

                                            &:nth-child(4) {
                                                min-width: rem(150);
                                            }

                                            &:nth-child(5) {
                                                min-width: rem(212);
                                            }

                                            &:nth-child(6) {
                                                min-width: rem(100);
                                            }
                                        }

                                        td {
                                            text-align: center;
                                            padding: rem(15) 0;
                                            font-weight: 600;
                                            font-size: rem(16);
                                            color: $cancel_btn_color;

                                            .dropdown-menu {
                                                padding: 0;
                                                border-radius: rem(12) 0 rem(12) rem(12);
                                                background: #FEFEFE;
                                                transform: translate(rem(-40), rem(30)) !important;

                                                .dropdown-item {
                                                    padding: rem(8) rem(20);
                                                    font-size: rem(16);
                                                    font-weight: 400;
                                                    color: $designation;
                                                    padding-left: rem(45);
                                                    position: relative;
                                                    border-bottom: 0.3px solid #808080;


                                                    &:focus,
                                                    &:hover {

                                                        // color: var(--bs-dropdown-link-hover-color);
                                                        // background-color: var(--bs-dropdown-link-hover-bg);
                                                        &:first-child {
                                                            border-radius: rem(12) 0 rem(0) rem(0);
                                                        }

                                                        &:last-child {
                                                            border-radius: rem(0) 0 rem(12) rem(12);
                                                        }

                                                    }


                                                    &:last-child {
                                                        border-bottom: none;
                                                    }

                                                    // .image_area {
                                                    //     padding-left: rem(8);
                                                    //     overflow: hidden;

                                                    //     img {
                                                    //         width: 100%;
                                                    //         height: 100%;
                                                    //         object-fit: cover;
                                                    //     }

                                                    //     &.delete_icon {
                                                    //         margin-top: rem(-4);
                                                    //     }
                                                    // }

                                                }

                                            }

                                            .dropdown-toggle::after {
                                                display: none !important;
                                            }

                                            .btn {
                                                color: $form_label_color;
                                                padding-top: 0;

                                                &:hover,
                                                &:focus {
                                                    background-color: $white;
                                                }
                                            }




                                            &:first-child {
                                                padding-left: rem(20);
                                            }

                                            &:last-child {
                                                padding-right: rem(50);
                                            }

                                            p {
                                                font-weight: 500;
                                                font-size: 14px;

                                                span {
                                                    background: rgba(55, 55, 128, 0.2);
                                                    border-radius: rem(8);
                                                    color: $blue;
                                                    background: $light_blue;
                                                    padding: 10px;

                                                    // padding: rem(3) rem(11);

                                                    &.designation {
                                                        // padding: 10px;
                                                        background: #D1D1E0;
                                                        color: $form_border_color;
                                                        white-space: nowrap;
                                                    }
                                                }
                                            }

                                            .badge {
                                                width: rem(165);
                                                margin: 0 auto;

                                                p {
                                                    padding: rem(5) rem(10);
                                                    height: rem(37);
                                                    margin: 0 auto;
                                                    border-radius: rem(8);
                                                    font-weight: 500;
                                                    font-size: rem(14);
                                                    color: $white;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;

                                                    &.warning {
                                                        background: $treatment_color;
                                                        width: rem(124);
                                                    }

                                                    &.danger {
                                                        background-color: $critical_color;
                                                        width: rem(100);
                                                    }

                                                    &.success {
                                                        background: $success_color;
                                                        width: rem(78);
                                                    }

                                                    &.discharge {
                                                        background: #FFEDAD;
                                                        color: #CD7915;
                                                        width: rem(82);
                                                    }
                                                }
                                            }

                                            .icon {
                                                .dropdown-menu {
                                                    padding: 0;
                                                    border-radius: rem(12) 0 rem(12) rem(12);
                                                    background: #FEFEFE;
                                                    transform: translate(rem(-40), rem(30)) !important;

                                                    .dropdown-item {
                                                        padding: rem(8) rem(20);
                                                        font-size: rem(16);
                                                        font-weight: 400;
                                                        color: $designation;
                                                        padding-left: rem(45);
                                                        position: relative;
                                                        border-bottom: 0.3px solid #808080;


                                                        &:focus,
                                                        &:hover {

                                                            // color: var(--bs-dropdown-link-hover-color);
                                                            // background-color: var(--bs-dropdown-link-hover-bg);
                                                            &:first-child {
                                                                border-radius: rem(12) 0 rem(0) rem(0);
                                                            }

                                                            &:last-child {
                                                                border-radius: rem(0) 0 rem(12) rem(12);
                                                            }

                                                        }


                                                        &:last-child {
                                                            border-bottom: none;
                                                        }

                                                        .image_area {
                                                            padding-left: rem(8);
                                                            overflow: hidden;

                                                            img {
                                                                width: 100%;
                                                                height: 100%;
                                                                object-fit: cover;
                                                            }

                                                            &.delete_icon {
                                                                margin-top: rem(-4);
                                                            }
                                                        }

                                                    }

                                                }

                                                .btn {
                                                    color: $form_label_color;
                                                    padding-top: 0;

                                                    &:hover,
                                                    &:focus {
                                                        background-color: $white;
                                                    }
                                                }

                                                .ellipsis_icon {
                                                    font-size: rem(27);
                                                    cursor: pointer;
                                                }

                                                .dropdown-toggle::after {
                                                    display: none !important;
                                                }
                                            }

                                            .badge_low {
                                                padding: rem(5) rem(10);
                                                background: $light_white;
                                                border-radius: rem(20);
                                                width: rem(73);
                                                height: rem(29);
                                                display: block;
                                                margin: 0 auto;

                                                p {
                                                    font-weight: 500;
                                                    font-size: rem(14);
                                                    color: $notification;
                                                }

                                            }

                                            .badge_high {
                                                padding: rem(5) rem(10);
                                                background: rgba(233, 97, 97, 0.3);
                                                border-radius: rem(20);
                                                width: rem(73);
                                                height: rem(29);
                                                display: block;
                                                margin: 0 auto;

                                                p {
                                                    font-weight: 500;
                                                    font-size: rem(14);
                                                    color: $fade_pink;
                                                }
                                            }

                                            .badge_complete {
                                                padding: rem(10);
                                                background: $light_white;
                                                border-radius: rem(20);
                                                width: rem(88);
                                                height: rem(37);
                                                display: block;
                                                margin: 0 auto;

                                                p {
                                                    font-weight: 500;
                                                    font-size: rem(14);
                                                    color: $notification;
                                                }

                                            }

                                            .under_line {
                                                font-weight: 600;
                                                font-size: rem(16);
                                                color: $designation;
                                                cursor: pointer;

                                                &:hover {
                                                    text-decoration: underline;
                                                }
                                            }

                                            .data_table {
                                                width: rem(230);
                                                display: flex;
                                                align-items: center;

                                                .left_area {
                                                    width: rem(50);
                                                    padding: 0;
                                                    margin-left: rem(20);

                                                    .img_area {
                                                        width: rem(50);
                                                        height: rem(50);
                                                        border-radius: rem(50);
                                                        margin-top: rem(5);
                                                        overflow: hidden;

                                                        img {
                                                            width: 100%;
                                                            height: 100%;
                                                            object-fit: cover;
                                                        }
                                                    }
                                                }

                                                .right_areaa {
                                                    width: calc(230px - 50px);
                                                    text-align: left;
                                                    margin-left: 10px;
                                                    cursor: pointer;

                                                    h4 {
                                                        font-weight: 600;
                                                        font-size: rem(16);
                                                        color: $designation;
                                                    }

                                                    p {
                                                        margin-top: rem(5);
                                                        font-weight: 500;
                                                        font-size: rem(14);

                                                        span {
                                                            background: rgba(55, 55, 128, 0.2);
                                                            border-radius: rem(5);
                                                            color: $blue;
                                                            background: $light_blue;
                                                            padding: rem(3) rem(11);

                                                            &.designation {
                                                                background: $off_white;
                                                                color: $form_border_color;
                                                                margin-right: rem(14);

                                                            }
                                                        }

                                                        &.patient_number {
                                                            color: $black_2;
                                                            font-weight: 400;
                                                        }

                                                    }
                                                }
                                            }

                                        }
                                    }
                                }

                                &::-webkit-scrollbar-track {
                                    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                                    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                                    background-color: $grey;
                                }

                                &::-webkit-scrollbar {
                                    width: 2px;
                                    height: 6px;
                                    background-color: $dark_red_2;
                                    margin: 0 rem(20);
                                }

                                &::-webkit-scrollbar-thumb {
                                    background-color: $dark_red_2;
                                }
                            }

                        }
                    }

                }

                .left_area {
                    float: left;
                    @include clearfix();

                    .inner_left {
                        float: left;
                        width: rem(106);

                        .img_area {
                            width: rem(106);
                            height: rem(106);
                            border-radius: rem(100);
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .inner_right {
                        float: left;
                        width: calc(100% - 106px);
                        padding-left: rem(15);

                        .name {
                            h3 {
                                font-size: rem(36);
                                font-weight: 600;
                                background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }
                        }

                        .gender {
                            display: flex;
                            margin-top: rem(15);

                            p {
                                font-size: rem(16);
                                font-weight: 400;
                                color: $designation;

                                .gender_icon {
                                    margin-right: rem(5);
                                    color: #D81B23;
                                }

                                span {
                                    margin-left: rem(30);
                                    background: rgba(55, 55, 128, 0.2);
                                    border-radius: rem(5);
                                    color: $form_border_color;
                                    padding: rem(3) rem(11);
                                }
                            }
                        }

                        .date {
                            display: flex;
                            margin-top: rem(15);

                            .date_icon {
                                margin-right: rem(5);
                                color: $dark_red_2;
                            }

                            p {
                                color: $designation;
                                font-size: rem(16);
                                font-weight: 400;
                            }
                        }
                    }
                }

                .right_area {
                    float: right;
                    width: calc(100% - 500px);
                    margin-top: rem(20);
                }
            }
        }

        .without_image_header {
            .header_area {
                .details {
                    .icon_area {
                        .img_one {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .manage_patient_list_screen {
        .patient_list_details {
            .title_btn_area {
                padding-top: 0;
                padding-left: rem(20);
                padding-right: rem(20);
            }

            .search_section {
                margin-bottom: 10px;

                .search_left_area {
                    width: 60%;

                    form {
                        .form-group {
                            .form-control {
                                height: rem(60);
                            }
                        }
                    }
                }

                .search_right_area {
                    width: calc(100% - 60%);

                    .form-group {
                        .form-select {
                            height: rem(60);
                        }

                        &.select_pagination {
                            margin-left: rem(13) !important;
                            margin-right: rem(13) !important;
                        }
                    }
                }
            }

            .list_detail {
                padding-left: rem(20);
                padding-right: rem(20);

                .list_data {
                    .card {
                        .card-body {
                            .table-responsive {
                                .table {
                                    tr {
                                        th {
                                            padding: rem(15) rem(20);
                                        }

                                        td {
                                            padding: rem(15) 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // .search_section {
            //     padding-left: 0;
            //     padding-right: 0;
            //     margin-bottom: 0;

            //     .search_left_area {
            //         width: 100%;

            //         form {
            //             .form-group {
            //                 margin-bottom: rem(20);

            //                 a {
            //                     .icon {
            //                         margin-top: rem(14);
            //                     }
            //                 }

            //                 .form-control {
            //                     padding-left: rem(40);
            //                 }
            //             }
            //         }
            //     }

            //     .search_right_area {
            //         float: left;
            //         width: 100%;
            //         margin-bottom: rem(0);

            //         form {
            //             .form-group {
            //                 &.select_pagination {
            //                     margin-left: 0;
            //                     margin-right: 0;
            //                 }

            //                 .form-select {
            //                     width: auto;
            //                 }
            //             }
            //         }

            //         .filter_button {
            //             width: 100%;

            //             .btn-primary {
            //                 height: rem(53);
            //             }
            //         }
            //     }
            // }
        }
    }
}

@media (min-width: rem(361)) and (max-width: rem(575)) {
    .manage_patient_list_screen {
        .patient_list_details {
            .title_btn_area {
                padding-top: 0;
                padding-left: rem(0);
                padding-right: rem(0);

                .left_area {
                    h3 {
                        font-size: rem(20);
                    }
                }

                .right_area {
                    .btn {
                        font-size: rem(20);
                        width: rem(150);
                    }
                }
            }

            .search_section {
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 0;

                .search_left_area {
                    width: 100%;

                    form {
                        .form-group {
                            margin-bottom: rem(20);

                            a {
                                .icon {
                                    margin-top: rem(14);
                                }
                            }

                            .form-control {
                                padding-left: rem(40);
                            }
                        }
                    }
                }

                .search_right_area {
                    float: left;
                    width: 100%;
                    margin-bottom: rem(0);

                    form {
                        .form-group {
                            &.select_pagination {
                                margin-left: 0;
                                margin-right: 0;
                            }

                            .form-select {
                                width: auto;
                            }
                        }
                    }

                    .filter_button {
                        width: 100%;

                        .btn-primary {
                            height: rem(53);
                        }
                    }
                }
            }

            .list_detail {
                padding-left: 0;
                padding-right: 0;

                .list_data {
                    .card {
                        border-radius: rem(15);

                        .card-body {
                            .table-responsive {
                                .table {
                                    tr {
                                        th {
                                            padding: rem(15) rem(20);
                                        }

                                        td {
                                            padding: rem(15) 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: rem(360)) {
    .manage_patient_list_screen {
        .patient_list_details {
            .title_btn_area {
                padding-top: 0;
                padding-left: rem(0);
                padding-right: rem(0);

                .left_area {
                    h3 {
                        font-size: rem(20);
                    }
                }

                .right_area {
                    .btn {
                        font-size: rem(20);
                        width: rem(150);
                    }
                }
            }

            .search_section {
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 0;

                .search_left_area {
                    width: 100%;

                    form {
                        .form-group {
                            margin-bottom: rem(20);

                            a {
                                .icon {
                                    margin-top: rem(14);
                                }
                            }

                            .form-control {
                                padding-left: rem(40);
                            }
                        }
                    }
                }

                .search_right_area {
                    float: left;
                    width: 100%;
                    margin-bottom: rem(0);

                    form {
                        .form-group {
                            &.select_pagination {
                                margin-left: 0;
                                margin-right: 0;
                            }

                            .form-select {
                                width: auto;
                            }
                        }
                    }

                    .filter_button {
                        width: 100%;

                        .btn-primary {
                            height: rem(53);
                        }
                    }
                }
            }

            .list_detail {
                padding-left: 0;
                padding-right: 0;

                .list_data {
                    .card {
                        border-radius: rem(15);

                        .card-body {
                            .table-responsive {
                                .table {
                                    tr {
                                        th {
                                            padding: rem(15) rem(20);
                                        }

                                        td {
                                            padding: rem(15) 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}