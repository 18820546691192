.calendar_modal {
    &.modal {
        .modal-dialog {
            &.modal-dialog-centered {
                width: 100%;
                max-width: rem(680);

                .modal-content {
                    background: linear-gradient(104.06deg, $white 4.74%, #F0F0F0 99.5%);
                    border: rem(1) solid $grey;
                    box-shadow: 0 rem(4) rem(25) rgba(55, 55, 128, 0.14);
                    border-radius: rem(25);
                    padding: 0 rem(26);

                    .modal-header {
                        padding: rem(22) 0;
                        &.header_area{
                            border-bottom: 1.2px solid #7A7A7A
                        }

                        .modal-title {
                            &.h4 {
                                font-weight: 600;
                                font-size: rem(22);
                                line-height: rem(26);
                                color: $form_label_color;
                                &.title {
                                    font-weight: 600;
                                    font-size: rem(24);
                                    line-height: rem(29);
                                    color: $black_2;
                                }
                            }
                        }
                    }
                }

                .modal-body {
                    padding: 0;

                    .report_details_form {
                        padding-top: rem(40);

                        .year_btns{
                            display: flex;
                            align-items: center;
                            justify-content: center; 
                            gap: rem(20);
                            .btn{
                                .show{
                                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                    border-radius: 14px;
                                    color: $white;
                                    width: 160px;
                                    height: 70px;
                                    font-size: 22px;
                                    font-weight: 400;
                                    position: relative;
                                    z-index: 9;
                                }
                                &:hover {
                                    background-color: transparent; 
                                }
                                .list {
                                    
                                    height: 135px;
                                    overflow: auto;
                                    background: $white;
                                    font-size: 20px; 
                                    border-radius: 0; 
                                    margin-top: -9px;
                                    z-index: 1;
                                    &::-webkit-scrollbar {
                                        width: 4px;
                                        border-radius: 2px;
                                        background-color: $grey;
                                    }
                                    &::-webkit-scrollbar-track {
                                        border-radius: 2px;
                                    }
                                    &::-webkit-scrollbar-thumb {
                                        background: $critical_color; 
                                        border-radius: 2px;
                                    }
                                    &::-webkit-scrollbar-thumb:hover {
                                        background: $critical_color; 
                                    }



                                    .dropdown-item {
                                        color: $designation;
                                        font-weight: 400;
                                        font-size: 14px;
                                        &:active, &.active {
                                            background: linear-gradient(90deg, rgba(255, 178, 178, 0.6) 13.09%, rgba(217, 217, 217, 0) 120.1%);
                                            border-left: rem(4) solid $critical_color;
                                            border-radius: 0px 0px 4px 0px;
                                            color: $black_2;
                                        }
                                    }

                                   
                                }
                                
                            }
                                
                        }

                        .btn_area {
                            margin-bottom: rem(55);
                            gap: rem(40);

                            .btn {
                                margin-top: rem(20);
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .add_report_modal {
        &.modal {
            .modal-dialog {
                &.modal-dialog-centered {
                    max-width: rem(550);

                    .modal-body {
                        .report_details_form {
                            .form_group {
                                label {
                                    &.form-label {
                                        font-size: rem(20);
                                    }
                                }

                                .form-control {
                                    font-size: rem(20);
                                    margin-bottom: rem(10);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .add_report_modal {
        &.modal {
            .modal-dialog {
                &.modal-dialog-centered {
                    max-width: rem(350);

                    .modal-content {
                        padding: 0 rem(15);

                        .modal-header {
                            padding: rem(18) 0;

                            .modal-title {
                                &.h4 {
                                    font-size: rem(20);
                                }
                            }
                        }
                    }

                    .modal-body {
                        .report_details_form {
                            padding-top: rem(25);

                            .form_group {
                                margin-bottom: rem(20);

                                label {
                                    &.form-label {
                                        font-size: rem(18);
                                    }
                                }

                                .form-control {
                                    font-size: rem(18);
                                    margin-bottom: rem(10);
                                }
                            }

                            .btn_area {
                                margin-bottom: rem(35);
                                gap: rem(20);
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Smaller phones */
@media (max-width: rem(360)) {
    .add_report_modal {
        &.modal {
            .modal-dialog {
                &.modal-dialog-centered {
                    max-width: rem(345);

                    .modal-content {
                        padding: 0 rem(15);

                        .modal-header {
                            padding: rem(18) 0;

                            .modal-title {
                                &.h4 {
                                    font-size: rem(20);
                                }
                            }
                        }
                    }

                    .modal-body {
                        .report_details_form {
                            padding-top: rem(25);

                            .form_group {
                                margin-bottom: rem(20);

                                label {
                                    &.form-label {
                                        font-size: rem(18);
                                    }
                                }

                                .form-control {
                                    font-size: rem(18);
                                    margin-bottom: rem(10);
                                }
                            }

                            .btn_area {
                                margin-bottom: rem(35);
                                gap: rem(20);
                            }
                        }
                    }
                }
            }
        }
    }
}