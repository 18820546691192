.filter_modal {
    &.modal {
        .modal-dialog {
            &.modal-dialog-centered {
                .modal-content {
                    .modal-header {
                        border-bottom: rem(1) solid $grey;
                        padding: rem(24);

                        .modal-title {
                            &.h4 {
                                font-size: rem(24);
                                font-weight: 600;
                            }
                        }
                    }

                    .modal-body {
                        padding: 0;

                        .filter_details {
                            .flex-column {
                                &.nav {
                                    &.nav-pills {
                                        .nav-item {
                                            .nav-link {
                                                background: #F3F3F3;
                                                border-radius: 0;
                                                color: $black_2;
                                                border-left: transparent;
                                                font-weight: 600;
                                                font-size: rem(24);
                                                width: rem(228);
                                                height: rem(89);
                                                display: flex;
                                                align-items: center;
                                                padding-left: rem(25);

                                                &.active {
                                                    background: linear-gradient(90deg, rgba(255, 179, 179, 0.52) 0%, rgba(217, 217, 217, 0) 95%);
                                                    border-left: rem(5) solid $critical_color;
                                                }
                                            }

                                        }
                                    }
                                }
                            }

                            .content_area {
                                margin: rem(30) 0 0 rem(30);
                                position: relative;

                                .tab-content {

                                    form {
                                        .check_box_slider {
                                            height: rem(455);
                                            overflow-y: auto;

                                            &::-webkit-scrollbar-track {
                                                box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                                                -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                                                background-color: $grey;
                                            }

                                            &::-webkit-scrollbar {
                                                width: 6px;
                                                height: 2px;
                                                background-color: $dark_red_2;
                                                margin: 0 rem(20);
                                            }

                                            &::-webkit-scrollbar-thumb {
                                                background-color: $dark_red_2;
                                            }

                                            .form-check {
                                                margin-bottom: rem(29);
                                                padding-left: 33px;

                                                .form-check-input {
                                                    font-weight: 400;
                                                    font-size: rem(22);
                                                    border-color: $red_gradient_color;
                                                    border-width: rem(2);

                                                    &:checked {
                                                        border-color: $red_gradient_color;
                                                        background-color: transparent;
                                                        background-image: url('../../../images/checkbox.png');
                                                        background-size: rem(13) rem(9);
                                                    }
                                                }

                                                label {
                                                    font-size: rem(22);
                                                    font-weight: 400;
                                                    color: $designation;
                                                    margin-top: rem(2);
                                                }
                                            }

                                        }

                                        .form-select {
                                            height: 60px;
                                            font-size: 22px;
                                            font-weight: 400;
                                            padding-left: 20px;
                                            border: 1.5px solid #373780;
                                            border-radius: 10px;
                                            color: #7A7A7A;

                                        }

                                        &.sort_form {
                                            margin-right: rem(37);
                                        }

                                        &.ward_form {
                                            margin-right: rem(37);

                                            .form-group {
                                                a {
                                                    .icon {
                                                        position: absolute;
                                                        top: 0;
                                                        right: 0;
                                                        margin-top: rem(16);
                                                        margin-right: rem(20);
                                                        font-size: rem(26);
                                                        color: #7a7a7a;
                                                    }
                                                }
                                            }

                                            .form-check {
                                                margin-left: rem(25);

                                                &:last-child {
                                                    margin-bottom: 0;
                                                }
                                            }
                                        }

                                        &.admission_date_form {
                                            margin-right: rem(30);
                                            position: relative;

                                            .form-group {
                                                label {
                                                    span {
                                                        color: $critical_color;
                                                    }
                                                }

                                                &:last-child {
                                                    margin-bottom: 0;
                                                }

                                                .icon {
                                                    position: absolute;
                                                    right: 0;
                                                    bottom: 0;
                                                    margin-bottom: rem(18);
                                                    margin-right: rem(20);
                                                    color: $card_text_color;
                                                    font-size: rem(20);
                                                    cursor: pointer;
                                                }
                                            }
                                        }
                                    }
                                }
                            }


                            .btn_area {
                                padding: rem(50) rem(37) rem(37) rem(37);
                                gap: rem(44);
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .filter_modal {
        &.modal {
            .modal-dialog {
                max-width: rem(525);
                &.modal-dialog-centered {
                    .modal-content {
                        .modal-header {
                            padding: rem(18) rem(20);
                        }

                        .modal-body {
                            .filter_details {
                                .flex-column {
                                    &.nav {
                                        &.nav-pills {
                                            .nav-item {
                                                .nav-link {
                                                    font-size: rem(20);
                                                    width: rem(155);
                                                    height: rem(80);
                                                    justify-content: flex-start;
                                                }
                                            }
                                        }
                                    }
                                }

                                .content_area {
                                    margin: rem(20);
                                    margin-bottom: 0;
                                    margin-left: 0;
                                    .tab-content {
                                        form {
                                            &.ward_form {
                                                margin-right: 0;
                                                .form-group {
                                                    a {
                                                        .icon {
                                                            font-size: rem(20);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        form {
                                            .check_box_slider {
                                                .form-check {
                                                    margin-bottom: rem(20);
                                                }
                                            }

                                            &.ward_form {

                                                .form-group {
                                                    margin-bottom: rem(20);

                                                    a {
                                                        .icon {
                                                            font-size: rem(20);
                                                        }
                                                    }
                                                }
                                            }

                                            &.admission_date_form {
                                                .form-group {
                                                    margin-bottom: rem(20);

                                                    .icon {
                                                        margin-bottom: rem(15);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .filter_modal {
        &.modal {
            .modal-dialog {
                &.modal-dialog-centered {
                    .modal-content {
                        .modal-header {
                            padding: rem(16);
                        }
                        .modal-body {
                            .filter_details {
                                .flex-column {
                                    &.nav {
                                        &.nav-pills {
                                            .nav-item {
                                                .nav-link {
                                                    font-size: rem(18);
                                                    width: rem(125);
                                                    height: rem(70);
                                                    justify-content: flex-start;
                                                }
                                            }
                                        }
                                    }
                                }

                                .content_area {
                                    margin: rem(15) 0 0 rem(15);

                                    .tab-content {
                                        form {
                                            &.patient_form {
                                                margin-left: rem(20);
                                            }

                                            .form-check {
                                                margin-bottom: rem(20);

                                                label {
                                                    font-size: rem(18);
                                                    margin-top: rem(4);
                                                }
                                            }

                                            &.ward_form {
                                                margin-right: rem(10);

                                                .form-group {
                                                    margin-bottom: rem(20);

                                                    a {
                                                        .icon {
                                                            font-size: rem(20);
                                                        }
                                                    }
                                                }

                                                .form-check {
                                                    margin-left: 0;
                                                    margin-bottom: 12px;

                                                    label {
                                                        font-size: 18px;
                                                    }

                                                    .form-check-input {
                                                        font-size: 18px;

                                                        &:checked {
                                                            background-size: 10px 8px;
                                                        }
                                                    }
                                                }
                                            }

                                            &.admission_date_form {
                                                margin-right: rem(15);

                                                .form-group {
                                                    margin-bottom: rem(20);

                                                    .form-control {
                                                        font-size: rem(16);
                                                    }

                                                    .icon {
                                                        margin-bottom: rem(19);
                                                        margin-right: rem(15);
                                                        font-size: rem(14);
                                                    }
                                                }
                                            }

                                            .check_box_slider {
                                                margin-right: 10px;
                                            }
                                        }
                                    }
                                }

                                .btn_area {
                                    padding: rem(40) rem(25) rem(25) rem(25);
                                    gap: rem(20);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Smaller phones */
@media (max-width: rem(360)) {
    .filter_modal {
        &.modal {
            .modal-dialog {
                &.modal-dialog-centered {
                    .modal-content {
                        .modal-header {
                            padding: rem(16);
                        }
                        .modal-body {
                            .filter_details {
                                .flex-column {
                                    &.nav {
                                        &.nav-pills {
                                            .nav-item {
                                                .nav-link {
                                                    font-size: rem(18);
                                                    width: rem(125);
                                                    height: rem(70);
                                                    justify-content: flex-start;
                                                }
                                            }
                                        }
                                    }
                                }

                                .content_area {
                                    margin: rem(15) 0 0 rem(15);

                                    .tab-content {
                                        form {
                                            &.patient_form {
                                                margin-left: rem(20);
                                            }

                                            .form-check {
                                                margin-bottom: rem(20);

                                                label {
                                                    font-size: rem(18);
                                                    margin-top: rem(4);
                                                }
                                            }

                                            &.ward_form {
                                                margin-right: rem(10);

                                                .form-group {
                                                    margin-bottom: rem(20);

                                                    a {
                                                        .icon {
                                                            font-size: rem(20);
                                                        }
                                                    }
                                                }

                                                .form-check {
                                                    margin-left: 0;
                                                    margin-bottom: 12px;

                                                    label {
                                                        font-size: 18px;
                                                    }

                                                    .form-check-input {
                                                        font-size: 18px;

                                                        &:checked {
                                                            background-size: 10px 8px;
                                                        }
                                                    }
                                                }
                                            }

                                            &.admission_date_form {
                                                margin-right: rem(15);

                                                .form-group {
                                                    margin-bottom: rem(20);

                                                    .form-control {
                                                        font-size: rem(16);
                                                    }

                                                    .icon {
                                                        margin-bottom: rem(19);
                                                        margin-right: rem(15);
                                                        font-size: rem(14);
                                                    }
                                                }
                                            }

                                            .check_box_slider {
                                                margin-right: 10px;
                                            }
                                        }
                                    }
                                }

                                .btn_area {
                                    padding: rem(40) rem(25) rem(25) rem(25);
                                    gap: rem(20);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}