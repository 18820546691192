.add_Report_hemotology {
    .report_main_area {
        margin: 0 rem(15);

        .btn_area {
            margin-bottom: rem(20);
            margin-top: rem(30);

            .cancelBtn {
                &:hover {
                    opacity: unset;
                }
            }

            .submitBtn {
                &:hover {
                    opacity: unset;
                }
            }
        }

        form {
            .form-group {
                label {
                    span {
                        color: $critical_color;
                    }
                }
            }
        }

        .inner_area {
            border-bottom: rem(1) solid $border_line2;
            padding-bottom: rem(15);

            .cross_icon {
                display: flex;
                justify-content: end;
                margin-top: rem(15);

                .close {
                    color: $grey_4;
                    font-size: rem(30);
                }
            }

            .form_div_area {
                @include clearfix();

                .left_area {
                    float: left;
                    width: calc(100% - 64px);
                    padding-right: rem(25);
                }

                .right_area {
                    float: right;
                    width: rem(64);

                    .plus_box {
                        background-color: $form_border_color;
                        border: rem(1.5) solid $form_border_color;
                        border-radius: rem(10);
                        height: rem(62);
                        width: rem(64);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 56%;

                        .icon_plus {
                            font-size: rem(20);
                            color: $white;
                        }
                    }
                }
            }

            .form_btns_area {
                @include clearfix();

                .left_area {
                    float: left;
                    width: calc(100% - 155px);
                    padding-right: rem(25);
                }

                .right_area {
                    float: right;
                    width: rem(155);

                    .icons_main {
                        display: flex;

                        .plus_box {
                            background-color: $form_border_color;
                            border: rem(1.5) solid $form_border_color;
                            border-radius: rem(10);
                            height: rem(62);
                            width: rem(64);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 56%;

                            &.space_area {
                                position: relative;
                                left: rem(25);
                            }

                            .icon_plus {
                                font-size: rem(20);
                                color: $white;
                            }
                        }
                    }

                }
            }
        }

        .add_btn_area {
            margin-top: rem(45);

            a {
                font-size: rem(24);
                font-weight: 600;
                color: $white;
                padding: rem(14) rem(25);
                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                border-radius: rem(40);
                min-width: rem(280);
            }
        }

        .upload_img_area {
            margin-top: rem(35);

            .before_upload_area {
                border: 1px dashed $border_line_3;
                border-radius: rem(20);
                height: rem(133);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: rem(15);

                .text_area {
                    font-size: rem(24);
                    font-weight: 600;
                    color: $designation;

                    .icon_area {
                        font-size: rem(20);
                        margin-right: rem(12);
                        color: $card_text_color;
                    }
                }
            }

            .after_upload_area {
                border: 1px dashed $border_line_3;
                border-radius: rem(20);
                height: rem(155);
                display: flex;
                padding: rem(15) rem(7);
                overflow-x: auto;


                .inner_img_area {
                    border: 1px solid $border_line_3;
                    border-radius: rem(20);
                    width: rem(120);
                    height: rem(120);
                    padding: rem(15);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 rem(7);

                    &.upload_file {
                        background-color: $file_bg;
                        position: relative;

                        .icon_text_area {
                            .icon {
                                color: $black;
                            }

                            p {
                                color: $black;
                                word-break: break-word;
                            }
                        }

                        .cross_icon {
                            font-size: rem(15);
                            color: $grey_3;
                            position: absolute;
                            top: 0;
                            right: 0;
                            margin-top: rem(10);
                            margin-right: rem(10);
                        }
                    }

                    .icon_text_area {
                        text-align: center;

                        .icon {
                            font-size: rem(18);
                            color: $card_text_color;
                            margin-bottom: rem(4);
                            text-align: center;
                        }

                        p {
                            font-size: rem(16);
                            font-weight: 400;
                            color: $designation;
                            width: rem(118);
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .add_Report_hemotology {
        .report_main_area {
            margin: 0;

            .btn_area {
                margin-top: rem(25);

                .btn {
                    font-size: rem(20);
                    height: rem(50);
                    margin-top: rem(20);
                }

                .cancelBtn {
                    &:hover {
                        opacity: unset;
                    }
                }

                .submitBtn {
                    &:hover {
                        opacity: unset;
                    }
                }
            }

            .inner_area {
                padding-bottom: rem(5);

                .form_div_area {
                    .right_area {
                        .plus_box {
                            height: rem(53);
                            width: rem(54);
                            margin-top: 60%;

                            .icon_plus {
                                font-size: rem(18);

                            }
                        }
                    }
                }

                .form_btns_area {
                    .left_area {
                        padding-right: rem(18);
                        width: calc(100% - 126px);
                    }

                    .right_area {
                        width: rem(126);

                        .icons_main {
                            .plus_box {
                                height: rem(53);
                                width: rem(54);
                                margin-top: 60%;

                                &.space_area {
                                    left: rem(18);
                                }

                                .icon_plus {
                                    font-size: rem(18);

                                }
                            }
                        }
                    }
                }

                .cross_icon {
                    margin-top: rem(8);

                    .close {
                        font-size: rem(25);
                    }
                }

                .form_div_area {
                    .left_area {
                        width: calc(100% - 54px);
                        padding-right: 18px;
                    }

                    .right_area {
                        width: rem(54);
                    }
                }
            }

            .add_btn_area {
                margin-top: rem(30);

                a {
                    font-size: rem(18);
                    padding: rem(12) rem(20);
                }
            }

            .upload_img_area {
                margin-top: rem(20);

                .before_upload_area {
                    height: rem(122);
                    border-radius: rem(12);

                    .text_area {
                        font-size: rem(18);

                        .icon_area {
                            font-size: rem(17);
                            margin-right: rem(8);
                        }
                    }
                }

                .after_upload_area {
                    height: rem(122);
                    border-radius: rem(12);

                    .inner_img_area {
                        border-radius: rem(12);
                        width: rem(98);
                        height: rem(90);
                        padding: rem(10);

                        &.upload_file {
                            .cross_icon {
                                font-size: rem(14);
                                margin-top: rem(6);
                                margin-right: rem(6);
                            }
                        }

                        .icon_text_area {
                            p {
                                font-size: rem(14);
                                width: rem(105);
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .add_Report_hemotology {
        .report_main_area {
            margin: 0;

            .btn_area {
                margin-top: rem(25);

                .btn {
                    font-size: rem(20);
                    height: rem(50);
                    margin-top: rem(20);
                }

                .cancelBtn {
                    &:hover {
                        opacity: unset;
                    }
                }

                .submitBtn {
                    &:hover {
                        opacity: unset;
                    }
                }
            }

            .inner_area {
                padding-bottom: rem(5);

                .form_div_area {
                    .right_area {
                        .plus_box {
                            height: rem(53);
                            width: rem(54);
                            margin-top: 60%;

                            .icon_plus {
                                font-size: rem(18);

                            }
                        }
                    }
                }

                .form_btns_area {
                    .left_area {
                        padding-right: rem(18);
                        width: calc(100% - 126px);
                    }

                    .right_area {
                        width: rem(126);

                        .icons_main {
                            .plus_box {
                                height: rem(53);
                                width: rem(54);
                                margin-top: 60%;

                                &.space_area {
                                    left: rem(18);
                                }

                                .icon_plus {
                                    font-size: rem(18);

                                }
                            }
                        }
                    }
                }

                .cross_icon {
                    margin-top: rem(8);

                    .close {
                        font-size: rem(25);
                    }
                }

                .form_div_area {
                    .left_area {
                        width: calc(100% - 54px);
                        padding-right: 18px;
                    }

                    .right_area {
                        width: rem(54);
                    }
                }
            }

            .add_btn_area {
                margin-top: rem(30);

                a {
                    font-size: rem(18);
                    padding: rem(12) rem(20);
                }
            }

            .upload_img_area {
                margin-top: rem(20);

                .before_upload_area {
                    height: rem(122);
                    border-radius: rem(12);

                    .text_area {
                        font-size: rem(18);

                        .icon_area {
                            font-size: rem(17);
                            margin-right: rem(8);
                        }
                    }
                }

                .after_upload_area {
                    height: rem(122);
                    border-radius: rem(12);

                    .inner_img_area {
                        border-radius: rem(12);
                        width: rem(98);
                        height: rem(90);
                        padding: rem(10);

                        &.upload_file {
                            .cross_icon {
                                font-size: rem(14);
                                margin-top: rem(6);
                                margin-right: rem(6);
                            }
                        }

                        .icon_text_area {
                            p {
                                font-size: rem(14);
                                width: rem(105);
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}