.medicine_update_screen {
    // position: relative;

    .medicine_main_area {
        .date_main {
            @include clearfix();
            display: flex;
            align-items: center;
            margin-bottom: rem(20);

            .left_area {
                float: left;
                width: calc(100% - 30px);
                display: flex;

                p {
                    font-size: rem(16);
                    font-weight: 600;
                    color: $black_2;
                }

                .check_select {
                    margin-left: 20px;

                    .form-check {
                        label {
                            font-size: rem(16);
                            font-weight: 600;
                            color: $black_2;
                        }

                        .form-check-input {
                            background-color: $white;
                            border: 1px solid $designation;
                            margin-top: 4px;
                            margin-right: 10px;

                            &:checked {
                                border-color: $red_gradient_color;
                                background-image: url('../../../images/vector.png');
                            }

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }

                .dropdown_select {
                    margin-left: 20px;

                    .ellipsis_icon {
                        font-size: rem(24);
                        cursor: pointer;
                        float: right;
                        // margin-top: rem(-32);
                        // margin-right: rem(-40);
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin-top: -10%;
                    }

                    .dropdown {
                        button {
                            &.dropdown-toggle {
                                // float: right;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                right: 0;

                                &::after {
                                    display: none;
                                    background-color: transparent;
                                }

                                &:focus,
                                &:hover {
                                    background-color: transparent;
                                }
                            }
                        }

                        .dropdown-menu.show {
                            transform: translate(3px, 18px) !important;
                            width: 10px;
                        }
                    }
                }
            }

            .right_area {
                float: right;
                width: rem(30);
                text-align: end;

                .img_area {
                    width: rem(30);

                    &:hover {
                        cursor: pointer;
                    }

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .accordion_area {
            .accordion {
                --bs-accordion-btn-icon-width: 15px;
                --bs-accordion-btn-icon: url('../../../images/down_icon.png');
                --bs-accordion-btn-active-icon: url('../../../images/down_icon.png');

                .accordion-item {
                    background: $white;
                    border: 0.5px solid $border_color_2;
                    border-radius: rem(12);
                    overflow: hidden;
                    margin-bottom: rem(20);

                    .accordion-header {
                        .accordion-button {
                            padding: rem(14) rem(25) rem(14) rem(25);
                            font-size: rem(16);
                            font-weight: 600;
                            color: $black_2;

                            &::after {
                                margin-top: rem(5);
                            }

                            &:not(.collapsed) {
                                color: unset;
                                background-color: unset;
                                box-shadow: unset;

                                &::after {
                                    margin-top: -6px;
                                }
                            }

                            &:focus {
                                z-index: 0;
                                border-color: unset;
                                outline: 0;
                                box-shadow: unset;
                            }
                        }

                        .check_box {
                            .form-check-input {
                                background-color: $white;
                                border: 1px solid $designation;
                                margin-top: 2px;
                                margin-right: 10px;

                                &:checked {
                                    border-color: $red_gradient_color;
                                    background-image: url('../../../images/vector.png');
                                }

                                &:focus {
                                    box-shadow: none;
                                }
                            }
                        }
                    }

                    .accordion-body {
                        padding: rem(16) rem(25) rem(12) rem(25);
                        position: relative;

                        &::after {
                            border-top: 1px solid $border_line2;
                            content: "";
                            display: block;
                            width: 94%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        }

                        .inner_body_area {
                            .top_area {
                                @include clearfix();
                                margin-bottom: rem(8);
                                display: flex;
                                align-items: center;

                                .check_box {
                                    .form-check-input {
                                        background-color: $white;
                                        border: 1px solid $designation;
                                        margin-top: 2px;
                                        margin-right: 10px;

                                        &:checked {
                                            border-color: $red_gradient_color;
                                            background-image: url('../../../images/vector.png');
                                        }

                                        &:focus {
                                            box-shadow: none;
                                        }
                                    }
                                }

                                .top_left_area {
                                    float: left;
                                    width: calc(100% - 145px);

                                    .text_area {
                                        display: flex;
                                        align-items: center;

                                        p {
                                            font-size: rem(16);
                                            font-weight: 600;
                                            color: $black_2;
                                        }

                                        .label {
                                            font-size: rem(14);
                                            font-weight: 500;
                                            color: $text_color2;
                                            background-color: $label_color;
                                            border-radius: rem(6);
                                            padding: rem(3) rem(8);
                                            display: inline-block;
                                            margin-left: rem(8);
                                        }
                                    }
                                }

                                .top_right_area {
                                    float: right;
                                    width: rem(145);
                                    text-align: end;

                                    .main_area {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;

                                        .icon_area {
                                            display: flex;

                                            .icon_style {
                                                color: $dark_red_2;
                                                font-size: rem(16);
                                                margin-right: rem(8);
                                            }
                                        }

                                        a {
                                            font-size: rem(14);
                                            font-weight: 500;
                                            padding: rem(4) rem(8);
                                            border-radius: rem(40);

                                            &.pending {
                                                color: $treatment_color;
                                                background-color: $bg_color;
                                            }

                                            &.complete {
                                                color: $notification;
                                                background: rgba(120, 182, 18, 0.18);
                                            }
                                        }
                                    }
                                }
                            }

                            .mid_content_area {
                                @include clearfix();

                                .left_con_area {
                                    float: left;
                                    width: calc(100% - 100px);

                                    ul {
                                        li {
                                            font-size: rem(14);
                                            font-weight: 500;
                                            color: $designation;
                                            margin-bottom: rem(10);

                                            &:last-child {
                                                margin-bottom: 0;
                                            }

                                            span {
                                                font-weight: 700;
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }

                                .right_con_area {
                                    float: right;
                                    width: rem(100);
                                    text-align: end;

                                    a {
                                        font-size: rem(13);
                                        font-weight: 600;
                                        color: $white;
                                        padding: rem(4) rem(18);
                                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                        border-radius: rem(26);
                                    }
                                }

                                &.remove_btn {
                                    width: 100%;

                                    .left_con_area {
                                        float: unset;
                                        width: 100%;
                                    }

                                    .right_con_area {
                                        float: unset;
                                        width: 100%;
                                    }
                                }
                            }

                            .bottom_area {
                                @include clearfix();
                                display: flex;
                                align-items: center;
                                margin-top: rem(20);
                                padding-top: rem(12);
                                border-top: 1px solid $border_line2;

                                .left_area {
                                    float: left;
                                    width: calc(100% - 240px);

                                    p {
                                        font-size: rem(14);
                                        font-weight: 400;
                                        color: $designation;

                                        span {
                                            font-weight: 700;
                                            text-decoration: underline;
                                        }
                                    }
                                }

                                .right_area {
                                    float: right;
                                    width: rem(240);
                                    text-align: end;

                                    p {
                                        font-size: rem(14);
                                        font-weight: 500;
                                        color: $designation;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .assign_task {
        // float: right;
        // padding-bottom: rem(50);
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: rem(50);
        // margin-bottom: rem(-40);
        margin-bottom: rem(8);
        cursor: pointer;

        .circle_area {
            // margin-right: rem(100);
            display: flex;
            align-items: center;
            justify-content: center;
            height: rem(100);
            width: rem(100);
            text-align: center;
            border-radius: 50%;
            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
            box-shadow: 0px 10px 27px rgba(227, 30, 36, 0.59);

            &:hover {
                background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
            }
        }

        .add_icon_area {
            color: $white;
            font-size: rem(18);

            p {
                font-weight: 500;
                font-size: 11px;
                color: $white;
                padding-bottom: 5px;
            }

        }


    }
}



/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {
    .medicine_update_screen {
        .medicine_main_area {
            .accordion_area {
                .accordion {
                    .accordion-item {
                        .accordion-body {
                            .inner_body_area {
                                .top_area {
                                    .top_right_area {
                                        .main_area {
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-end;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .medicine_update_screen {
        .medicine_main_area {
            .accordion_area {
                .accordion {
                    .accordion-item {
                        .accordion-body {
                            .inner_body_area {
                                .top_area {
                                    .top_right_area {
                                        .main_area {
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-end;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(min-width:768px) and (max-width:1023px) {}




/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .medicine_update_screen {
        .medicine_main_area {
            .date_main {
                margin-bottom: rem(15);

                .left_area {
                    width: calc(100% - 22px);

                    p {
                        font-size: rem(15);
                    }
                }

                .right_area {
                    width: rem(22);

                    .img_area {
                        width: rem(22);
                    }
                }
            }

            .accordion_area {
                .accordion {
                    --bs-accordion-btn-icon-width: 12px;

                    .accordion-item {
                        margin-bottom: rem(12);

                        .accordion-header {
                            .accordion-button {
                                padding: rem(10) rem(15);
                                font-size: rem(15);
                            }
                        }

                        .accordion-body {
                            padding: 12px 15px 10px 15px;

                            &::after {
                                width: 91%;
                            }

                            .inner_body_area {
                                .top_area {
                                    width: 100%;
                                    display: block;

                                    .top_left_area {
                                        float: unset;
                                        width: 100%;
                                    }

                                    .top_right_area {
                                        float: unset;
                                        width: 100%;
                                        margin: rem(12) 0;

                                        // a {
                                        //     font-size: rem(13);
                                        // }
                                        .main_area {
                                            .icon_area {
                                                .icon_style {
                                                    font-size: rem(15);
                                                }
                                            }

                                            a {
                                                font-size: rem(13);
                                            }
                                        }
                                    }
                                }

                                .bottom_area {
                                    margin-top: 15px;
                                    padding-top: 9px;
                                    width: 100%;
                                    display: block;

                                    .left_area {
                                        float: unset;
                                        width: 100%;
                                    }

                                    .right_area {
                                        float: right;
                                        width: 100%;
                                        text-align: left;
                                        margin-top: rem(5);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .assign_task {
            right: 0;

            .circle_area {
                height: rem(100);
                width: rem(100);

                .add_icon_area {
                    font-size: rem(16);
                    margin-bottom: rem(3);

                    p {
                        font-size: rem(12);
                    }
                }
            }
        }
    }
}


/* Smaller phones */
@media (max-width: rem(360)) {
    .medicine_update_screen {
        .medicine_main_area {
            .date_main {
                margin-bottom: rem(15);

                .left_area {
                    width: calc(100% - 22px);

                    p {
                        font-size: rem(15);
                    }
                }

                .right_area {
                    width: rem(22);

                    .img_area {
                        width: rem(22);
                    }
                }
            }

            .accordion_area {
                .accordion {
                    .accordion-item {
                        .accordion-header {
                            .accordion-button {
                                padding: rem(10) rem(15);
                                font-size: rem(15);
                            }
                        }

                        .accordion-body {
                            padding: 12px 15px 10px 15px;

                            &::after {
                                width: auto;
                                margin: 0px 15px;
                            }

                            .inner_body_area {
                                .top_area {
                                    width: 100%;
                                    display: block;

                                    .top_left_area {
                                        float: unset;
                                        width: 100%;
                                    }

                                    .top_right_area {
                                        float: unset;
                                        width: 100%;
                                        margin: rem(12) 0;

                                        .main_area {
                                            .icon_area {
                                                .icon_style {
                                                    font-size: rem(15);
                                                }
                                            }

                                            a {
                                                font-size: rem(13);
                                            }
                                        }
                                    }
                                }

                                .bottom_area {
                                    margin-top: 15px;
                                    padding-top: 9px;
                                    width: 100%;
                                    display: block;

                                    .left_area {
                                        float: unset;
                                        width: 100%;
                                    }

                                    .right_area {
                                        float: right;
                                        width: 100%;
                                        text-align: left;
                                        margin-top: rem(5);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .assign_task {
            right: 0;

            .circle_area {
                height: rem(100);
                width: rem(100);

                .add_icon_area {
                    font-size: rem(16);
                    margin-bottom: rem(3);

                    p {
                        font-size: rem(12);
                    }
                }
            }
        }
    }
}