.general_tab_screen {
    margin-top: rem(43);
    padding-bottom: rem(45);
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .general_tab_screen {
        margin-top: rem(40);
        padding-bottom: rem(40);
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .general_tab_screen {
        margin-top: rem(40);
        padding-bottom: rem(40);
    }
}


/* Smaller phones */
@media (max-width: rem(360)) {
    .general_tab_screen {
        margin-top: rem(40);
        padding-bottom: rem(40);
    }
}