.patient_profile_modal {
    &.modal {
        .modal-dialog {
            .modal-content {
                padding: rem(20) rem(20) rem(30) rem(20);
                background: linear-gradient(104.06deg, $white 4.74%, #F0F0F0 99.5%);
                border: 1px solid $grey;
                box-shadow: 0px 4px 23px rgba(55, 55, 128, 0.14);
                border-radius: 25px;

                .modal-header {
                    border-bottom: rem(1) solid $grey;
                    padding: 0;
                    padding-bottom: rem(20);

                    .modal-title {
                        font-size: rem(24);
                        font-weight: 600;
                        color: $black_2;
                    }
                }

                .modal-body {
                    padding: 0;

                    .patient_profile_area {

                        .profile_detail {
                            @include clearfix();
                            margin-top: rem(30);
                            display: flex;
                            align-items: center;

                            .left_area {
                                float: left;
                                width: rem(106);

                                .img_area {
                                    height: rem(106);
                                    width: rem(106);
                                    border-radius: 50%;
                                    overflow: hidden;

                                    img {
                                        height: 100%;
                                        width: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }

                            .right_area {
                                float: right;
                                width: calc(100% - 106px);
                                padding-left: rem(14);

                                h3 {
                                    font-size: rem(36);
                                    font-weight: 600;
                                    background: linear-gradient(135.83deg, #E31E24 8.45%, #7F001F 115.01%);
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    background-clip: text;
                                    text-fill-color: transparent;
                                    margin-bottom: rem(7);
                                }

                                .text_list {
                                    margin-bottom: rem(14);

                                    ul {
                                        display: flex;
                                        align-items: center;

                                        li {
                                            margin-right: rem(15);

                                            &:last-child {
                                                margin-right: 0;
                                            }

                                            .gender_area {
                                                font-size: rem(16);
                                                font-weight: 400;
                                                color: $designation;

                                                .icon {
                                                    font-size: rem(14);
                                                    color: $dark_red_2;
                                                    margin-right: rem(4);
                                                }
                                            }

                                            .label_area {
                                                font-size: rem(16);
                                                font-weight: 400;
                                                color: $form_border_color;
                                                padding: rem(3) rem(10);
                                                background: rgba(55, 55, 128, 0.2);
                                                border-radius: rem(5);
                                                display: inline-block;
                                            }

                                            .general {
                                                font-size: rem(16);
                                                font-weight: 400;
                                                color: $dark_green;

                                                .icon_heart {
                                                    margin-right: rem(4);
                                                }
                                            }
                                        }
                                    }
                                }

                                .contact_area {
                                    @include clearfix();

                                    .left_con {
                                        float: left;
                                        width: calc(100% - 250px);

                                        ul {
                                            display: flex;

                                            li {
                                                font-size: rem(16);
                                                font-weight: 400;
                                                color: $designation;
                                                position: relative;
                                                margin-right: rem(8);

                                                &:last-child {
                                                    margin-right: 0;

                                                    &::before {
                                                        margin-right: 0;
                                                        display: none;
                                                    }
                                                }

                                                &::before {
                                                    content: ",";
                                                    color: $designation;
                                                    position: absolute;
                                                    top: 0;
                                                    bottom: 0;
                                                    right: 0;
                                                    margin-right: rem(-3);
                                                }
                                                &:hover{
                                                    text-decoration: underline;
                                                }
                                            }
                                        }
                                    }

                                    .right_con {
                                        float: right;
                                        width: rem(250);
                                        text-align: end;

                                        .email {
                                            font-size: rem(16);
                                            font-weight: 400;
                                            color: $designation;
                                            word-break: break-word;
                                        }
                                    }
                                }
                            }
                        }

                        .patient_information {
                            background-color: $accordin_body;
                            border: 1px solid $grey;
                            border-radius: 25px;
                            padding: rem(25);
                            margin-top: rem(30);
                            position: relative;
                            overflow: hidden;

                            .circle_area {
                                width: rem(160);
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                margin-bottom: rem(-50);
                                margin-right: rem(-36);

                                img {
                                    height: 100%;
                                    width: 100%;
                                    object-fit: cover;
                                }
                            }

                            .profile_info {
                                @include clearfix();
                                display: flex;
                                align-items: center;
                                margin-bottom: rem(18);

                                &.space_info {
                                    margin-bottom: 0;
                                }

                                .left_info {
                                    float: left;
                                    width: 35%;
                                    position: relative;

                                    .name {
                                        font-size: rem(20);
                                        font-weight: 600;
                                        color: $black_2;
                                    }

                                    &::before {
                                        content: ":";
                                        color: $black_2;
                                        position: absolute;
                                        top: 0;
                                        bottom: 0;
                                        right: 0;
                                        font-size: rem(18);
                                    }
                                }

                                .right_info {
                                    float: right;
                                    width: calc(100% - 35%);
                                    padding-left: rem(5);

                                    .info {
                                        font-size: rem(20);
                                        font-weight: 400;
                                        color: $black_2;
                                    }
                                }
                            }
                        }

                        .btn_area {
                            margin-top: rem(50);
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .patient_profile_modal {
        &.modal {
            .modal-dialog {
                .modal-content {
                    .modal-header {
                        padding-bottom: rem(10);

                        .modal-title {
                            font-size: rem(22);
                        }
                    }

                    .modal-body {
                        .patient_profile_area {
                            .profile_detail {
                                margin-top: rem(20);

                                .left_area {
                                    width: rem(100);

                                    .img_area {
                                        height: rem(100);
                                        width: rem(100);
                                    }
                                }

                                .right_area {
                                    width: calc(100% - 100px);

                                    h3 {
                                        font-size: rem(26);
                                        margin-bottom: rem(4);
                                    }

                                    .text_list {
                                        margin-bottom: rem(10);

                                        ul {
                                            li {
                                                .gender_area {
                                                    font-size: rem(15);

                                                    .icon {
                                                        font-size: rem(13);
                                                    }
                                                }

                                                .label_area {
                                                    font-size: rem(15);
                                                }

                                                .gender {
                                                    font-size: rem(15);
                                                }
                                            }
                                        }
                                    }

                                    .contact_area {
                                        width: 100%;

                                        .left_con {
                                            float: unset;
                                            width: 100%;

                                            ul {
                                                li {
                                                    font-size: rem(15);
                                                }
                                            }
                                        }

                                        .right_con {
                                            float: unset;
                                            width: 100%;
                                            text-align: start;
                                            margin-top: rem(4);

                                            .email {
                                                font-size: rem(15);
                                            }
                                        }
                                    }
                                }
                            }

                            .patient_information {
                                padding: rem(20);
                                margin-top: rem(20);

                                .profile_info {
                                    margin-bottom: rem(12);

                                    .left_info {
                                        width: 40%;

                                        .name {
                                            font-size: rem(18);
                                        }
                                    }

                                    .right_info {
                                        width: calc(100% - 40%);

                                        .info {
                                            font-size: rem(18);
                                        }
                                    }
                                }
                            }

                            .btn_area {
                                margin-top: rem(25);

                                .btn {
                                    font-size: rem(22);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .patient_profile_modal {
        &.modal {
            .modal-dialog {
                .modal-content {
                    padding: rem(15);

                    .modal-header {
                        padding-bottom: rem(10);

                        .modal-title {
                            font-size: rem(22);
                        }
                    }

                    .modal-body {
                        .patient_profile_area {
                            .profile_detail {
                                margin-top: rem(20);
                                width: 100%;
                                display: block;

                                .left_area {
                                    // width: rem(100);
                                    width: 100%;
                                    float: unset;

                                    .img_area {
                                        height: rem(100);
                                        width: rem(100);
                                        // margin: 0 auto;
                                    }
                                }

                                .right_area {
                                    width: 100%;
                                    float: unset;
                                    padding-left: 0;
                                    // width: calc(100% - 100px);

                                    h3 {
                                        font-size: rem(26);
                                        margin-bottom: rem(4);
                                        margin-top: rem(5);
                                    }

                                    .text_list {
                                        margin-bottom: rem(10);

                                        ul {
                                            li {
                                                .gender_area {
                                                    font-size: rem(15);

                                                    .icon {
                                                        font-size: rem(13);
                                                    }
                                                }

                                                .label_area {
                                                    font-size: rem(15);
                                                }

                                                .gender {
                                                    font-size: rem(15);
                                                }
                                            }
                                        }
                                    }

                                    .contact_area {
                                        width: 100%;

                                        .left_con {
                                            float: unset;
                                            width: 100%;

                                            ul {
                                                li {
                                                    font-size: rem(15);
                                                }
                                            }
                                        }

                                        .right_con {
                                            float: unset;
                                            width: 100%;
                                            text-align: start;
                                            margin-top: rem(4);

                                            .email {
                                                font-size: rem(15);
                                            }
                                        }
                                    }
                                }
                            }

                            .patient_information {
                                padding: rem(15);
                                margin-top: rem(20);
                                border-radius: rem(16);

                                .circle_area {
                                    width: rem(140);
                                }

                                .profile_info {
                                    margin-bottom: rem(12);

                                    .left_info {
                                        width: 40%;

                                        .name {
                                            font-size: rem(16);
                                        }

                                        &::before {
                                            font-size: rem(14);
                                        }
                                    }

                                    .right_info {
                                        width: calc(100% - 40%);

                                        .info {
                                            font-size: rem(16);
                                        }
                                    }
                                }
                            }

                            .btn_area {
                                margin-top: rem(15);

                                .btn {
                                    font-size: rem(18);
                                    height: rem(50);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Smaller phones */
@media (max-width: rem(360)) {
    .patient_profile_modal {
        &.modal {
            .modal-dialog {
                .modal-content {
                    padding: rem(15);

                    .modal-header {
                        padding-bottom: rem(10);

                        .modal-title {
                            font-size: rem(22);
                        }
                    }

                    .modal-body {
                        .patient_profile_area {
                            .profile_detail {
                                margin-top: rem(20);
                                width: 100%;
                                display: block;

                                .left_area {
                                    // width: rem(100);
                                    width: 100%;
                                    float: unset;

                                    .img_area {
                                        height: rem(100);
                                        width: rem(100);
                                        // margin: 0 auto;
                                    }
                                }

                                .right_area {
                                    width: 100%;
                                    float: unset;
                                    padding-left: 0;
                                    // width: calc(100% - 100px);

                                    h3 {
                                        font-size: rem(26);
                                        margin-bottom: rem(4);
                                        margin-top: rem(5);
                                    }

                                    .text_list {
                                        margin-bottom: rem(10);

                                        ul {
                                            li {
                                                .gender_area {
                                                    font-size: rem(15);

                                                    .icon {
                                                        font-size: rem(13);
                                                    }
                                                }

                                                .label_area {
                                                    font-size: rem(15);
                                                }

                                                .gender {
                                                    font-size: rem(15);
                                                }
                                            }
                                        }
                                    }

                                    .contact_area {
                                        width: 100%;

                                        .left_con {
                                            float: unset;
                                            width: 100%;

                                            ul {
                                                li {
                                                    font-size: rem(15);
                                                }
                                            }
                                        }

                                        .right_con {
                                            float: unset;
                                            width: 100%;
                                            text-align: start;
                                            margin-top: rem(4);

                                            .email {
                                                font-size: rem(15);
                                            }
                                        }
                                    }
                                }
                            }

                            .patient_information {
                                padding: rem(15);
                                margin-top: rem(20);
                                border-radius: rem(16);

                                .circle_area {
                                    width: rem(140);
                                }

                                .profile_info {
                                    margin-bottom: rem(12);

                                    .left_info {
                                        width: 40%;

                                        .name {
                                            font-size: rem(16);
                                        }

                                        &::before {
                                            font-size: rem(14);
                                        }
                                    }

                                    .right_info {
                                        width: calc(100% - 40%);

                                        .info {
                                            font-size: rem(16);
                                        }
                                    }
                                }
                            }

                            .btn_area {
                                margin-top: rem(15);

                                .btn {
                                    font-size: rem(18);
                                    height: rem(50);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}