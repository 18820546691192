.edit_patient_screen {
    .edit_patient {
        padding: 0 rem(15);

        .img_section {
            position: relative;
            margin-top: rem(70);
            margin-bottom: rem(70);

            .img_profile {
                height: rem(175);
                width: rem(175);
                border-radius: 50%;
                margin: 0 auto;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .img_upload {
                height: rem(42);
                width: rem(42);
                border-radius: 50%;
                position: absolute;
                bottom: 0;
                right: 0;
                margin-right: rem(298);

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        .dropdown_box {
            position: relative;

            p {
                &.text-danger {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    margin-bottom: rem(-20);
                }
            }

            h6 {
                font-size: rem(22);
                font-weight: 600;
                color: $form_label_color;

                span {
                    color: $critical_color;
                }
            }

            .dropdown_area {
                margin-top: rem(12);
                // margin-right: rem(45);
                background: transparent;
                border: 1px solid transparent;
                border-radius: rem(10);
                color: $white;
                width: 100%;
                // width: rem(342);
                margin-bottom: rem(30);
            }
        }

        .btn_area {
            margin-bottom: rem(30);
        }
    }
}


/* Above 1920 desktop */
@media (min-width: 1920px) {}

/* 1680 desktop */
@media (min-width: 1680px) and (max-width: 1919px) {
   
}

/* 1600 desktop */
@media (min-width: 1600px) and (max-width: 1679px) {
  
}

/* Above 1400 and Below 1599 Desktop*/
@media (min-width: 1400px) and (max-width: 1599px) {
  
}

/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {

}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {

}

/* Landscape tablets and medium desktops */
@media (min-width: 1024px) and (max-width: 1199px) {
 
}

/* Landscape tablets and ipad */
@media (min-width: 768px) and (max-width: 1023px) {
    .edit_patient_screen {
        .edit_patient {
            padding: 0 rem(15);
    
            .img_section {
                position: relative;
                margin-top: rem(0);
                margin-bottom: rem(70);
    
                .img_profile {
                    height: rem(175);
                    width: rem(175);
                    border-radius: 50%;
                    margin: 0 auto;
    
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
    
                .img_upload {
                    height: rem(42);
                    width: rem(42);
                    border-radius: 50%;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    margin-right: rem(298);
    
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
    
            .dropdown_box {
                position: relative;
    
                p {
                    &.text-danger {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        margin-bottom: rem(-20);
                    }
                }
    
                h6 {
                    font-size: rem(22);
                    font-weight: 600;
                    color: $form_label_color;
    
                    span {
                        color: $critical_color;
                    }
                }
    
                .dropdown_area {
                    margin-top: rem(12);
                    // margin-right: rem(45);
                    background: transparent;
                    border: 1px solid transparent;
                    border-radius: rem(10);
                    color: $white;
                    width: 100%;
                    // width: rem(342);
                    margin-bottom: rem(30);
                }
            }
    
            .btn_area {
                margin-bottom: rem(30);
            }
        }
    }
}



/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .edit_patient_screen {
        .edit_patient {
            .img_section {
                margin-top: 0;
                margin-bottom: rem(40);

                .img_profile {
                    height: rem(140);
                    width: rem(140);
                }

                .img_upload {
                    height: rem(35);
                    width: rem(35);
                    margin-right: rem(250);
                }
            }

            .btn_area {
                margin-bottom: rem(20);
            }
        }
    }

    .css-b62m3t-container {
        .css-13cymwt-control {
            height: auto;
            min-height: rem(53);
            font-size: rem(20);
        }

        .css-t3ipsp-control {
            height: auto;
            min-height: rem(53);
            font-size: rem(20);
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .edit_patient_screen {
        .edit_patient {
            padding: 0;

            .img_section {
                margin-top: 0;
                margin-bottom: rem(30);

                .img_profile {
                    height: rem(120);
                    width: rem(120);
                }

                .img_upload {
                    height: rem(30);
                    width: rem(30);
                    margin-right: rem(128);
                }
            }

            .btn_area {
                margin-bottom: rem(20);
            }

            .form-group {
                margin-bottom: rem(25);
            }

            .dropdown_box {
                h6 {
                    font-size: rem(18);
                }

                .dropdown_area {
                    margin-bottom: rem(25);
                }
            }
        }
    }

    .css-b62m3t-container {
        .css-13cymwt-control {
            height: auto;
            min-height: rem(53);
            font-size: rem(20);
        }

        .css-t3ipsp-control {
            height: auto;
            min-height: rem(53);
            font-size: rem(20);
        }
    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .edit_patient_screen {
        .edit_patient {
            padding: 0;

            .img_section {
                margin-top: 0;
                margin-bottom: rem(30);

                .img_profile {
                    height: rem(120);
                    width: rem(120);
                }

                .img_upload {
                    height: rem(30);
                    width: rem(30);
                    margin-right: rem(128);
                }
            }

            .btn_area {
                margin-bottom: rem(20);
            }

            .form-group {
                margin-bottom: rem(25);
            }

            .dropdown_box {
                h6 {
                    font-size: rem(18);
                }

                .dropdown_area {
                    margin-bottom: rem(25);
                }
            }
        }
    }

    .css-b62m3t-container {
        .css-13cymwt-control {
            height: rem(53);
            font-size: rem(20);
        }

        .css-t3ipsp-control {
            height: rem(53);
            font-size: rem(20);
        }
    }
}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}