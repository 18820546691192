.select_assign_task {
    &.modal {
        .modal-dialog {
            .modal-content {
                padding: rem(20) rem(20) rem(75) rem(20);
                background: linear-gradient(104.06deg, $white 4.74%, #F0F0F0 99.5%);
                border: 1px solid $grey;
                box-shadow: 0px 4px 23px rgba(55, 55, 128, 0.14);
                border-radius: 25px;

                .modal-header {
                    border-bottom: rem(0) solid transparent;
                    padding: 0;

                    .modal-title {}
                }

                .modal-body {
                    padding: 0;

                    .select_assign_area {
                        .top_area {
                            @include clearfix();
                            margin-bottom: rem(12);
                            margin-top: rem(25);

                            .top_left {
                                float: left;

                                p {
                                    font-weight: 600;
                                    font-size: rem(22);
                                    color: $form_label_color;

                                    span {
                                        color: $red_gradient_color;
                                    }
                                }
                            }

                            .top_right {
                                float: right;

                                p {
                                    font-weight: 400;
                                    font-size: rem(22);
                                    color: $designation;
                                }
                            }
                        }

                        .form-group {
                            position: relative;
                            margin-bottom: rem(22);

                            .form-control {
                                background: linear-gradient(104.06deg, $white 4.74%, #F0F0F0 99.5%);
                                box-shadow: 0px 4px 23px rgba(55, 55, 128, 0.14);
                            }

                            .search_icon {
                                font-size: rem(28);
                                color: $designation;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                right: 0;
                                margin-right: rem(15);
                                margin-top: rem(13);
                            }
                        }

                        .profile_area {
                            display: flex;
                            margin-bottom: rem(30);
                            // margin-left: rem(-10);
                            // margin-right: rem(-10);
                            overflow-x: auto;

                            &::-webkit-scrollbar-track {
                                box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                                -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                                background-color: $grey;
                            }

                            &::-webkit-scrollbar {
                                width: 2px;
                                height: 6px;
                                background-color: $dark_red_2;
                                margin: 0 rem(20);
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: $dark_red_2;
                            }

                            .inner_profile_area {
                                // width: rem(109);
                                padding: 0 10px;
                                text-align: center;

                                .img_area {
                                    height: rem(40);
                                    width: rem(40);
                                    border-radius: 50%;
                                    margin: 0 auto;


                                    img {
                                        height: 100%;
                                        width: 100%;
                                        object-fit: cover;
                                    }
                                }

                                h6 {
                                    font-weight: 500;
                                    font-size: rem(14);
                                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    background-clip: text;
                                    padding-top: rem(7);
                                    width: rem(109);
                                    margin: 0 auto;
                                    cursor: pointer;
                                }

                                p {
                                    font-weight: 500;
                                    font-size: rem(12);
                                    color: $form_label_color;
                                    margin-bottom: rem(12);
                                    background-color: $off_white;
                                    padding: rem(2) rem(12);
                                    display: inline-block;
                                    border-radius: rem(5);
                                    margin-top: rem(5);
                                }
                            }
                        }

                        .scroller_area {
                            max-height: rem(405);
                            overflow-y: auto;
                            overflow-x: hidden;
                            margin: 0 rem(-20);

                            &::-webkit-scrollbar-track {
                                box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                                -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                                background-color: $grey;
                            }

                            &::-webkit-scrollbar {
                                width: 6px;
                                height: 4px;
                                background-color: $dark_red_2;
                                margin: 0 rem(20);
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: $dark_red_2;
                            }

                            .info_check_area {
                                @include clearfix();
                                display: flex;
                                align-items: center;
                                // margin-left: rem(-20);
                                // margin-right: rem(-20);
                                // padding-left: rem(20);
                                padding: rem(18) rem(25) rem(12) rem(25);
                                border-top: 1px solid $grey;
                                // border-bottom: 1px solid $grey;
                                border-radius: 0 rem(0) 0 0;
                                background-color: transparent;

                                &.active {
                                    background-color: $light_red;
                                    border-top: 1px solid $grey;
                                    // border-bottom: 1px solid $grey;
                                    border-radius: 0 rem(20) 0 0;
                                }

                                &:hover {
                                    background-color: $light_red;
                                    border-top: 1px solid $grey;
                                    // border-bottom: 1px solid $grey;
                                    border-radius: 0 rem(20) 0 0;
                                }

                                .left_area {
                                    float: left;
                                    width: rem(70);

                                    .img_main {
                                        height: rem(70);
                                        width: rem(70);
                                        border-radius: 50%;

                                        img {
                                            height: 100%;
                                            width: 100%;
                                            object-fit: cover;
                                        }
                                    }
                                }

                                .right_area {
                                    float: right;
                                    width: calc(100% - 70px);
                                    padding-left: 10px;

                                    .content_area {
                                        @include clearfix();
                                        display: flex;
                                        align-items: center;

                                        .left_text {
                                            float: left;
                                            width: calc(100% - 40px);

                                            h6 {
                                                font-size: rem(24);
                                                font-weight: 600;
                                                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                                -webkit-background-clip: text;
                                                -webkit-text-fill-color: transparent;
                                                background-clip: text;
                                                margin-bottom: 5px;
                                                cursor: pointer;
                                            }

                                            p {
                                                font-size: rem(16);
                                                font-weight: 400;
                                                color: $form_label_color;
                                                padding: rem(2) rem(12);
                                                display: inline-block;
                                                border-radius: rem(5);
                                                background-color: $off_white;
                                            }
                                        }

                                        .right_check {
                                            float: right;
                                            width: rem(40);
                                            text-align: end;

                                            .form-check-input {
                                                width: rem(20);
                                                height: rem(20);
                                                border: 1px solid $red_gradient_color;
                                                background-color: $white;

                                                &:checked {
                                                    border-color: $red_gradient_color;
                                                    background-image: url('../../../images/vector.png');
                                                    background-size: rem(20) rem(20);
                                                }

                                                &:focus {
                                                    box-shadow: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .select_assign_task {
        &.modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .select_assign_area {
                            .top_area {
                                margin-bottom: rem(8);

                                .top_left {
                                    p {
                                        font-size: rem(18);
                                    }
                                }

                                .top_right {
                                    p {
                                        font-size: rem(18);
                                    }
                                }
                            }

                            .form-group {
                                .search_icon {
                                    font-size: rem(20);
                                }
                            }

                            .profile_area {
                                margin-bottom: rem(20);

                                .inner_profile_area {
                                    h6 {
                                        width: rem(82);
                                    }
                                }
                            }

                            .scroller_area {
                                max-height: rem(341);

                                .info_check_area {
                                    padding: rem(12) rem(20) rem(12) rem(20);

                                    .left_area {
                                        width: rem(60);

                                        .img_main {
                                            height: rem(60);
                                            width: rem(60);
                                        }
                                    }

                                    .right_area {
                                        width: calc(100% - 60px);

                                        .content_area {
                                            .left_text {
                                                h6 {
                                                    font-size: rem(20);
                                                }

                                                p {
                                                    font-size: rem(14);
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .btn_area {
                                .btn {
                                    font-size: rem(20);
                                    height: rem(50);
                                    margin-top: rem(20);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .select_assign_task {
        &.modal {
            .modal-dialog {
                margin: 0 auto;

                .modal-content {
                    padding: rem(20) rem(20) rem(30) rem(20);

                    .modal-body {
                        .select_assign_area {
                            .top_area {
                                margin-bottom: rem(8);

                                .top_left {
                                    p {
                                        font-size: rem(18);
                                    }
                                }

                                .top_right {
                                    p {
                                        font-size: rem(18);
                                    }
                                }
                            }

                            .form-group {
                                .search_icon {
                                    font-size: rem(20);
                                }
                            }

                            .profile_area {
                                margin-bottom: rem(20);

                                .inner_profile_area {

                                    // width: 33.33%;
                                    h6 {
                                        width: rem(81);
                                    }
                                }
                            }

                            .scroller_area {
                                max-height: rem(321);

                                .info_check_area {
                                    padding: rem(12) rem(20) rem(12) rem(20);

                                    .left_area {
                                        width: rem(55);

                                        .img_main {
                                            height: rem(55);
                                            width: rem(55);
                                        }
                                    }

                                    .right_area {
                                        width: calc(100% - 55px);

                                        .content_area {
                                            .left_text {
                                                h6 {
                                                    font-size: rem(18);
                                                }

                                                p {
                                                    font-size: rem(14);
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .btn_area {
                                .btn {
                                    font-size: rem(20);
                                    height: rem(50);
                                    margin-top: rem(20);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Smaller phones */
@media (max-width: rem(360)) {
    .select_assign_task {
        &.modal {
            .modal-dialog {
                margin: 0 auto;

                .modal-content {
                    padding: rem(20) rem(20) rem(30) rem(20);

                    .modal-body {
                        .select_assign_area {
                            .top_area {
                                margin-bottom: rem(8);

                                .top_left {
                                    p {
                                        font-size: rem(18);
                                    }
                                }

                                .top_right {
                                    p {
                                        font-size: rem(18);
                                    }
                                }
                            }

                            .form-group {
                                .search_icon {
                                    font-size: rem(20);
                                }
                            }

                            .profile_area {
                                margin-bottom: rem(20);

                                .inner_profile_area {

                                    // width: 33.33%;
                                    h6 {
                                        width: rem(81);
                                    }
                                }
                            }

                            .scroller_area {
                                max-height: rem(321);

                                .info_check_area {
                                    padding: rem(12) rem(20) rem(12) rem(20);

                                    .left_area {
                                        width: rem(55);

                                        .img_main {
                                            height: rem(55);
                                            width: rem(55);
                                        }
                                    }

                                    .right_area {
                                        width: calc(100% - 55px);

                                        .content_area {
                                            .left_text {
                                                h6 {
                                                    font-size: rem(18);
                                                }

                                                p {
                                                    font-size: rem(14);
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .btn_area {
                                .btn {
                                    font-size: rem(20);
                                    height: rem(50);
                                    margin-top: rem(20);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}