.myprofile_main_area {
    .profile_inner_area {
        margin-top: rem(-60);

        .content_area {
            .img_area {
                height: rem(180);
                width: rem(180);
                border-radius: 50%;
                overflow: hidden;
                margin: 0 auto;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            h5 {
                font-size: rem(24);
                font-weight: 600;
                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                text-align: center;
                margin-top: rem(10);
                margin-bottom: rem(10);
            }

            .label {
                text-align: center;

                p {
                    font-size: rem(20);
                    font-weight: 500;
                    color: $blue;
                    padding: rem(3) rem(8);
                    border-radius: rem(6);
                    background-color: $light_blue;
                    display: inline-block;
                }
            }
        }

        .information_con_area {
            margin-top: rem(45);
            background-color: $accordin_body;
            border: 1px solid $grey;
            box-shadow: 0px 4px 25px rgba(55, 55, 128, 0.12);
            border-radius: 25px;
            padding: rem(50) rem(50) rem(30) rem(50);
            position: relative;

            .info_area {
                @include clearfix();
                display: flex;
                align-items: start;
                margin-bottom: rem(30);

                .left_area {
                    float: left;
                    width: rem(230);

                    .inner_area {
                        @include clearfix();
                        display: flex;
                        align-items: center;

                        .left {
                            float: left;
                            width: rem(22);

                            .icon_area {
                                width: rem(22);

                                img {
                                    height: 100%;
                                    width: 100%;
                                    object-fit: cover;
                                }
                            }
                        }

                        .right {
                            float: right;
                            width: calc(100% - 22px);
                            padding-left: rem(15);

                            p {
                                font-size: rem(22);
                                font-weight: 600;
                                color: $profile_info;
                            }
                        }
                    }
                }

                .right_area {
                    float: right;
                    width: calc(100% - 230px);

                    .txt {
                        display: flex;
                        align-items: start;

                        span {
                            font-size: rem(20);
                            color: $black_3;
                            margin-right: rem(8);
                        }

                        p {
                            font-size: rem(22);
                            font-weight: 600;
                            color: $black_3;
                            word-break: break-word;
                        }
                    }
                }
            }

            .edit_btn {
                text-align: center;
                margin-top: rem(50);
                margin-bottom: rem(80);

                a {
                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                    border-radius: rem(40);
                    padding: rem(15) rem(40);
                    width: rem(230);
                    font-size: rem(24);
                    font-weight: 600;
                    color: $white;

                    .icon_edit {
                        font-size: rem(16);
                        color: $white;
                        margin-left: 10px;
                    }
                }
            }

            .circle_box {
                width: rem(140);
                position: absolute;
                bottom: 0;
                right: 0;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}




/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .myprofile_main_area {
        .profile_inner_area {
            .content_area {
                .img_area {
                    height: rem(130);
                    width: rem(130);
                }

                h5 {
                    font-size: rem(20);
                    margin-top: rem(6);
                    margin-bottom: rem(6);
                }

                .label {
                    p {
                        font-size: rem(16);
                    }
                }
            }

            .information_con_area {
                margin-top: rem(30);
                padding: rem(15);
                border-radius: rem(15);

                .info_area {
                    margin-bottom: rem(20);

                    .left_area {
                        width: rem(150);

                        .inner_area {
                            .left {
                                width: rem(16);

                                .icon_area {
                                    width: rem(16);
                                }
                            }

                            .right {
                                width: calc(100% - 16px);
                                padding-left: rem(10);

                                p {
                                    font-size: rem(16);
                                }
                            }
                        }
                    }

                    .right_area {
                        width: calc(100% - 150px);

                        .txt {

                            span {
                                font-size: rem(16);
                                margin-right: rem(5);
                                position: relative;
                                top: rem(-1);
                            }

                            p {
                                font-size: rem(16);
                            }
                        }
                    }
                }

                .edit_btn {
                    margin-top: rem(40);

                    a {
                        padding: rem(12) rem(30);
                        font-size: rem(18);

                        .icon_edit {
                            font-size: rem(15);
                            margin-left: rem(5);
                        }
                    }
                }

                .circle_box {
                    width: rem(95);
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .myprofile_main_area {
        .profile_inner_area {
            .content_area {
                .img_area {
                    height: rem(130);
                    width: rem(130);
                }

                h5 {
                    font-size: rem(20);
                    margin-top: rem(6);
                    margin-bottom: rem(6);
                }

                .label {
                    p {
                        font-size: rem(16);
                    }
                }
            }

            .information_con_area {
                margin-top: rem(30);
                padding: rem(15);
                border-radius: rem(15);

                .info_area {
                    margin-bottom: rem(20);

                    .left_area {
                        width: rem(150);

                        .inner_area {
                            .left {
                                width: rem(16);

                                .icon_area {
                                    width: rem(16);
                                }
                            }

                            .right {
                                width: calc(100% - 16px);
                                padding-left: rem(10);

                                p {
                                    font-size: rem(16);
                                }
                            }
                        }
                    }

                    .right_area {
                        width: calc(100% - 150px);

                        .txt {

                            span {
                                font-size: rem(16);
                                margin-right: rem(5);
                                position: relative;
                                top: rem(-1);
                            }

                            p {
                                font-size: rem(16);
                            }
                        }
                    }
                }

                .edit_btn {
                    margin-top: rem(40);

                    a {
                        padding: rem(12) rem(30);
                        font-size: rem(18);

                        .icon_edit {
                            font-size: rem(15);
                            margin-left: rem(5);
                        }
                    }
                }

                .circle_box {
                    width: rem(95);
                }
            }
        }
    }
}