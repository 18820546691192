.assign_medicine_section {
    .assign_medicine_area {
        .medicine_main_area {
            margin: rem(60) rem(250);

            .form {
                border-bottom: 1px solid $border_line2;

                .form-group {
                    label {
                        span {
                            color: $critical_color;
                        }
                    }
                }

                .tag_area {
                    h6 {
                        font-size: rem(22);
                        font-weight: 600;
                        color: $form_label_color;

                        span {
                            color: $critical_color;
                        }
                    }
                }
            }

            .form_area {
                margin-top: rem(20);
                border-bottom: 1px solid $border_line2;

                .icon_cross {
                    font-size: rem(25);
                    color: $grey_3;
                    text-align: end;

                    a {
                        color: $grey_3;
                    }
                }

                .tag_area {
                    h6 {
                        font-size: rem(22);
                        font-weight: 600;
                        color: $form_label_color;

                        span {
                            color: $critical_color;
                        }
                    }

                    .react-tagsinput {
                        margin-top: rem(12);
                        margin-bottom: rem(30);

                        .react-tagsinput-input {
                            border: 1px solid $form_border_color;
                            min-height: 60px;
                            height: auto;
                            width: 100%;
                            border-radius: rem(10);
                            font-size: rem(22);
                            font-weight: 400;
                            padding: 10px;
                            padding-left: rem(20);
                            color: $designation;
                        }
                    }
                }
            }

            .more_btn_area {
                margin-top: rem(35);

                .btn-primary {
                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                    border-radius: rem(40);
                    border: none;
                    height: rem(68);
                    width: rem(200);
                    font-size: rem(24);
                    font-weight: 600;
                    color: $white;
                    line-height: rem(54);

                    .plus_icon {
                        font-size: rem(20);
                        color: $white;
                        margin-right: rem(5);
                    }

                    &:hover {
                        background: linear-gradient(135.83deg, #E31E24 15%, #800020 125%);
                        opacity: unset;
                    }
                }
            }

            .btn_area {
                margin-top: rem(30);
                margin-bottom: rem(20);
            }
        }
    }
}




/* Above 1920 desktop */
@media (min-width: 1920px) {}

/* 1680 desktop */
@media (min-width: 1680px) and (max-width: 1919px) {
    .assign_medicine_section {
        .assign_medicine_area {
            .medicine_main_area {
                margin: rem(50) rem(230);
            }
        }
    }
}

/* 1600 desktop */
@media (min-width: 1600px) and (max-width: 1679px) {
    .assign_medicine_section {
        .assign_medicine_area {
            .medicine_main_area {
                margin: rem(50) rem(230);
            }
        }
    }
}

/* Above 1400 and Below 1599 Desktop*/
@media (min-width: 1400px) and (max-width: 1599px) {
    .assign_medicine_section {
        .assign_medicine_area {
            .medicine_main_area {
                margin: rem(50) rem(230);
            }
        }
    }
}

/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {
    .assign_medicine_section {
        .assign_medicine_area {
            .medicine_main_area {
                margin: rem(30) rem(170);
            }
        }
    }
}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {
    .assign_medicine_section {
        .assign_medicine_area {
            .medicine_main_area {
                margin: rem(30) rem(150);
            }
        }
    }
}

/* Landscape tablets and medium desktops */
@media (min-width: 1024px) and (max-width: 1199px) {
    .assign_medicine_section {
        .assign_medicine_area {
            .medicine_main_area {
                margin: rem(30) rem(150);
            }
        }
    }
}

/* Landscape tablets and ipad */
@media (min-width: 768px) and (max-width: 1023px) {
    .assign_medicine_section {
        .assign_medicine_area {
            .medicine_main_area {
                margin: 0 rem(15);

                .form {
                    border-bottom: 1px solid $border_line2;

                    .form-group {
                        label {
                            span {
                                color: $critical_color;
                            }
                        }
                    }

                    .tag_area {
                        h6 {
                            font-size: rem(22);
                            font-weight: 600;
                            color: $form_label_color;

                            span {
                                color: $critical_color;
                            }
                        }

                        // .react-tagsinput {
                        //     margin-top: rem(12);
                        //     margin-bottom: rem(30);
                        //     border: 1px solid $form_border_color;
                        //     min-height: 60px;
                        //     height: auto;
                        //     width: 100%;
                        //     border-radius: rem(10);
                        //     // font-size: rem(22);
                        //     // font-weight: 400;
                        //     padding: 10px;
                        //     padding-left: rem(20);

                        //     // color: $designation;
                        //     .react-tagsinput-input {
                        //         font-size: rem(22);
                        //         font-weight: 400;
                        //         color: $designation;
                        //         width: 100%;
                        //         margin-bottom: 0;
                        //     }

                        //     .react-tagsinput-tag {
                        //         font-size: rem(22);
                        //         font-weight: 400;
                        //         color: $designation;
                        //         padding: rem(9) rem(20);
                        //         border-radius: rem(24);
                        //         background-color: $grey;
                        //         border: none;
                        //         // position: relative;

                        //         a {
                        //             &::before {
                        //                 content: "✖";
                        //                 background-color: $designation;
                        //                 color: $white;
                        //                 height: rem(14) !important;
                        //                 width: rem(14) !important;
                        //                 border-radius: 50%;
                        //                 font-size: rem(9);
                        //                 padding: rem(4);
                        //                 text-align: center;
                        //                 margin-left: rem(15);
                        //                 position: relative;
                        //                 top: -4px;
                        //             }
                        //         }
                        //     }
                        // }
                    }
                }

                .form_area {
                    margin-top: rem(20);
                    border-bottom: 1px solid $border_line2;

                    .icon_cross {
                        font-size: rem(25);
                        color: $grey_3;
                        text-align: end;

                        a {
                            color: $grey_3;
                        }
                    }

                    .tag_area {
                        h6 {
                            font-size: rem(22);
                            font-weight: 600;
                            color: $form_label_color;

                            span {
                                color: $critical_color;
                            }
                        }

                        .react-tagsinput {
                            margin-top: rem(12);
                            margin-bottom: rem(30);

                            .react-tagsinput-input {
                                border: 1px solid $form_border_color;
                                min-height: 60px;
                                height: auto;
                                width: 100%;
                                border-radius: rem(10);
                                font-size: rem(22);
                                font-weight: 400;
                                padding: 10px;
                                padding-left: rem(20);
                                color: $designation;
                            }
                        }
                    }
                }

                .more_btn_area {
                    margin-top: rem(35);

                    .btn-primary {
                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                        border-radius: rem(40);
                        border: none;
                        height: rem(68);
                        width: rem(200);
                        font-size: rem(24);
                        font-weight: 600;
                        color: $white;
                        line-height: rem(54);

                        .plus_icon {
                            font-size: rem(20);
                            color: $white;
                            margin-right: rem(5);
                        }

                        &:hover {
                            background: linear-gradient(135.83deg, #E31E24 15%, #800020 125%);
                            opacity: unset;
                        }
                    }
                }

                .btn_area {
                    margin-top: rem(30);
                    margin-bottom: rem(20);
                }
            }
        }
    }
}



/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .assign_medicine_section {
        .assign_medicine_area {
            .medicine_main_area {
                margin: 0 rem(15);

                .form {
                    .tag_area {
                        .react-tagsinput {
                            .react-tagsinput-input {
                                min-height: rem(53);
                            }
                        }
                    }
                }

                .form_area {
                    .tag_area {
                        .react-tagsinput {
                            .react-tagsinput-input {
                                min-height: rem(53);
                            }
                        }
                    }
                }

                .more_btn_area {
                    margin-top: rem(20);

                    .btn-primary {
                        height: rem(60);
                        font-size: rem(22);
                        line-height: rem(46);
                    }
                }

                .btn_area {
                    margin-top: rem(20);

                    .btn {
                        font-size: rem(22);
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .assign_medicine_section {
        .assign_medicine_area {
            .medicine_main_area {
                margin: 0;

                .form {
                    .form-group {
                        margin-bottom: rem(20);
                    }

                    .tag_area {
                        h6 {
                            font-size: rem(18);
                        }

                        .react-tagsinput {
                            min-height: rem(53);
                            margin-bottom: rem(20);

                            .react-tagsinput-input {
                                font-size: rem(20);
                            }

                            .react-tagsinput-tag {
                                font-size: 18px;
                                padding: 7px 15px;

                                a {
                                    &::before {
                                        font-size: 8px;
                                        padding: 4px;
                                        margin-left: 10px;
                                    }
                                }
                            }
                        }
                    }
                }

                .form_area {
                    margin-top: rem(10);

                    .form-group {
                        margin-bottom: rem(20);
                    }

                    .tag_area {
                        h6 {
                            font-size: rem(18);
                        }

                        // .react-tagsinput {
                        //     min-height: rem(53);
                        //     margin-bottom: rem(20);

                        //     .react-tagsinput-input {
                        //         font-size: rem(20);
                        //     }

                        //     .react-tagsinput-tag {
                        //         font-size: 18px;
                        //         padding: 7px 15px;

                        //         a {
                        //             &::before {
                        //                 font-size: 8px;
                        //                 padding: 4px;
                        //                 margin-left: 10px;
                        //             }
                        //         }
                        //     }
                        // }
                    }

                    .icon_cross {
                        font-size: rem(20);
                    }
                }

                .more_btn_area {
                    margin-top: rem(20);

                    .btn-primary {
                        height: rem(55);
                        font-size: rem(20);
                        line-height: rem(42);
                        width: rem(160);

                        .plus_icon {
                            font-size: rem(16);
                            position: relative;
                            top: rem(-1);
                        }
                    }
                }

                .btn_area {
                    margin-top: rem(10);

                    .btn {
                        font-size: rem(20);
                        height: rem(52);
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .assign_medicine_section {
        .assign_medicine_area {
            .medicine_main_area {
                margin: 0;

                .form {
                    .form-group {
                        margin-bottom: rem(20);
                    }

                    .tag_area {
                        h6 {
                            font-size: rem(18);
                        }

                        // .react-tagsinput {
                        //     min-height: rem(53);
                        //     margin-bottom: rem(20);

                        //     .react-tagsinput-input {
                        //         font-size: rem(20);
                        //     }

                        //     .react-tagsinput-tag {
                        //         font-size: 18px;
                        //         padding: 7px 15px;

                        //         a {
                        //             &::before {
                        //                 font-size: 8px;
                        //                 padding: 4px;
                        //                 margin-left: 10px;
                        //             }
                        //         }
                        //     }
                        // }
                    }
                }

                .form_area {
                    margin-top: rem(10);

                    .form-group {
                        margin-bottom: rem(20);
                    }

                    .tag_area {
                        h6 {
                            font-size: rem(18);
                        }

                        .react-tagsinput {
                            .react-tagsinput-input {
                                min-height: rem(53);
                                font-size: rem(20);
                            }
                        }
                    }

                    .icon_cross {
                        font-size: rem(20);
                    }
                }

                .more_btn_area {
                    margin-top: rem(20);

                    .btn-primary {
                        height: rem(55);
                        font-size: rem(20);
                        line-height: rem(42);
                        width: rem(160);

                        .plus_icon {
                            font-size: rem(16);
                            position: relative;
                            top: rem(-1);
                        }
                    }
                }

                .btn_area {
                    margin-top: rem(10);

                    .btn {
                        font-size: rem(20);
                        height: rem(52);
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}