.hospital_list_area {

    .hospital_area {
        padding: 0 rem(30);
        background-color: $white;

        .form_area {
            margin-top: -15px;

            .form-group {
                position: relative;

                .form-control {}

                .icon_search {
                    position: absolute;
                    font-size: rem(22);
                    color: $designation;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin-right: rem(15);
                    margin-top: rem(15);
                }
            }
        }

        .no_data_area {
            padding-bottom: rem(20);

            .img_area {
                width: rem(692);
                margin-bottom: rem(10);

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            p {
                font-size: rem(22);
                font-weight: 600;
                color: $black_2;
                text-align: center;
            }
        }
    }
}





/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .hospital_list_area {
        .hospital_area {


            .no_data_area {
                .img_area {
                    width: rem(607);
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .without_image_header .header_area {
        height: 156px;
    }

    .hospital_list_area {
        .hospital_area {
            padding: 0 rem(20);

            .form_area {
                .form-group {
                    .icon_search {
                        font-size: rem(20);
                        margin-top: rem(13);
                    }
                }
            }

            .no_data_area {
                .img_area {
                    width: rem(335);
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .without_image_header .header_area {
        height: 156px;
    }

    .hospital_list_area {
        .hospital_area {
            padding: 0 rem(20);

            .form_area {
                .form-group {
                    .icon_search {
                        font-size: rem(20);
                        margin-top: rem(13);
                    }
                }
            }

            .no_data_area {
                .img_area {
                    width: rem(335);
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}