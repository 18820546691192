.main_dashboard_screen {       
    .dashboard_area {
        .inner_dashboard_area{
            .ward_view_page{                
                .inner_ward{
                    .top_head{                        
                        a{
                            float: right;
                            top: 0;
                            margin-top: rem(-30);   
                        }
                    }  
                    .ward_listing{
                        .list_view{
                            display: flow-root;
                           .left_text{
                                float: left;                                
                           }
                           .right_text{
                            float: right;  
                            }
                        }
                    }                  
                }
            }
        }
    }
}










/* Above 1920 desktop */
@media (min-width: 1920px){
    
}

/* 1680 desktop */
@media (min-width: 1680px) and (max-width: 1919px){
    
}

/* 1600 desktop */
@media (min-width: 1600px) and (max-width: 1679px){
    
}

/* Above 1400 and Below 1599 Desktop*/
@media (min-width: 1400px) and (max-width: 1599px){
    
}

/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px){
    
}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px){
    
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
	
}

/* Landscape tablets and ipad */
@media (min-width: 768px) and (max-width: 991px) {
	
}

/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
	
}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .main_dashboard_screen {       
        .dashboard_area {
            .inner_dashboard_area{
                .ward_view_page{                
                    .inner_ward{
                        .top_head{                        
                            a{
                                float: right;
                                top: 0;
                                margin-top: rem(-25);   
                            }
                        }  
                    }
                }
            }
        }
    }
	
}

/* Smaller phones */
@media (max-width: 360px) {
    .main_dashboard_screen {       
        .dashboard_area {
            .inner_dashboard_area{
                .ward_view_page{                
                    .inner_ward{
                        .top_head{                        
                            a{
                                float: right;
                                top: 0;
                                margin-top: rem(-25);     
                            }
                        }  
                    }
                }
            }
        }
    }  
}

/* Smaller phones */
@media (max-width: 320px) {
    
}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {
    
}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {
    
}