.medicine_tab_screen {
    padding: rem(20) 0 0 0;

    .card {
        background: $card_color;
        border: rem(1) solid $grey;
        border-radius: rem(25);
        margin-bottom: rem(40);

        .card-body {
            padding: rem(34) rem(20) rem(34) rem(60);

            .table {
                margin-bottom: rem(40);
                overflow: auto;
                max-height: rem(600);

                td {
                    word-break: break-word;
                    white-space: nowrap;
                    border: none;
                    padding-right: rem(20);

                    &.medicine_title {
                        font-size: rem(16);
                        font-weight: 600;
                        color: $black_2;
                        width: rem(185);
                    }

                    .list-inline {
                        display: flex;
                        gap: rem(11);

                        li {
                            .check_icon {
                                color: $success_color;
                                font-size: rem(20);
                            }

                            .circle_icon_one {
                                color: $red_gradient_color;
                                font-size: rem(20);
                            }

                            .circle_icon_two {
                                color: #D9D9D9;
                                font-size: rem(20);
                            }
                        }
                    }

                    &.nurse_name {
                        font-size: rem(16);
                        font-weight: 400;
                        color: $cancel_btn_color;
                    }

                    &.timing {
                        font-size: rem(16);
                        font-weight: 400;
                        color: $cancel_btn_color;

                        .tooltip_icon {
                            color: #E31E24;
                            cursor: pointer;
                        }
                    }
                }
            }

            .bottom_area {
                @include clearfix();
                margin-left: rem(-4);
                margin-right: rem(20);

                .left_area {
                    width: 50%;
                    float: left;

                    p {
                        font-weight: 400;
                        font-size: rem(16);
                        color: $cancel_btn_color;
                    }
                }

                .right_area {
                    width: calc(100% - 50%);
                    float: right;

                    p {
                        font-weight: 400;
                        font-size: rem(16);
                        text-align: right;
                        color: $cancel_btn_color;

                        a {
                            &.prescribed {
                                color: #D81B23;
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .medicine_tab_screen {
        .card {
            .card-body {
                padding: rem(20);

                .table {
                    margin-bottom: rem(20);

                    td {
                        &.medicine_title {
                            width: auto;
                            font-size: rem(14);
                        }

                        .list-inline {
                            li {

                                .check_icon,
                                .circle_icon_one,
                                .circle_icon_two {
                                    font-size: rem(18);
                                }
                            }
                        }

                        &.nurse_name,
                        &.timing {
                            font-size: rem(14);
                        }
                    }
                }

                .bottom_area {
                    margin-left: 0;
                    margin-right: rem(10);

                    .left_area,
                    .right_area {
                        p {
                            font-size: rem(15);
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .medicine_tab_screen {
        .card {
            border-radius: rem(10);
            margin-bottom: rem(20);

            .card-body {
                padding: rem(10);

                .table {
                    margin-bottom: rem(20);

                    td {
                        &.medicine_title {
                            width: auto;
                            font-size: rem(10);
                        }

                        .list-inline {
                            gap: rem(6);

                            li {

                                .check_icon,
                                .circle_icon_one,
                                .circle_icon_two {
                                    font-size: rem(14);
                                }
                            }
                        }

                        &.nurse_name,
                        &.timing {
                            font-size: rem(10);
                        }
                    }
                }

                .bottom_area {
                    margin-left: 0;
                    margin-right: 0;

                    .left_area,
                    .right_area {
                        p {
                            font-size: rem(10);
                        }
                    }
                }
            }
        }
    }
}


/* Smaller phones */
@media (max-width: rem(360)) {
    .medicine_tab_screen {
        .card {
            border-radius: rem(10);
            margin-bottom: rem(20);

            .card-body {
                padding: rem(10);

                .table {
                    margin-bottom: rem(20);

                    td {
                        &.medicine_title {
                            width: auto;
                            font-size: rem(10);
                        }

                        .list-inline {
                            gap: rem(6);

                            li {

                                .check_icon,
                                .circle_icon_one,
                                .circle_icon_two {
                                    font-size: rem(14);
                                }
                            }
                        }

                        &.nurse_name,
                        &.timing {
                            font-size: rem(10);
                        }
                    }
                }

                .bottom_area {
                    margin-left: 0;
                    margin-right: 0;

                    .left_area,
                    .right_area {
                        p {
                            font-size: rem(10);
                        }
                    }
                }
            }
        }
    }
}