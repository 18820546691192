.dashboard_superadmin {
    .dashboard_logo_area {
        margin: 0 rem(15);

        .logo_main_area {
            @include clearfix();
            margin-top: rem(30);
            margin-bottom: rem(30);
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;

            .left_area {
                float: left;
                width: rem(130);

                .img_logo {
                    width: rem(126);

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }

            }

            .right_area {
                float: right;
                width: calc(100% - 130px);
                padding-left: rem(44);

                .dropdown_area {
                    margin-top: rem(0);
                    margin-right: rem(0);
                    background: $dashboard;
                    border: 1px solid $dashboard;
                    border-radius: rem(6);
                    color: $white;
                    margin-bottom: rem(0);
                    // width: 100%;
                    width: 415px;
                    max-width: 100%;
                    margin-left: auto;

                    a {
                        .btn-primary {
                            background: linear-gradient(135.83deg, #E31E24 8.45%, #7F001F 115.01%);
                            border-radius: 30px;
                            color: #ffffff;
                            border: none;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 36px;
                            width: 200px;
                        }
                    }

                    button {
                        position: relative;

                        &.btn-primary {
                            border-radius: rem(6);
                            border: none;
                            height: rem(58);
                            // width: rem(342);
                            width: 100%;
                            font-size: rem(22);
                            font-weight: 400;
                            color: $white;
                            text-align: start;
                            padding: 0 rem(20);
                            background-color: transparent;

                            &::placeholder {
                                opacity: 0.5;
                            }

                            &:hover {
                                background-color: transparent;
                                color: $white;
                            }

                            &:focus {
                                background: transparent;
                                color: $white;
                            }
                        }

                        .icon {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            margin-right: 20px;
                            margin-top: 15px;
                            @include transition(0.3s ease-in-out);
                        }

                        &.dropdown-toggle {
                            &:after {
                                content: none;
                            }
                        }

                        &:focus {
                            box-shadow: none;

                            .down_icon {
                                @include rotate(180deg);
                                margin-bottom: rem(15);
                            }
                        }
                    }

                    .dropdown-menu {
                        &.show {
                            left: 49% !important;
                            width: 100%;
                            transform: translate(calc(-50% + 6px), 60px) !important;
                            // right: auto !important;
                            // transform: translate(calc(-50% + 3px), 60px) !important;
                        }
                    }
                }

                .btn_patient_area {
                    float: right;

                    .btn-primary {
                        background: linear-gradient(135.83deg, #E31E24 8.45%, #7F001F 115.01%);
                        border-radius: 30px;
                        color: #ffffff;
                        border: none;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 36px;
                        width: 200px;
                        height: 50px;
                    }
                }
            }

        }

        .patient_admit_area {
            padding-top: rem(90);
            padding-left: rem(15);

            .noImg {
                width: 40% !important;
            }
        }

        .information_area {
            // height: rem(249);
            padding: rem(30);
            background-color: #FFF1F1;
            // background-image: url('../../images/dash_bg.png');
            // background-repeat: no-repeat;
            // background-size: cover;
            // overflow: hidden;
            border-radius: rem(15);
            @include clearfix();
            display: flex;
            align-items: center;
            margin-bottom: rem(30);

            .left_info {
                float: left;
                width: calc(100% - 265px);

                h6 {
                    font-size: rem(24);
                    font-weight: 600;
                    color: $dashboard;
                    margin-bottom: rem(20);
                }

                .inner_info_area {
                    @include clearfix();
                    cursor: pointer;

                    &.space_mb {
                        margin-bottom: rem(18);
                    }

                    .left_area {
                        float: left;
                        width: rem(15);

                        .img_icon {
                            width: rem(15);

                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .right_area {
                        float: right;
                        width: calc(100% - 15px);
                        padding-left: rem(12);

                        p {
                            font-size: rem(16);
                            font-weight: 400;
                            color: $designation;
                        }
                    }
                }
            }

            .right_info {
                float: right;
                width: rem(265);
                position: relative;
                margin-left: 20px;
                // z-index: 1;

                &::before {
                    content: "";
                    background: rgba(255, 78, 78, 0.23);
                    filter: blur(8px);
                    border-radius: 23px;
                    overflow: hidden;
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: rem(195);
                    margin: 0 rem(20);
                    z-index: -1;
                }

                .img_area {
                    // width: rem(265);
                    height: rem(199);
                    border-radius: rem(28);
                    overflow: hidden;
                    margin: 0 auto;


                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .text_btn_area {
            @include clearfix();
            display: flex;
            align-items: center;
            margin-bottom: rem(30);

            .left_text_area {
                float: left;
                width: calc(100% - 200px);

                p {
                    font-size: rem(22);
                    font-weight: 600;
                    color: $form_label_color;
                }
            }

            .right_btn_area {
                float: right;
                width: rem(200);

                .btn-primary {
                    background: linear-gradient(135.83deg, #E31E24 8.45%, #7F001F 115.01%);
                    border-radius: rem(30);
                    color: $white;
                    border: none;
                    font-size: rem(16);
                    font-weight: 400;
                    line-height: rem(36);
                    width: 200px;

                    &:hover {
                        box-shadow: none;
                        background: linear-gradient(135.83deg, #E31E24 20%, #7F001F 120%);
                        opacity: unset;
                    }
                }
            }
        }

        .box_contain {
            .box_area {
                background: $accordin_body;
                border: 1px solid $grey;
                border-radius: 25px;
                overflow: hidden;
                padding: rem(20) rem(20) rem(20) rem(30);
                position: relative;
                margin-bottom: rem(30);

                .circle_area {
                    width: rem(85);
                    position: absolute;
                    right: 0;
                    bottom: 0;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }

                .top_area {
                    @include clearfix();
                    margin-bottom: rem(30);

                    .top_left {
                        float: left;

                        .user_area {
                            height: rem(44);
                            width: rem(48);
                            line-height: rem(42);
                            background: rgba(227, 30, 36, 0.21);
                            border-radius: rem(10);
                            text-align: center;
                            cursor: pointer;

                            .img_area {
                                width: rem(24);
                                margin: 0 auto;
                                cursor: pointer;

                                img {
                                    height: 100%;
                                    width: 100%;
                                    object-fit: none;
                                }
                            }
                        }
                    }

                    .top_right {
                        float: right;

                        .arrow_area {
                            height: rem(28);
                            width: rem(34);
                            // opacity: 0.28;
                            border-radius: 6px;
                            background-color: $light_green;
                            z-index: -1;
                            text-align: center;
                            padding-top: rem(4);

                            .icon {
                                font-size: rem(20);
                                color: $green;
                                margin: 0 auto;
                            }
                        }
                    }
                }

                .middle {
                    h4 {
                        font-size: rem(36);
                        font-weight: 700;
                        color: $form_label_color;
                        margin-bottom: rem(3);
                    }
                }

                .bottom_area {
                    p {
                        font-size: rem(24);
                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        font-weight: 400;
                    }
                }
            }
        }

        .average_main_area {
            background: $accordin_body;
            border: 1px solid $grey;
            border-radius: 25px;
            padding: rem(35) rem(35) rem(25) rem(35);
            @include clearfix();
            margin-bottom: rem(50);

            .left_average_area {
                float: left;
                width: 40%;

                h5 {
                    font-size: rem(22);
                    font-weight: 600;
                    color: $form_label_color;
                    margin-bottom: rem(8);
                }

                .text_area {
                    margin-bottom: rem(35);

                    p {
                        font-size: rem(16);
                        font-weight: 400;
                        color: $black_2;
                        padding: rem(5) rem(13);
                        display: inline-block;
                        background-color: $light_pur;
                        border-radius: rem(5);
                    }
                }

                .content_text {
                    @include clearfix();
                    display: flex;
                    align-items: center;

                    &.space_text {
                        margin-bottom: rem(20);
                    }

                    .left_box {
                        float: left;
                        width: rem(24);
                        cursor: pointer;

                        .box_contain {
                            height: rem(21);
                            width: rem(24);
                            background: linear-gradient(142.62deg, #CE9FFC -43.31%, #7367F0 129.93%);
                            border-radius: 4px;

                            &.task {
                                background: linear-gradient(135deg, #FFE985 -50%, #FA742B 150%);
                            }
                        }
                    }

                    .right_text {
                        float: right;
                        width: calc(100% - 24px);
                        padding-left: rem(10);

                        p {
                            font-size: rem(16);
                            font-weight: 400;
                            color: $black_2;
                            cursor: pointer;
                        }
                    }
                }
            }

            .right_average_area {
                float: right;
                width: calc(100% - 40%);

                .dropdown_area {
                    background: transparent;
                    color: $white;
                    text-align: end;

                    button {
                        position: relative;

                        &.btn-primary {
                            border: none;
                            height: rem(15);
                            width: rem(102);
                            font-size: rem(13);
                            font-weight: 500;
                            color: $dark_yellow;
                            text-align: start;
                            background-color: transparent;
                            margin-top: -30px;

                            &::placeholder {
                                opacity: 0.5;
                            }

                            &:hover {
                                background-color: transparent;
                                color: darken($dark_yellow, 5%);
                            }

                            &:focus {
                                background: transparent;
                                color: $designation;
                            }
                        }

                        .icon {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            font-size: rem(18);
                            color: $grey_2;
                            margin-right: rem(13);
                            margin-top: rem(3);
                            @include transition(0.3s ease-in-out);
                        }

                        &.dropdown-toggle {
                            &:after {
                                content: none;
                            }
                        }

                        &:focus {
                            box-shadow: none;

                            .down_icon {
                                @include rotate(180deg);
                                margin-bottom: rem(-15);
                            }
                        }
                    }

                    .dropdown-menu {
                        &.show {
                            left: 50% !important;
                            right: auto !important;
                            // transform: translate(calc(10% + 3px), 30px) !important;
                            transform: translate(calc(10% + 350px), 30px) !important;


                        }
                    }
                }

                .circle_percentage {
                    float: right;
                    margin-right: 30px;

                    .circle_part {
                        height: rem(180) !important;
                        width: rem(180) !important;
                        cursor: pointer;
                    }
                }
            }
        }

        .patient_admit_area {
            .title {
                h4 {
                    font-size: rem(22);
                    font-weight: 600;
                    color: $form_label_color;
                    margin-bottom: rem(7);
                }
            }

            .date_area {
                @include clearfix();
                display: flex;
                align-items: center;
                margin-bottom: rem(20);
                margin-left: rem(95);

                .left_date {
                    float: left;
                    width: calc(100% - 125px);

                    p {
                        font-size: rem(16);
                        font-weight: 700;
                        color: $designation;
                    }
                }

                .right_date {
                    float: right;
                    width: rem(125);

                    .btn-primary {
                        height: rem(41);
                        width: rem(125);
                        line-height: rem(30);
                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                        border-radius: 26px;
                        font-size: rem(24);
                        font-weight: 600;
                        color: $white;
                        border: none;

                        &:hover {
                            background: linear-gradient(135.83deg, #E31E24 10%, #800020 120%);
                        }
                    }
                }
            }

            .patient_main_area {
                @include clearfix();
                position: relative;
                z-index: 1;
                margin-top: 20px;

                &::before {
                    content: '';
                    position: absolute;
                    border-left: 2px dashed $light_pur_2;
                    height: 50%;
                    // height: 72%;
                    left: 0;
                    bottom: 0;
                    margin-left: 30px;
                    z-index: -1;
                    overflow: hidden;
                }

                .left_main {
                    float: left;
                    width: rem(75);

                    .text {
                        p {
                            font-size: rem(16);
                            font-weight: 400;
                            color: $designation;
                            margin-bottom: rem(4);
                        }
                    }

                    .check_icon {
                        color: $light_sky;
                        font-size: rem(25);
                        text-align: center;
                        margin-left: rem(-13);
                    }
                }

                .right_area {
                    float: right;
                    width: calc(100% - 75px);
                    padding-left: rem(20);

                    // .date_area {
                    //     @include clearfix();
                    //     display: flex;
                    //     align-items: center;
                    //     margin-bottom: rem(20);

                    //     .left_date {
                    //         float: left;
                    //         width: calc(100% - 125px);

                    //         p {
                    //             font-size: rem(16);
                    //             font-weight: 700;
                    //             color: $designation;
                    //         }
                    //     }

                    //     .right_date {
                    //         float: right;
                    //         width: rem(125);

                    //         .btn-primary {
                    //             height: rem(41);
                    //             width: rem(125);
                    //             line-height: rem(25);
                    //             background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                    //             border-radius: 26px;
                    //             font-size: rem(24);
                    //             font-weight: 600;
                    //             color: $white;
                    //         }
                    //     }
                    // }

                    .patient_info_area {
                        @include clearfix();
                        background: $light_red_2;
                        border: 1px solid $grey;
                        border-radius: 25px;
                        padding: rem(15) rem(30) rem(15) rem(12);
                        margin-bottom: rem(25);

                        .left_info_area {
                            float: left;
                            width: rem(50);

                            .img_girl {
                                height: rem(50);
                                width: rem(50);
                                overflow: hidden;
                                border-radius: 50%;

                                img {
                                    height: 100%;
                                    width: 100%;
                                    object-fit: cover;
                                }
                            }
                        }

                        .right_info_area {
                            float: right;
                            width: calc(100% - 50px);
                            padding-left: rem(15);

                            .patient_name_area {
                                @include clearfix();
                                display: flex;
                                align-items: center;

                                .left_area {
                                    float: left;
                                    width: rem(140);

                                    h6 {
                                        font-size: rem(15);
                                        font-weight: 500;
                                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                        background-clip: text;
                                        text-fill-color: transparent;
                                        margin-bottom: rem(4);
                                    }

                                    .text {
                                        p {
                                            font-size: rem(14);
                                            font-weight: 400;
                                            color: $dashboard;
                                            padding: rem(3) rem(10);
                                            background: rgba(55, 55, 128, 0.2);
                                            border-radius: 5px;
                                            display: inline-block;
                                        }
                                    }
                                }

                                .right_area {
                                    float: right;
                                    width: calc(100% - 120px);

                                    .disease_area {
                                        @include clearfix();
                                        display: flex;
                                        align-items: center;

                                        .left_disease {
                                            float: left;
                                            width: calc(100% - 20px);

                                            p {
                                                font-size: rem(14);
                                                font-weight: 500;
                                                color: $black_2;
                                                margin-bottom: rem(7);
                                            }

                                            .list_area {
                                                ul {
                                                    display: flex;

                                                    li {
                                                        margin-right: rem(18);

                                                        &:last-child {
                                                            margin-right: 0;
                                                        }

                                                        .text_icon {
                                                            font-size: rem(16);
                                                            font-weight: 400;
                                                            color: $designation;

                                                            .icon {
                                                                font-size: rem(16);
                                                                color: $dark_red_2;
                                                                margin-right: rem(7);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .right_disease {
                                            float: right;
                                            width: rem(20);

                                            .icon_dots {
                                                font-size: rem(28);
                                                color: $form_label_color;
                                            }

                                            .dropdown {
                                                button {
                                                    &.dropdown-toggle {
                                                        width: rem(20);
                                                        border: none;
                                                        // height: none;
                                                        // float: right;
                                                        font-size: rem(28);
                                                        color: $form_label_color;

                                                        &::after {
                                                            display: none;
                                                            background-color: transparent;
                                                        }

                                                        &:focus,
                                                        &:hover {
                                                            background-color: transparent;
                                                        }
                                                    }
                                                }

                                                .image_area {
                                                    padding-left: rem(8);
                                                    overflow: hidden;

                                                    img {
                                                        width: 100%;
                                                        height: 100%;
                                                        object-fit: cover;
                                                    }
                                                }

                                                .dropdown-menu.show {
                                                    transform: translate(0px, 50px) !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {
    .dashboard_superadmin {
        .dashboard_logo_area {
            .average_main_area {
                .right_average_area {
                    .dropdown_area {
                        .dropdown-menu {
                            &.show {
                                transform: translate(calc(10% + 180px), 30px) !important;
                            }
                        }
                    }
                }
            }

        }
    }
}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {
    .dashboard_superadmin {
        .dashboard_logo_area {
            .average_main_area {
                .right_average_area {
                    .dropdown_area {
                        .dropdown-menu {
                            &.show {
                                transform: translate(calc(10% + 155px), 30px) !important;
                            }
                        }
                    }
                }
            }

        }
    }
}

@media (min-width: 1023px) and (max-width: 1199px) {
    .dashboard_superadmin {
        .dashboard_logo_area {
            .average_main_area {
                .right_average_area {
                    .dropdown_area {
                        .dropdown-menu {
                            &.show {
                                transform: translate(calc(10% + 82px), 30px) !important;
                            }
                        }
                    }
                }
            }

        }
    }
}


@media(min-width:768px) and (max-width:1023px) {
    .dashboard_superadmin {
        .dashboard_logo_area {
            margin: 0 rem(15);

            .logo_main_area {
                @include clearfix();
                margin-top: rem(-58);
                margin-bottom: rem(30);
                position: relative;
                z-index: 1;
                display: flex;
                align-items: center;

                .left_area {
                    float: left;
                    width: rem(130);

                    .img_logo {
                        width: rem(126);

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }

                }

                .right_area {
                    float: right;
                    width: calc(100% - 130px);
                    padding-left: rem(44);

                    .dropdown_area {
                        margin-top: rem(12);
                        margin-right: rem(45);
                        background: $dashboard;
                        border: 1px solid $dashboard;
                        border-radius: rem(6);
                        color: $white;
                        width: 100%;
                        margin-bottom: rem(30);

                        a {
                            .btn-primary {
                                background: linear-gradient(135.83deg, #E31E24 8.45%, #7F001F 115.01%);
                                border-radius: 30px;
                                color: #ffffff;
                                border: none;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 36px;
                                width: 200px;
                            }
                        }

                        button {
                            position: relative;

                            &.btn-primary {
                                border-radius: rem(6);
                                border: none;
                                height: rem(58);
                                // width: rem(342);
                                width: 100%;
                                font-size: rem(22);
                                font-weight: 400;
                                color: $white;
                                text-align: start;
                                padding: 0 rem(20);
                                background-color: transparent;

                                &::placeholder {
                                    opacity: 0.5;
                                }

                                &:hover {
                                    background-color: transparent;
                                    color: $white;
                                }

                                &:focus {
                                    background: transparent;
                                    color: $white;
                                }
                            }

                            .icon {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                right: 0;
                                margin-right: 20px;
                                margin-top: 15px;
                                @include transition(0.3s ease-in-out);
                            }

                            &.dropdown-toggle {
                                &:after {
                                    content: none;
                                }
                            }

                            &:focus {
                                box-shadow: none;

                                .down_icon {
                                    @include rotate(180deg);
                                    margin-bottom: rem(15);
                                }
                            }
                        }

                        .dropdown-menu {
                            &.show {
                                left: 49% !important;
                                width: 100%;
                                transform: translate(calc(-50% + 6px), 60px) !important;
                                // right: auto !important;
                                // transform: translate(calc(-50% + 3px), 60px) !important;
                            }
                        }
                    }

                    .btn_patient_area {
                        float: right;

                        .btn-primary {
                            background: linear-gradient(135.83deg, #E31E24 8.45%, #7F001F 115.01%);
                            border-radius: 30px;
                            color: #ffffff;
                            border: none;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 36px;
                            width: 200px;
                            height: 50px;
                        }
                    }
                }
            }

            .patient_admit_area {
                padding-top: 0;
                padding-left: 0;

                .noImg {
                    width: 75% !important;
                }
            }

            .information_area {
                height: rem(249);
                padding: rem(25);
                background-image: url('../../images/dash_bg.png');
                background-repeat: no-repeat;
                background-size: cover;
                overflow: hidden;
                border-radius: rem(20);
                @include clearfix();
                display: flex;
                align-items: center;
                margin-bottom: rem(30);

                .left_info {
                    float: left;
                    width: calc(100% - 265px);

                    h6 {
                        font-size: rem(24);
                        font-weight: 600;
                        color: $dashboard;
                        margin-bottom: rem(20);
                    }

                    .inner_info_area {
                        @include clearfix();
                        cursor: pointer;

                        &.space_mb {
                            margin-bottom: rem(18);
                        }

                        .left_area {
                            float: left;
                            width: rem(15);

                            .img_icon {
                                width: rem(15);

                                img {
                                    height: 100%;
                                    width: 100%;
                                    object-fit: cover;
                                }
                            }
                        }

                        .right_area {
                            float: right;
                            width: calc(100% - 15px);
                            padding-left: rem(12);

                            p {
                                font-size: rem(16);
                                font-weight: 400;
                                color: $designation;
                            }
                        }
                    }
                }

                .right_info {
                    float: right;
                    width: rem(265);
                    position: relative;
                    // z-index: 1;

                    &::before {
                        content: "";
                        background: rgba(255, 78, 78, 0.23);
                        filter: blur(8px);
                        border-radius: 23px;
                        overflow: hidden;
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: rem(195);
                        margin: 0 rem(20);
                        z-index: -1;
                    }

                    .img_area {
                        // width: rem(265);
                        height: rem(199);
                        border-radius: rem(28);
                        overflow: hidden;
                        margin: 0 auto;


                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }

            .text_btn_area {
                @include clearfix();
                display: flex;
                align-items: center;
                margin-bottom: rem(30);

                .left_text_area {
                    float: left;
                    width: calc(100% - 200px);

                    p {
                        font-size: rem(22);
                        font-weight: 600;
                        color: $form_label_color;
                    }
                }

                .right_btn_area {
                    float: right;
                    width: rem(200);

                    .btn-primary {
                        background: linear-gradient(135.83deg, #E31E24 8.45%, #7F001F 115.01%);
                        border-radius: rem(30);
                        color: $white;
                        border: none;
                        font-size: rem(16);
                        font-weight: 400;
                        line-height: rem(36);
                        width: 200px;

                        &:hover {
                            box-shadow: none;
                            background: linear-gradient(135.83deg, #E31E24 20%, #7F001F 120%);
                            opacity: unset;
                        }
                    }
                }
            }

            .box_contain {
                .box_area {
                    background: $accordin_body;
                    border: 1px solid $grey;
                    border-radius: 25px;
                    overflow: hidden;
                    padding: rem(20) rem(20) rem(20) rem(30);
                    position: relative;
                    margin-bottom: rem(30);

                    .circle_area {
                        width: rem(85);
                        position: absolute;
                        right: 0;
                        bottom: 0;

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }

                    .top_area {
                        @include clearfix();
                        margin-bottom: rem(30);

                        .top_left {
                            float: left;

                            .user_area {
                                height: rem(44);
                                width: rem(48);
                                line-height: rem(42);
                                background: rgba(227, 30, 36, 0.21);
                                border-radius: rem(10);
                                text-align: center;
                                cursor: pointer;

                                .img_area {
                                    width: rem(24);
                                    margin: 0 auto;
                                    cursor: pointer;

                                    img {
                                        height: 100%;
                                        width: 100%;
                                        object-fit: none;
                                    }
                                }
                            }
                        }

                        .top_right {
                            float: right;

                            .arrow_area {
                                height: rem(28);
                                width: rem(34);
                                // opacity: 0.28;
                                border-radius: 6px;
                                background-color: $light_green;
                                z-index: -1;
                                text-align: center;
                                padding-top: rem(4);

                                .icon {
                                    font-size: rem(20);
                                    color: $green;
                                    margin: 0 auto;
                                }
                            }
                        }
                    }

                    .middle {
                        h4 {
                            font-size: rem(36);
                            font-weight: 700;
                            color: $form_label_color;
                            margin-bottom: rem(3);
                        }
                    }

                    .bottom_area {
                        p {
                            font-size: rem(24);
                            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            text-fill-color: transparent;
                            font-weight: 400;
                        }
                    }
                }
            }

            .average_main_area {
                background: $accordin_body;
                border: 1px solid $grey;
                border-radius: 25px;
                padding: rem(35) rem(35) rem(25) rem(35);
                @include clearfix();
                margin-bottom: rem(50);

                .left_average_area {
                    float: left;
                    width: 40%;

                    h5 {
                        font-size: rem(22);
                        font-weight: 600;
                        color: $form_label_color;
                        margin-bottom: rem(8);
                    }

                    .text_area {
                        margin-bottom: rem(60);

                        p {
                            font-size: rem(16);
                            font-weight: 400;
                            color: $black_2;
                            padding: rem(5) rem(13);
                            display: inline-block;
                            background-color: $light_pur;
                            border-radius: rem(5);
                        }
                    }

                    .content_text {
                        @include clearfix();
                        display: flex;
                        align-items: center;

                        &.space_text {
                            margin-bottom: rem(35);
                        }

                        .left_box {
                            float: left;
                            width: rem(24);
                            cursor: pointer;

                            .box_contain {
                                height: rem(21);
                                width: rem(24);
                                background: linear-gradient(142.62deg, #CE9FFC -43.31%, #7367F0 129.93%);
                                border-radius: 4px;

                                &.task {
                                    background: linear-gradient(135deg, #FFE985 -50%, #FA742B 150%);
                                }
                            }
                        }

                        .right_text {
                            float: right;
                            width: calc(100% - 24px);
                            padding-left: rem(10);

                            p {
                                font-size: rem(16);
                                font-weight: 400;
                                color: $black_2;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .right_average_area {
                    float: right;
                    width: calc(100% - 40%);

                    .dropdown_area {
                        background: transparent;
                        color: $white;
                        text-align: end;

                        button {
                            position: relative;

                            &.btn-primary {
                                border: none;
                                height: rem(15);
                                width: rem(102);
                                font-size: rem(13);
                                font-weight: 500;
                                color: $dark_yellow;
                                text-align: start;
                                background-color: transparent;
                                margin-top: -30px;

                                &::placeholder {
                                    opacity: 0.5;
                                }

                                &:hover {
                                    background-color: transparent;
                                    color: darken($dark_yellow, 5%);
                                }

                                &:focus {
                                    background: transparent;
                                    color: $designation;
                                }
                            }

                            .icon {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                right: 0;
                                font-size: rem(18);
                                color: $grey_2;
                                margin-right: rem(13);
                                margin-top: rem(3);
                                @include transition(0.3s ease-in-out);
                            }

                            &.dropdown-toggle {
                                &:after {
                                    content: none;
                                }
                            }

                            &:focus {
                                box-shadow: none;

                                .down_icon {
                                    @include rotate(180deg);
                                    margin-bottom: rem(-15);
                                }
                            }
                        }

                        .dropdown-menu {
                            &.show {
                                left: 50% !important;
                                right: auto !important;
                                transform: translate(calc(10% + 3px), 30px) !important;

                            }
                        }
                    }

                    .circle_percentage {
                        float: right;
                        margin-right: 30px;

                        .circle_part {
                            height: rem(220) !important;
                            width: rem(220) !important;
                            cursor: pointer;
                        }
                    }
                }
            }

            .patient_admit_area {
                padding-top: 0;
                padding-left: 0;

                .title {
                    h4 {
                        font-size: rem(22);
                        font-weight: 600;
                        color: $form_label_color;
                        margin-bottom: rem(7);
                    }
                }

                .date_area {
                    @include clearfix();
                    display: flex;
                    align-items: center;
                    margin-bottom: rem(20);
                    margin-left: rem(95);

                    .left_date {
                        float: left;
                        width: calc(100% - 125px);

                        p {
                            font-size: rem(16);
                            font-weight: 700;
                            color: $designation;
                        }
                    }

                    .right_date {
                        float: right;
                        width: rem(125);

                        .btn-primary {
                            height: rem(41);
                            width: rem(125);
                            line-height: rem(30);
                            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                            border-radius: 26px;
                            font-size: rem(24);
                            font-weight: 600;
                            color: $white;
                            border: none;

                            &:hover {
                                background: linear-gradient(135.83deg, #E31E24 10%, #800020 120%);
                            }
                        }
                    }
                }

                .patient_main_area {
                    @include clearfix();
                    position: relative;
                    z-index: 1;

                    &::before {
                        content: '';
                        position: absolute;
                        border-left: 2px dashed $light_pur_2;
                        // height: 100%;
                        left: 0;
                        bottom: 0;
                        margin-left: 30px;
                        z-index: -1;
                        overflow: hidden;
                    }

                    .left_main {
                        float: left;
                        width: rem(75);

                        .text {
                            p {
                                font-size: rem(16);
                                font-weight: 400;
                                color: $designation;
                                margin-bottom: rem(4);
                            }
                        }

                        .check_icon {
                            color: $light_sky;
                            font-size: rem(25);
                            text-align: center;
                            margin-left: rem(-13);
                        }
                    }

                    .right_area {
                        float: right;
                        width: calc(100% - 75px);
                        padding-left: rem(20);

                        // .date_area {
                        //     @include clearfix();
                        //     display: flex;
                        //     align-items: center;
                        //     margin-bottom: rem(20);

                        //     .left_date {
                        //         float: left;
                        //         width: calc(100% - 125px);

                        //         p {
                        //             font-size: rem(16);
                        //             font-weight: 700;
                        //             color: $designation;
                        //         }
                        //     }

                        //     .right_date {
                        //         float: right;
                        //         width: rem(125);

                        //         .btn-primary {
                        //             height: rem(41);
                        //             width: rem(125);
                        //             line-height: rem(25);
                        //             background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                        //             border-radius: 26px;
                        //             font-size: rem(24);
                        //             font-weight: 600;
                        //             color: $white;
                        //         }
                        //     }
                        // }

                        .patient_info_area {
                            @include clearfix();
                            background: $light_red_2;
                            border: 1px solid $grey;
                            border-radius: 25px;
                            padding: rem(15) rem(30) rem(15) rem(12);
                            margin-bottom: rem(25);

                            .left_info_area {
                                float: left;
                                width: rem(50);

                                .img_girl {
                                    height: rem(50);
                                    width: rem(50);
                                    overflow: hidden;
                                    border-radius: 50%;

                                    img {
                                        height: 100%;
                                        width: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }

                            .right_info_area {
                                float: right;
                                width: calc(100% - 50px);
                                padding-left: rem(15);

                                .patient_name_area {
                                    @include clearfix();
                                    display: flex;
                                    align-items: center;

                                    .left_area {
                                        float: left;
                                        width: rem(120);

                                        h6 {
                                            font-size: rem(15);
                                            font-weight: 500;
                                            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                            -webkit-background-clip: text;
                                            -webkit-text-fill-color: transparent;
                                            background-clip: text;
                                            text-fill-color: transparent;
                                            margin-bottom: rem(4);
                                        }

                                        .text {
                                            p {
                                                font-size: rem(14);
                                                font-weight: 400;
                                                color: $dashboard;
                                                padding: rem(3) rem(10);
                                                background: rgba(55, 55, 128, 0.2);
                                                border-radius: 5px;
                                                display: inline-block;
                                            }
                                        }
                                    }

                                    .right_area {
                                        float: right;
                                        width: calc(100% - 120px);

                                        .disease_area {
                                            @include clearfix();
                                            display: flex;
                                            align-items: center;

                                            .left_disease {
                                                float: left;
                                                width: calc(100% - 20px);

                                                p {
                                                    font-size: rem(14);
                                                    font-weight: 500;
                                                    color: $black_2;
                                                    margin-bottom: rem(7);
                                                }

                                                .list_area {
                                                    ul {
                                                        display: flex;

                                                        li {
                                                            margin-right: rem(18);

                                                            &:last-child {
                                                                margin-right: 0;
                                                            }

                                                            .text_icon {
                                                                font-size: rem(16);
                                                                font-weight: 400;
                                                                color: $designation;

                                                                .icon {
                                                                    font-size: rem(16);
                                                                    color: $dark_red_2;
                                                                    margin-right: rem(7);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .right_disease {
                                                float: right;
                                                width: rem(20);

                                                .icon_dots {
                                                    font-size: rem(28);
                                                    color: $form_label_color;
                                                }

                                                .dropdown {
                                                    button {
                                                        &.dropdown-toggle {
                                                            width: rem(20);
                                                            border: none;
                                                            // height: none;
                                                            // float: right;
                                                            font-size: rem(28);
                                                            color: $form_label_color;

                                                            &::after {
                                                                display: none;
                                                                background-color: transparent;
                                                            }

                                                            &:focus,
                                                            &:hover {
                                                                background-color: transparent;
                                                            }
                                                        }
                                                    }

                                                    .image_area {
                                                        padding-left: rem(8);
                                                        overflow: hidden;

                                                        img {
                                                            width: 100%;
                                                            height: 100%;
                                                            object-fit: cover;
                                                        }
                                                    }

                                                    .dropdown-menu.show {
                                                        // transform: translate(-151px, -30px) !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .dashboard_superadmin {
        .dashboard_logo_area {
            .logo_main_area {
                margin-bottom: 0;
            }

            .information_area {
                height: rem(214);
                padding: rem(20);
                margin-bottom: rem(25);

                .left_info {
                    width: calc(100% - 230px);

                    h6 {
                        margin-bottom: rem(15);
                    }

                    .inner_info_area {
                        .space_mb {
                            margin-bottom: rem(15);
                        }
                    }
                }

                .right_info {
                    width: rem(230);

                    &::before {
                        height: rem(175);
                    }

                    .img_area {
                        width: rem(230);
                        height: rem(170);
                        border-radius: rem(20);
                    }
                }
            }

            .text_btn_area {
                margin-bottom: rem(25);

                .left_text_area {
                    p {
                        font-size: rem(20);
                    }
                }
            }

            .box_contain {
                .box_area {
                    margin-bottom: rem(25);

                    .middle {
                        h4 {
                            font-size: rem(30);
                        }
                    }

                    .bottom_area {
                        p {
                            font-size: rem(20);
                        }
                    }
                }
            }

            .average_main_area {
                padding: rem(30) rem(30) rem(20) rem(30);
                margin-bottom: rem(35);

                .left_average_area {
                    h5 {
                        font-size: rem(20);
                    }

                    .text_area {
                        margin-bottom: rem(50);

                        p {
                            font-size: rem(15);
                        }
                    }

                    .content_text {
                        &.space_text {
                            margin-bottom: rem(30);
                        }
                    }
                }

                .right_average_area {
                    .circle_percentage {
                        .circle_part {
                            height: rem(200) !important;
                            width: rem(200) !important;
                        }
                    }
                }
            }

            .patient_admit_area {
                padding-top: 0;
                padding-left: 0;

                .title {
                    h4 {
                        font-size: rem(20);
                    }
                }

                .date_area {
                    margin-bottom: rem(15);

                    .left_date {
                        p {
                            font-size: rem(15);
                        }
                    }

                    .right_date {
                        .btn-primary {
                            font-size: rem(20);
                        }
                    }
                }

                .patient_main_area {
                    padding-left: rem(15);

                    &::before {
                        margin-left: 44px;
                    }

                    .left_main {
                        .text {
                            p {
                                font-size: rem(15);
                            }
                        }
                    }

                    .right_area {
                        .patient_info_area {
                            margin-bottom: rem(20);

                            .right_info_area {
                                .patient_name_area {
                                    .right_area {
                                        .disease_area {
                                            .right_disease {
                                                .dropdown {
                                                    .dropdown-menu.show {
                                                        transform: translate(0, 65px) !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .dashboard_superadmin {
        .dashboard_logo_area {
            margin: 0;

            .logo_main_area {
                margin-bottom: 0;
                width: 100%;
                display: block;

                .left_area {
                    float: unset;
                    width: 100%;
                    // width: rem(85);

                    .img_logo {
                        width: rem(85);
                        margin: 0 auto;
                    }
                }

                .right_area {
                    float: unset;
                    width: 100%;
                    // width: calc(100% - 85px);
                    padding-left: rem(0);
                    margin-top: rem(10);

                    .dropdown_area {
                        margin-top: 0;
                        margin-right: 0;
                        margin-bottom: rem(25);

                        button {
                            &.btn-primary {
                                padding: 0 rem(15);
                                height: rem(45);
                                font-size: rem(20);
                            }

                            .icon {
                                margin-right: rem(15);
                                margin-top: rem(9);
                            }

                            &:focus {
                                .down_icon {
                                    margin-bottom: rem(7);
                                }
                            }
                        }
                    }

                    .btn_patient_area {
                        margin-bottom: 15px;

                        .btn-primary {
                            font-size: 15px;
                            line-height: 32px;
                            width: 162px;
                            height: 45px;
                        }
                    }
                }
            }

            .information_area {
                display: block;
                width: 100%;
                height: 100%;
                padding: rem(20);
                margin-bottom: rem(20);

                .left_info {
                    float: none;
                    width: 100%;

                    h6 {
                        font-size: rem(22);
                        margin-bottom: rem(15);
                    }

                    .inner_info_area {
                        &.space_mb {
                            margin-bottom: rem(15);
                        }
                    }
                }

                .right_info {
                    float: none;
                    width: 100%;

                    &::before {
                        height: rem(225);
                    }

                    .img_area {
                        width: 100%;
                        border-radius: rem(15);
                        margin-top: rem(15);
                    }
                }
            }

            .text_btn_area {
                margin-bottom: rem(20);

                .left_text_area {
                    width: calc(100% - 150px);

                    p {
                        font-size: rem(18);
                    }
                }

                .right_btn_area {
                    width: rem(150);

                    .btn-primary {
                        width: rem(150);
                        height: rem(45);
                        line-height: rem(32);
                    }
                }
            }

            .box_contain {
                .box_area {
                    margin-bottom: rem(15);
                    border-radius: rem(20);
                }
            }

            .average_main_area {
                width: 100%;
                padding: rem(25);
                border-radius: rem(20);
                margin-bottom: rem(30);

                .left_average_area {
                    float: none;
                    width: 100%;

                    .text_area {
                        margin-bottom: rem(35);
                    }

                    .content_text {
                        &.space_text {
                            margin-bottom: rem(25);
                        }

                        .right_text {
                            p {
                                font-size: rem(15);
                            }
                        }
                    }
                }

                .right_average_area {
                    float: none;
                    width: 100%;

                    .dropdown_area {
                        margin-top: rem(10);
                    }

                    .circle_percentage {
                        float: none;
                        margin-right: 0;

                        canvas {
                            margin: 0 auto;
                        }
                    }
                }
            }

            .patient_admit_area {
                padding-top: 0;
                padding-left: 0;

                .title {
                    h4 {
                        font-size: rem(20);
                    }
                }

                .date_area {
                    margin-bottom: rem(12);
                    margin-left: rem(78);

                    .left_date {
                        width: calc(100% - 108px);
                    }

                    .right_date {
                        width: rem(108);

                        .btn-primary {
                            width: rem(108);
                            font-size: rem(20);
                        }
                    }
                }

                .patient_main_area {
                    &::before {
                        height: 70%;
                    }

                    .left_main {
                        width: rem(65);

                        .text {
                            p {
                                font-size: rem(14);
                                margin-bottom: 0;
                            }
                        }

                        .check_icon {
                            font-size: rem(20);
                            margin-left: rem(-6);
                        }
                    }

                    .right_area {
                        width: calc(100% - 65px);
                        padding-left: rem(10);

                        .patient_info_area {
                            border-radius: rem(20);
                            margin-bottom: rem(15);
                            padding: rem(15);

                            .right_info_area {
                                padding-left: rem(10);

                                .patient_name_area {
                                    display: block;

                                    .left_area {
                                        float: none;
                                        width: 100%;
                                    }

                                    .right_area {
                                        float: none;
                                        width: 100%;
                                        padding-left: 0;

                                        .disease_area {
                                            margin-top: rem(15);
                                            margin-left: -60px;

                                            .left_disease {
                                                width: calc(100% - 10px);
                                            }

                                            .right_disease {
                                                width: rem(10);
                                                text-align: end;

                                                // .icon_dots {
                                                //     font-size: rem(22);
                                                // }
                                                .dropdown {
                                                    button {
                                                        &.dropdown-toggle {
                                                            font-size: rem(22);
                                                        }
                                                    }

                                                    .dropdown-menu.show {
                                                        transform: translate(0, 40px) !important;

                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .dashboard_superadmin {
        .dashboard_logo_area {
            margin: 0;

            .logo_main_area {
                margin-bottom: 0;
                width: 100%;
                display: block;

                .left_area {
                    float: unset;
                    width: 100%;
                    // width: rem(85);

                    .img_logo {
                        width: rem(85);
                        margin: 0 auto;
                    }
                }

                .right_area {
                    float: unset;
                    width: 100%;
                    margin-top: rem(10);
                    // width: calc(100% - 85px);
                    padding-left: rem(0);

                    .dropdown_area {
                        margin-top: 0;
                        margin-right: 0;
                        margin-bottom: rem(25);

                        button {
                            &.btn-primary {
                                padding: 0 rem(15);
                                height: rem(45);
                                font-size: rem(20);
                            }

                            .icon {
                                margin-right: rem(15);
                                margin-top: rem(9);
                            }

                            &:focus {
                                .down_icon {
                                    margin-bottom: rem(7);
                                }
                            }
                        }
                    }

                    .btn_patient_area {
                        margin-bottom: 15px;

                        .btn-primary {
                            font-size: 15px;
                            line-height: 32px;
                            width: 162px;
                            height: 45px;
                        }
                    }
                }
            }

            .information_area {
                display: block;
                width: 100%;
                height: 100%;
                padding: rem(20);
                margin-bottom: rem(20);

                .left_info {
                    float: none;
                    width: 100%;

                    h6 {
                        font-size: rem(22);
                        margin-bottom: rem(15);
                    }

                    .inner_info_area {
                        &.space_mb {
                            margin-bottom: rem(15);
                        }
                    }
                }

                .right_info {
                    float: none;
                    width: 100%;

                    &::before {
                        height: rem(225);
                    }

                    .img_area {
                        width: 100%;
                        border-radius: rem(15);
                        margin-top: rem(15);
                    }
                }
            }

            .box_contain {
                .box_area {
                    margin-bottom: rem(15);
                    border-radius: rem(20);
                }
            }

            .average_main_area {
                width: 100%;
                padding: rem(25);
                border-radius: rem(20);
                margin-bottom: rem(30);

                .left_average_area {
                    float: none;
                    width: 100%;

                    .text_area {
                        margin-bottom: rem(35);
                    }

                    .content_text {
                        &.space_text {
                            margin-bottom: rem(25);
                        }

                        .right_text {
                            p {
                                font-size: rem(15);
                            }
                        }
                    }
                }

                .right_average_area {
                    float: none;
                    width: 100%;

                    .dropdown_area {
                        margin-top: rem(10);
                    }

                    .circle_percentage {
                        float: none;
                        margin-right: 0;

                        canvas {
                            margin: 0 auto;
                        }
                    }
                }
            }

            .patient_admit_area {
                padding-top: 0;
                padding-left: 0;

                .title {
                    h4 {
                        font-size: rem(20);
                    }
                }

                .date_area {
                    margin-bottom: rem(12);
                    margin-left: rem(78);

                    .left_date {
                        width: calc(100% - 108px);
                    }

                    .right_date {
                        width: rem(108);

                        .btn-primary {
                            width: rem(108);
                            font-size: rem(20);
                        }
                    }
                }

                .patient_main_area {
                    &::before {
                        height: 70%;
                    }

                    .left_main {
                        width: rem(65);

                        .text {
                            p {
                                font-size: rem(14);
                                margin-bottom: 0;
                            }
                        }

                        .check_icon {
                            font-size: rem(20);
                            margin-left: rem(-6);
                        }
                    }

                    .right_area {
                        width: calc(100% - 65px);
                        padding-left: rem(10);

                        .patient_info_area {
                            border-radius: rem(20);
                            margin-bottom: rem(15);
                            padding: rem(15);

                            .right_info_area {
                                padding-left: rem(10);

                                .patient_name_area {
                                    display: block;

                                    .left_area {
                                        float: none;
                                        width: 100%;
                                    }

                                    .right_area {
                                        float: none;
                                        width: 100%;
                                        padding-left: 0;

                                        .disease_area {
                                            margin-top: rem(15);
                                            margin-left: -60px;

                                            .left_disease {
                                                width: calc(100% - 10px);
                                            }

                                            .right_disease {
                                                width: rem(10);
                                                text-align: end;

                                                // .icon_dots {
                                                //     font-size: rem(22);
                                                // }
                                                .dropdown {
                                                    button {
                                                        &.dropdown-toggle {
                                                            font-size: rem(22);
                                                        }
                                                    }

                                                    .dropdown-menu.show {
                                                        transform: translate(0, 40px) !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}