.document_area {
    padding: rem(45) rem(20);

    .card {
        background: $card_color;
        border: rem(1) solid $grey;
        border-radius: rem(25);

        .card-body {
            padding: rem(30) rem(45);

            .card {
                background: $card_color;
                border: rem(1) solid $grey;
                box-shadow: 0 rem(4) rem(25) rgba(55, 55, 128, 0.12);
                border-radius: rem(10);
                margin-bottom: rem(20);

                .card-body {
                    padding: rem(20);

                    .card_details {
                        @include clearfix();

                        .left_area {
                            float: left;
                            width: rem(320);

                            .inner_area {
                                @include clearfix();

                                .inner_left_area {
                                    float: left;
                                    width: rem(70);

                                    .inner_img {
                                        width: rem(50);
                                        height: rem(50);
                                        overflow: hidden;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                        }
                                    }
                                }

                                .inner_right_area {
                                    float: left;
                                    width: calc(100% - 70px);
                                    margin-top: rem(15);

                                    h3 {
                                        font-weight: 400;
                                        font-size: rem(20);
                                        color: $black;
                                    }
                                }
                            }
                        }

                        .right_area {
                            float: right;
                            width: calc(100% - 320px);
                            margin-top: rem(15);

                            .icons_area {
                                float: right;
                                display: flex;
                                gap: rem(30);
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .document_area {
        .card {
            .card-body {
                padding: rem(20);

                .card {
                    .card-body {
                        padding: rem(10);

                        .card_details {
                            .left_area {
                                width: rem(155);

                                .inner_area {
                                    .inner_left_area {
                                        width: rem(45);

                                        .inner_img {
                                            width: rem(40);
                                            height: rem(40);
                                        }
                                    }

                                    .inner_right_area {
                                        width: calc(100% - 45px);

                                        h3 {
                                            font-size: rem(15);
                                        }
                                    }
                                }
                            }

                            .right_area {
                                width: calc(100% - 155px);
                                margin-top: rem(8);

                                .icons_area {
                                    gap: rem(15);

                                    .img_one,
                                    .img_two {
                                        width: rem(16);
                                        height: rem(19);
                                        overflow: hidden;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                            object-fit: contain;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .document_area {
        .card {
            .card-body {
                padding: rem(20);

                .card {
                    .card-body {
                        padding: rem(10);

                        .card_details {
                            .left_area {
                                width: rem(155);

                                .inner_area {
                                    .inner_left_area {
                                        width: rem(45);

                                        .inner_img {
                                            width: rem(40);
                                            height: rem(40);
                                        }
                                    }

                                    .inner_right_area {
                                        width: calc(100% - 45px);

                                        h3 {
                                            font-size: rem(15);
                                        }
                                    }
                                }
                            }

                            .right_area {
                                width: calc(100% - 155px);
                                margin-top: rem(8);

                                .icons_area {
                                    gap: rem(15);

                                    .img_one,
                                    .img_two {
                                        width: rem(16);
                                        height: rem(19);
                                        overflow: hidden;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                            object-fit: contain;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}