.patient_profile_screen {
    .profile_data {
        padding: 0 rem(20);
        @include clearfix();

        .left_area {
            float: left;
            width: 50%;

            .profile_details {
                width: rem(334);
                height: rem(355);
                overflow: hidden;
                border-radius: rem(15);

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .right_area {
            float: right;
            width: calc(100% - 50%);

            .inner_area {
                padding-top: rem(15);
                padding-left: rem(10);

                h3 {
                    font-size: rem(22);
                    font-weight: 600;
                    background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                }

                h6 {
                    font-size: rem(20);
                    font-weight: 400;
                    color: $form_border_color;
                    text-transform: capitalize;
                    margin-top: rem(6);
                }

                .gender_area {
                    @include clearfix();
                    font-weight: 400;
                    font-size: rem(20);
                    color: $card_text_color;
                    margin-top: rem(15);
                    margin-bottom: rem(17);

                    span {
                        float: left;
                        display: flex;
                        gap: rem(10);
                        width: 50%;
                    }

                    p {
                        float: right;
                        width: calc(100% - 50%);
                    }
                }

                .data {
                    font-weight: 400;
                    font-size: rem(20);
                    color: $card_text_color;
                    margin-bottom: rem(20);

                    p {
                        display: flex;
                        gap: rem(15);
                    }
                }

                .btn_warning {
                    font-weight: 600;
                    font-size: rem(14);
                    background: $treatment_color;
                    width: rem(150);

                    &:hover,
                    &:focus {
                        opacity: 0.6;
                        color: $white;
                    }
                }
            }
        }
    }

    .height_weight_area {
        padding: rem(60) rem(20) rem(55) rem(20);

        .record {
            width: rem(200);
            height: rem(60);
            border-radius: rem(12);
            margin: 0 auto;

            img {
                float: right;
                margin-right: rem(10);
                margin-top: rem(5);
            }

            p {
                font-weight: 400;
                font-size: rem(16);
                width: 100%;
                display: flex;
                gap: rem(13);
                margin-left: rem(40);
                padding-top: rem(5);
            }
        }

        .height_details {
            background: #FFECEF;
            border: rem(1) solid #D81B23;
        }

        .weight_details {
            background: #DBDBFF;
            border: rem(1) solid $form_border_color;

            p {
                margin-left: rem(48);
            }
        }

        .history_details {
            background: #DBDBFF;
            border: rem(1) solid $form_border_color;

            p {
                align-items: center;
                justify-content: center;
                margin: 0 auto;
            }
        }
    }

    .buttons_area {
        border: rem(1) solid #F0F0F0;

        ul {
            display: flex;
            padding: rem(12) rem(24);
            gap: rem(40);

            li {
                width: rem(140);
                height: rem(47);
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    font-weight: 600;
                    font-size: rem(24);
                }

                &.active_btn {
                    background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                    box-shadow: 0 rem(4) rem(18) rgba(227, 30, 36, 0.66);
                    border-radius: rem(10);

                    a {
                        color: $white;
                    }
                }

                &.inactive_btn {
                    a {
                        color: $card_text_color;
                    }
                }


            }
        }
    }


}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .patient_profile_screen {
        .profile_data {
            padding: 0 rem(10);

            .left_area {
                .profile_details {
                    width: rem(230);
                    height: rem(250);
                }
            }

            .right_area {
                .inner_area {
                    padding-top: rem(3);
                    padding-left: 0;

                    h3 {
                        font-size: rem(18);
                    }

                    h6 {
                        font-size: rem(16);
                        margin-top: rem(2);
                    }

                    .gender_area {
                        font-size: rem(16);
                        margin-top: rem(8);
                        margin-bottom: rem(10);
                    }

                    .data {
                        font-size: rem(16);
                        margin-bottom: rem(10);
                    }

                    .btn_warning {
                        margin-top: rem(3);
                        font-size: rem(13);
                        width: rem(140);
                    }
                }
            }
        }

        .height_weight_area {
            padding: rem(50) rem(5);

            .record {
                width: rem(160);
                height: rem(55);

                p {
                    font-size: rem(14);
                    margin-left: rem(10);
                }
            }

            .history_details {
                p {
                    margin: 0 auto;
                }
            }
        }

        .buttons_area {
            ul {
                gap: rem(15);

                li {
                    a {
                        font-size: rem(20);
                    }
                }
            }
        }


    }

}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .patient_profile_screen {
        .profile_data {
            padding: 0 rem(25);

            .left_area {
                width: 100%;

                .profile_details {
                    width: rem(285);
                    height: rem(300);
                }
            }

            .right_area {
                float: left;
                width: 100%;
                padding-top: rem(20);

                .inner_area {
                    padding-top: rem(3);
                    padding-left: 0;

                    h6 {
                        margin-top: rem(2);
                    }

                    .gender_area {
                        font-size: rem(18);
                        margin-top: rem(10);
                        margin-bottom: rem(12);
                    }

                    .data {
                        font-size: rem(18);
                        margin-bottom: rem(12);
                    }

                }
            }
        }

        .height_weight_area {
            padding: rem(50) rem(10);

            .record {
                width: rem(100);
                height: rem(50);
                border-radius: rem(10);

                img {
                    width: rem(65);
                }

                p {
                    font-size: rem(10);
                    gap: rem(10);
                    margin-left: rem(10);
                    padding-top: rem(8);
                }
            }

            .history_details {
                p {
                    margin: 0 auto;
                }
            }
        }

        .buttons_area {
            ul {
                gap: rem(20);

                li {
                    a {
                        font-weight: 600;
                        font-size: rem(18);
                    }
                }
            }
        }



    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .patient_profile_screen {
        .profile_data {
            padding: 0 rem(25);

            .left_area {
                width: 100%;

                .profile_details {
                    width: rem(285);
                    height: rem(300);
                }
            }

            .right_area {
                float: left;
                width: 100%;
                padding-top: rem(20);

                .inner_area {
                    padding-top: rem(3);
                    padding-left: 0;

                    h6 {
                        margin-top: rem(2);
                    }

                    .gender_area {
                        font-size: rem(18);
                        margin-top: rem(10);
                        margin-bottom: rem(12);
                    }

                    .data {
                        font-size: rem(18);
                        margin-bottom: rem(12);
                    }

                }
            }
        }

        .height_weight_area {
            padding: rem(50) rem(10);

            .record {
                width: rem(100);
                height: rem(50);
                border-radius: rem(10);

                img {
                    width: rem(65);
                }

                p {
                    font-size: rem(10);
                    gap: rem(10);
                    margin-left: rem(10);
                    padding-top: rem(8);
                }
            }

            .history_details {
                p {
                    margin: 0 auto;
                }
            }
        }

        .buttons_area {
            ul {
                gap: rem(20);

                li {
                    a {
                        font-weight: 600;
                        font-size: rem(18);
                    }
                }
            }
        }


    }
}