.forget_password_screen {
    .form {
        padding: rem(50) rem(30);

        .btn_area {
            &.more_space {
                margin-top: rem(30);
            }

            .cancelBtn {
                font-size: rem(24);
                font-weight: 600;
                width: 100%;
                height: rem(60);
                margin-top: rem(30);
                background: $grey;
                border: 0px solid transparent;
                color: $designation;

                &:hover {
                    background: darken($grey, 2%);
                    color: $white;
                    border: 0px solid transparent;
                    opacity: unset;
                }
            }

            .submitBtn {
                font-size: rem(24);
                font-weight: 600;
                width: 100%;
                height: rem(60);
                margin-top: rem(30);
                background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                border: 0px solid transparent;
                color: $white;

                &:hover {
                    background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                    color: $white;
                    border: 0px solid transparent;
                    opacity: unset;
                }
            }
        }
    }
}


@media (min-width: 576px) and (max-width: 767px) {}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .forget_password_screen {
        .form {
            padding: 0;
            margin-top: rem(30);

            .btn_area {
                .cancelBtn {
                    height: rem(50);
                    font-size: rem(22);
                }

                .submitBtn {
                    height: rem(50);
                    font-size: rem(22);
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .forget_password_screen {
        .form {
            padding: 0;
            margin-top: rem(30);

            .btn_area {
                .cancelBtn {
                    height: rem(50);
                    font-size: rem(22);
                }

                .submitBtn {
                    height: rem(50);
                    font-size: rem(22);
                }
            }
        }
    }
}