.hospital_list_data {
    .hospital_data {
        margin: 0 rem(30);
        margin-top: rem(60);
        position: relative;

        .content_area {
            @include clearfix();
            display: flex;
            align-items: center;
            margin-bottom: rem(40);

            .left_area {
                float: left;
                width: calc(100% - 200px);

                p {
                    font-size: rem(24);
                    font-weight: 600;
                    color: $form_label_color;
                }
            }

            .right_area {
                float: right;
                width: rem(200);
                text-align: end;

                a {
                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                    height: rem(60);
                    line-height: rem(44);
                    border: rem(2) solid #ffffff;
                    border-radius: rem(30);
                    font-size: rem(24);
                    font-weight: 600;

                    &:hover {
                        color: $white;
                        background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                    }
                }
            }
        }

        .search_icon_area {
            @include clearfix();
            display: flex;
            margin-bottom: 20px;

            .search_area {
                float: left;
                width: calc(100% - 90px);

                .form-group {
                    position: relative;

                    .form-control {
                        padding-left: rem(45);
                    }

                    .icon_search {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        font-size: rem(20);
                        color: $form_label_color;
                        margin-top: rem(18);
                        margin-left: rem(15);
                    }
                }
            }

            .pagination_list {
                margin-left: 20px;
                float: right;
                width: rem(90);

                .form-group {
                    .form-select {
                        width: 90px;
                        color: $designation;
                        font-size: rem(20);

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
            }

            &.width_fix {
                width: 100%;

                .search_area {
                    float: none;
                    width: 100%;
                }

                .icon_filter_area {
                    float: none;
                    width: 100%;
                }
            }
        }

        .table_content_area {
            background: $white;
            border: 1px solid $light_grey_2;
            box-shadow: 0px 0px 8px rgba(17, 17, 17, 0.06);
            border-radius: 25px;
            margin-bottom: rem(20);
            overflow: hidden;

            .table-responsive {
                overflow-x: auto;
                min-height: rem(265);

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                    background-color: $grey;
                }

                &::-webkit-scrollbar {
                    width: 2px;
                    height: 6px;
                    background-color: $dark_red_2;
                    margin: 0 rem(20);
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $dark_red_2;
                }

                table {
                    thead {
                        border-bottom: 1.5px solid rgba(133, 133, 133, 0.6);

                        tr {
                            th {
                                padding: 25px 15px;
                                font-size: rem(20);
                                font-weight: 600;
                                color: $form_label_color;
                                text-align: center;

                                &:last-child {
                                    padding-right: rem(30);
                                }

                                // &:first-child {
                                //     padding-left: rem(30);
                                //     cursor: pointer;
                                // }

                                &.hosp_name {
                                    min-width: rem(202);
                                }

                                &.hosp_adrs {
                                    min-width: rem(276);
                                }

                                &.act_pat {
                                    min-width: rem(180);
                                }

                                &.cont_no {
                                    min-width: rem(195);
                                }

                                &.action {
                                    min-width: rem(65);
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            position: relative;

                            td {
                                padding: 15px;
                                border-bottom: 0.5px solid rgba(128, 128, 128, 0.8);
                                font-size: 16px;
                                font-weight: 600;
                                color: $designation;

                                &:last-child {
                                    padding-right: rem(30);
                                }

                                &:first-child {
                                    padding-left: rem(30);
                                }

                                .text_img_area {
                                    @include clearfix();
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;

                                    .left_area {
                                        float: left;
                                        width: rem(50);

                                        .img_area {
                                            height: rem(50);
                                            width: rem(50);
                                            border-radius: 50%;
                                            overflow: hidden;

                                            img {
                                                height: 100%;
                                                width: 100%;
                                                object-fit: cover;
                                            }
                                        }
                                    }

                                    .right_area {
                                        float: right;
                                        width: calc(100% - 50px);
                                        padding-left: rem(15);

                                        p {
                                            font-size: rem(16);
                                            font-weight: 600;
                                        }

                                        .under_line {
                                            &:hover {
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }

                                p {
                                    font-size: rem(16);
                                    font-weight: 600;

                                    &.center_space {
                                        text-align: center;
                                    }
                                }

                                .center_box {
                                    text-align: center;

                                    .num_box {
                                        font-size: rem(14);
                                        font-weight: 400;
                                        color: $green;
                                        padding: rem(10);
                                        border-radius: rem(6);
                                        background-color: $light_green;
                                        display: inline-block;
                                        margin: 0 auto;
                                        cursor: pointer;
                                        min-width: 37px;
                                    }
                                }

                                .icon {
                                    .dropdown {
                                        text-align: end;

                                        .btn {
                                            color: $form_label_color;
                                            padding-top: 0;
                                            font-size: rem(22);
                                            position: relative;
                                            top: 4px;

                                            &:hover,
                                            &:focus {
                                                background-color: $white;
                                            }
                                        }

                                        .ellipsis_icon {
                                            font-size: rem(27);
                                            cursor: pointer;
                                        }

                                        .dropdown-toggle::after {
                                            display: none !important;
                                        }

                                        .dropdown-menu {
                                            padding: 0;
                                            inset: auto !important;
                                            border-radius: rem(12) 0 rem(12) rem(12);
                                            border: rem(0.3) solid $dark_grey;
                                            background: $white;
                                            transform: translate(-100px, 0) !important;
                                            overflow: hidden;

                                            .dropdown-item {
                                                padding: rem(8) rem(30);
                                                font-size: rem(16);
                                                font-weight: 400;
                                                color: $designation;
                                                padding-left: 45px;
                                                position: relative;
                                                border-bottom: rem(0.2) solid #858585;
                                                overflow: hidden;

                                                &:last-child {
                                                    border-bottom: none;
                                                }

                                                .image_area {
                                                    width: rem(12);
                                                    margin-left: rem(5);
                                                    position: relative;
                                                    margin-top: rem(-3);

                                                    img {
                                                        width: 100%;
                                                        height: 100%;
                                                        object-fit: cover;
                                                    }

                                                    &.delete_icon {
                                                        margin-top: -4px;
                                                    }
                                                }

                                                &.active,
                                                &:focus {
                                                    background-color: transparent;
                                                }
                                            }
                                        }
                                    }
                                }

                            }

                            td {
                                &::before {
                                    content: unset;
                                }
                            }


                            &:hover {
                                td {
                                    background: rgba(228, 228, 228, 0.21);
                                    cursor: pointer;

                                    &::before {
                                        content: "";
                                        height: 100%;
                                        width: rem(6);
                                        background-color: $dark_red_2;
                                        position: absolute;
                                        top: 0;
                                        bottom: 0;
                                        left: 0;
                                    }

                                    .icon {
                                        .dropdown {
                                            .btn {
                                                background: transparent;
                                            }
                                        }
                                    }
                                }
                            }

                            &:last-child {
                                td {
                                    border-bottom: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .value_list {
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: rem(20);
            font-weight: 400;
            color: $designation;
            padding: rem(8) rem(15);
            background-color: $white;
            box-shadow: 0px 0px 8px rgba(17, 17, 17, 0.12);
            border-radius: rem(10);
            display: inline-block;
            margin-bottom: rem(40);

            .icon {
                font-size: rem(15);
                color: $designation;
                margin-left: rem(12);
                position: relative;
                top: rem(-2);
            }
        }
    }
}


/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {}


@media (min-width: 1024px) and (max-width: 1199px) {
    .hospital_list_data {
        .hospital_data {
            .search_icon_area {
                margin-bottom: 10px;
            }

            .table_content_area {
                .table-responsive {
                    table {
                        thead {
                            tr {
                                th {
                                    padding: 20px 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media(min-width:768px) and (max-width:1023px) {
    .hospital_list_data {
        .hospital_data {
            margin: 0 rem(15);
            margin-top: rem(0);
            position: relative;

            .content_area {
                @include clearfix();
                display: flex;
                align-items: center;
                margin-bottom: rem(40);

                .left_area {
                    float: left;
                    width: calc(100% - 200px);

                    p {
                        font-size: rem(24);
                        font-weight: 600;
                        color: $form_label_color;
                    }
                }

                .right_area {
                    float: right;
                    width: rem(200);
                    text-align: end;

                    a {
                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                        height: rem(60);
                        line-height: rem(44);
                        border: rem(2) solid #ffffff;
                        border-radius: rem(30);
                        font-size: rem(24);
                        font-weight: 600;

                        &:hover {
                            color: $white;
                            background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                        }
                    }
                }
            }

            .search_icon_area {
                @include clearfix();
                display: flex;
                margin-bottom: 0;

                .search_area {
                    float: left;
                    width: calc(100% - 90px);

                    .form-group {
                        position: relative;

                        .form-control {
                            padding-left: rem(45);
                        }

                        .icon_search {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            font-size: rem(20);
                            color: $form_label_color;
                            margin-top: rem(18);
                            margin-left: rem(15);
                        }
                    }
                }

                // .icon_filter_area {
                //     float: right;
                //     width: rem(150);

                //     .btn-primary {
                //         float: right;
                //         font-size: rem(22);
                //         font-weight: 400;
                //         color: $form_label_color;
                //         display: flex;
                //         align-items: center;
                //         width: rem(125);
                //         border: 1.5px solid $form_border_color;
                //         height: rem(60);
                //         border-radius: rem(10);
                //         text-align: end;

                //         .img_area {
                //             width: rem(20);
                //             margin-right: rem(10);

                //             img {
                //                 height: 100%;
                //                 width: 100%;
                //                 object-fit: cover;
                //             }
                //         }

                //         &:hover {
                //             background-color: transparent;
                //         }
                //     }
                // }

                .pagination_list {
                    // padding-top: 30px;
                    margin-left: 20px;
                    float: right;
                    width: rem(90);

                    .form-group {
                        .form-select {
                            width: 90px;
                            color: $designation;
                            font-size: rem(20);

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }

                &.width_fix {
                    width: 100%;

                    .search_area {
                        float: none;
                        width: 100%;
                    }

                    .icon_filter_area {
                        float: none;
                        width: 100%;
                    }
                }
            }

            .table_content_area {
                background: $white;
                border: 1px solid $light_grey_2;
                box-shadow: 0px 0px 8px rgba(17, 17, 17, 0.06);
                border-radius: 25px;
                margin-bottom: rem(20);
                overflow: hidden;

                .table-responsive {
                    overflow-x: auto;

                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                        -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                        background-color: $grey;
                    }

                    &::-webkit-scrollbar {
                        width: 2px;
                        height: 6px;
                        background-color: $dark_red_2;
                        margin: 0 rem(20);
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: $dark_red_2;
                    }

                    table {
                        thead {
                            border-bottom: 1.5px solid rgba(133, 133, 133, 0.6);

                            tr {
                                th {
                                    padding: 15px;
                                    font-size: rem(22);
                                    font-weight: 600;
                                    color: $form_label_color;
                                    text-align: center;

                                    &:last-child {
                                        padding-right: rem(30);
                                    }

                                    &:first-child {
                                        padding-left: rem(30);
                                        cursor: pointer;
                                    }

                                    &.hosp_name {
                                        min-width: rem(202);
                                    }

                                    &.hosp_adrs {
                                        min-width: rem(276);
                                    }

                                    &.act_pat {
                                        min-width: rem(180);
                                    }

                                    &.cont_no {
                                        min-width: rem(195);
                                    }

                                    &.action {
                                        min-width: rem(65);
                                    }
                                }
                            }
                        }

                        tbody {
                            tr {
                                position: relative;

                                td {
                                    padding: 15px;
                                    border-bottom: 0.5px solid rgba(128, 128, 128, 0.8);
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: $designation;

                                    &:last-child {
                                        padding-right: rem(30);
                                    }

                                    &:first-child {
                                        padding-left: rem(30);
                                    }

                                    .text_img_area {
                                        @include clearfix();
                                        display: flex;
                                        align-items: center;
                                        cursor: pointer;

                                        .left_area {
                                            float: left;
                                            width: rem(50);

                                            .img_area {
                                                height: rem(50);
                                                width: rem(50);
                                                border-radius: 50%;
                                                overflow: hidden;

                                                img {
                                                    height: 100%;
                                                    width: 100%;
                                                    object-fit: cover;
                                                }
                                            }
                                        }

                                        .right_area {
                                            float: right;
                                            width: calc(100% - 50px);
                                            padding-left: rem(15);

                                            p {
                                                font-size: rem(16);
                                                font-weight: 600;
                                            }

                                            .under_line {
                                                &:hover {
                                                    text-decoration: underline;
                                                }
                                            }
                                        }
                                    }

                                    p {
                                        font-size: rem(16);
                                        font-weight: 600;

                                        &.center_space {
                                            text-align: center;
                                        }
                                    }

                                    .center_box {
                                        text-align: center;

                                        .num_box {
                                            font-size: rem(14);
                                            font-weight: 400;
                                            color: $green;
                                            padding: rem(10);
                                            border-radius: rem(6);
                                            background-color: $light_green;
                                            display: inline-block;
                                            margin: 0 auto;
                                            cursor: pointer;
                                            min-width: 37px;
                                        }
                                    }

                                    .icon {
                                        .dropdown {
                                            text-align: end;

                                            .btn {
                                                color: $form_label_color;
                                                padding-top: 0;
                                                font-size: rem(22);
                                                position: relative;
                                                top: 4px;

                                                &:hover,
                                                &:focus {
                                                    background-color: $white;
                                                }
                                            }

                                            .ellipsis_icon {
                                                font-size: rem(27);
                                                cursor: pointer;
                                            }

                                            .dropdown-toggle::after {
                                                display: none !important;
                                            }

                                            .dropdown-menu {
                                                padding: 0;
                                                border-radius: rem(12) 0 rem(12) rem(12);
                                                border: rem(0.3) solid $dark_grey;
                                                background: $white;
                                                overflow: hidden;

                                                .dropdown-item {
                                                    padding: rem(8) rem(30);
                                                    font-size: rem(16);
                                                    font-weight: 400;
                                                    color: $designation;
                                                    padding-left: 45px;
                                                    position: relative;
                                                    border-bottom: rem(0.2) solid #858585;
                                                    overflow: hidden;

                                                    &:last-child {
                                                        border-bottom: none;
                                                    }

                                                    .image_area {
                                                        width: rem(12);
                                                        margin-left: rem(5);
                                                        position: relative;
                                                        margin-top: rem(-3);

                                                        img {
                                                            width: 100%;
                                                            height: 100%;
                                                            object-fit: cover;
                                                        }

                                                        &.delete_icon {
                                                            margin-top: -4px;
                                                        }
                                                    }

                                                    &.active,
                                                    &:focus {
                                                        background-color: transparent;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }

                                td {
                                    &::before {
                                        content: unset;
                                    }
                                }


                                &:hover {
                                    td {
                                        background: rgba(228, 228, 228, 0.21);
                                        cursor: pointer;

                                        &::before {
                                            content: "";
                                            // height: rem(80);
                                            width: rem(6);
                                            // background-color: $green;
                                            background-color: $dark_red_2;
                                            position: absolute;
                                            top: 0;
                                            bottom: 0;
                                            left: 0;
                                        }

                                        .icon {
                                            .dropdown {
                                                .btn {
                                                    background: transparent;
                                                }
                                            }
                                        }
                                    }
                                }

                                &:last-child {
                                    td {
                                        border-bottom: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .value_list {
                position: absolute;
                bottom: 0;
                left: 0;
                font-size: rem(20);
                font-weight: 400;
                color: $designation;
                padding: rem(8) rem(15);
                background-color: $white;
                box-shadow: 0px 0px 8px rgba(17, 17, 17, 0.12);
                border-radius: rem(10);
                display: inline-block;
                margin-bottom: rem(40);

                .icon {
                    font-size: rem(15);
                    color: $designation;
                    margin-left: rem(12);
                    position: relative;
                    top: rem(-2);
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .hospital_list_data {
        .hospital_data {
            margin: 0;
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .hospital_list_data {
        .hospital_data {
            margin: 0;
            margin-top: rem(0);

            .content_area {
                margin-top: rem(-20);
                margin-bottom: rem(20);

                .left_area {
                    width: calc(100% - 155px);

                    p {
                        font-size: rem(18);
                    }
                }

                .right_area {
                    width: rem(155);

                    a {
                        height: rem(50);
                        line-height: rem(35);
                        font-size: rem(18);
                        width: rem(155);
                    }
                }
            }

            .search_icon_area {
                display: block;
                margin-bottom: 0;

                .search_area {
                    float: none;
                    width: 100%;
                    // width: calc(100% - 120px);

                    .form-group {
                        margin-bottom: rem(20);

                        .form-control {
                            padding-left: rem(35);
                        }

                        .icon_search {
                            font-size: rem(18) !important;
                            margin-left: rem(12);
                        }
                    }
                }

                .pagination_list {
                    float: none;
                    width: 100%;
                    margin-left: 0;
                }

                .icon_filter_area {
                    width: rem(120);

                    .btn-primary {
                        font-size: 18px;
                        width: 108px;
                        height: 53px;
                        border-radius: 10px;
                    }
                }
            }

            .table_content_area {
                border-radius: rem(15);

                .table-responsive {

                    table {
                        thead {
                            tr {
                                th {
                                    font-size: 18px;

                                    .text_img_area {
                                        .left_area {
                                            .img_area {
                                                height: rem(45);
                                                width: rem(45);
                                            }

                                        }

                                        .right_area {
                                            p {
                                                font-size: rem(15);
                                            }
                                        }
                                    }

                                    p {
                                        font-size: rem(15);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .hospital_list_data {
        .hospital_data {
            margin: 0;
            margin-top: 0;

            .content_area {
                margin-top: rem(-20);
                margin-bottom: rem(20);

                .left_area {
                    width: calc(100% - 155px);

                    p {
                        font-size: rem(18);
                    }
                }

                .right_area {
                    width: rem(155);

                    a {
                        height: rem(50);
                        line-height: rem(35);
                        font-size: rem(18);
                        width: rem(155);
                    }
                }
            }

            .search_icon_area {
                display: block;
                margin-bottom: 0;

                .search_area {
                    float: none;
                    width: 100%;
                    // width: calc(100% - 120px);

                    .form-group {
                        margin-bottom: rem(20);

                        .form-control {
                            padding-left: rem(35);
                        }

                        .icon_search {
                            font-size: rem(18) !important;
                            margin-left: rem(12);
                        }
                    }
                }

                .pagination_list {
                    float: none;
                    width: 100%;
                    margin-left: 0;
                }

                .icon_filter_area {
                    width: rem(120);

                    .btn-primary {
                        font-size: 18px;
                        width: 108px;
                        height: 53px;
                        border-radius: 10px;
                    }
                }
            }

            .table_content_area {
                border-radius: rem(15);

                .table-responsive {

                    table {
                        thead {
                            tr {
                                th {
                                    font-size: 18px;

                                    .text_img_area {
                                        .left_area {
                                            .img_area {
                                                height: rem(45);
                                                width: rem(45);
                                            }

                                        }

                                        .right_area {
                                            p {
                                                font-size: rem(15);
                                            }
                                        }
                                    }

                                    p {
                                        font-size: rem(15);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}