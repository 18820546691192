.main_dashboard_screen {
    .dashboard_area {
        padding: rem(20);

        .logo_main_area {
            @include clearfix();
            margin-top: rem(0);

            .left_area {
                float: unset;
                width: unset;
            }

            .right_area {
                float: right;
                min-width: rem(270);
                width: auto;
                padding-left: rem(0);

                .dropdown_area {
                    margin-top: rem(12);
                    margin-right: rem(45);
                    background: $dashboard;
                    border: 1px solid $dashboard;
                    border-radius: rem(6);
                    color: $white;
                    width: 100%;
                    margin-bottom: rem(30);

                    button {
                        position: relative;

                        &.btn-primary {
                            border-radius: rem(6);
                            border: none;
                            height: rem(58);
                            width: 100%;
                            font-size: rem(22);
                            font-weight: 400;
                            color: $white;
                            text-align: start;
                            padding: 0 rem(20);
                            background-color: transparent;

                            &::placeholder {
                                opacity: 0.5;
                            }

                            &:hover {
                                background-color: transparent;
                                color: $white;
                            }

                            &:focus {
                                background: transparent;
                                color: $white;
                            }
                        }

                        .icon {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            margin-right: 20px;
                            margin-top: 15px;
                            @include transition(0.3s ease-in-out);
                        }

                        &.dropdown-toggle {
                            &:after {
                                content: none;
                            }
                        }

                        &:focus {
                            box-shadow: none;

                            .down_icon {
                                @include rotate(180deg);
                                margin-bottom: rem(15);
                            }
                        }
                    }

                    .dropdown-menu {
                        &.show {
                            left: 49% !important;
                            width: 100%;
                            transform: translate(calc(-50% + 6px), 60px) !important;
                        }
                    }
                }
            }
        }

        .inner_dashboard_area {
            .ward_view_page {
                background: #FFFFFF;
                border: rem(1) solid #E0E0E0;
                box-shadow: rem(0) rem(0) rem(8) rgba(0, 0, 0, 0.12);
                border-radius: rem(25);

                .inner_ward {
                    .top_head {
                        border-bottom: rem(1) solid #E0E0E0;
                        padding: rem(30) rem(20) rem(10) rem(20);

                        h4 {
                            font-weight: 600;
                            font-size: rem(22);
                            color: #140745;
                        }
                    }

                    .ward_listing {
                        padding: rem(20);

                        .list_view {
                            background: #FAFAFA;
                            border: rem(1) solid #E7E7E7;
                            border-radius: rem(10);
                            padding: rem(12) rem(20);
                            margin: rem(10) 0;
                            cursor: pointer;

                            p {
                                font-weight: 600;
                                font-size: rem(16);
                                color: #373780;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        .text_btn_area {
            @include clearfix();
            float: right;
            margin-bottom: rem(10);
            margin-top: 10px;

            .left_text_area {
                float: none;
                width: 100%;

                p {
                    font-size: rem(22);
                    font-weight: 600;
                    color: $form_label_color;
                }
            }

            .right_btn_area {
                float: none;
                width: 100%;

                .btn-primary {
                    background: linear-gradient(135.83deg, #E31E24 8.45%, #7F001F 115.01%);
                    border-radius: rem(30);
                    color: $white;
                    border: none;
                    font-size: rem(16);
                    font-weight: 400;
                    line-height: rem(36);
                    width: 200px;

                    &:hover {
                        box-shadow: none;
                        background: linear-gradient(135.83deg, #E31E24 20%, #7F001F 120%);
                        opacity: unset;
                    }
                }
            }
        }

    }

}



/* Above 1920 desktop */
@media (min-width: 1920px) {}

/* 1680 desktop */
@media (min-width: 1680px) and (max-width: 1919px) {}

/* 1600 desktop */
@media (min-width: 1600px) and (max-width: 1679px) {}

/* Above 1400 and Below 1599 Desktop*/
@media (min-width: 1400px) and (max-width: 1599px) {}

/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {}

/* Landscape tablets and ipad */
@media (min-width: 768px) and (max-width: 991px) {
    .main_dashboard_screen {
        .dashboard_area {
            padding: rem(20);

            .logo_main_area {
                @include clearfix();
                margin-top: rem(-55);

                .left_area {
                    float: unset;
                    width: unset;
                }

                .right_area {
                    float: right;
                    min-width: rem(270);
                    width: auto;
                    padding-left: rem(0);

                    .dropdown_area {
                        margin-top: rem(12);
                        margin-right: rem(45);
                        background: $dashboard;
                        border: 1px solid $dashboard;
                        border-radius: rem(6);
                        color: $white;
                        width: 100%;
                        margin-bottom: rem(30);

                        button {
                            position: relative;

                            &.btn-primary {
                                border-radius: rem(6);
                                border: none;
                                height: rem(58);
                                width: 100%;
                                font-size: rem(22);
                                font-weight: 400;
                                color: $white;
                                text-align: start;
                                padding: 0 rem(20);
                                background-color: transparent;

                                &::placeholder {
                                    opacity: 0.5;
                                }

                                &:hover {
                                    background-color: transparent;
                                    color: $white;
                                }

                                &:focus {
                                    background: transparent;
                                    color: $white;
                                }
                            }

                            .icon {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                right: 0;
                                margin-right: 20px;
                                margin-top: 15px;
                                @include transition(0.3s ease-in-out);
                            }

                            &.dropdown-toggle {
                                &:after {
                                    content: none;
                                }
                            }

                            &:focus {
                                box-shadow: none;

                                .down_icon {
                                    @include rotate(180deg);
                                    margin-bottom: rem(15);
                                }
                            }
                        }

                        .dropdown-menu {
                            &.show {
                                left: 49% !important;
                                width: 100%;
                                transform: translate(calc(-50% + 6px), 60px) !important;
                            }
                        }
                    }
                }
            }

            .inner_dashboard_area {
                .ward_view_page {
                    background: #FFFFFF;
                    border: rem(1) solid #E0E0E0;
                    box-shadow: rem(0) rem(0) rem(8) rgba(0, 0, 0, 0.12);
                    border-radius: rem(25);

                    .inner_ward {
                        .top_head {
                            border-bottom: rem(1) solid #E0E0E0;
                            padding: rem(30) rem(20) rem(10) rem(20);

                            h4 {
                                font-weight: 600;
                                font-size: rem(22);
                                color: #140745;
                            }
                        }

                        .ward_listing {
                            padding: rem(20);

                            .list_view {
                                background: #FAFAFA;
                                border: rem(1) solid #E7E7E7;
                                border-radius: rem(10);
                                padding: rem(12) rem(20);
                                margin: rem(10) 0;
                                cursor: pointer;

                                p {
                                    font-weight: 600;
                                    font-size: rem(16);
                                    color: #373780;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }

            .text_btn_area {
                @include clearfix();
                float: right;
                margin-bottom: rem(10);
                margin-top: 10px;

                .left_text_area {
                    float: none;
                    width: 100%;

                    p {
                        font-size: rem(22);
                        font-weight: 600;
                        color: $form_label_color;
                    }
                }

                .right_btn_area {
                    float: none;
                    width: 100%;

                    .btn-primary {
                        background: linear-gradient(135.83deg, #E31E24 8.45%, #7F001F 115.01%);
                        border-radius: rem(30);
                        color: $white;
                        border: none;
                        font-size: rem(16);
                        font-weight: 400;
                        line-height: rem(36);
                        width: 200px;

                        &:hover {
                            box-shadow: none;
                            background: linear-gradient(135.83deg, #E31E24 20%, #7F001F 120%);
                            opacity: unset;
                        }
                    }
                }
            }

        }

    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .dashboard_screen {
        .dashboardheader_area {
            height: rem(228);

            .linking_area {
                margin-top: rem(70);
            }
        }
    }

}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .main_dashboard_screen {
        .dashboard_area {
            padding: 50px 0 10px 0;

            .logo_main_area {
                width: 100%;
                @include clearfix();

                .left_area {
                    float: unset;
                    width: unset;
                }

                .right_area {
                    float: unset;
                    width: 100%;

                    .dropdown_area {
                        margin-top: 0;
                        margin-right: 0;
                        margin-bottom: rem(25);

                        button {
                            &.btn-primary {
                                padding: 0 rem(15);
                                height: rem(45);
                                font-size: rem(20);
                            }

                            .icon {
                                margin-right: rem(15);
                                margin-top: rem(9);
                            }

                            &:focus {
                                .down_icon {
                                    margin-bottom: rem(7);
                                }
                            }
                        }
                    }
                }
            }

            .inner_dashboard_area {
                .ward_view_page {
                    .inner_ward {
                        .top_head {
                            padding: rem(10) rem(20) rem(10) rem(20);

                            h4 {
                                font-weight: 600;
                                font-size: rem(20);
                            }
                        }
                    }
                }
            }

            .text_btn_area {
                .right_btn_area {
                    .btn-primary {
                        border-radius: 30px;
                        line-height: 30px;
                        width: 152px;
                        height: 45px;
                    }
                }
            }
        }
    }

}

/* Smaller phones */
@media (max-width: 360px) {
    .main_dashboard_screen {
        .dashboard_area {
            padding: 50px 0 10px 0;

            .logo_main_area {
                width: 100%;
                @include clearfix();

                .left_area {
                    float: unset;
                    width: unset;
                }

                .right_area {
                    float: unset;
                    width: 100%;

                    .dropdown_area {
                        margin-top: 0;
                        margin-right: 0;
                        margin-bottom: rem(25);

                        button {
                            &.btn-primary {
                                padding: 0 rem(15);
                                height: rem(45);
                                font-size: rem(20);
                            }

                            .icon {
                                margin-right: rem(15);
                                margin-top: rem(9);
                            }

                            &:focus {
                                .down_icon {
                                    margin-bottom: rem(7);
                                }
                            }
                        }
                    }
                }
            }

            .inner_dashboard_area {
                .ward_view_page {
                    .inner_ward {
                        .top_head {
                            padding: rem(10) rem(20) rem(10) rem(20);

                            h4 {
                                font-weight: 600;
                                font-size: rem(20);
                            }
                        }
                    }
                }
            }

            .text_btn_area {
                .right_btn_area {
                    .btn-primary {
                        border-radius: 30px;
                        line-height: 30px;
                        width: 152px;
                        height: 45px;
                    }
                }
            }
        }
    }


}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}