.task_detail_section {
    .task_detail_area {
        padding: 0 rem(15);
        margin-top: rem(-35);

        .date_num_area {
            @include clearfix();
            margin-bottom: rem(40);

            .left_date_area {
                float: left;
                width: rem(200);
                display: flex;
                gap: rem(10);

                .date_img {
                    width: rem(32);
                    height: rem(31);
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }

                }


                p {
                    font-size: rem(24);
                    font-weight: 600;
                    color: $designation;
                }
            }

            .right_num_area {
                float: right;
                width: calc(100% - 200px);
                text-align: end;

                p {
                    font-weight: 600;
                    font-size: rem(24);
                    color: $designation;
                }
            }
        }

        .inner_profile_area {
            @include clearfix();
            margin-bottom: rem(40);
            margin-top: 90px;

            .left_profile_area {
                float: left;
                width: rem(106);

                .img_area {
                    height: rem(106);
                    width: rem(106);
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }

            .right_profile_area {
                float: right;
                width: calc(100% - 106px);

                .info_main {
                    @include clearfix();

                    .left_area {
                        float: left;
                        width: calc(100% - rem(200));
                        padding-left: rem(15);

                        h3 {
                            font-weight: 600;
                            font-size: rem(36);
                            background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            margin-bottom: rem(6);
                            cursor: pointer;
                        }

                        .gender_area {
                            display: flex;
                            align-items: center;
                            margin-bottom: rem(15);

                            p {
                                font-weight: 400;
                                font-size: rem(16);
                                color: $designation;
                                margin-right: rem(15);

                                .gen_icon {
                                    color: $dark_red_2;
                                    font-size: rem(14);
                                    margin-right: rem(4);
                                }
                            }

                            span {
                                font-size: rem(16);
                                font-weight: 400;
                                color: $form_border_color;
                                padding: rem(3) rem(10);
                                background: rgba(55, 55, 128, 0.2);
                                border-radius: rem(5);
                            }
                        }

                        .icons_area {
                            display: flex;

                            p {
                                font-weight: 400;
                                font-size: rem(16);
                                color: $designation;
                                margin-right: rem(18);

                                .icon_one {
                                    color: $dark_red_2;
                                    font-size: rem(14);
                                    margin-right: rem(4);
                                }

                                .icon_calender {
                                    margin-right: rem(4);
                                    width: rem(18);
                                    height: rem(17);
                                    margin-top: rem(-4);
                                }
                            }
                        }
                    }

                    .right_area {
                        float: right;
                        width: rem(200);
                        margin-top: rem(20);

                        .text {
                            .p_handle {
                                width: 100%;
                                @include clearfix();
                            }

                            .s_handle {
                                @include clearfix();
                                width: 100%;
                            }

                            p {
                                font-size: rem(14);
                                font-weight: 400;
                                color: $light_grey;
                                padding: rem(3) rem(10);
                                background: rgba(154, 154, 154, 0.2);
                                border-radius: rem(5);
                                text-align: center;
                                float: right;
                                margin-bottom: rem(15);
                            }

                            span {
                                &.pending {
                                    float: right;
                                    font-size: rem(16);
                                    font-weight: 600;
                                    color: $white;
                                    padding: rem(13);
                                    background-color: $treatment_color;
                                    border-radius: rem(22);

                                    &:hover {
                                        background: darken($treatment_color, 4%);
                                    }
                                }

                                &.complete {
                                    float: right;
                                    font-size: rem(16);
                                    font-weight: 600;
                                    color: $green;
                                    padding: rem(13);
                                    background: rgba(139, 182, 18, 0.15);
                                    border-radius: rem(22);

                                    &:hover {
                                        background: darken(rgba(139, 182, 18, 0.15), 4%);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .patient_information {
            .card {
                background: $accordin_body;
                border: rem(1) solid #E7E7E7;
                border-radius: rem(25);
                margin-bottom: rem(35);

                .card-body {
                    padding: rem(24) rem(25) rem(20) rem(40);
                    @include clearfix();
                    position: relative;

                    .left_area {
                        width: rem(470);
                        float: left;
                        @include clearfix();

                        table {
                            tr {

                                td {
                                    margin-bottom: rem(18);

                                    &.without_space {
                                        margin-bottom: 0;
                                    }

                                    &:nth-child(1) {
                                        min-width: rem(150);
                                    }

                                    &:nth-child(2) {
                                        min-width: rem(200);
                                    }

                                    .details {
                                        margin-bottom: rem(18);
                                        background-color: transparent;

                                        &.without_space {
                                            margin-bottom: 0;
                                        }

                                        h5 {
                                            font-size: rem(20);
                                            font-weight: 600;
                                            color: $black_2;
                                        }
                                    }

                                    .text_con {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: rem(18);
                                        background-color: transparent;

                                        &.without_space {
                                            margin-bottom: 0;
                                        }

                                        span {
                                            font-size: rem(15);
                                            color: $black_2;
                                        }

                                        h5 {
                                            font-size: rem(20);
                                            font-weight: 400;
                                            color: $black_2;
                                            padding-left: rem(6);

                                            &.space_txt {
                                                text-decoration: underline;
                                                cursor: pointer;
                                            }
                                        }

                                        a {
                                            font-size: rem(14);
                                            font-weight: 500;
                                            padding: rem(6) rem(23);
                                            border-radius: rem(20);
                                            margin-left: rem(6);

                                            &.low {
                                                color: #8BB612;
                                                background: rgba(139, 182, 18, 0.1);
                                            }

                                            &.high {
                                                color: $fade_pink;
                                                background: rgba(233, 97, 97, 0.3);
                                            }

                                            &.medium {
                                                color: $treatment_color;
                                                background-color: rgba(255, 135, 23, 0.21);
                                                ;
                                            }
                                        }

                                        p {
                                            font-size: rem(16);
                                            font-weight: 400;
                                            color: #373780;
                                            padding: rem(3) rem(10);
                                            background: rgba(55, 55, 128, 0.2);
                                            border-radius: rem(5);
                                            margin-left: rem(6);
                                        }
                                    }
                                }
                            }
                        }

                        // .inner_left_area {
                        //     width: rem(200);
                        //     float: left;

                        //     ul {
                        //         li {
                        //             padding: rem(10);

                        //             .details {
                        //                 display: flex;
                        //                 justify-content: space-between;

                        //                 h5 {
                        //                     font-size: rem(20);
                        //                     font-weight: 600;
                        //                     color: $black_2;
                        //                 }
                        //             }
                        //         }
                        //     }
                        // }

                        // .inner_right_area {
                        //     width: calc(100% - 200px);
                        //     float: right;

                        //     ul {
                        //         li {
                        //             padding: rem(10);
                        //             padding-left: rem(5);
                        //             padding-top: rem(12);

                        //             span {
                        //                 font-size: rem(16);
                        //                 font-weight: 400;
                        //                 color: #373780;
                        //                 padding: rem(3) rem(10);
                        //                 background: rgba(55, 55, 128, 0.2);
                        //                 border-radius: rem(5);
                        //             }

                        //             h5 {
                        //                 font-size: rem(20);
                        //                 font-weight: 400;
                        //                 color: $black_2;
                        //                 white-space: nowrap;

                        //                 // text-decoration: underline;
                        //                 &.space_txt {
                        //                     text-decoration: underline;
                        //                     margin-top: rem(-2);
                        //                     cursor: pointer;
                        //                 }

                        //                 &.date_space {
                        //                     margin-top: rem(-7);
                        //                 }

                        //                 &.date_pp {
                        //                     margin-top: rem(-2);
                        //                 }
                        //             }

                        //             a {
                        //                 font-size: rem(14);
                        //                 font-weight: 500;
                        //                 // color: #8BB612;
                        //                 padding: rem(6) rem(23);
                        //                 border-radius: rem(20);
                        //                 // background: rgba(139, 182, 18, 0.1);

                        //                 &.low {
                        //                     color: #8BB612;
                        //                     background: rgba(139, 182, 18, 0.1);
                        //                 }

                        //                 &.high {
                        //                     color: $fade_pink;
                        //                     background: rgba(233, 97, 97, 0.3);
                        //                 }

                        //                 &.medium {
                        //                     color: $treatment_color;
                        //                     background-color: rgba(255, 135, 23, 0.21);
                        //                     ;
                        //                 }
                        //             }
                        //         }
                        //     }
                        // }
                    }

                    .right_area {
                        width: calc(100% - 470px);
                        float: right;
                        height: 100%;

                        .inner_details {
                            padding-top: rem(116);
                            margin-left: rem(70);
                            position: relative;
                            display: flex;
                            justify-content: flex-end;

                            .img_one {
                                position: absolute;
                                bottom: 0;
                                width: rem(110);
                                height: rem(110);
                                overflow: hidden;
                                border-radius: 50%;
                                cursor: pointer;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    overflow: hidden;
                                }
                            }

                            .img_two {
                                position: absolute;
                                left: 0;
                                margin-left: rem(23);
                                bottom: 0;
                                width: rem(36);
                                height: rem(36);
                                overflow: hidden;
                                border-radius: 50%;
                                cursor: pointer;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    overflow: hidden;
                                }
                            }

                            .img_three_area {
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                margin-left: rem(45);
                                background: #FFB057;
                                border: rem(2) solid $white;
                                border-radius: 50%;
                                width: rem(36);
                                height: rem(36);
                                overflow: hidden;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;

                                .details {
                                    h6 {
                                        font-size: rem(14);
                                        font-weight: 600;
                                        color: $white;
                                    }
                                }
                            }
                        }

                        .edit_area {
                            // margin-top: rem(63);
                            // display: flex;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            margin-right: rem(28);
                            margin-bottom: rem(25);

                            a {
                                font-size: rem(24);
                                font-weight: 600;
                                color: $white;
                                padding: rem(6) rem(25);
                                border-radius: rem(26);
                                word-spacing: rem(2);
                                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);

                                &:hover {
                                    background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                                }
                            }

                        }
                    }

                }
            }


            .description_card {
                box-shadow: 0px 4px 25px rgba(55, 55, 128, 0.12);

                .card-body {
                    padding: rem(30) rem(30) rem(25) rem(50);

                    .description_area {

                        h5 {
                            font-size: rem(20);
                            font-weight: 600;
                            color: $black_2;
                        }

                        p {
                            font-weight: 400;
                            font-size: rem(20);
                            line-height: rem(25);
                            margin-top: rem(17);
                            color: $black_2;
                        }
                    }

                    .description_edit {
                        margin-top: rem(6);
                        float: right;

                        a {
                            font-size: rem(24);
                            font-weight: 600;
                            color: $white;
                            padding: rem(6) rem(25);
                            border-radius: rem(26);
                            word-spacing: rem(2);
                            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);

                            &:hover {
                                background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                            }
                        }

                    }
                }
            }

        }

        .bottom_area {
            a {
                font-size: rem(24);
                font-weight: 600;
                color: $white;
                padding: rem(6) rem(52);
                border-radius: rem(26);
                word-spacing: rem(2);
                margin-bottom: rem(40);
                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);

                &:hover {
                    background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                }
            }

            .card {
                background: #FAFAFA;
                border: rem(1) solid #E7E7E7;
                border-radius: rem(25);
                margin-bottom: rem(20);

                .card-body {
                    padding: 0;
                    padding: rem(55) rem(40);
                    padding-bottom: rem(20);

                    .comment_area {
                        display: flex;
                        gap: rem(19);
                        align-items: center;
                        margin-top: rem(-25);
                        margin-bottom: rem(20);

                        h6 {
                            font-size: rem(20);
                            font-weight: 400;
                            color: $black_2;
                        }

                        .count {
                            width: rem(32);
                            height: rem(32);
                            line-height: rem(32);
                            text-align: center;
                            border-radius: 50%;
                            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);

                            span {
                                font-size: rem(14);
                                font-weight: 500;
                                color: $white;
                            }
                        }


                    }

                    .send_comment {
                        // display: flex;
                        // gap: rem(25);
                        // align-items: baseline;
                        position: relative;
                        display: block;
                        text-align: end;
                        margin-bottom: 10px;

                        .form-group {
                            width: 100%;
                            text-align: start;

                            .form-control {
                                color: $send_comments;
                            }

                            .emoji_icon {
                                position: absolute;
                                right: 0;
                                top: 0;
                                margin-top: rem(21);
                                margin-right: rem(20);
                                font-size: rem(20);
                                cursor: pointer;
                            }
                        }

                        a {
                            width: 30%;
                            padding: rem(9) rem(52);
                            text-align: center;
                        }

                        .btn-primary {
                            width: 10%;
                            padding: rem(9) rem(52);
                            text-align: center;
                            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                            border-radius: 26px;
                            border: none;
                            font-size: rem(24);
                            color: $white;
                            height: rem(53);

                            &:hover {
                                background-color: $dark_red_2;
                                color: $white;
                                opacity: unset;
                                border: none;
                            }
                        }
                    }

                    .comments {
                        padding-bottom: rem(40);

                        .comment_details {
                            display: flex;
                            align-items: center;
                            gap: rem(10);

                            h6 {
                                font-weight: 600;
                                font-size: rem(22);
                                color: $black_2;
                            }

                            p {
                                font-weight: 400;
                                font-size: rem(14);
                                color: #B4BBC6;
                                margin-top: 0;
                            }

                            .image_one {
                                height: rem(40);
                                width: rem(40);
                                border-radius: 50%;
                                overflow: hidden;

                                img {
                                    height: 100%;
                                    width: 100%;
                                    object-fit: cover;
                                }
                            }
                        }

                        p {
                            margin-top: rem(6);
                            font-size: rem(16);
                            line-height: rem(26);
                            color: #272727;
                            font-weight: 400;
                        }

                        .like {
                            margin-top: rem(20);

                            color: #8991A0;

                            span {
                                font-weight: 500;
                                font-size: rem(14);
                                line-height: rem(17);
                            }
                        }
                    }

                }
            }
        }
    }
}


@media(min-width:768px) and (max-width:1023px) {
    .task_detail_section {
        .task_detail_area {
            padding: 0 rem(15);
            margin-top: rem(-35);

            .date_num_area {
                @include clearfix();
                margin-bottom: rem(40);

                .left_date_area {
                    float: left;
                    width: rem(200);
                    display: flex;
                    gap: rem(10);

                    .date_img {
                        width: rem(32);
                        height: rem(31);
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }

                    }


                    p {
                        font-size: rem(24);
                        font-weight: 600;
                        color: $designation;
                    }
                }

                .right_num_area {
                    float: right;
                    width: calc(100% - 200px);
                    text-align: end;

                    p {
                        font-weight: 600;
                        font-size: rem(24);
                        color: $designation;
                    }
                }
            }

            .inner_profile_area {
                @include clearfix();
                margin-bottom: rem(40);
                margin-top: 0px;

                .left_profile_area {
                    float: left;
                    width: rem(106);

                    .img_area {
                        height: rem(106);
                        width: rem(106);
                        border-radius: 50%;
                        overflow: hidden;

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .right_profile_area {
                    float: right;
                    width: calc(100% - 106px);

                    .info_main {
                        @include clearfix();

                        .left_area {
                            float: left;
                            width: calc(100% - rem(200));
                            padding-left: rem(15);

                            h3 {
                                font-weight: 600;
                                font-size: rem(36);
                                background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                                margin-bottom: rem(6);
                                cursor: pointer;
                            }

                            .gender_area {
                                display: flex;
                                align-items: center;
                                margin-bottom: rem(15);

                                p {
                                    font-weight: 400;
                                    font-size: rem(16);
                                    color: $designation;
                                    margin-right: rem(15);

                                    .gen_icon {
                                        color: $dark_red_2;
                                        font-size: rem(14);
                                        margin-right: rem(4);
                                    }
                                }

                                span {
                                    font-size: rem(16);
                                    font-weight: 400;
                                    color: $form_border_color;
                                    padding: rem(3) rem(10);
                                    background: rgba(55, 55, 128, 0.2);
                                    border-radius: rem(5);
                                }
                            }

                            .icons_area {
                                display: flex;

                                p {
                                    font-weight: 400;
                                    font-size: rem(16);
                                    color: $designation;
                                    margin-right: rem(18);

                                    .icon_one {
                                        color: $dark_red_2;
                                        font-size: rem(14);
                                        margin-right: rem(4);
                                    }

                                    .icon_calender {
                                        margin-right: rem(4);
                                        width: rem(18);
                                        height: rem(17);
                                        margin-top: rem(-4);
                                    }
                                }
                            }
                        }

                        .right_area {
                            float: right;
                            width: rem(200);
                            margin-top: rem(20);

                            .text {
                                .p_handle {
                                    width: 100%;
                                    @include clearfix();
                                }

                                .s_handle {
                                    @include clearfix();
                                    width: 100%;
                                }

                                p {
                                    font-size: rem(14);
                                    font-weight: 400;
                                    color: $light_grey;
                                    padding: rem(3) rem(10);
                                    background: rgba(154, 154, 154, 0.2);
                                    border-radius: rem(5);
                                    text-align: center;
                                    float: right;
                                    margin-bottom: rem(15);
                                }

                                span {
                                    &.pending {
                                        float: right;
                                        font-size: rem(16);
                                        font-weight: 600;
                                        color: $white;
                                        padding: rem(13);
                                        background-color: $treatment_color;
                                        border-radius: rem(22);

                                        &:hover {
                                            background: darken($treatment_color, 4%);
                                        }
                                    }

                                    &.complete {
                                        float: right;
                                        font-size: rem(16);
                                        font-weight: 600;
                                        color: $green;
                                        padding: rem(13);
                                        background: rgba(139, 182, 18, 0.15);
                                        border-radius: rem(22);

                                        &:hover {
                                            background: darken(rgba(139, 182, 18, 0.15), 4%);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .patient_information {
                .card {
                    background: $accordin_body;
                    border: rem(1) solid #E7E7E7;
                    border-radius: rem(25);
                    margin-bottom: rem(35);

                    .card-body {
                        padding: rem(24) rem(25) rem(20) rem(40);
                        @include clearfix();
                        position: relative;

                        .left_area {
                            width: rem(470);
                            float: left;
                            @include clearfix();

                            table {
                                tr {

                                    td {
                                        margin-bottom: rem(18);

                                        &.without_space {
                                            margin-bottom: 0;
                                        }

                                        &:nth-child(1) {
                                            min-width: rem(150);
                                        }

                                        &:nth-child(2) {
                                            min-width: rem(200);
                                        }

                                        .details {
                                            margin-bottom: rem(18);

                                            &.without_space {
                                                margin-bottom: 0;
                                            }

                                            h5 {
                                                font-size: rem(20);
                                                font-weight: 600;
                                                color: $black_2;
                                            }
                                        }

                                        .text_con {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: rem(18);

                                            &.without_space {
                                                margin-bottom: 0;
                                            }

                                            span {
                                                font-size: rem(15);
                                                color: $black_2;
                                            }

                                            h5 {
                                                font-size: rem(20);
                                                font-weight: 400;
                                                color: $black_2;
                                                padding-left: rem(6);

                                                &.space_txt {
                                                    text-decoration: underline;
                                                    cursor: pointer;
                                                }
                                            }

                                            a {
                                                font-size: rem(14);
                                                font-weight: 500;
                                                padding: rem(6) rem(23);
                                                border-radius: rem(20);
                                                margin-left: rem(6);

                                                &.low {
                                                    color: #8BB612;
                                                    background: rgba(139, 182, 18, 0.1);
                                                }

                                                &.high {
                                                    color: $fade_pink;
                                                    background: rgba(233, 97, 97, 0.3);
                                                }

                                                &.medium {
                                                    color: $treatment_color;
                                                    background-color: rgba(255, 135, 23, 0.21);
                                                    ;
                                                }
                                            }

                                            p {
                                                font-size: rem(16);
                                                font-weight: 400;
                                                color: #373780;
                                                padding: rem(3) rem(10);
                                                background: rgba(55, 55, 128, 0.2);
                                                border-radius: rem(5);
                                                margin-left: rem(6);
                                            }
                                        }
                                    }
                                }
                            }

                            // .inner_left_area {
                            //     width: rem(200);
                            //     float: left;

                            //     ul {
                            //         li {
                            //             padding: rem(10);

                            //             .details {
                            //                 display: flex;
                            //                 justify-content: space-between;

                            //                 h5 {
                            //                     font-size: rem(20);
                            //                     font-weight: 600;
                            //                     color: $black_2;
                            //                 }
                            //             }
                            //         }
                            //     }
                            // }

                            // .inner_right_area {
                            //     width: calc(100% - 200px);
                            //     float: right;

                            //     ul {
                            //         li {
                            //             padding: rem(10);
                            //             padding-left: rem(5);
                            //             padding-top: rem(12);

                            //             span {
                            //                 font-size: rem(16);
                            //                 font-weight: 400;
                            //                 color: #373780;
                            //                 padding: rem(3) rem(10);
                            //                 background: rgba(55, 55, 128, 0.2);
                            //                 border-radius: rem(5);
                            //             }

                            //             h5 {
                            //                 font-size: rem(20);
                            //                 font-weight: 400;
                            //                 color: $black_2;
                            //                 white-space: nowrap;

                            //                 // text-decoration: underline;
                            //                 &.space_txt {
                            //                     text-decoration: underline;
                            //                     margin-top: rem(-2);
                            //                     cursor: pointer;
                            //                 }

                            //                 &.date_space {
                            //                     margin-top: rem(-7);
                            //                 }

                            //                 &.date_pp {
                            //                     margin-top: rem(-2);
                            //                 }
                            //             }

                            //             a {
                            //                 font-size: rem(14);
                            //                 font-weight: 500;
                            //                 // color: #8BB612;
                            //                 padding: rem(6) rem(23);
                            //                 border-radius: rem(20);
                            //                 // background: rgba(139, 182, 18, 0.1);

                            //                 &.low {
                            //                     color: #8BB612;
                            //                     background: rgba(139, 182, 18, 0.1);
                            //                 }

                            //                 &.high {
                            //                     color: $fade_pink;
                            //                     background: rgba(233, 97, 97, 0.3);
                            //                 }

                            //                 &.medium {
                            //                     color: $treatment_color;
                            //                     background-color: rgba(255, 135, 23, 0.21);
                            //                     ;
                            //                 }
                            //             }
                            //         }
                            //     }
                            // }
                        }

                        .right_area {
                            width: calc(100% - 470px);
                            float: right;
                            height: 100%;

                            .inner_details {
                                padding-top: rem(116);
                                margin-left: rem(70);
                                position: relative;
                                display: block;

                                .img_one {
                                    position: absolute;
                                    bottom: 0;
                                    width: rem(36);
                                    height: rem(36);
                                    overflow: hidden;
                                    border-radius: 50%;
                                    cursor: pointer;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        overflow: hidden;
                                    }
                                }

                                .img_two {
                                    position: absolute;
                                    left: 0;
                                    margin-left: rem(23);
                                    bottom: 0;
                                    width: rem(36);
                                    height: rem(36);
                                    overflow: hidden;
                                    border-radius: 50%;
                                    cursor: pointer;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        overflow: hidden;
                                    }
                                }

                                .img_three_area {
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    margin-left: rem(45);
                                    background: #FFB057;
                                    border: rem(2) solid $white;
                                    border-radius: 50%;
                                    width: rem(36);
                                    height: rem(36);
                                    overflow: hidden;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    cursor: pointer;

                                    .details {
                                        h6 {
                                            font-size: rem(14);
                                            font-weight: 600;
                                            color: $white;
                                        }
                                    }
                                }
                            }

                            .edit_area {
                                // margin-top: rem(63);
                                // display: flex;
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                margin-right: rem(28);
                                margin-bottom: rem(25);

                                a {
                                    font-size: rem(24);
                                    font-weight: 600;
                                    color: $white;
                                    padding: rem(6) rem(25);
                                    border-radius: rem(26);
                                    word-spacing: rem(2);
                                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);

                                    &:hover {
                                        background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                                    }
                                }

                            }
                        }

                    }
                }


                .description_card {
                    box-shadow: 0px 4px 25px rgba(55, 55, 128, 0.12);

                    .card-body {
                        padding: rem(30) rem(30) rem(25) rem(50);

                        .description_area {

                            h5 {
                                font-size: rem(20);
                                font-weight: 600;
                                color: $black_2;
                            }

                            p {
                                font-weight: 400;
                                font-size: rem(20);
                                line-height: rem(25);
                                margin-top: rem(17);
                                color: $black_2;
                            }
                        }

                        .description_edit {
                            margin-top: rem(6);
                            float: right;

                            a {
                                font-size: rem(24);
                                font-weight: 600;
                                color: $white;
                                padding: rem(6) rem(25);
                                border-radius: rem(26);
                                word-spacing: rem(2);
                                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);

                                &:hover {
                                    background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                                }
                            }

                        }
                    }
                }

            }

            .bottom_area {
                a {
                    font-size: rem(24);
                    font-weight: 600;
                    color: $white;
                    padding: rem(6) rem(52);
                    border-radius: rem(26);
                    word-spacing: rem(2);
                    margin-bottom: rem(40);
                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);

                    &:hover {
                        background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                    }
                }

                .card {
                    background: #FAFAFA;
                    border: rem(1) solid #E7E7E7;
                    border-radius: rem(25);
                    margin-bottom: rem(20);

                    .card-body {
                        padding: 0;
                        padding: rem(55) rem(40);
                        padding-bottom: rem(20);

                        .comment_area {
                            display: flex;
                            gap: rem(19);
                            align-items: center;
                            margin-top: rem(-25);
                            margin-bottom: rem(20);

                            h6 {
                                font-size: rem(20);
                                font-weight: 400;
                                color: $black_2;
                            }

                            .count {
                                width: rem(32);
                                height: rem(32);
                                line-height: rem(32);
                                text-align: center;
                                border-radius: 50%;
                                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);

                                span {
                                    font-size: rem(14);
                                    font-weight: 500;
                                    color: $white;
                                }
                            }


                        }

                        .send_comment {
                            // display: flex;
                            // gap: rem(25);
                            // align-items: baseline;
                            position: relative;
                            display: block;
                            text-align: end;
                            margin-bottom: 10px;

                            .form-group {
                                width: 100%;
                                text-align: start;

                                .form-control {
                                    color: $send_comments;
                                }

                                .emoji_icon {
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    margin-top: rem(21);
                                    margin-right: rem(20);
                                    font-size: rem(20);
                                    cursor: pointer;
                                }
                            }

                            a {
                                width: 30%;
                                padding: rem(9) rem(52);
                                text-align: center;
                            }

                            .btn-primary {
                                width: 30%;
                                padding: rem(9) rem(52);
                                text-align: center;
                                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                border-radius: 26px;
                                border: none;
                                font-size: rem(24);
                                color: $white;
                                height: rem(53);

                                &:hover {
                                    background-color: $dark_red_2;
                                    color: $white;
                                    opacity: unset;
                                    border: none;
                                }
                            }
                        }

                        .comments {
                            padding-bottom: rem(40);

                            .comment_details {
                                display: flex;
                                align-items: center;
                                gap: rem(10);

                                h6 {
                                    font-weight: 600;
                                    font-size: rem(22);
                                    color: $black_2;
                                }

                                p {
                                    font-weight: 400;
                                    font-size: rem(14);
                                    color: #B4BBC6;
                                    margin-top: 0;
                                }

                                .image_one {
                                    height: rem(40);
                                    width: rem(40);
                                    border-radius: 50%;
                                    overflow: hidden;

                                    img {
                                        height: 100%;
                                        width: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }

                            p {
                                margin-top: rem(6);
                                font-size: rem(16);
                                line-height: rem(26);
                                color: #272727;
                                font-weight: 400;
                            }

                            .like {
                                margin-top: rem(20);

                                color: #8991A0;

                                span {
                                    font-weight: 500;
                                    font-size: rem(14);
                                    line-height: rem(17);
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .task_detail_section {
        .task_detail_area {
            .inner_profile_area {
                margin-top: 0;

                .right_profile_area {
                    .info_main {
                        .left_area {
                            h3 {
                                font-size: rem(30);
                            }
                        }

                        .right_area {
                            float: left;
                            width: 100%;
                            margin-left: rem(15);

                            .text {
                                display: flex;
                                gap: rem(25);

                                .p_handle {
                                    display: flex;
                                    justify-content: flex-start;

                                    p {
                                        padding: 6px 10px;
                                        align-self: flex-start;
                                    }
                                }

                                // align-items: baseline;
                            }
                        }
                    }
                }
            }

            .patient_information {
                .card {
                    .card-body {
                        .right_area {
                            float: left;
                            width: 100%;

                            .inner_details {
                                padding-top: rem(60);
                                margin-left: rem(15);
                                display: block;

                                .img_one {
                                    width: 50px;
                                    height: 50px;
                                }
                            }

                            .edit_area {
                                a {
                                    font-size: rem(20);
                                }
                            }
                        }
                    }
                }

                .description_card {
                    .card-body {
                        padding: rem(30);

                        .description_area {
                            p {
                                font-size: rem(18);
                                line-height: rem(24);
                            }
                        }

                        .description_edit {
                            a {
                                font-size: rem(20);
                            }
                        }
                    }
                }
            }

            .bottom_area {
                a {
                    font-size: rem(20);
                    padding: rem(6) rem(20);
                    margin-bottom: rem(30);
                }

                .card {
                    .card-body {
                        .send_comment {
                            a {
                                width: 20%;
                                padding: rem(6) rem(20);
                                text-align: center;
                            }

                            .btn-primary {
                                width: 26%;
                                padding: rem(6) rem(20);
                                font-size: 22px;
                                line-height: 30px;
                                height: 45px;
                            }

                            .form-group {
                                .emoji_icon {
                                    margin-top: 18px;
                                    margin-right: 18px;
                                }
                            }
                        }

                        .comments {
                            .comment_details {
                                h6 {
                                    font-size: 20px;
                                }

                                p {
                                    margin-top: 2px;
                                }
                            }

                            p {
                                font-size: 14px;
                                line-height: 21px;
                            }
                        }
                    }
                }
            }
        }
    }
}




/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .task_detail_section {
        .task_detail_area {

            .date_num_area {
                margin-bottom: 25px;

                .left_date_area {
                    width: 140px;

                    .date_img {
                        width: 20px;
                        height: 20px;
                    }

                    p {
                        font-size: 18px;
                    }
                }

                .right_num_area {
                    width: calc(100% - 140px);

                    p {
                        font-size: 18px;
                    }
                }
            }

            .inner_profile_area {
                margin-bottom: 20px;
                margin-top: 0;

                .right_profile_area {
                    .info_main {
                        .left_area {
                            h3 {
                                font-size: 24px;
                            }

                            .icons_area {
                                p {
                                    font-size: 12px;
                                    margin-right: 5px;

                                    .icon_calender {
                                        margin-right: 2px;
                                        width: 10px;
                                        height: 12px;
                                    }

                                    .icon_one {
                                        font-size: 10px;
                                    }
                                }
                            }

                        }

                        .right_area {
                            float: left;
                            width: 100%;

                            .text {
                                gap: 15px;
                                display: flex;
                                align-items: baseline;

                                p {
                                    font-size: 12px;
                                    float: left;
                                }

                                span {
                                    font-size: 12px;
                                    padding: 10px;
                                }
                            }
                        }
                    }
                }
            }

            .patient_information {
                .card {
                    border-radius: 10px;
                    margin-bottom: 30px;

                    .card-body {
                        padding: 10px;

                        .left_area {
                            width: 100%;

                            table {
                                tr {
                                    td {
                                        &:nth-child(1) {
                                            min-width: rem(112);
                                        }

                                        &:nth-child(2) {
                                            min-width: auto;
                                        }

                                        .details {
                                            margin-bottom: rem(15);

                                            h5 {
                                                font-size: rem(16);
                                            }
                                        }

                                        .text_con {
                                            margin-bottom: rem(15);

                                            h5 {
                                                font-size: 16px;
                                            }

                                            p {
                                                font-size: rem(14);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .right_area {
                            float: left;
                            width: 100%;

                            .inner_details {
                                padding-top: 60px;
                                margin-left: 15px;
                                display: block;

                                .img_one {
                                    width: 40px;
                                    height: 40px;
                                }
                            }

                            .edit_area {
                                margin-right: 18px;
                                margin-bottom: 13px;

                                a {
                                    font-size: rem(16);
                                }
                            }
                        }
                    }
                }

                .description_card {
                    .card-body {
                        padding: 20px;

                        .description_area {

                            h5 {
                                font-size: 18px;
                            }

                            p {
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }

                        .description_edit {
                            a {
                                font-size: rem(16);
                            }
                        }
                    }
                }
            }

            .bottom_area {
                a {
                    font-size: 16px;
                    padding: 6px 20px;
                    margin-bottom: 25px;
                }

                .card {
                    .card-body {
                        padding: 20px;

                        .comment_area {
                            margin-top: 0;

                            h6 {
                                font-size: 18px;
                            }
                        }

                        .send_comment {
                            display: block;

                            a {
                                width: 30%;
                                padding: 6px 20px;
                                text-align: center;
                            }

                            .btn-primary {
                                width: 45%;
                                padding: rem(6) rem(20);
                                font-size: 20px;
                                line-height: 30px;
                                height: 45px;
                            }

                            .form-group {
                                width: auto;

                                .form-control {
                                    font-size: 16px;
                                }

                                .emoji_icon {
                                    margin-top: 18px;
                                    margin-right: 18px;
                                }
                            }
                        }

                        .comments {
                            padding-bottom: 25px;

                            .comment_details {
                                h6 {
                                    font-size: 16px;
                                }

                                .image_one {
                                    height: rem(35);
                                    width: rem(35);
                                }
                            }

                            p {
                                font-size: 14px;
                                line-height: 21px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .task_detail_section {
        .task_detail_area {

            .date_num_area {
                margin-bottom: 25px;

                .left_date_area {
                    width: 140px;

                    .date_img {
                        width: 20px;
                        height: 20px;
                    }

                    p {
                        font-size: 18px;
                    }
                }

                .right_num_area {
                    width: calc(100% - 140px);

                    p {
                        font-size: 18px;
                    }
                }
            }

            .inner_profile_area {
                margin-bottom: 20px;
                margin-top: 0;

                .right_profile_area {
                    .info_main {
                        .left_area {
                            h3 {
                                font-size: 24px;
                            }

                            .icons_area {
                                p {
                                    font-size: 12px;
                                    margin-right: 5px;

                                    .icon_calender {
                                        margin-right: 2px;
                                        width: 10px;
                                        height: 12px;
                                    }

                                    .icon_one {
                                        font-size: 10px;
                                    }
                                }
                            }

                        }

                        .right_area {
                            float: left;
                            width: 100%;

                            .text {
                                gap: 15px;
                                display: flex;
                                align-items: baseline;

                                p {
                                    font-size: 12px;
                                    float: left;
                                }

                                span {
                                    font-size: 12px;
                                    padding: 10px;
                                }
                            }
                        }
                    }
                }
            }

            .patient_information {
                .card {
                    border-radius: 10px;
                    margin-bottom: 30px;

                    .card-body {
                        padding: 10px;

                        .left_area {
                            width: 100%;

                            table {
                                tr {
                                    td {
                                        &:nth-child(1) {
                                            min-width: rem(112);
                                        }

                                        &:nth-child(2) {
                                            min-width: auto;
                                        }

                                        .details {
                                            margin-bottom: rem(15);

                                            h5 {
                                                font-size: rem(16);
                                            }
                                        }

                                        .text_con {
                                            margin-bottom: rem(15);

                                            h5 {
                                                font-size: 16px;
                                            }

                                            p {
                                                font-size: rem(14);
                                            }
                                        }
                                    }
                                }
                            }

                            // .inner_left_area {
                            //     width: 135px;

                            //     ul {
                            //         li {
                            //             .details {
                            //                 h5 {
                            //                     font-size: 16px;
                            //                 }
                            //             }
                            //         }
                            //     }
                            // }

                            // .inner_right_area {
                            //     width: calc(100% - 135px);

                            //     ul {
                            //         li {
                            //             h5 {
                            //                 font-size: 14px;

                            //                 &.space_txt {
                            //                     margin-top: rem(1);
                            //                 }

                            //                 &.date_space {
                            //                     margin-top: rem(-6);
                            //                 }

                            //                 &.date_pp {
                            //                     margin-top: 0;
                            //                 }
                            //             }

                            //             span {
                            //                 font-size: rem(14);
                            //             }

                            //             a {
                            //                 margin-top: rem(-4);
                            //             }
                            //         }
                            //     }
                            // }
                        }

                        .right_area {
                            float: left;
                            width: 100%;

                            .inner_details {
                                padding-top: 60px;
                                margin-left: 15px;
                                display: block;

                                .img_one {
                                    width: 40px;
                                    height: 40px;
                                }
                            }

                            .edit_area {
                                margin-right: 18px;
                                margin-bottom: 13px;

                                a {
                                    font-size: rem(16);
                                }
                            }
                        }
                    }
                }

                .description_card {
                    .card-body {
                        padding: 20px;

                        .description_area {

                            h5 {
                                font-size: 18px;
                            }

                            p {
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }

                        .description_edit {
                            a {
                                font-size: rem(16);
                            }
                        }
                    }
                }
            }

            .bottom_area {
                a {
                    font-size: 16px;
                    padding: 6px 20px;
                    margin-bottom: 25px;
                }

                .card {
                    .card-body {
                        padding: 20px;

                        .comment_area {
                            margin-top: 0;

                            h6 {
                                font-size: 18px;
                            }
                        }

                        .send_comment {
                            display: block;

                            a {
                                width: 30%;
                                padding: 6px 20px;
                                text-align: center;
                            }

                            .btn-primary {
                                width: 45%;
                                padding: rem(6) rem(20);
                                font-size: 20px;
                                line-height: 30px;
                                height: 45px;
                            }

                            .form-group {
                                width: auto;

                                .form-control {
                                    font-size: 16px;
                                }

                                .emoji_icon {
                                    margin-top: 18px;
                                    margin-right: 18px;
                                }
                            }
                        }

                        .comments {
                            padding-bottom: 25px;

                            .comment_details {
                                h6 {
                                    font-size: 16px;
                                }

                                .image_one {
                                    height: rem(35);
                                    width: rem(35);
                                }
                            }

                            p {
                                font-size: 14px;
                                line-height: 21px;
                            }
                        }
                    }
                }
            }
        }
    }
}