.pagination_screen {
    padding: rem(10) 0 rem(40) 0;

    ul {
        &.pagination {
            display: flex;
            align-items: center;
            justify-content: center;

            li {
                &.page-item {
                    &:hover {
                        background-color: transparent;
                    }

                    a {
                        font-size: rem(20);
                        font-weight: 400;

                        &.page-link {
                            border: none;
                            color: $cancel_btn_color;
                        }
                    }

                    &.active {

                        span {
                            &.page-link {
                                background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                                border: none;
                                font-size: rem(20);
                                font-weight: 400;
                            }
                        }
                    }
                }

                &.pagination_buttons {
                    &.page-item {
                        a {
                            &.page-link {
                                font-size: rem(55);
                                margin-top: rem(-9);

                                &:hover {
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}