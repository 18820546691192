.nurse_list_screen {
    overflow-y: scroll;
    height: 550px;
    padding-bottom: 50px;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
        -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
        background-color: #A7A7A7;
    }

    &::-webkit-scrollbar {
        width: 7px;
        background-color: #FFC2C2;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #FFC2C2;
        border: 1px solid #FFC2C2;
    }

    .nurse_details {
        padding: 18px 50px;
        background: transparent;
        border-width: 1px 0px;
        border-style: solid;
        border-color: #E7E7E7;
        border-radius: 0px;
        display: flex;
        align-items: center;
        @include clearfix();

        &:active,
        &.active,
        &:hover {
            background: $light_red;
        }

        .left_area {
            width: 70px;
            float: left;

            .img_one {
                width: 70px;
                height: 70px;
                overflow: hidden;
                border-radius: 50%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .right_area {
            width: calc(100% - 70px);
            float: right;
            padding-left: 9px;

            h3 {
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                margin-bottom: 10px;
            }

            .tag {
                p {

                    width: auto;
                    height: auto;
                    background: $light_blue;
                    border-radius: 6px;
                    font-weight: 500;
                    font-size: 14px;
                    color: $blue;
                    padding: 2px 8px;
                    cursor: pointer;
                    display: inline-block;
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .nurse_list_screen {
        .nurse_details {
            padding: 15px 25px;

            .left_area {
                .img_one {
                    width: 60px;
                    height: 60px;
                }
            }

            .right_area {
                h3 {
                    font-size: 20px;
                    margin-bottom: 4px;
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .nurse_list_screen {
        .nurse_details {
            padding: 15px 25px;

            .left_area {
                .img_one {
                    width: 60px;
                    height: 60px;
                }
            }

            .right_area {
                h3 {
                    font-size: 18px;
                    margin-bottom: 2px;
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .nurse_list_screen {
        .nurse_details {
            padding: 15px 25px;

            .left_area {
                .img_one {
                    width: 60px;
                    height: 60px;
                }
            }

            .right_area {
                h3 {
                    font-size: 18px;
                    margin-bottom: 2px;
                }
            }
        }
    }
}