.history_tab_screen {
    padding: rem(45) rem(20);

    .card {
        background: $card_color;
        border: rem(1) solid $grey;
        border-radius: rem(25);
        margin-bottom: rem(15);

        .card-body {
            text-transform: capitalize;
            @include clearfix();

            .card_left {
                float: left;
                width: rem(136);

                .img_area {
                    width: rem(136);
                    height: rem(137);
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .card_right {
                float: right;
                width: calc(100% - 136px);
                padding-left: rem(25);

                .personal_data {
                    .name_number_area {
                        @include clearfix();

                        h3 {
                            float: left;
                            font-size: rem(22);
                            font-weight: 600;
                            background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }

                        span {
                            float: right;
                            color: $card_text_color;
                            font-weight: 400;
                            font-size: rem(16);
                            display: flex;
                            gap: rem(20);

                            .ellipsis_icon {
                                font-size: rem(27);
                                cursor: pointer;
                            }
                        }
                    }

                    h4 {
                        font-weight: 400;
                        font-size: rem(20);
                        color: $form_border_color;
                        padding-top: rem(10);
                        padding-bottom: rem(10);
                        word-spacing: rem(2);
                    }

                    p {
                        color: $card_text_color;
                        font-size: rem(16);
                        font-weight: 400;

                        .gender_icon {
                            margin-left: rem(10);
                        }
                    }

                    .date_information {
                        position: relative;
                        @include clearfix();

                        .date {
                            display: flex;
                            gap: rem(10);
                            align-items: center;
                            float: left;
                            position: absolute;
                            bottom: 0;
                            margin-bottom: rem(5);

                            .date_icon {
                                color: #D81B23;
                            }
                        }

                        .btn {
                            float: right;
                            border-radius: rem(30);
                            font-weight: 600;
                            font-size: rem(16);
                            height: auto;
                            width: auto;
                            margin: 0;
                            padding: rem(12);
                            line-height: initial;
                            background-color: #E7E7E7;
                            color: #989898;
                            border: none;

                            &:hover,
                            &:focus {
                                opacity: 0.6;
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .history_tab_screen {
        .card {
            .card-body {
                .card_right {
                    .personal_data {
                        .name_number_area {
                            h3 {
                                float: left;
                                font-size: rem(18);
                            }

                            span {
                                font-size: rem(13);

                                .ellipsis_icon {
                                    font-size: rem(23);
                                }
                            }
                        }

                        h4 {
                            font-size: rem(17);
                        }

                        .date_information {
                            .btn {
                                font-size: rem(14);
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .history_tab_screen {
        margin-top: rem(10);

        .card {
            .card-body {
                .card_left {
                    width: rem(65);

                    .img_area {
                        width: rem(65);
                        height: rem(70);
                    }
                }

                .card_right {
                    width: calc(100% - 65px);
                    padding-left: rem(20);

                    .personal_data {
                        .name_number_area {
                            h3 {
                                font-size: rem(17);
                            }

                            span {
                                font-size: rem(15);
                                margin-top: rem(4);

                                .ellipsis_icon {
                                    font-size: rem(23);
                                }
                            }
                        }

                        h4 {
                            font-size: rem(14);
                            padding-top: rem(6);
                            padding-bottom: rem(5);
                            word-spacing: 0;
                        }

                        .date_information {
                            .date {
                                position: relative;
                                top: 0;
                                margin-top: rem(5);
                            }

                            .btn {
                                float: left;
                                font-size: rem(14);
                                margin-top: rem(15);
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .history_tab_screen {
        margin-top: rem(10);

        .card {
            .card-body {
                .card_left {
                    width: rem(65);

                    .img_area {
                        width: rem(65);
                        height: rem(70);
                    }
                }

                .card_right {
                    width: calc(100% - 65px);
                    padding-left: rem(20);

                    .personal_data {
                        .name_number_area {
                            h3 {
                                font-size: rem(17);
                            }

                            span {
                                font-size: rem(15);
                                margin-top: rem(4);

                                .ellipsis_icon {
                                    font-size: rem(23);
                                }
                            }
                        }

                        h4 {
                            font-size: rem(14);
                            padding-top: rem(6);
                            padding-bottom: rem(5);
                            word-spacing: 0;
                        }

                        .date_information {
                            .date {
                                position: relative;
                                top: 0;
                                margin-top: rem(5);
                            }

                            .btn {
                                float: left;
                                font-size: rem(14);
                                margin-top: rem(15);
                            }
                        }
                    }
                }
            }
        }
    }
}