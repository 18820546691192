.consultant_tab_screen {
    margin-top: rem(43);
    padding-bottom: rem(45);

    form {
        .btn_area {
            margin-top: rem(40);
        }

        .form-group {
            .form-select {
                color: #7A7A7A;
            }
        }
    }

}


/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .consultant_tab_screen {
        margin-top: rem(40);
        padding-bottom: rem(40);

        form {
            .btn_area {
                margin-top: rem(0);
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .consultant_tab_screen {
        margin-top: rem(40);
        padding-bottom: rem(40);

        form {
            .btn_area {
                margin-top: rem(0);
            }
        }
    }
}


/* Smaller phones */
@media (max-width: rem(360)) {
    .consultant_tab_screen {
        margin-top: rem(40);
        padding-bottom: rem(40);

        form {
            .btn_area {
                margin-top: rem(0);
            }
        }
    }
}