.logs_tab_screen {
    padding-left: 0;
    margin-top: rem(15);

    .main_log {
        @include clearfix();

        .title_area {
            @include clearfix();
            display: flex;
            align-items: center;

            .left_title {
                float: left;
                position: relative;
                background-color: $white;
                width: 100%;

                p {
                    font-weight: 400;
                    font-size: rem(18);
                    color: $designation;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: rem(51);
                    width: rem(2);
                    background-color: $white;
                    z-index: 999;
                    // margin-top: rem(8);
                    margin-top: rem(2);
                    margin-left: rem(30);
                }
            }

            .right_title {
                float: right;
                display: flex;
                align-items: center;
                white-space: nowrap;

                p {
                    font-weight: 400;
                    font-size: rem(18);
                    color: $designation;
                }

                .icon {
                    width: rem(30);
                    margin-left: rem(10);

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .date_area {
            margin-top: rem(7);
            // margin-left: rem(100);
            // background: #6284FF;

            p {
                font-weight: 400;
                font-size: rem(16);
                color: #000000;
                text-align: center;

            }
        }

        .main_area {
            @include clearfix();
            margin-top: rem(5);
            margin-bottom: rem(25);

            .left_area {
                float: left;
                width: rem(80);
                position: relative;

                p {
                    font-weight: 400;
                    font-size: rem(16);
                    color: $designation;
                }

                &:before {
                    content: '';
                    position: absolute;
                    border-left: rem(1) dashed $card_text_color;
                    height: 100%;
                    left: 0;
                    bottom: 0;
                    margin-bottom: rem(55);
                    margin-left: rem(30);
                }

                .check_icon {
                    color: #6284FF;
                    font-size: rem(26);
                    margin-left: rem(18);
                    z-index: 2;
                }
            }

            .right_area {
                float: right;
                width: calc(100% - 90px);
                background: #FFF9F9;
                border: rem(1) solid #E7E7E7;
                border-radius: rem(25);
                padding: rem(15);
                @include clearfix();
                display: flex;
                align-items: center;

                .inner_left_area {
                    float: left;
                    position: relative;
                    width: 38%;
                    @include clearfix();
                    display: flex;
                    align-items: center;

                    .left_img_area {
                        float: left;
                        width: rem(51);
                        height: rem(51);
                        border-radius: 50%;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                    }

                    .right_name_area {
                        float: left;
                        margin-left: rem(15);
                        width: calc(100% - 60px);

                        p {
                            font-weight: 500;
                            font-size: rem(14);
                            color: $profile_info;
                            word-break: break-word;

                            &.label {
                                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                                text-fill-color: transparent;
                                cursor: pointer;
                            }

                            span {
                                color: $black_2;
                                font-weight: 500;
                            }

                        }

                    }

                }

                .inner_right_area {
                    float: right;
                    width: calc(100% - 38%);

                    p {
                        font-weight: 400;
                        font-size: rem(14);
                        color: $designation;
                        line-height: rem(22);
                        word-break: break-word;

                        span {
                            color: $black_2;
                            font-weight: 500;
                        }
                    }

                    .comment {
                        font-size: rem(16);
                        margin-top: rem(10);
                        margin-bottom: rem(10);

                        p {
                            margin: 0;
                            text-transform: capitalize;

                            b {
                                font-weight: bold;
                            }
                        }
                    }

                }
            }

        }
    }


}


@media (min-width: rem(361)) and (max-width: rem(575)) {
    .logs_tab_screen {
        .main_log {
            .main_area {
                &:last-child {
                    .left_area {
                        &:before {
                            content: none;
                        }
                    }
                }

                .left_area {
                    p {
                        background: #fff;
                    }

                    &:before {
                        margin-bottom: -124px;
                        height: 122px;
                    }
                }

                .right_area {
                    display: block;

                    .inner_left_area {
                        float: left;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        margin-bottom: rem(5);

                        .right_name_area {
                            float: unset;
                            width: 100%;
                            margin-left: 10px;
                        }
                    }

                    .inner_right_area {
                        float: unset;
                        width: 100%;
                    }

                }
            }
        }

    }
}

@media (max-width: rem(360)) {
    .logs_tab_screen {
        .main_log {
            .main_area {
                &:last-child {
                    .left_area {
                        &:before {
                            content: none;
                        }
                    }
                }

                .left_area {
                    p {
                        background: #fff;
                    }

                    &:before {
                        margin-bottom: -124px;
                        height: 122px;
                    }
                }

                .right_area {
                    display: block;

                    .inner_left_area {
                        float: left;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        margin-bottom: rem(5);

                        .right_name_area {
                            float: unset;
                            width: 100%;
                            margin-left: 10px;
                        }


                    }

                    .inner_right_area {
                        float: unset;
                        width: 100%;
                    }

                }
            }
        }

    }

}