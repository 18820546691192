.add_patient_screen {
    form {
        padding-left: rem(30);
        padding-right: rem(30);

        .patient_profile_area {
            position: relative;
            margin-top: rem(90);
            margin-bottom: rem(60);

            .patient_profile {
                width: rem(175);
                height: rem(175);
                margin: 0 auto;
                overflow: hidden;
                border-radius: 50%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .change_profile {
                position: absolute;
                width: rem(42);
                height: rem(42);
                border-radius: 50%;
                overflow: hidden;
                bottom: 0;
                left: 0;
                margin-left: 55%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .form-group {
            margin-bottom: rem(20);

            .form-label {
                span {
                    color: $critical_color;
                }
            }

            .form-select {
                color: #7A7A7A;
            }

            .date_img {
                position: absolute;
                right: 0;
                bottom: 0;
                margin-bottom: rem(19);
                margin-right: rem(19);
                width: rem(22);
                height: rem(24);
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .form-select {
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }

        .emergency_checkbox {
            &.form-check {
                display: flex;
                align-items: center;
                margin-bottom: rem(40);
                margin-top: rem(35);

                .form-check-label {
                    font-size: rem(22);
                    font-weight: 600;
                    color: $critical_color;
                }

                .form-check-input {
                    width: rem(26);
                    height: rem(26);
                    margin-right: rem(10);
                    background-color: $white;
                    border: 1px solid $dark_red_2;

                    &:checked {
                        border-color: $critical_color;
                        background-image: url('../../../assets/images/vector.png');
                        outline: none;
                    }

                    &:focus {
                        outline: none;
                        border-color: $dark_red_2;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}



/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {}


@media (min-width: 1024px) and (max-width: 1199px) {
    .add_hospital_area {
        .hospital_content_area {
            .form_section {
                .btn_area {
                    gap: 20px;
                }
            }
        }
    }
}


@media(min-width:768px) and (max-width:1023px) {
    .add_patient_screen {
        form {
            padding-left: rem(20);
            padding-right: rem(20);

            .patient_profile_area {
                position: relative;
                margin-top: rem(0);
                margin-bottom: rem(60);

                .patient_profile {
                    width: rem(175);
                    height: rem(175);
                    margin: 0 auto;
                    overflow: hidden;
                    border-radius: 50%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .change_profile {
                    position: absolute;
                    width: rem(42);
                    height: rem(42);
                    border-radius: 50%;
                    overflow: hidden;
                    bottom: 0;
                    left: 0;
                    margin-left: 55%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .form-group {
                margin-bottom: rem(20);

                .form-label {
                    span {
                        color: $critical_color;
                    }
                }

                .form-select {
                    color: #7A7A7A;
                }

                .date_img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    margin-bottom: rem(19);
                    margin-right: rem(19);
                    width: rem(22);
                    height: rem(24);
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .form-select {
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }

            .emergency_checkbox {
                &.form-check {
                    display: flex;
                    align-items: center;
                    margin-bottom: rem(40);
                    margin-top: rem(35);

                    .form-check-label {
                        font-size: rem(22);
                        font-weight: 600;
                        color: $critical_color;
                    }

                    .form-check-input {
                        width: rem(26);
                        height: rem(26);
                        margin-right: rem(10);
                        background-color: $white;
                        border: 1px solid $dark_red_2;

                        &:checked {
                            border-color: $critical_color;
                            background-image: url('../../../assets/images/vector.png');
                            outline: none;
                        }

                        &:focus {
                            outline: none;
                            border-color: $dark_red_2;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }

}



/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .add_patient_screen {
        form {
            padding-left: rem(20);
            padding-right: rem(20);

            .patient_profile_area {
                margin-top: rem(0);
                margin-bottom: rem(40);

                .patient_profile {
                    width: rem(150);
                    height: rem(150);
                }
            }

            .form-group {
                .date_img {
                    margin-bottom: rem(15);
                }
            }

            .emergency_checkbox {
                &.form-check {
                    .form-check-input {
                        width: rem(20);
                        height: rem(20);
                    }

                    .form-check-label {
                        font-size: rem(21);
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .add_patient_screen {
        form {
            padding-left: 0;
            padding-right: 0;

            .patient_profile_area {
                margin-top: rem(0);
                margin-bottom: rem(40);

                .patient_profile {
                    width: rem(130);
                    height: rem(130);
                }

                .change_profile {
                    width: rem(35);
                    height: rem(35);
                    margin-bottom: rem(-8);
                    margin-left: 56%;
                }

                .img_icon_area {
                    .icon_area {
                        margin-right: rem(124);
                    }
                }
            }

            .form-group {
                .date_img {
                    margin-bottom: rem(15);
                }
            }

            .emergency_checkbox {
                &.form-check {
                    .form-check-input {
                        width: rem(20);
                        height: rem(20);
                    }

                    .form-check-label {
                        font-size: rem(21);
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .add_patient_screen {
        form {
            padding-left: 0;
            padding-right: 0;

            .patient_profile_area {
                margin-top: rem(0);
                margin-bottom: rem(40);

                .patient_profile {
                    width: rem(130);
                    height: rem(130);
                }

                .change_profile {
                    width: rem(35);
                    height: rem(35);
                    margin-bottom: rem(-8);
                    margin-left: 56%;
                }
            }

            .form-group {
                .date_img {
                    margin-bottom: rem(15);
                }
            }

            .emergency_checkbox {
                &.form-check {
                    .form-check-input {
                        width: rem(20);
                        height: rem(20);
                    }

                    .form-check-label {
                        font-size: rem(21);
                    }
                }
            }
        }
    }
}