.role_list_screen {
    &.ward_list_area {
        .role_list_details {
            .title_btn_area {
                margin-top: rem(25);
                // margin-top: rem(60);
            }

            .search_section {
                .dropdown {
                    .dropdown-toggle:hover {
                        background-color: white !important;
                    }

                    .btn:focus {
                        background-color: white !important;
                    }
                }

                .pagination_list {
                    padding-top: 30px;
                    margin-left: 20px;

                    .form-group {
                        .form-select {
                            width: 90px;
                            color: $designation;
                            font-size: rem(20);

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }

                .filter_button {
                    position: relative;

                    .btn-primary {
                        float: right;
                        width: rem(124);
                        height: rem(60);
                        font-size: rem(22);
                        font-weight: 400;
                        border-radius: rem(10);
                        background: rgba(255, 0, 0, 0.15);
                        border: rem(1.5) solid rgba(255, 0, 0, 0.4);
                        color: #140745;
                    }

                    .btn-border {
                        background-color: $white;
                        border: rem(1.5) solid $form_border_color;
                    }

                    .filter_icon {
                        position: absolute;
                        right: 0;
                        top: 0;
                        margin-top: 10px;
                        margin-right: 10px;
                        font-size: 7px;
                        color: $dark_red_2;
                    }
                }

                .dropdown-toggle::after {
                    content: none;
                }

                .sort_button {
                    .btn-primary {
                        border: rem(2) solid $designation;
                        color: $designation;
                        background: $white;
                        font-size: rem(20);
                        height: rem(60);
                        width: rem(124);
                    }

                    .image_area {
                        height: rem(22);
                        padding-left: rem(15);
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .sort_button1 {
                    .btn-primary {
                        border: rem(2) solid $designation;
                        color: $designation;
                        background: rgba(255, 0, 0, 0.15);
                        border: rem(1.5) solid rgba(255, 0, 0, 0.4);
                        font-size: rem(20);
                        height: rem(60);
                        width: rem(124);
                    }

                    .image_area {
                        height: rem(22);
                        padding-left: rem(15);
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }

            .list_detail {
                .table-responsive {
                    overflow-x: auto;
                    min-height: rem(265);

                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                        -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                        background-color: $grey;
                    }

                    &::-webkit-scrollbar {
                        width: 2px;
                        height: 6px;
                        background-color: $dark_red_2;
                        margin: 0 rem(20);
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: $dark_red_2;
                    }

                    table {
                        overflow: visible;

                        tr {
                            th {
                                text-align: left;
                                padding-right: 10px;

                                &:nth-child(1) {
                                    min-width: rem(240);
                                    padding-left: rem(30);
                                }

                                &:nth-child(2) {
                                    min-width: rem(160);
                                }

                                &:nth-child(3) {
                                    min-width: rem(140);
                                }

                                &:nth-child(4) {
                                    min-width: rem(180);
                                }

                                &:nth-child(5) {
                                    min-width: rem(100);
                                }
                            }

                            &:hover {
                                td {
                                    background: rgba(232, 232, 232, 0.21);

                                    &::before {
                                        background-color: $dark_red_2;
                                    }
                                }
                            }
                        }

                        td {
                            text-align: start;
                            padding-right: rem(15);
                            background-color: transparent;

                            &.test {
                                background-color: transparent;
                            }

                            &:first-child {
                                padding-left: rem(30);
                            }


                            &.text_area {
                                font-size: 16px;
                                font-weight: 600;
                                color: $designation;
                            }

                            .icon {
                                .dropdown-menu {
                                    padding: 0;
                                    border-radius: 12px 0 12px 12px;
                                    background: #ffffff;
                                    transform: translate(-140px, 30px) !important;
                                    margin-bottom: 10px;
                                }
                            }

                            .switch_area {
                                padding-left: rem(0);
                                background-color: transparent;

                                .form-switch {
                                    display: flex;
                                    align-items: center;

                                    .form-check-input {
                                        background-color: rgba(233, 97, 97, 0.4);
                                        border: 1px solid;
                                        border-color: rgba(233, 97, 97, 0.4);
                                        background-image: url('../../images/c_red.png');

                                        &:focus {
                                            box-shadow: none;
                                            border-color: $grey;
                                        }

                                        &:checked {
                                            border: 1px solid;
                                            background-color: rgba(120, 182, 18, 0.2);
                                            border-color: rgba(120, 182, 18, 0.2);
                                            background-image: url('../../images/c_green.png');
                                        }
                                    }

                                    .form-check-label {
                                        color: #E96161;
                                        font-size: 14px;
                                        font-weight: 500;
                                        margin-left: rem(5);
                                        margin-top: rem(2);
                                    }

                                    .form-check-input:checked~.form-check-label {
                                        color: #8BB612;
                                    }
                                }
                            }

                            .label {
                                font-size: rem(14);
                                font-weight: 500;
                                color: $form_border_color;
                                padding: rem(10);
                                background-color: $light_bg;
                                border-radius: rem(8);
                                display: inline-block;
                            }

                            .profile_info {
                                @include clearfix();
                                display: flex;
                                align-items: center;
                                background-color: transparent;
                                // padding-left: rem(30);

                                .left_area {
                                    float: left;
                                    width: rem(50);

                                    .img_profile {
                                        height: rem(50);
                                        width: rem(50);
                                        border-radius: 50%;
                                        overflow: hidden;

                                        img {
                                            height: 100%;
                                            width: 100%;
                                            object-fit: cover;
                                        }
                                    }
                                }

                                .right_area {
                                    float: right;
                                    width: calc(100% - 50px);
                                    padding-left: rem(15);


                                    p {
                                        font-size: rem(16);
                                        font-weight: 600;
                                        color: $designation;
                                        text-align: start;
                                        margin-bottom: 0;
                                        margin-top: rem(-20);
                                        cursor: pointer;

                                        &:hover {
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.manage_spacing {
            .list_detail {
                .table-responsive {
                    table {
                        tr {
                            th {
                                &:nth-child(1) {
                                    min-width: rem(210);
                                }

                                &:nth-child(2) {
                                    min-width: rem(170);
                                }

                                &:nth-child(3) {
                                    min-width: rem(180);
                                }

                                &:nth-child(4) {
                                    min-width: rem(180);
                                }

                                &:nth-child(5) {
                                    min-width: rem(160);
                                }

                                &:nth-child(6) {
                                    min-width: rem(175);
                                }

                                &:nth-child(7) {
                                    min-width: rem(115);
                                }
                            }
                        }
                    }
                }
            }
        }

        &.bed_list_spacing {
            .list_detail {
                .table-responsive {
                    table {
                        tr {
                            th {
                                text-align: center;

                                &:nth-child(1) {
                                    min-width: rem(210);
                                }

                                &:nth-child(2) {
                                    min-width: rem(170);
                                    padding-left: 0;
                                }

                                &:nth-child(3) {
                                    min-width: rem(180);
                                }

                                &:nth-child(4) {
                                    min-width: rem(145);
                                }

                                &:nth-child(5) {
                                    min-width: rem(125);
                                }

                                &:nth-child(6) {
                                    min-width: rem(185);
                                }

                                &:nth-child(7) {
                                    min-width: rem(115);
                                }
                            }

                            td {
                                text-align: center;

                                .profile_info {
                                    &.no_space {
                                        padding-left: 0;
                                        width: 100%;
                                    }

                                    .right_area {
                                        &.no_space {
                                            padding-left: 0;
                                            width: 100%;

                                            p {
                                                text-align: center;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {
    .role_list_screen {
        &.ward_list_area {
            .role_list_details {
                .list_detail {
                    .table-responsive {
                        table {
                            td {
                                .icon {
                                    .dropdown-menu {
                                        transform: translate(-105px, 30px) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {
    .role_list_screen {
        &.ward_list_area {
            .role_list_details {
                .list_detail {
                    .table-responsive {
                        table {
                            td {
                                .icon {
                                    .dropdown-menu {
                                        transform: translate(-92px, 30px) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .role_list_screen {
        &.ward_list_area {
            .role_list_details {
                .list_detail {
                    .table-responsive {
                        table {
                            td {
                                .icon {
                                    .dropdown-menu {
                                        transform: translate(-70px, 30px) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .role_list_screen {
        &.ward_list_area {
            .role_list_details {
                .title_btn_area {
                    margin-top: rem(-18);
                }

                .search_section {
                    .dropdown {
                        .dropdown-toggle:hover {
                            background-color: white !important;
                        }

                        .btn:focus {
                            background-color: white !important;
                        }
                    }

                    .pagination_list {
                        padding-top: 30px;
                        margin-left: 20px;

                        .form-group {
                            .form-select {
                                width: 90px;
                                color: $designation;
                                font-size: rem(20);

                                &:focus {
                                    box-shadow: none;
                                }
                            }
                        }
                    }

                    .filter_button {
                        position: relative;

                        .btn-primary {
                            float: right;
                            width: rem(124);
                            height: rem(60);
                            font-size: rem(22);
                            font-weight: 400;
                            border-radius: rem(10);
                            background: rgba(255, 0, 0, 0.15);
                            border: rem(1.5) solid rgba(255, 0, 0, 0.4);
                            color: #140745;
                        }

                        .btn-border {
                            background-color: $white;
                            border: rem(1.5) solid $form_border_color;
                        }

                        .filter_icon {
                            position: absolute;
                            right: 0;
                            top: 0;
                            margin-top: 10px;
                            margin-right: 10px;
                            font-size: 7px;
                            color: $dark_red_2;
                        }
                    }

                    .dropdown-toggle::after {
                        content: none;
                    }

                    .sort_button {
                        .btn-primary {
                            border: rem(2) solid $designation;
                            color: $designation;
                            background: $white;
                            font-size: rem(20);
                            height: rem(60);
                            width: rem(124);
                        }

                        .image_area {
                            height: rem(22);
                            padding-left: rem(15);
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .sort_button1 {
                        .btn-primary {
                            border: rem(2) solid $designation;
                            color: $designation;
                            background: rgba(255, 0, 0, 0.15);
                            border: rem(1.5) solid rgba(255, 0, 0, 0.4);
                            font-size: rem(20);
                            height: rem(60);
                            width: rem(124);
                        }

                        .image_area {
                            height: rem(22);
                            padding-left: rem(15);
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }

                .list_detail {
                    .table-responsive {
                        overflow-x: auto;
                        min-height: rem(250);

                        &::-webkit-scrollbar-track {
                            box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                            -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                            background-color: $grey;
                        }

                        &::-webkit-scrollbar {
                            width: 2px;
                            height: 6px;
                            background-color: $dark_red_2;
                            margin: 0 rem(20);
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $dark_red_2;
                        }

                        table {
                            tr {
                                th {
                                    text-align: left;
                                    padding-right: 10px;

                                    &:nth-child(1) {
                                        min-width: rem(240);
                                        padding-left: rem(30);
                                    }

                                    &:nth-child(2) {
                                        min-width: rem(160);
                                    }

                                    &:nth-child(3) {
                                        min-width: rem(140);
                                    }

                                    &:nth-child(4) {
                                        min-width: rem(180);
                                    }

                                    &:nth-child(5) {
                                        min-width: rem(100);
                                    }
                                }

                                &:hover {
                                    td {
                                        background: rgba(232, 232, 232, 0.21);

                                        &::before {
                                            background-color: $dark_red_2;
                                        }
                                    }
                                }
                            }

                            td {
                                text-align: center;
                                padding-right: rem(15);

                                .icon {
                                    .dropdown-menu {
                                        padding: 0;
                                        border-radius: 12px 0 12px 12px;
                                        background: #ffffff;
                                        transform: translate(-36px, 30px) !important;
                                        margin-bottom: 10px;
                                    }
                                }

                                &:first-child {
                                    padding-left: rem(30);
                                }


                                &.text_area {
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: $designation;
                                }

                                .switch_area {
                                    padding-left: rem(0);

                                    .form-switch {
                                        display: flex;
                                        align-items: center;

                                        .form-check-input {
                                            background-color: rgba(233, 97, 97, 0.4);
                                            border: 1px solid;
                                            border-color: rgba(233, 97, 97, 0.4);
                                            background-image: url('../../images/c_red.png');

                                            &:focus {
                                                box-shadow: none;
                                                border-color: $grey;
                                            }

                                            &:checked {
                                                border: 1px solid;
                                                background-color: rgba(120, 182, 18, 0.2);
                                                border-color: rgba(120, 182, 18, 0.2);
                                                background-image: url('../../images/c_green.png');
                                            }
                                        }

                                        .form-check-label {
                                            color: #E96161;
                                            font-size: 14px;
                                            font-weight: 500;
                                            margin-left: rem(5);
                                            margin-top: rem(2);
                                        }

                                        .form-check-input:checked~.form-check-label {
                                            color: #8BB612;
                                        }
                                    }
                                }

                                .label {
                                    font-size: rem(14);
                                    font-weight: 500;
                                    color: $form_border_color;
                                    padding: rem(10);
                                    background-color: $light_bg;
                                    border-radius: rem(8);
                                    display: inline-block;
                                }

                                .profile_info {
                                    @include clearfix();
                                    display: flex;
                                    align-items: center;
                                    // padding-left: rem(30);

                                    .left_area {
                                        float: left;
                                        width: rem(50);

                                        .img_profile {
                                            height: rem(50);
                                            width: rem(50);
                                            border-radius: 50%;
                                            overflow: hidden;

                                            img {
                                                height: 100%;
                                                width: 100%;
                                                object-fit: cover;
                                            }
                                        }
                                    }

                                    .right_area {
                                        float: right;
                                        width: calc(100% - 50px);
                                        padding-left: rem(15);


                                        p {
                                            font-size: rem(16);
                                            font-weight: 600;
                                            color: $designation;
                                            text-align: start;
                                            margin-bottom: 0;
                                            margin-top: rem(-20);
                                            cursor: pointer;

                                            &:hover {
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.manage_spacing {
                .list_detail {
                    .table-responsive {
                        table {
                            tr {
                                th {
                                    &:nth-child(1) {
                                        min-width: rem(210);
                                    }

                                    &:nth-child(2) {
                                        min-width: rem(170);
                                    }

                                    &:nth-child(3) {
                                        min-width: rem(180);
                                    }

                                    &:nth-child(4) {
                                        min-width: rem(180);
                                    }

                                    &:nth-child(5) {
                                        min-width: rem(160);
                                    }

                                    &:nth-child(6) {
                                        min-width: rem(175);
                                    }

                                    &:nth-child(7) {
                                        min-width: rem(115);
                                    }
                                }


                            }
                        }
                    }
                }
            }

            &.bed_list_spacing {
                .list_detail {
                    .table-responsive {
                        table {
                            tr {
                                th {
                                    text-align: center;

                                    &:nth-child(1) {
                                        min-width: rem(210);
                                    }

                                    &:nth-child(2) {
                                        min-width: rem(170);
                                        padding-left: 0;
                                    }

                                    &:nth-child(3) {
                                        min-width: rem(180);
                                    }

                                    &:nth-child(4) {
                                        min-width: rem(145);
                                    }

                                    &:nth-child(5) {
                                        min-width: rem(125);
                                    }

                                    &:nth-child(6) {
                                        min-width: rem(185);
                                    }

                                    &:nth-child(7) {
                                        min-width: rem(115);
                                    }
                                }

                                td {
                                    text-align: center;

                                    .profile_info {
                                        &.no_space {
                                            padding-left: 0;
                                            width: 100%;
                                        }

                                        .right_area {
                                            &.no_space {
                                                padding-left: 0;
                                                width: 100%;

                                                p {
                                                    text-align: center;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .role_list_screen {
        &.ward_list_area {
            .role_list_details {
                .title_btn_area {
                    margin-top: rem(-18);
                }

                .search_section {
                    .search_area {
                        .icon {
                            font-size: rem(18);
                            margin-top: 0;
                        }
                    }

                    .filter_button {
                        .btn-primary {
                            height: rem(53);
                            font-size: rem(20);
                        }

                        .image_area {
                            height: rem(18);
                            position: relative;
                            top: rem(-1);
                        }
                    }
                }

                .list_detail {
                    .table-responsive {
                        table {
                            td {
                                .icon {
                                    .dropdown-menu {
                                        transform: translate(-65px, 30px) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .role_list_screen {
        &.ward_list_area {
            .role_list_details {
                padding: rem(0);

                .title_btn_area {
                    margin-top: rem(-10);
                    margin-bottom: rem(20);

                    .left_area {
                        width: 58%;

                        p {
                            font-size: rem(20);
                        }
                    }

                    .right_area {
                        width: calc(100% - 58%);

                        .btn-primary {
                            font-size: 20px;
                            width: 132px;
                            height: 50px;
                            line-height: 36px;
                        }
                    }
                }

                .search_section {
                    margin-bottom: rem(25);
                    display: block;
                    width: 100%;

                    .search_area {
                        float: none;
                        width: 100%;

                        .form-group {
                            margin-bottom: rem(15);
                        }

                        .icon {
                            margin-top: rem(-1);
                        }
                    }

                    .filter_button {
                        float: right;
                        // width: 100%;
                        display: inline-block;

                        .btn-primary {
                            height: rem(53);
                            font-size: rem(20);
                        }

                        .image_area {
                            height: rem(18);
                            position: relative;
                            top: rem(-1);
                        }
                    }

                    .pagination_list {
                        display: inline-block;
                        padding-top: 0;
                        margin-left: 0;
                    }
                }

                .list_detail {
                    border-radius: rem(16);

                    .table-responsive {
                        table {
                            tr {
                                th {
                                    font-size: rem(18);
                                }

                                td {
                                    .icon {
                                        .dropdown-menu {
                                            transform: translate(-65px, 30px) !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .role_list_screen {
        &.ward_list_area {
            .role_list_details {
                padding: rem(0);

                .title_btn_area {
                    margin-top: rem(-10);
                    margin-bottom: rem(20);

                    .left_area {
                        width: 58%;

                        p {
                            font-size: rem(20);
                        }
                    }

                    .right_area {
                        width: calc(100% - 58%);

                        .btn-primary {
                            font-size: 20px;
                            width: 132px;
                            height: 50px;
                            line-height: 36px;
                        }
                    }
                }

                .search_section {
                    margin-bottom: rem(25);
                    display: block;
                    width: 100%;

                    .search_area {
                        float: none;
                        width: 100%;

                        .form-group {
                            margin-bottom: rem(15);
                        }

                        .icon {
                            margin-top: rem(-1);
                        }
                    }

                    .filter_button {
                        float: right;
                        // width: 100%;
                        display: inline-block;

                        .btn-primary {
                            height: rem(53);
                            font-size: rem(20);
                        }

                        .image_area {
                            height: rem(18);
                            position: relative;
                            top: rem(-1);
                        }
                    }

                    .pagination_list {
                        display: inline-block;
                        padding-top: 0;
                        margin-left: 0;
                    }
                }

                .list_detail {
                    border-radius: rem(16);

                    .table-responsive {
                        table {
                            tr {
                                th {
                                    font-size: rem(18);
                                }

                                td {
                                    .icon {
                                        .dropdown-menu {
                                            transform: translate(-65px, 30px) !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}