.existing_patient {
    .existing_area {
        margin: 0 rem(15);

        .title_btn_area {
            display: flex;
            align-items: center;
            margin-bottom: rem(40);
            margin-top: rem(-12);

            .left_area {
                float: left;
                width: 70%;

                p {
                    font-weight: 600;
                    font-size: rem(24);
                    color: $form_label_color;
                }
            }

            .right_area {
                float: right;
                width: calc(100% - 70%);

                .btn-primary {
                    float: right;
                    font-size: rem(24);
                    font-weight: 600;
                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                    border-radius: rem(41);
                    width: rem(200);
                    height: rem(61);
                    line-height: rem(45);
                    border: 0px solid transparent;

                    &:hover,
                    &:focus {
                        color: $white;
                        height: rem(61);
                        border: 0px solid transparent;
                        background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                        opacity: unset;
                    }
                }


            }
        }

        .search_section {
            @include clearfix();
            margin-bottom: rem(45);
            display: flex;
            align-items: center;
            position: relative;
            z-index: 2;

            .filter_button {
                position: relative;

                .btn-primary {
                    float: right;
                    width: rem(124);
                    height: rem(60);
                    margin-left: rem(20);
                    font-size: rem(22);
                    font-weight: 400;
                    border-radius: rem(10);
                    background: rgba(255, 0, 0, 0.15);
                    border: rem(1.5) solid rgba(255, 0, 0, 0.4);
                    color: #140745;
                }

                .btn-border {
                    background-color: $white;
                    border: rem(1.5) solid $form_border_color;
                }

                .filter_icon {
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin-top: 10px;
                    margin-right: 10px;
                    font-size: 7px;
                    color: $dark_red_2;
                }

                .image_area {
                    height: rem(22);
                    padding-right: rem(10);
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .search_area {
                width: calc(100% - 250px);
                float: left;

                .form-group {
                    position: relative;
                    margin-bottom: 0;

                    .form-control {
                        // height: rem(55);
                        // padding-right: rem(50);
                        padding-left: rem(45);

                    }
                }

                .icon {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    top: 0;
                    font-size: rem(20);
                    margin-top: rem(18);
                    margin-left: rem(15);
                    color: $form_label_color;
                }
            }

            .pagination_list {
                padding-top: 30px;
                margin-left: 20px;

                .form-group {
                    .form-select {
                        width: 90px;
                        color: $designation;
                        font-size: rem(20);

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
            }

            .filter_area {
                width: rem(30);
                margin-left: rem(20);
                float: left;
                color: $form_label_color;
                border: rem(1) solid $form_label_color;
                padding: rem(5);

                .img_area {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

            }
        }

        .patient_list {
            background: #FFFFFF;
            border: rem(1) solid #E0E0E0;
            box-shadow: 0px 0px 8px rgba(17, 17, 17, 0.12);
            border-radius: rem(25);
            text-transform: capitalize;
            display: flex;
            align-items: center;
            @include clearfix();
            overflow: hidden;

            .patient_data {
                width: 100%;
            }

            .table-responsive {
                overflow-x: auto;

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
                    background-color: $grey;
                }

                &::-webkit-scrollbar {
                    width: 2px;
                    height: 6px;
                    background-color: $dark_red_2;
                    margin: 0 rem(20);
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $dark_red_2;
                }

                table {
                    width: 100%;
                    height: auto;
                    margin-bottom: 0px;
                    overflow: hidden;

                    tr {
                        th {
                            border-bottom: rem(1) solid rgba(133, 133, 133, 0.8);
                            padding: rem(15) 0;
                            text-align: center;
                            color: $form_label_color;
                            font-weight: 600;
                            font-size: rem(22);

                            &.role_text {
                                text-align: start;
                                padding-left: rem(30);
                            }

                            &:nth-child(1) {
                                min-width: rem(215);
                            }

                            &:nth-child(2) {
                                min-width: rem(150);
                            }

                            &:nth-child(3) {
                                min-width: rem(150);
                            }

                            &:nth-child(4) {
                                min-width: rem(150);
                            }

                            &:nth-child(5) {
                                min-width: rem(212);
                            }

                            &:nth-child(6) {
                                min-width: rem(100);
                            }
                        }
                    }

                    tr {
                        position: relative;

                        td {
                            font-size: rem(16);
                            font-weight: 600;
                            color: $designation;
                            padding: rem(15) 0;
                            text-align: center;

                            .profile_info {
                                @include clearfix();
                                display: flex;
                                align-items: center;
                                padding-left: rem(30);

                                .left_area {
                                    float: left;
                                    width: rem(50);

                                    .img_profile {
                                        height: rem(50);
                                        width: rem(50);
                                        border-radius: 50%;
                                        overflow: hidden;

                                        img {
                                            height: 100%;
                                            width: 100%;
                                            object-fit: cover;
                                        }
                                    }
                                }

                                .right_area {
                                    float: right;
                                    width: calc(100% - 50px);
                                    padding-left: rem(12);

                                    h5 {
                                        font-size: rem(16);
                                        font-weight: 600;
                                        color: $designation;
                                        margin-bottom: rem(4);
                                        text-align: start;
                                    }

                                    p {
                                        font-size: rem(14);
                                        font-weight: 400;
                                        color: $black_2;
                                        text-align: start;
                                    }
                                }
                            }

                            .label {
                                font-size: rem(14);
                                font-weight: 500;
                                color: $form_border_color;
                                padding: rem(10);
                                background-color: $light_bg;
                                border-radius: rem(8);
                                display: inline-block;
                            }

                            .icon {

                                .dropdown-menu {
                                    padding: 0;
                                    border-radius: rem(12) 0 rem(12) rem(12);
                                    background: $white;
                                    transform: translate(rem(-40), rem(30)) !important;

                                    .dropdown-item {
                                        padding: rem(8) rem(20);
                                        font-size: rem(16);
                                        font-weight: 400;
                                        color: $designation;
                                        padding-left: rem(45);
                                        position: relative;
                                        border-bottom: rem(1) solid #858585;

                                        &:last-child {
                                            border-bottom: none;
                                        }

                                        .image_area {
                                            padding-left: rem(8);
                                            overflow: hidden;

                                            img {
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                            }

                                            &.delete_icon {
                                                margin-top: rem(-4);
                                            }
                                        }

                                    }

                                }

                                .btn {
                                    color: $form_label_color;
                                    padding-top: 0;
                                    position: relative;
                                    top: rem(3);

                                    &:hover,
                                    &:focus {
                                        background-color: $white;
                                    }
                                }

                                .ellipsis_icon {
                                    font-size: rem(22);
                                    cursor: pointer;
                                }

                                .dropdown-toggle::after {
                                    display: none !important;
                                }
                            }

                            &::before {
                                content: "";
                                height: auto;
                                width: rem(8);
                                background-color: transparent;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                            }
                        }

                        &:hover {
                            td {
                                background: rgba(232, 232, 232, 0.21);

                                &::before {
                                    background-color: $critical_color;
                                }
                            }
                        }

                        &.active {
                            td {
                                background: rgba(232, 232, 232, 0.21);

                                &::before {
                                    background-color: $critical_color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape tablets and ipad */
@media (min-width: 768px) and (max-width: 991px) {}



/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .existing_patient {
        .existing_area {
            .title_btn_area {
                margin-bottom: rem(25);

                .left_area {
                    width: calc(100% - 175px);

                    p {
                        font-size: rem(22);
                    }
                }

                .right_area {
                    width: rem(175);

                    .btn-primary {
                        font-size: rem(22);
                        width: rem(175);
                        height: rem(50);
                        line-height: rem(36);
                    }
                }
            }

            .search_section {
                .search_area {
                    .icon {
                        margin-top: rem(13);
                    }
                }

                .filter_button {
                    .btn-primary {
                        height: rem(53);
                        font-size: rem(20);
                    }

                    .image_area {
                        height: rem(18);
                        position: relative;
                        top: rem(-2);
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .existing_patient {
        .existing_area {
            margin: 0;

            .title_btn_area {
                margin-bottom: rem(20);

                .left_area {
                    width: calc(100% - 155px);

                    p {
                        font-size: rem(20);
                    }
                }

                .right_area {
                    width: rem(155);

                    .btn-primary {
                        font-size: rem(20);
                        width: rem(155);
                        height: rem(50);
                        line-height: rem(36);
                    }
                }
            }

            .search_section {
                margin-bottom: rem(20);
                display: block;

                .search_area {
                    width: 100%;
                    float: unset;

                    .icon {
                        margin-top: rem(13);
                    }
                }

                .filter_button {
                    .btn-primary {
                        float: unset;
                        width: 100%;
                        margin-top: 15px;
                        margin-left: 0;
                        font-size: rem(20);
                    }

                    .filter_icon {
                        margin-top: 25px;
                    }
                }

                .pagination_list {
                    margin-left: 0;
                    padding-left: rem(0);
                    padding-top: rem(15);

                    .form-group {
                        margin-bottom: 0;

                        .form-select {
                            width: 100%;
                        }
                    }
                }
            }

            .patient_list {
                border-radius: rem(20);
            }
        }
    }
}


/* Smaller phones */
@media (max-width: rem(360)) {}