.add_staff_section {
    .add_staff_area {
        margin: 0 rem(18);

        .image_section {
            position: relative;
            margin-top: rem(80);
            margin-bottom: rem(70);

            .img_area {
                height: rem(200);
                width: rem(200);
                border-radius: 50%;
                margin: 0 auto;
                overflow: hidden;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .gallery_img {
                height: rem(42);
                width: rem(42);
                border-radius: 50%;
                position: absolute;
                bottom: 0;
                right: 0;
                margin-right: rem(292);

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        .form_area {
            width: 1330px;
            max-width: 100%;
            margin: 0 auto;

            .form-group {
                position: relative;

                input[type='date']::-webkit-calendar-picker-indicator {
                    background: transparent;
                    bottom: 0;
                    color: transparent;
                    cursor: pointer;
                    height: auto;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: auto;
                }

                .form-label {
                    span {
                        color: $critical_color;
                    }
                }

                .icon_area {
                    font-size: rem(25);
                    color: $dark_red_2;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin-top: rem(52);
                    margin-right: rem(20);
                }

                .icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-size: rem(28);
                    color: #7A7A7A;
                    margin-top: rem(51);
                    margin-right: rem(15);
                }
            }

            .dropdown_box {
                h6 {
                    font-size: rem(22);
                    font-weight: 600;
                    color: $form_label_color;

                    span {
                        color: $critical_color;
                    }
                }

                .dropdown_area {
                    margin-top: rem(12);
                    margin-right: rem(45);
                    background: transparent;
                    // border: 1px solid $dashboard;
                    border-radius: rem(10);
                    color: $white;
                    width: 100%;
                    margin-bottom: rem(30);
                    text-transform: capitalize;
                }
            }

            .btn_area {
                margin-bottom: rem(30);

                .submitBtn {
                    position: relative;

                    .text-danger {
                        &.manage_text {
                            position: absolute;
                            left: 0;
                            right: 0;
                            color: $dark_red_2;
                            font-size: rem(14);
                            font-weight: 400;
                            margin-top: rem(15) !important;
                        }
                    }
                }
            }
        }

        .add_role_screen {
            .add_role_details {
                padding: 0;
                margin-top: rem(30);

                .title_btn_area {
                    .form-check-input {
                        border-color: $designation;

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }

                .list_detail {
                    .card {
                        .card-body {
                            table {
                                td {
                                    .list-inline {
                                        li {
                                            .form-check-input {
                                                &:focus {
                                                    box-shadow: none;
                                                }
                                            }

                                            // &.fix-area {
                                            //     width: rem(140);
                                            // }

                                            // &.list-gap {
                                            //     margin-left: rem(126);
                                            // }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}




/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {}


@media (min-width: 1024px) and (max-width: 1199px) {}


@media(min-width:768px) and (max-width:1023px) {
    .add_staff_section {
        .add_staff_area {
            margin: 0 rem(18);

            .image_section {
                position: relative;
                margin-top: rem(30);
                margin-bottom: rem(70);

                .img_area {
                    height: rem(175);
                    width: rem(175);
                    border-radius: 50%;
                    margin: 0 auto;
                    overflow: hidden;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }

                .gallery_img {
                    height: rem(42);
                    width: rem(42);
                    border-radius: 50%;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    margin-right: rem(292);

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }

            .form_area {
                .form-group {
                    position: relative;

                    input[type='date']::-webkit-calendar-picker-indicator {
                        background: transparent;
                        bottom: 0;
                        color: transparent;
                        cursor: pointer;
                        height: auto;
                        left: 0;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: auto;
                    }

                    .form-label {
                        span {
                            color: $critical_color;
                        }
                    }

                    .icon_area {
                        font-size: rem(25);
                        color: $dark_red_2;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin-top: rem(52);
                        margin-right: rem(20);
                    }

                    .icon {
                        position: absolute;
                        top: 0;
                        right: 0;
                        font-size: rem(28);
                        color: #7A7A7A;
                        margin-top: rem(51);
                        margin-right: rem(15);
                    }
                }

                .dropdown_box {
                    h6 {
                        font-size: rem(22);
                        font-weight: 600;
                        color: $form_label_color;

                        span {
                            color: $critical_color;
                        }
                    }

                    .dropdown_area {
                        margin-top: rem(12);
                        margin-right: rem(45);
                        background: transparent;
                        // border: 1px solid $dashboard;
                        border-radius: rem(10);
                        color: $white;
                        width: 100%;
                        margin-bottom: rem(30);
                        text-transform: capitalize;
                    }
                }

                .btn_area {
                    margin-bottom: rem(30);

                    .submitBtn {
                        position: relative;

                        .text-danger {
                            &.manage_text {
                                position: absolute;
                                left: 0;
                                right: 0;
                                color: $dark_red_2;
                                font-size: rem(14);
                                font-weight: 400;
                                margin-top: rem(15) !important;
                            }
                        }
                    }
                }
            }

            .add_role_screen {
                .add_role_details {
                    padding: 0;
                    margin-top: rem(30);

                    .title_btn_area {
                        .form-check-input {
                            border-color: $designation;

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }

                    .list_detail {
                        .card {
                            .card-body {
                                table {
                                    td {
                                        .list-inline {
                                            li {
                                                .form-check-input {
                                                    &:focus {
                                                        box-shadow: none;
                                                    }
                                                }

                                                // &.fix-area {
                                                //     width: rem(140);
                                                // }

                                                // &.list-gap {
                                                //     margin-left: rem(126);
                                                // }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .add_staff_section {
        .add_staff_area {
            .image_section {
                margin-top: rem(20);
                margin-bottom: rem(50);

                .img_area {
                    height: rem(150);
                    width: rem(150);
                }

                .gallery_img {
                    height: rem(35);
                    width: rem(35);
                    margin-right: rem(240);
                }
            }

            .form_area {
                .form-group {
                    .form-label {
                        font-size: rem(20);
                    }

                    .icon_area {
                        font-size: rem(22);
                        margin-top: rem(48);
                    }
                }

                .dropdown_box {
                    h6 {
                        font-size: rem(20);
                    }
                }
            }

            .add_role_screen {
                .add_role_details {
                    margin-top: rem(20);

                    .title_btn_area {
                        .left_area {
                            width: 85%;
                        }

                        .right_area {
                            width: calc(100% - 85%);
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .add_staff_section {
        .add_staff_area {
            margin: 0;

            .image_section {
                margin-top: rem(30);
                margin-bottom: rem(50);

                .img_area {
                    height: rem(120);
                    width: rem(120);
                }

                .gallery_img {
                    height: rem(30);
                    width: rem(30);
                    margin-right: rem(105);
                }
            }

            .form_area {
                .form-group {
                    margin-bottom: rem(20);

                    .form-label {
                        font-size: rem(18);
                    }

                    .icon_area {
                        font-size: rem(20);
                        margin-top: rem(46);
                    }
                }

                .dropdown_box {
                    h6 {
                        font-size: rem(18);
                    }
                }

                .dropdown_box {
                    .dropdown_area {
                        margin-bottom: rem(20);

                        button {
                            &.btn-primary {
                                height: rem(53);
                                font-size: rem(20);
                            }
                        }
                    }
                }
            }

            .add_role_screen {
                .add_role_details {
                    margin-top: rem(10);

                    .title_btn_area {
                        .left_area {
                            width: 75%;
                        }

                        .right_area {
                            width: calc(100% - 75%);
                        }
                    }
                }
            }

            .btn_area {
                .btn {
                    font-size: rem(20);
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .add_staff_section {
        .add_staff_area {
            margin: 0;

            .image_section {
                margin-top: rem(30);
                margin-bottom: rem(50);

                .img_area {
                    height: rem(120);
                    width: rem(120);
                }

                .gallery_img {
                    height: rem(30);
                    width: rem(30);
                    margin-right: rem(105);
                }
            }

            .form_area {
                .form-group {
                    margin-bottom: rem(20);

                    .form-label {
                        font-size: rem(18);
                    }

                    .icon_area {
                        font-size: rem(20);
                        margin-top: rem(46);
                    }
                }

                .dropdown_box {
                    h6 {
                        font-size: rem(18);
                    }
                }

                .dropdown_box {
                    .dropdown_area {
                        margin-bottom: rem(20);

                        button {
                            &.btn-primary {
                                height: rem(53);
                                font-size: rem(20);
                            }
                        }
                    }
                }
            }

            .btn_area {
                .btn {
                    font-size: rem(20);
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}