a[role="button"] {
    background-color: transparent;

    &:focus,
    &:hover {
        box-shadow: none;
        background-color: transparent;
    }
}

.form-group {
    margin-bottom: rem(30);
    position: relative;

    .form-label {
        font-size: rem(22);
        font-weight: 600;
        margin-bottom: rem(12);
        color: $form_label_color;

        span {
            color: $critical_color;
        }
    }

    .form-control {
        height: rem(60);
        font-size: rem(22);
        font-weight: 400;
        padding-left: rem(20);
        border: 1.5px solid $form_border_color;
        border-radius: rem(10);
        color: $designation;

        &::placeholder {
            color: $designation;
            opacity: 0.5;
        }
    }

    .right_icon_upload {
        padding-right: 10px;
    }

    .form-select {
        height: rem(60);
        font-size: rem(22);
        font-weight: 400;
        padding-left: rem(20);
        border: 1px solid $form_border_color;
        border-radius: rem(10);
    }

    .input_icon {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: rem(12);
        font-size: rem(24);
        margin-right: rem(15);
    }

    .eye_icon {
        position: absolute;
        top: 0;
        right: 0;
        padding: rem(19);
        font-size: rem(20);
        margin-top: rem(40);
        color: $designation;
        cursor: pointer;
    }
}

input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(18%) sepia(81%) saturate(3778%) hue-rotate(347deg) brightness(75%) contrast(99%);
    cursor: pointer;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(48%) sepia(2%) saturate(18%) hue-rotate(320deg) brightness(98%) contrast(82%);
    cursor: pointer;
}

.cursor_pointer {
    cursor: pointer;
}

.btn_area {
    display: flex;
    gap: rem(15);

    .btn {
        font-size: rem(24);
        font-weight: 600;
        width: 50%;
        height: rem(60);
        margin-top: rem(30);

        &:hover,
        &:focus {
            // opacity: 0.8;
            color: $white;
        }
    }

    .cancelBtn {
        color: $cancel_btn_color;
        background: $grey;
        border: none;

        &:hover,
        &:focus {
            color: $cancel_btn_color;
            background: darken($grey, 4%);
        }
    }

    .submitBtn {
        border: none;
        background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);

        &:hover,
        &:focus {
            color: $white;
            background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
            border: none;
        }
    }

}

//Custom-DatePicker
.custom_datepicker_section {
    display: flex;
    margin: 0 auto;
    scroll-behavior: smooth;
    overflow-x: scroll;
    position: relative;

    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    .inner_datepicker_area {
        height: rem(122);
        width: rem(85);
        border-radius: rem(70);
        background-color: transparent;
        margin: 0 rem(8);
        margin-bottom: rem(20);

        &.active {
            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
            box-shadow: 0px 9px 16px rgba(227, 30, 36, 0.52);
            @include transition(all .5s ease);

            .content_area {
                h6 {
                    color: $white;
                }

                p {
                    color: $white;
                }

                .dot {
                    opacity: 1;
                }
            }
        }

        .content_area {
            padding: rem(20) rem(10) rem(0) rem(10);
            width: rem(85);

            h6 {
                font-size: rem(24);
                font-weight: 600;
                color: $designation;
                margin-bottom: rem(5);
                text-align: center;
            }

            p {
                font-size: rem(24);
                font-weight: 600;
                color: $designation;
                text-align: center;
                margin-bottom: rem(10);
            }

            .dot {
                height: rem(8);
                width: rem(8);
                border-radius: 50%;
                background-color: $white;
                margin: 0 auto;
                opacity: 0;
            }
        }
    }
}

//Drawer_navigation
.offcanvas {
    background-color: transparent;
    --bs-offcanvas-width: 460px;

    &.offcanvas-end {
        left: 0;
        right: none;
    }
}

.modal-open {
    padding-right: 0;
}



.sidenav.open {
    left: 0;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    backdrop-filter: blur(5px); /* Apply Blur */
    z-index: 1000;
    transition: opacity 0.3s ease-in-out;
}

.sidenav {
    // height: 100%;
    // width: 0;
    // position: fixed;
    // z-index: 99999999;
    // top: 0;
    // left: 0;
    // background-color: #ffffff;
    // overflow-x: hidden;
    // transition: 0.5s;



    position: fixed;
    width: 300px; /* Adjust as needed */
    height: 100%;
    background: white;
    top: 0;
    left: -300px;
    transition: left 0.3s ease-in-out;
    z-index: 1001;
    overflow-x: hidden;
    // margin-left: calc(calc(100% - 768px) / 2);

    &.active {
        width: 450px;
    }

    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }

    .header_area {
        display: block;
        padding: rem(25) rem(35) 0 rem(35);
        background: linear-gradient(170.64deg, rgba(255, 255, 255, 0.87) 4.77%, rgba(255, 255, 255, 0.87) 97.23%);
        box-shadow: 0px 0px 110px rgba(17, 17, 17, 0.1);
        backdrop-filter: blur(13.5914px);
        position: relative;

        .logout_top {
            position: absolute;
            top: 0;
            right: 0;
            margin-top: 33px;
            margin-right: 35px;

            .btn-primary {
                font-size: rem(20);
                font-weight: 500;
                color: $white;
                border-radius: rem(30);
                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                border: none;
                padding: 8px 15px;

                &:hover {
                    background: linear-gradient(135.83deg, #E31E24 10%, #800020 130%);
                    border-radius: rem(30);
                    opacity: unset;
                }
            }
        }

        .icon_top {
            width: rem(48);

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .profile_info {
            text-align: center;

            .img_main {
                position: relative;

                .img_area {
                    height: rem(200);
                    width: rem(200);
                    border-radius: 50%;
                    margin: 0 auto;
                    overflow: hidden;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }

                .edit_img {
                    height: rem(35);
                    width: rem(35);
                    border-radius: 50%;
                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                    font-size: rem(15);
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    margin-right: rem(120);
                    cursor: pointer;
                }
            }

            h5 {
                font-size: rem(22);
                font-weight: 600;
                color: $black_2;
                text-align: center;
                margin-top: rem(15);
            }

            p {
                font-size: rem(16);
                font-weight: 600;
                color: $dark_red_2;
                margin-top: rem(5);
                text-align: center;
                padding: rem(4) rem(8);
                background-color: #F8EFE6;
                display: inline-block;
                border-radius: rem(4);
                margin-bottom: rem(15);
            }
        }
    }

    .body_area {
        background-color: $white;

        .content_area {
            @include clearfix();
            padding: rem(15) rem(10) rem(15) rem(35);
            display: flex;
            align-items: center;
            background-color: $grey;
            width: 100%;
            // margin: 0 rem(-35);
            // margin-top: rem(15);

            .left_area {
                float: unset;
                width: 100%;

                p {
                    font-size: rem(24);
                    font-weight: 600;
                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                }
            }

            .right_area {
                float: unset;
                width: 100%;

                a {
                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                    border: none;
                    width: rem(180);
                    font-size: rem(24);
                    font-weight: 600;

                    &:hover,
                    &:focus {
                        background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                        color: $white;
                    }
                }
            }
        }

        .list_area {
            margin-top: rem(20);

            ul {
                li {
                    margin-bottom: rem(5);
                    @include transition(all .5s ease);

                    a {
                        .text_icon_area {
                            @include clearfix();
                            display: flex;
                            align-items: center;
                            padding: rem(18) rem(35);


                            .left_icon {
                                float: left;
                                width: rem(66);

                                .icon_box {
                                    height: rem(63);
                                    width: rem(66);
                                    line-height: rem(63);
                                    border-radius: rem(10);
                                    color: $designation;
                                    text-align: center;
                                    background-color: $grey ;
                                    font-size: 25px;
                                }
                            }

                            .right_text {
                                float: right;
                                width: calc(100% - 66px);
                                padding-left: rem(15);

                                p {
                                    font-size: rem(20);
                                    font-weight: 400;
                                    color: $light_grey;
                                }
                            }
                        }
                    }

                    &.active {
                        a {
                            .text_icon_area {
                                background: rgba(255, 0, 0, 0.04);

                                .left_icon {
                                    .icon_box {
                                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                        box-shadow: 0px 7px 10px #FFB3BF;
                                        color: $white;
                                    }
                                }

                                .right_text {
                                    p {
                                        color: $black_2;
                                    }
                                }
                            }
                        }
                    }

                    &:hover {
                        a {
                            .text_icon_area {
                                background: rgba(255, 0, 0, 0.04);

                                .left_icon {
                                    .icon_box {
                                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                        box-shadow: 0px 7px 10px #FFB3BF;
                                        color: $white;
                                    }
                                }

                                .right_text {
                                    p {
                                        color: $black_2;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Manage-Select
.manage_select_react {
    .dropdown_box {
        position: relative;

        p {
            &.text-danger {
                position: absolute;
                bottom: 0;
                left: 0;
                margin-bottom: rem(-20);
            }
        }

        h6 {
            font-size: rem(22);
            font-weight: 600;
            color: $form_label_color;

            span {
                color: $critical_color;
            }
        }

        .dropdown_area {
            margin-top: rem(12);
            margin-right: rem(45);
            background: transparent;
            border: 1px solid transparent;
            border-radius: rem(10);
            color: $white;
            width: 100%;
            margin-bottom: rem(30);
        }
    }
}

.upload_file {
    padding-right: 60px !important;
}


//Select
.css-b62m3t-container {
    &.error_border_select {
        .css-13cymwt-control {
            border-color: #f00;
        }
    }

    .css-13cymwt-control {
        border-radius: rem(10);
        border: 1px solid $dashboard;
        // height: rem(60);
        height: auto;
        width: 100%;
        font-size: rem(22);
        font-weight: 400;
        color: $designation;
        min-height: rem(60);
        box-sizing: none;

        .css-1dimb5e-singleValue {
            color: $designation;
        }

        .css-1fdsijx-ValueContainer {
            .css-qbdosj-Input {
                color: $designation !important;
            }

            .css-1jqq78o-placeholder {
                color: lighten($designation, 27%);
                white-space: nowrap;
            }
        }

        &:hover {
            border-color: $dashboard;
        }

        &.active {
            border-radius: rem(10);
            border: 1px solid $dashboard;
            height: rem(60);
            width: 100%;
            font-size: rem(22);
            font-weight: 400;
            color: $designation;
            min-height: rem(60);
            box-sizing: none;
        }

        //placeholder-arrow
        .css-1xc3v61-indicatorContainer {
            display: block;
            margin-top: rem(4);
        }

        //Tags inputs area--> afterselect-tag
        .css-3w2yfm-ValueContainer {
            .css-1p3m7a8-multiValue {
                border-radius: rem(24);
                overflow: hidden;
                position: relative;

                .css-wsp0cs-MultiValueGeneric {
                    font-size: rem(20);
                    font-weight: 400;
                    color: $designation;
                    padding: rem(9) rem(20);
                    padding-right: rem(10);
                    // border-radius: rem(24);
                    background-color: $grey;
                    border: none;
                }

                .css-12a83d4-MultiValueRemove {
                    background-color: $designation;
                    color: $white;
                    height: rem(20);
                    width: rem(19);
                    border-radius: 50%;
                    font-size: rem(9);
                    padding: rem(2);
                    text-align: center;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin-top: rem(14);
                    margin-right: rem(15);
                    position: relative;
                    top: rem(-2);
                }
            }

            .css-qbdosj-Input {
                padding: 0;
                margin: 0;
            }
        }

        // icons-area
        .css-1hb7zxy-IndicatorsContainer {
            // position: relative;

            .css-1xc3v61-indicatorContainer {
                padding: rem(5);
                // position: absolute;
                // top: 0;
                // bottom: 0;
                // right: 0;
                // margin-right: rem(15);
                display: inline-block;
                margin-top: rem(6);
            }
        }
    }

    .css-t3ipsp-control {
        border-radius: rem(10);
        border: 1px solid $dashboard !important;
        // height: rem(60);
        height: auto;
        width: 100%;
        font-size: rem(22);
        font-weight: 400;
        color: $designation;
        box-shadow: none;
        min-height: rem(60);

        &:hover {
            background-color: transparent;
        }

        .css-1fdsijx-ValueContainer {
            .css-qbdosj-Input {
                color: $designation !important;
            }

            .css-1jqq78o-placeholder {
                white-space: nowrap;
            }

            .css-1dimb5e-singleValue {
                color: $designation;
            }
        }

        //Tags inputs area- -- before
        .css-3w2yfm-ValueContainer {
            .css-1p3m7a8-multiValue {
                border-radius: rem(24);
                overflow: hidden;
                position: relative;

                .css-wsp0cs-MultiValueGeneric {
                    font-size: rem(20);
                    font-weight: 400;
                    color: $designation;
                    padding: rem(9) rem(20);
                    padding-right: rem(10);
                    // border-radius: rem(24);
                    background-color: $grey;
                    border: none;
                }

                .css-12a83d4-MultiValueRemove {
                    background-color: $designation;
                    color: $white;
                    height: rem(20);
                    width: rem(19);
                    border-radius: 50%;
                    font-size: rem(9);
                    padding: rem(2);
                    text-align: center;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin-top: rem(14);
                    margin-right: rem(15);
                    position: relative;
                    top: rem(-2);
                }
            }

            .css-qbdosj-Input {
                padding: 0;
                margin: 0;
            }
        }
    }

    .css-1hb7zxy-IndicatorsContainer {
        display: block;

        .css-15lsz6c-indicatorContainer {
            display: inline-block;
            margin-top: rem(8);
            @include rotate(180deg);
        }
    }
}

.css-1n6sfyn-MenuList {
    max-height: 350px !important;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    // padding-bottom: 4px;
    // padding-top: 4px;
    font-size: rem(15);
    color: $black_2;
    font-weight: 400;
    border-radius: rem(8);
    box-sizing: unset !important;
    background-color: $white !important;

    .css-tr4s17-option {
        background-color: $white;
        color: $black_2;

        &:hover,
        &:active,
        &:focus {
            background-color: $primary_color;
            color: $black_2;
        }
    }

    .css-10wo9uf-option {
        @extend .css-tr4s17-option;
    }

    .css-d7l1ni-option {
        @extend .css-tr4s17-option;
        background-color: $primary_color;
        color: $black_2;
    }


    // background-color: red;
    &::-webkit-scrollbar {
        width: 0;
        background-color: transparent;
    }

    &:hover {
        background-color: transparent;
    }

    &.active {
        background-color: transparent !important;
    }

    &:focus {
        background-color: transparent !important;
    }
}

.css-1nmdiq5-menu {
    @extend .css-1n6sfyn-MenuList;
}

.css-qr46ko {
    @extend .css-1n6sfyn-MenuList;
}

//Disable select
.css-3iigni-container {
    .css-16xfy0z-control {
        border-radius: rem(10);
        border: 1px solid $dashboard;
        height: rem(60);
        width: 100%;
        font-size: rem(22);
        font-weight: 400;
        color: $designation;
        min-height: rem(60);
        box-sizing: none;

        .css-1hb7zxy-IndicatorsContainer {
            .css-894a34-indicatorSeparator {
                width: 0;
                display: none;
            }

            .css-1xc3v61-indicatorContainer {
                display: none;
            }
        }
    }
}

.input_otp {
    input {
        height: 100px;
        width: 100px !important;
        margin: 0 5px;
        font-size: 30px;
        color: $designation;
        font-weight: 500;
        border: 1px solid #7A7A7A;
        border-radius: 3px;

        &:focus {
            outline: 2px solid $dark_red_2;
            border: none;
        }
    }

}

.error_border {
    border: 1px solid red !important;
}

// Manage_tag---->
.manage_tags {
    .react-tagsinput {
        margin-top: rem(12);
        margin-bottom: rem(30);
        border: 1px solid $form_border_color;
        min-height: 60px;
        height: auto;
        width: 100%;
        border-radius: rem(10);
        padding: 10px;
        padding-left: rem(20);

        .react-tagsinput-input {
            font-size: rem(22);
            font-weight: 400;
            color: $designation;
            width: 100%;
            margin-bottom: 0;
        }

        .react-tagsinput-tag {
            font-size: rem(22);
            font-weight: 400;
            color: $designation;
            padding: rem(9) rem(20);
            border-radius: rem(24);
            background-color: $grey;
            border: none;
            // position: relative;

            a {
                &::before {
                    content: "✖";
                    background-color: $designation;
                    color: $white;
                    height: rem(14) !important;
                    width: rem(14) !important;
                    border-radius: 50%;
                    font-size: rem(9);
                    padding: rem(4);
                    text-align: center;
                    margin-left: rem(15);
                    position: relative;
                    top: -4px;
                }
            }
        }
    }
}

// AutComplete--Style
.input_auto_area {
    width: 100%;

    ul {
        &.menu_listing_area {
            background: #FEFEFE;
            border: rem(1) solid $form_border_color;
            border-radius: 0px 0px 12px 12px;
            // border-radius: rem(8);
            margin-top: rem(4);
            overflow: hidden;

            li {
                font-size: rem(20);
                font-weight: 400;
                color: $txt_color_2;
                padding: rem(15);
                border-bottom: rem(0.5) solid $border_line;
                overflow: hidden;

                &:last-child {
                    border-bottom: 0;
                    border-radius: 0px 0px 12px 12px;
                }

                &:hover,
                &.active {
                    background-color: $border_line2;
                }
            }
        }
    }
}



@media (min-width: 768px) and (max-width: 1023px) {
    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 99999999;
        top: 0;
        left: 0;
        background-color: #ffffff;
        overflow-x: hidden;
        transition: 0.5s;
        margin-left: calc(calc(100% - 768px) / 2);

        &.active {
            width: 450px;
        }

        &::-webkit-scrollbar {
            width: 0;
            background: transparent;
        }

        .header_area {
            display: block;
            padding: rem(25) rem(35) 0 rem(35);
            background: linear-gradient(170.64deg, rgba(255, 255, 255, 0.87) 4.77%, rgba(255, 255, 255, 0.87) 97.23%);
            box-shadow: 0px 0px 110px rgba(17, 17, 17, 0.1);
            backdrop-filter: blur(13.5914px);
            position: relative;

            .logout_top {
                position: absolute;
                top: 0;
                right: 0;
                margin-top: 33px;
                margin-right: 35px;

                .btn-primary {
                    font-size: rem(20);
                    font-weight: 500;
                    color: $white;
                    border-radius: rem(30);
                    background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                    border: none;
                    padding: 8px 15px;

                    &:hover {
                        background: linear-gradient(135.83deg, #E31E24 10%, #800020 130%);
                        border-radius: rem(30);
                        opacity: unset;
                    }
                }
            }

            .icon_top {
                width: rem(48);

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .profile_info {
                text-align: center;

                .img_main {
                    position: relative;

                    .img_area {
                        height: rem(200);
                        width: rem(200);
                        border-radius: 50%;
                        margin: 0 auto;
                        overflow: hidden;

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }

                    .edit_img {
                        height: rem(35);
                        width: rem(35);
                        border-radius: 50%;
                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                        font-size: rem(15);
                        color: $white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        margin-right: rem(120);
                        cursor: pointer;
                    }
                }

                h5 {
                    font-size: rem(22);
                    font-weight: 600;
                    color: $black_2;
                    text-align: center;
                    margin-top: rem(15);
                }

                p {
                    font-size: rem(16);
                    font-weight: 600;
                    color: $dark_red_2;
                    margin-top: rem(5);
                    text-align: center;
                    padding: rem(4) rem(8);
                    background-color: #F8EFE6;
                    display: inline-block;
                    border-radius: rem(4);
                    margin-bottom: rem(15);
                }
            }
        }

        .body_area {
            background-color: $white;

            .content_area {
                @include clearfix();
                padding: rem(15) rem(10) rem(15) rem(35);
                display: flex;
                align-items: center;
                background-color: $grey;
                width: 100%;
                // margin: 0 rem(-35);
                // margin-top: rem(15);

                .left_area {
                    float: unset;
                    width: 100%;

                    p {
                        font-size: rem(24);
                        font-weight: 600;
                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                    }
                }

                .right_area {
                    float: unset;
                    width: 100%;

                    a {
                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                        border: none;
                        width: rem(180);
                        font-size: rem(24);
                        font-weight: 600;

                        &:hover,
                        &:focus {
                            background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                            color: $white;
                        }
                    }
                }
            }

            .list_area {
                margin-top: rem(20);

                ul {
                    li {
                        margin-bottom: rem(5);
                        @include transition(all .5s ease);

                        a {
                            .text_icon_area {
                                @include clearfix();
                                display: flex;
                                align-items: center;
                                padding: rem(18) rem(35);


                                .left_icon {
                                    float: left;
                                    width: rem(66);

                                    .icon_box {
                                        height: rem(63);
                                        width: rem(66);
                                        line-height: rem(63);
                                        border-radius: rem(10);
                                        color: $designation;
                                        text-align: center;
                                        background-color: $grey ;
                                        font-size: 25px;
                                    }
                                }

                                .right_text {
                                    float: right;
                                    width: calc(100% - 66px);
                                    padding-left: rem(15);

                                    p {
                                        font-size: rem(20);
                                        font-weight: 400;
                                        color: $light_grey;
                                    }
                                }
                            }
                        }

                        &.active {
                            a {
                                .text_icon_area {
                                    background: rgba(255, 0, 0, 0.04);

                                    .left_icon {
                                        .icon_box {
                                            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                            box-shadow: 0px 7px 10px #FFB3BF;
                                            color: $white;
                                        }
                                    }

                                    .right_text {
                                        p {
                                            color: $black_2;
                                        }
                                    }
                                }
                            }
                        }

                        &:hover {
                            a {
                                .text_icon_area {
                                    background: rgba(255, 0, 0, 0.04);

                                    .left_icon {
                                        .icon_box {
                                            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                            box-shadow: 0px 7px 10px #FFB3BF;
                                            color: $white;
                                        }
                                    }

                                    .right_text {
                                        p {
                                            color: $black_2;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .sidenav {
        margin-left: 0;

        &.active {
            width: 100%;
        }

        .header_area {
            padding: rem(20);
            padding-bottom: 0;

            .icon_top {
                width: rem(40);
            }

            .profile_info {
                .img_main {
                    .img_area {
                        height: rem(140);
                        width: rem(140);
                    }

                    .edit_img {
                        height: rem(30);
                        width: rem(30);
                        font-size: rem(13);
                        margin-right: rem(258);
                    }
                }

                h5 {
                    font-size: rem(18);
                    margin-top: rem(10);
                }

                p {
                    font-size: rem(14);
                    margin-bottom: rem(10);
                }
            }

            .logout_top {
                margin-top: 28px;
                margin-right: 20px;
            }
        }

        .body_area {
            .content_area {
                padding: rem(15) rem(10) rem(15) rem(20);
                width: 100%;

                .left_area {
                    float: unset;
                    width: 100%;

                    p {
                        font-size: rem(20);
                    }
                }

                .right_area {
                    float: unset;
                    width: 100%;

                    a {
                        width: rem(160);
                        font-size: rem(20);
                        line-height: rem(34);
                    }
                }
            }

            .list_area {
                ul {
                    li {
                        a {
                            .text_icon_area {
                                padding: rem(15) rem(20);

                                .left_icon {
                                    width: rem(57);

                                    .icon_box {
                                        height: rem(55);
                                        width: rem(57);
                                        line-height: rem(57);
                                        border-radius: rem(8);
                                        font-size: rem(20);
                                    }
                                }

                                .right_text {
                                    width: calc(100% - 57px);
                                    padding-left: rem(12);

                                    p {
                                        font-size: rem(18);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .input_otp {
        input {
            height: 80px;
            width: 80px !important;
            font-size: 25px;

        }
    }

    .manage_select_react {
        .dropdown_box {
            h6 {
                font-size: rem(20);
            }

            .dropdown_area {
                width: 100%;
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .custom_datepicker_section {
        .inner_datepicker_area {
            height: rem(105);
            width: rem(72);

            .content_area {
                width: rem(72);

                h6 {
                    font-size: rem(20);
                }

                p {
                    font-size: rem(20);
                }
            }
        }
    }

    .sidenav {
        margin-left: 0;

        &.active {
            width: 100%;
        }

        .header_area {
            padding: rem(20);
            padding-bottom: 0;

            .icon_top {
                width: rem(35);
            }

            .profile_info {

                // .img_area {
                //     height: rem(120);
                //     width: rem(120);
                // }
                .img_main {
                    .img_area {
                        height: rem(120);
                        width: rem(120);
                    }

                    .edit_img {
                        height: rem(30);
                        width: rem(30);
                        font-size: rem(13);
                        margin-right: rem(115);
                    }
                }

                h5 {
                    font-size: rem(17);
                    margin-top: rem(6);
                }

                p {
                    font-size: rem(14);
                    margin-bottom: rem(10);
                }
            }

            .logout_top {
                margin-top: 28px;
                margin-right: 20px;

                .btn-primary {
                    font-size: 18px;
                }
            }
        }

        .body_area {
            .content_area {
                padding: rem(15) rem(10) rem(15) rem(20);
                width: 100%;
                // margin: 0 rem(-20);
                // margin-top: rem(10);

                .left_area {
                    float: unset;
                    width: 100%;

                    p {
                        font-size: rem(18);
                    }
                }

                .right_area {
                    float: unset;
                    width: 100%;

                    a {
                        width: rem(160);
                        font-size: rem(18);
                        line-height: rem(34);
                    }
                }
            }

            .list_area {
                margin-top: rem(15);

                ul {
                    li {
                        a {
                            .text_icon_area {
                                padding: rem(15) rem(20);

                                .left_icon {
                                    width: rem(53);

                                    .icon_box {
                                        height: rem(50);
                                        width: rem(53);
                                        line-height: rem(49);
                                        border-radius: rem(8);
                                        font-size: rem(20);
                                    }
                                }

                                .right_text {
                                    width: calc(100% - 53px);
                                    padding-left: rem(12);

                                    p {
                                        font-size: rem(18);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .form-group {
        .form-label {
            font-size: rem(18) !important;
        }

        .eye_icon {
            font-size: 18px;
            margin-top: 33px;
        }
    }

    .input_otp {
        input {
            height: 50px;
            width: 50px !important;
            margin: 0 5px;
            font-size: 20px;
        }
    }

    .manage_select_react {
        .dropdown_box {
            h6 {
                font-size: rem(18);
            }

            .dropdown_area {
                width: 100%;
                margin-bottom: rem(22);
            }
        }
    }

    .manage_tags {
        .react-tagsinput {
            min-height: rem(53);
            margin-bottom: rem(20);

            .react-tagsinput-input {
                font-size: rem(20);
            }

            .react-tagsinput-tag {
                font-size: 18px;
                padding: 7px 15px;

                a {
                    &::before {
                        font-size: 8px;
                        padding: 4px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .css-b62m3t-container {
        .css-13cymwt-control {
            height: auto;
            min-height: rem(53);

            .css-3w2yfm-ValueContainer {
                .css-1p3m7a8-multiValue {
                    border-radius: rem(24);

                    .css-wsp0cs-MultiValueGeneric {
                        font-size: rem(16);
                        padding: rem(8) rem(15);
                        padding-right: rem(6);
                    }

                    .css-12a83d4-MultiValueRemove {
                        height: rem(18);
                        width: rem(17);
                        margin-top: rem(11);
                        margin-right: rem(12);
                    }
                }
            }
        }

        .css-t3ipsp-control {
            height: auto;
            min-height: rem(53);

            //Tags inputs area- -- before
            .css-3w2yfm-ValueContainer {
                .css-1p3m7a8-multiValue {
                    border-radius: rem(24);

                    .css-wsp0cs-MultiValueGeneric {
                        font-size: rem(16);
                        padding: rem(8) rem(15);
                        padding-right: rem(6);
                    }

                    .css-12a83d4-MultiValueRemove {
                        height: rem(18);
                        width: rem(17);
                        margin-top: rem(11);
                        margin-right: rem(12);
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: 360px) {
    .form-group {
        .form-label {
            font-size: rem(18) !important;
        }

        .eye_icon {
            font-size: 18px;
            margin-top: 33px;
        }
    }

    .sidenav {
        margin-left: 0;

        &.active {
            width: 100%;
        }

        .header_area {
            padding: rem(20);
            padding-bottom: 0;

            .icon_top {
                width: rem(35);
            }

            .profile_info {
                .img_main {
                    .img_area {
                        height: rem(120);
                        width: rem(120);
                    }

                    .edit_img {
                        height: rem(30);
                        width: rem(30);
                        font-size: rem(13);
                        margin-right: rem(115);
                    }
                }

                h5 {
                    font-size: rem(17);
                    margin-top: rem(6);
                }

                p {
                    font-size: rem(14);
                    margin-bottom: rem(10);
                }
            }

            .logout_top {
                margin-top: 28px;
                margin-right: 20px;

                .btn-primary {
                    font-size: 18px;
                }
            }
        }

        .body_area {
            .content_area {
                padding: rem(15) rem(10) rem(15) rem(20);
                width: 100%;
                // margin: 0 rem(-20);
                // margin-top: rem(10);

                .left_area {
                    float: unset;
                    width: 100%;

                    p {
                        font-size: rem(18);
                    }
                }

                .right_area {
                    float: unset;
                    width: 100%;

                    a {
                        width: rem(160);
                        font-size: rem(18);
                        line-height: rem(34);
                    }
                }
            }

            .list_area {
                margin-top: rem(15);

                ul {
                    li {
                        a {
                            .text_icon_area {
                                padding: rem(15) rem(20);

                                .left_icon {
                                    width: rem(53);

                                    .icon_box {
                                        height: rem(50);
                                        width: rem(53);
                                        line-height: rem(49);
                                        border-radius: rem(8);
                                        font-size: rem(20);
                                    }
                                }

                                .right_text {
                                    width: calc(100% - 53px);
                                    padding-left: rem(12);

                                    p {
                                        font-size: rem(18);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .input_otp {
        input {
            height: 40px;
            width: 40px !important;
            margin: 0 5px;
            font-size: 20px;
        }
    }

    .manage_select_react {
        .dropdown_box {
            h6 {
                font-size: rem(18);
            }

            .dropdown_area {
                width: 100%;
                margin-bottom: rem(22);
            }
        }
    }

    .manage_tags {
        .react-tagsinput {
            min-height: rem(53);
            margin-bottom: rem(20);

            .react-tagsinput-input {
                font-size: rem(20);
            }

            .react-tagsinput-tag {
                font-size: 18px;
                padding: 7px 15px;

                a {
                    &::before {
                        font-size: 8px;
                        padding: 4px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .css-b62m3t-container {
        .css-13cymwt-control {
            height: auto;
            min-height: rem(53);
        }

        .css-t3ipsp-control {
            height: auto;
            min-height: rem(53);
        }
    }
}

/* Smaller phones */
@media (max-width: 320px) {}