.scroller_main {
    border: 1.5px solid $form_border_color;
    border-radius: 10px;
    padding: rem(25) rem(10) rem(15) rem(25);
    margin-bottom: rem(25);
    height: rem(155);
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;

    .select_area_owl {
        overflow-y: hidden;
        overflow-x: auto;

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
            -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
            background-color: $grey;
        }

        &::-webkit-scrollbar {
            width: 2px;
            height: 6px;
            background-color: $dark_red_2;
            margin: 0 rem(20);
            border-radius: 10px;
            overflow: hidden;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $dark_red_2;
        }

        .slick-slide {
            // .slick-list {
            //     overflow-y: hidden;
            //     overflow-x: auto;

            //     &::-webkit-scrollbar-track {
            //         box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
            //         -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
            //         background-color: $grey;
            //     }

            //     &::-webkit-scrollbar {
            //         width: 2px;
            //         height: 6px;
            //         background-color: $dark_red_2;
            //         margin: 0 rem(20);
            //         border-radius: 10px;
            //         overflow: hidden;
            //     }

            //     &::-webkit-scrollbar-thumb {
            //         background-color: $dark_red_2;
            //     }

            .user-slide {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                background: transparent;

                .user-image-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 5px;

                    .user-image {
                        height: rem(40);
                        width: rem(40);
                        border-radius: 50%;
                        object-fit: cover;
                        align-self: center;
                    }
                }

                .user-details {
                    color: #333;

                    h5 {
                        margin-bottom: 5px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        word-break: break-word;

                    }

                    p {
                        margin: 0;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: $form_label_color;
                        margin-bottom: 15px;
                        word-break: break-all;
                        padding: 2px 12px;
                        display: inline-block;
                        border-radius: 5px;
                        background-color: $off_white;
                    }
                }
            }

            // }
        }
    }
}


// update------------------------->
// .scroller_main {
//     border: 1.5px solid $form_border_color;
//     border-radius: 10px;
//     padding: rem(25) rem(10) rem(15) rem(25);
//     margin-bottom: rem(25);
//     height: rem(155);
//     padding-left: 0;
//     padding-right: 0;
//     overflow: hidden;

//     .select_area_owl {
//         .slick-slide {
//             width: rem(140) !important;
//             text-align: center;


//             .slick-list {
//                 overflow-y: hidden;
//                 overflow-x: auto;

//                 &::-webkit-scrollbar-track {
//                     box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
//                     -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
//                     background-color: $grey;
//                 }

//                 &::-webkit-scrollbar {
//                     width: 2px;
//                     height: 6px;
//                     background-color: $dark_red_2;
//                     margin: 0 rem(20);
//                     border-radius: 10px;
//                     overflow: hidden;
//                 }

//                 &::-webkit-scrollbar-thumb {
//                     background-color: $dark_red_2;
//                 }
//             }

//             // .slick-track {

//                 .user-image-container {
//                     height: rem(40);
//                     width: rem(40);
//                     border-radius: 50%;
//                     margin: 0 auto;

//                     .user-image {
//                         height: 100%;
//                         width: 100%;
//                         object-fit: cover;
//                     }
//                 }

//                 .user-details {
//                     color: #333;

//                     h5 {
//                         margin-bottom: 5px;
//                         font-weight: 400;
//                         font-size: 16px;
//                         line-height: 20px;
//                         background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
//                         -webkit-background-clip: text;
//                         -webkit-text-fill-color: transparent;
//                         background-clip: text;
//                         word-break: break-word;

//                     }

//                     p {
//                         margin: 0;
//                         font-weight: 500;
//                         font-size: 14px;
//                         line-height: 17px;
//                         color: $black_2;
//                         margin-bottom: 15px;
//                     }
//                 }
//             // }
//         }
//     }
// }