.change_password_screen {
    .form {
        padding: rem(50) rem(250) rem(50) rem(250);

        .password_icon {
            position: relative;

            .confirm_password {
                border: rem(1) solid $critical_color;
            }

            .error_icon {
                position: absolute;
                top: 0;
                right: 0;
                padding: rem(19);
                font-size: rem(25);
                color: $critical_color;
                cursor: pointer;
            }

            .eye_icon {
                margin-top: 0;
            }
        }

        .btn_area {
            display: flex;
            gap: rem(10);
            margin-top: 120px;

            .btn {
                font-size: rem(24);
                font-weight: 600;
                width: 100%;
                height: rem(60);
                margin-top: rem(30);

                &:hover {
                    // opacity: 0.8;
                    color: $white;
                }
            }

            .cancelBtn {
                color: $cancel_btn_color;
                background: $grey;

                &:hover {
                    background: darken($grey, 4%);
                }
            }

            .submitBtn {
                background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);

                &:hover {
                    background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                }
            }

        }
    }

    .without_image_header {
        .header_area {
            .details {
                .icon_area {
                    display: none;
                }
            }

            .breadcrum_area {
                display: none;
            }
        }
    }
}

@media(min-width:1024px) and (max-width:1199px) {
    .change_password_screen {
        .form {
            padding: rem(50) rem(160) rem(50) rem(160);
        }
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .change_password_screen {
        .form {
            padding: 0 rem(30) rem(50) rem(30);

            .password_icon {
                position: relative;

                .confirm_password {
                    border: rem(1) solid $critical_color;
                }

                .error_icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: rem(19);
                    font-size: rem(25);
                    color: $critical_color;
                    cursor: pointer;
                }

                .eye_icon {
                    margin-top: 0;
                }
            }

            .btn_area {
                display: flex;
                gap: rem(10);
                margin-top: 0;

                .btn {
                    font-size: rem(24);
                    font-weight: 600;
                    width: 100%;
                    height: rem(60);
                    margin-top: rem(30);

                    &:hover {
                        // opacity: 0.8;
                        color: $white;
                    }
                }

                .cancelBtn {
                    color: $cancel_btn_color;
                    background: $grey;

                    &:hover {
                        background: darken($grey, 4%);
                    }
                }

                .submitBtn {
                    background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);

                    &:hover {
                        background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                    }
                }

            }
        }

        .without_image_header {
            .header_area {
                .details {
                    .icon_area {
                        display: none;
                    }
                }

                .breadcrum_area {
                    display: none;
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .change_password_screen {
        .form {
            padding: 0 30px 50px 30px;

            .btn_area {
                margin-top: 0;
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .change_password_screen {
        .form {
            padding: 0 20px 50px 20px;

            .btn_area {
                .btn {
                    font-size: rem(18);
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .change_password_screen {
        .form {
            padding: 0 20px 50px 20px;

            .btn_area {
                .btn {
                    font-size: rem(18);
                }
            }
        }
    }
}