.edit_profile_screen {
    margin-bottom: rem(100);

    .parent_area {
        padding-bottom: rem(70);
        padding-top: rem(90);


        .innerr_area {
            position: relative;
            margin-bottom: rem(50);
            border-bottom: 0px solid $grey;
            background: $white;
            padding: 0 rem(30);

            .profile_area {
                margin-bottom: rem(50);

                .image_area {
                    margin: 0 auto;
                    text-align: center;
                    width: rem(175);
                    height: rem(175);
                    border-radius: 50%;
                    margin-top: rem(-40);
                    position: relative;
                    z-index: 1;

                    Image {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        overflow: hidden;
                    }

                    .editicon {
                        background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                        position: absolute;
                        z-index: 2;
                        right: 0;
                        bottom: 0;
                        margin-right: rem(20);
                        margin-bottom: rem(4);
                        padding-top: rem(5);
                        width: rem(35);
                        height: rem(35);
                        border-radius: 50%;

                        Image {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }

            .form {
                padding: 0 rem(220);

                .form-group {
                    position: relative;

                    .icon {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: rem(22);
                        height: rem(24);
                        margin-top: rem(55);
                        margin-right: rem(15);

                        Image {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .btn_area {
                    margin-bottom: rem(105);
                }
            }
        }
    }
}








/* Above 1920 desktop */
@media (min-width: 1920px) {}

/* 1680 desktop */
@media (min-width: 1680px) and (max-width: 1919px) {}

/* 1600 desktop */
@media (min-width: 1600px) and (max-width: 1679px) {}

/* Above 1400 and Below 1599 Desktop*/
@media (min-width: 1400px) and (max-width: 1599px) {}

/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {
    .edit_profile_screen {
        .parent_area {
            .innerr_area {
                .form {
                    padding: 0 rem(150);
                }
            }
        }
    }
}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {
    .edit_profile_screen {
        .parent_area {
            .innerr_area {
                .form {
                    padding: 0 rem(120);
                }
            }
        }
    }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    .edit_profile_screen {
        .parent_area {
            .innerr_area {
                .form {
                    padding: 0 rem(70);
                }
            }
        }
    }
}

/* Landscape tablets and ipad */
@media (min-width: 768px) and (max-width: 991px) {
    .edit_profile_screen {
        margin-bottom: rem(100);

        .parent_area {
            padding-bottom: rem(70);
            padding-top: rem(0);


            .innerr_area {
                position: relative;
                margin-bottom: rem(50);
                border-bottom: 0px solid $grey;
                background: $white;
                // box-shadow: 0px 4px 25px rgba(55, 55, 128, 0.12);
                // border-radius: rem(25);
                padding: 0 rem(30);

                .profile_area {
                    margin-bottom: rem(50);

                    .image_area {
                        margin: 0 auto;
                        text-align: center;
                        width: rem(175);
                        height: rem(175);
                        border-radius: 50%;
                        margin-top: rem(-40);
                        position: relative;
                        z-index: 1;

                        Image {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            overflow: hidden;
                        }

                        .editicon {
                            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                            position: absolute;
                            z-index: 2;
                            right: 0;
                            bottom: 0;
                            margin-right: rem(20);
                            margin-bottom: rem(4);
                            padding-top: rem(5);
                            width: rem(35);
                            height: rem(35);
                            border-radius: 50%;

                            Image {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }

                .form {
                    padding: 0;

                    .form-group {
                        position: relative;

                        .icon {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: rem(22);
                            height: rem(24);
                            margin-top: rem(55);
                            margin-right: rem(15);

                            Image {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .btn_area {
                        margin-bottom: rem(105);
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .form-group {
        .form-control {
            height: rem(53);
            font-size: rem(20);
        }

        .form-select {
            height: rem(53);
            font-size: rem(20);
        }
    }

    .edit_profile_screen {
        .parent_area {
            padding-top: rem(0);

            .innerr_area {
                .form {
                    padding: 0;

                    .form-group {
                        .icon {
                            margin-top: rem(50);
                        }
                    }
                }
            }
        }
    }

}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .form-group {
        .form-label {
            font-size: rem(20);

        }

        .form-control {
            font-size: rem(20);
            height: rem(53);
        }

        .form-select {
            font-size: rem(20);
            height: rem(53);
        }
    }

    .edit_profile_screen {
        .parent_area {
            padding-top: rem(0);

            .innerr_area {
                padding: 0 rem(10);

                .form {
                    padding: 0;

                    .form-group {
                        .icon {
                            margin-top: rem(48);
                        }
                    }
                }
            }
        }
    }


}

/* Smaller phones */
@media (max-width: 360px) {
    .form-group {
        .form-label {
            font-size: rem(20);
        }

        .form-control {
            font-size: rem(20);
            height: rem(53);
        }

        .form-select {
            font-size: rem(20);
            height: rem(53);
        }
    }

    .edit_profile_screen {
        .parent_area {
            padding-top: rem(0);

            .innerr_area {
                padding: 0 rem(10);

                .form {
                    padding: 0;

                    .form-group {
                        .icon {
                            margin-top: rem(48);
                        }
                    }
                }
            }
        }
    }


}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}