.splash_screen_one {
    height: 100vh;
    display: flex;
    align-items: center;

    .splash_area_one {
        .splash_img {
            width: rem(400);
            margin: 0 auto;
            overflow: hidden;
            @include transition(all 0.5s ease-in-out);

            &:hover {
                @include scale(1.1);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .splash_screen_one {
        .splash_area_one {
            .splash_img {
                width: 280px;
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .splash_screen_one {
        .splash_area_one {
            .splash_img {
                width: 200px;
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .splash_screen_one {
        .splash_area_one {
            .splash_img {
                width: 200px;
            }
        }
    }
}