.card_section {
    margin-top: rem(20);
    position: relative;
    z-index: 1;

    .card_area {
        background: $accordin_body;
        border: rem(1) solid $grey;
        border-radius: 25px;
        padding: rem(20) 0 0 0;
        margin-bottom: rem(20);

        //--------.mytask_area class for my tasks page------------
        .mytask_area {
            @include clearfix();
            display: flex;
            align-items: center;
            padding: 0 rem(15);

            .mytask_left_area {
                float: left;
                width: 65%;
                padding-left: rem(20);
                @include clearfix();
                display: flex;
                align-items: center;

                .img_area {
                    float: left;
                    width: rem(58);
                    height: rem(58);
                    border-radius: 50%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                p {
                    font-weight: 400;
                    font-size: rem(16);
                    color: #140745;
                    padding-left: rem(10);
                    cursor: pointer;

                    span {
                        color: $designation;
                        font-weight: 500;
                        font-size: rem(14);
                    }
                }

            }

            .mytask_right_area {
                @include clearfix();
                float: right;
                width: calc(100% - 65%);

                p {
                    float: right;
                    font-weight: 400;
                    font-size: rem(16);
                    color: $dashboard;

                    span {
                        background: rgba(55, 55, 128, 0.2);
                        border-radius: rem(5);
                        padding: rem(3) rem(11);
                    }
                }

                &.test {
                    p {
                        color: #FF8717;
                    }
                }
            }
        }

        .sub_title {
            @include clearfix();

            .left_area {
                float: left;
                width: 50%;
                padding-left: rem(20);

                p {
                    font-weight: 500;
                    font-size: rem(14);
                    line-height: rem(17);
                    color: $designation;
                }
            }

            .right_area {
                float: right;
                width: calc(100% - 50%);
                padding-right: rem(20);

                p {
                    float: right;
                    font-weight: 500;
                    font-size: rem(14);
                    line-height: rem(17);
                    color: $success_color;
                }

                &.test {
                    p {
                        color: #FF8717;
                    }
                }
            }
        }

        .card_inner {
            border: rem(1) solid $grey;
            border-radius: rem(25);
            padding: rem(15);
            margin-top: rem(25);
            background: #FFF3F4;

            &.pending {
                background: #F9FFE7;
            }

            &.medium {
                background: #F8DEBD;
            }

            .card_inner_detail {
                padding: 0 rem(20);

                .sub_title {
                    .left_area {
                        float: left;
                        width: rem(73);
                        height: rem(29);
                        background: rgba(233, 97, 97, 0.1);
                        border-radius: 20px;
                        padding: 0;

                        p {
                            font-size: rem(14);
                            line-height: rem(17);
                            margin-top: rem(5);
                            text-align: center;
                            color: #E96161;
                        }

                        &.testt {
                            background: rgba(139, 182, 18, 0.1);

                            p {
                                color: $success_color;
                            }
                        }

                        &.medium {
                            background: rgba(255, 135, 23, 0.21);

                            p {
                                color: $treatment_color;
                            }
                        }
                    }

                    //--------task_right_area class for my tasks page------------
                    .task_right_area {
                        float: right;
                        width: rem(85);
                        height: rem(29);
                        background: rgba(120, 182, 18, 0.18);
                        border-radius: 20px;

                        p {
                            font-weight: 500;
                            font-size: rem(14);
                            line-height: rem(17);
                            margin-top: rem(5);
                            text-align: center;
                            color: $success_color;
                        }

                        &.pending {
                            background: #F8DFC7;

                            p {
                                color: #FF8717;
                            }
                        }
                    }
                }
            }

            .title_area {
                margin-top: rem(20);

                h5 {
                    font-weight: 400;
                    font-size: rem(16);
                    color: $black_2;
                }
            }

            .description {
                margin-top: rem(10);
                max-height: rem(105);
                overflow: hidden;

                p {
                    font-weight: 400;
                    font-size: rem(16);
                    line-height: rem(26);
                    color: $designation;
                    word-break: break-all;

                    span {
                        margin-top: rem(15);
                        font-weight: 500;
                        font-size: rem(14);
                        color: $designation;
                    }

                }
            }

            .group_image {
                align-items: center;
                margin-top: rem(24);
                display: flex;

                .image_area {
                    height: rem(37);
                    width: rem(37);
                    border-radius: rem(100);
                    overflow: hidden;
                    cursor: pointer;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    &.img_2 {

                        color: $white;
                        margin-left: rem(-12);
                        border: 2px solid $white;
                    }

                    &.img_3 {
                        margin-left: rem(-12);
                        background: #FFB057;
                        line-height: rem(37);
                        text-align: center;
                        border: 2px solid $white;

                        p {
                            color: $white;
                        }
                    }
                }
            }

            .bottom_area {
                margin-top: rem(24);
                padding-top: rem(12);
                border-top: rem(1) solid #DFDFDF;

                .inner_area {
                    @include clearfix();
                    display: flex;
                    align-items: center;
                    position: relative;

                    .left_area {
                        float: left;
                        width: rem(36);

                        .img_area {
                            width: rem(36);
                            height: rem(34);
                            border-radius: rem(100);
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .right_area {
                        width: calc(100% - 36px);

                        .name_area {
                            margin-left: rem(10);

                            .ellipsis_icon {
                                font-size: rem(24);
                                cursor: pointer;
                                float: right;
                                // margin-top: rem(-32);
                                // margin-right: rem(-40);
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                right: 0;
                                margin-top: -88%;
                            }

                            h3 {
                                font-weight: 500;
                                font-size: rem(16);
                                line-height: rem(19);
                                background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }

                            p {
                                font-weight: 500;
                                font-size: rem(14);
                                color: $black_2;
                            }

                            .dropdown {
                                button {
                                    &.dropdown-toggle {
                                        // float: right;
                                        position: absolute;
                                        top: 0;
                                        bottom: 0;
                                        right: 0;

                                        &::after {
                                            display: none;
                                            background-color: transparent;
                                        }

                                        &:focus,
                                        &:hover {
                                            background-color: transparent;
                                        }
                                    }
                                }

                                .dropdown-menu.show {
                                    // transform: translate(90px, -2px) !important;
                                    transform: translate(680px, 10px) !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .assign_task {
        // float: right;
        // padding-bottom: rem(50);
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: rem(50);
        margin-bottom: rem(-40);

        .circle_area {
            // margin-right: rem(100);
            display: flex;
            align-items: center;
            justify-content: center;
            height: rem(100);
            width: rem(100);
            text-align: center;
            border-radius: 50%;
            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
            box-shadow: 0px 10px 27px rgba(227, 30, 36, 0.59);

            &:hover {
                background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
            }
        }

        .add_icon_area {
            color: $white;
            font-size: rem(20);

            p {
                font-weight: 500;
                font-size: 14px;
                color: $white;
            }

        }


    }
}



/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {
    .card_section {
        .card_area {
            .card_inner {
                .bottom_area {
                    .inner_area {
                        .right_area {
                            .name_area {
                                .dropdown {
                                    .dropdown-menu.show {
                                        transform: translate(400px, 10px) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {
    .card_section {
        .card_area {
            .card_inner {
                .bottom_area {
                    .inner_area {
                        .right_area {
                            .name_area {
                                .dropdown {
                                    .dropdown-menu.show {
                                        transform: translate(360px, 10px) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    .card_section {
        .card_area {
            .card_inner {
                .bottom_area {
                    .inner_area {
                        .right_area {
                            .name_area {
                                .dropdown {
                                    .dropdown-menu.show {
                                        transform: translate(235px, 10px) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape tablets and ipad */
@media (min-width: 768px) and (max-width: 991px) {
    .card_section {
        margin-top: rem(20);
        position: relative;
        z-index: 1;

        .card_area {
            background: $accordin_body;
            border: rem(1) solid $grey;
            border-radius: 25px;
            padding: rem(20) 0 0 0;
            margin-bottom: rem(20);

            //--------.mytask_area class for my tasks page------------
            .mytask_area {
                @include clearfix();
                display: flex;
                align-items: center;
                padding: 0 rem(15);

                .mytask_left_area {
                    float: left;
                    width: 65%;
                    padding-left: rem(20);
                    @include clearfix();
                    display: flex;
                    align-items: center;

                    .img_area {
                        float: left;
                        width: rem(58);
                        height: rem(58);
                        border-radius: 50%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    p {
                        font-weight: 400;
                        font-size: rem(16);
                        color: #140745;
                        padding-left: rem(10);
                        cursor: pointer;

                        span {
                            color: $designation;
                            font-weight: 500;
                            font-size: rem(14);
                        }
                    }

                }

                .mytask_right_area {
                    @include clearfix();
                    float: right;
                    width: calc(100% - 65%);

                    p {
                        float: right;
                        font-weight: 400;
                        font-size: rem(16);
                        color: $dashboard;

                        span {
                            background: rgba(55, 55, 128, 0.2);
                            border-radius: rem(5);
                            padding: rem(3) rem(11);
                        }
                    }

                    &.test {
                        p {
                            color: #FF8717;
                        }
                    }
                }
            }

            .sub_title {
                @include clearfix();

                .left_area {
                    float: left;
                    width: 50%;
                    padding-left: rem(20);

                    p {
                        font-weight: 500;
                        font-size: rem(14);
                        line-height: rem(17);
                        color: $designation;
                    }
                }

                .right_area {
                    float: right;
                    width: calc(100% - 50%);
                    padding-right: rem(20);

                    p {
                        float: right;
                        font-weight: 500;
                        font-size: rem(14);
                        line-height: rem(17);
                        color: $success_color;
                    }

                    &.test {
                        p {
                            color: #FF8717;
                        }
                    }
                }
            }

            .card_inner {
                border: rem(1) solid $grey;
                border-radius: rem(25);
                padding: rem(15);
                margin-top: rem(25);
                background: #FFF3F4;

                &.pending {
                    background: #F9FFE7;
                }

                &.medium {
                    background: #F8DEBD;
                }

                .card_inner_detail {
                    padding: 0 rem(20);

                    .sub_title {
                        .left_area {
                            float: left;
                            width: rem(73);
                            height: rem(29);
                            background: rgba(233, 97, 97, 0.1);
                            border-radius: 20px;
                            padding: 0;

                            p {
                                font-size: rem(14);
                                line-height: rem(17);
                                margin-top: rem(5);
                                text-align: center;
                                color: #E96161;
                            }

                            &.testt {
                                background: rgba(139, 182, 18, 0.1);

                                p {
                                    color: $success_color;
                                }
                            }

                            &.medium {
                                background: rgba(255, 135, 23, 0.21);

                                p {
                                    color: $treatment_color;
                                }
                            }
                        }

                        //--------task_right_area class for my tasks page------------
                        .task_right_area {
                            float: right;
                            width: rem(85);
                            height: rem(29);
                            background: rgba(120, 182, 18, 0.18);
                            border-radius: 20px;

                            p {
                                font-weight: 500;
                                font-size: rem(14);
                                line-height: rem(17);
                                margin-top: rem(5);
                                text-align: center;
                                color: $success_color;
                            }

                            &.pending {
                                background: #F8DFC7;

                                p {
                                    color: #FF8717;
                                }
                            }
                        }
                    }
                }

                .title_area {
                    margin-top: rem(20);

                    h5 {
                        font-weight: 400;
                        font-size: rem(16);
                        color: $black_2;
                    }
                }

                .description {
                    margin-top: rem(10);
                    max-height: rem(105);
                    overflow: hidden;

                    p {
                        font-weight: 400;
                        font-size: rem(16);
                        line-height: rem(26);
                        color: $designation;
                        word-break: break-all;

                        span {
                            margin-top: rem(15);
                            font-weight: 500;
                            font-size: rem(14);
                            color: $designation;
                        }

                    }
                }

                .group_image {
                    align-items: center;
                    margin-top: rem(24);
                    display: flex;

                    .image_area {
                        height: rem(37);
                        width: rem(37);
                        border-radius: rem(100);
                        overflow: hidden;
                        cursor: pointer;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        &.img_2 {

                            color: $white;
                            margin-left: rem(-12);
                            border: 2px solid $white;
                        }

                        &.img_3 {
                            margin-left: rem(-12);
                            background: #FFB057;
                            line-height: rem(37);
                            text-align: center;
                            border: 2px solid $white;

                            p {
                                color: $white;
                            }
                        }
                    }
                }

                .bottom_area {
                    margin-top: rem(24);
                    padding-top: rem(12);
                    border-top: rem(1) solid #DFDFDF;

                    .inner_area {
                        @include clearfix();
                        display: flex;
                        align-items: center;
                        position: relative;

                        .left_area {
                            float: left;
                            width: rem(36);

                            .img_area {
                                width: rem(36);
                                height: rem(34);
                                border-radius: rem(100);
                                overflow: hidden;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                        }

                        .right_area {
                            width: calc(100% - 36px);

                            .name_area {
                                margin-left: rem(10);

                                .ellipsis_icon {
                                    font-size: rem(24);
                                    cursor: pointer;
                                    float: right;
                                    // margin-top: rem(-32);
                                    // margin-right: rem(-40);
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    right: 0;
                                    margin-top: -88%;
                                }

                                h3 {
                                    font-weight: 500;
                                    font-size: rem(16);
                                    line-height: rem(19);
                                    background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    background-clip: text;
                                }

                                p {
                                    font-weight: 500;
                                    font-size: rem(14);
                                    color: $black_2;
                                }

                                .dropdown {
                                    button {
                                        &.dropdown-toggle {
                                            // float: right;
                                            position: absolute;
                                            top: 0;
                                            bottom: 0;
                                            right: 0;

                                            &::after {
                                                display: none;
                                                background-color: transparent;
                                            }

                                            &:focus,
                                            &:hover {
                                                background-color: transparent;
                                            }
                                        }
                                    }

                                    .dropdown-menu.show {
                                        transform: translate(90px, -2px) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .assign_task {
            // float: right;
            // padding-bottom: rem(50);
            position: absolute;
            z-index: 2;
            bottom: 0;
            right: rem(50);
            margin-bottom: rem(-40);

            .circle_area {
                // margin-right: rem(100);
                display: flex;
                align-items: center;
                justify-content: center;
                height: rem(100);
                width: rem(100);
                text-align: center;
                border-radius: 50%;
                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                box-shadow: 0px 10px 27px rgba(227, 30, 36, 0.59);

                &:hover {
                    background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                }
            }

            .add_icon_area {
                color: $white;
                font-size: rem(20);

                p {
                    font-weight: 500;
                    font-size: 14px;
                    color: $white;
                }

            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .card_section {

        .card_area {
            .card_inner {
                .description {
                    max-height: rem(85);

                    p {
                        font-size: rem(13);
                        line-height: rem(20);
                        padding-bottom: rem(15);
                    }
                }

                .group_image {
                    margin-top: rem(13);
                }

                .bottom_area {
                    .inner_area {
                        .right_area {
                            .name_area {
                                .dropdown {
                                    .dropdown-menu.show {
                                        // transform: translate3d(20.5px, -5px, 0px) !important;
                                        transform: translate(70px, 10px) !important;

                                    }
                                }
                            }
                        }
                    }
                }
            }

            .mytask_area {
                .mytask_left_area {
                    width: 57%;
                    padding-left: 0;

                    .img_area {
                        height: 45px;
                        width: 45px;
                    }
                }

                .mytask_right_area {
                    width: calc(100% - 57%);
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .card_section {
        .card_area {
            margin-bottom: rem(15);

            .card_inner {
                .bottom_area {
                    .inner_area {
                        .right_area {
                            .name_area {
                                .dropdown {
                                    .dropdown-menu.show {
                                        // transform: translate3d(20.5px, -5px, 0px) !important;
                                        transform: translate(0, 10px) !important;

                                    }
                                }
                            }
                        }
                    }
                }
            }

            .mytask_area {
                .mytask_left_area {
                    width: 57%;
                    padding-left: 0;

                    .img_area {
                        height: 45px;
                        width: 45px;
                    }
                }

                .mytask_right_area {
                    width: calc(100% - 57%);
                }
            }
        }

        .assign_task {
            right: 25px;
            margin-bottom: -30px;

            .circle_area {
                height: rem(85);
                width: rem(85);
            }

            .add_icon_area {
                font-size: rem(18);

                p {
                    font-size: rem(13);
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .card_section {
        .card_area {
            margin-bottom: rem(15);

            .card_inner {
                .bottom_area {
                    .inner_area {
                        .right_area {
                            .name_area {
                                .dropdown {
                                    .dropdown-menu.show {
                                        // transform: translate3d(20.5px, -5px, 0px) !important;
                                        transform: translate(0, 10px) !important;

                                    }
                                }
                            }
                        }
                    }
                }
            }

            .mytask_area {
                .mytask_left_area {
                    width: 57%;
                    padding-left: 0;

                    .img_area {
                        height: 45px;
                        width: 45px;
                    }
                }

                .mytask_right_area {
                    width: calc(100% - 57%);
                }
            }
        }

        .assign_task {
            right: 25px;
            margin-bottom: -30px;

            .circle_area {
                height: rem(85);
                width: rem(85);
            }

            .add_icon_area {
                font-size: rem(18);

                p {
                    font-size: rem(13);
                }
            }
        }
    }
}