.assign_task_screen {
    margin-bottom: rem(0);

    .parent_area {
        .innerr_area {
            position: relative;
            border-bottom: 1px solid $grey;
            background: $card_color;
            box-shadow: 0px 4px 25px rgba(55, 55, 128, 0.12);
            padding: rem(60) rem(250);

            .bed_info_title {
                text-align: center;
                padding-top: rem(20);
                padding-bottom: rem(10);

                h5 {
                    color: #140745;
                    font-size: rem(26);
                    font-weight: 600;
                    border-bottom: 3px solid #140745;
                    display: inline-block;
                }
            }

            .form-group {
                position: relative;

                input[type='date']::-webkit-calendar-picker-indicator {
                    background: transparent;
                    bottom: 0;
                    color: transparent;
                    cursor: pointer;
                    height: auto;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: auto;
                }

                span {
                    color: $critical_color;
                    font-size: rem(22);
                }

                textarea {
                    height: rem(138);
                }

                .assign_date {
                    &::-webkit-calendar-picker-indicator {
                        filter: invert(48%) sepia(2%) saturate(18%) hue-rotate(320deg) brightness(98%) contrast(82%);
                        cursor: pointer;
                    }
                }

                .icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-size: rem(28);
                    color: #7A7A7A;
                    margin-top: rem(51);
                    margin-right: rem(15);

                    Image {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .clock_icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-top: 60px;
                    margin-right: 16px;
                    width: 21px;
                    height: 20px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .form-select {
                    color: #7A7A7A;
                }
            }

            .dropdown_box {
                position: relative;

                p {
                    &.text-danger {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        margin-bottom: rem(-20);
                    }
                }

                h6 {
                    font-size: rem(22);
                    font-weight: 600;
                    color: $form_label_color;

                    span {
                        color: $critical_color;
                    }
                }

                .dropdown_area {
                    margin-top: rem(15);
                    margin-right: rem(45);
                    background: transparent;
                    border: 1px solid transparent;
                    border-radius: rem(10);
                    color: $white;
                    width: 100%;
                    margin-bottom: rem(30);

                    button {
                        position: relative;

                        &.btn-primary {
                            border-radius: rem(10);
                            border: 1px solid $dashboard;
                            height: rem(60);
                            width: 100%;
                            font-size: rem(22);
                            font-weight: 400;
                            color: $designation;
                            text-align: start;
                            padding: 0 rem(20);
                            background-color: transparent;
                            overflow: hidden;

                            &::placeholder {
                                opacity: 0.5;
                            }

                            &:hover {
                                background-color: transparent;
                                color: $designation;
                            }

                            &:focus {
                                background: transparent;
                                color: $designation;
                            }
                        }

                        .icon {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            margin-right: 20px;
                            margin-top: 15px;
                            @include transition(0.3s ease-in-out);
                        }

                        &.dropdown-toggle {
                            &:after {
                                content: none;
                            }
                        }

                        &:focus {
                            box-shadow: none;

                            .down_icon {
                                @include rotate(180deg);
                                margin-bottom: rem(15);
                            }
                        }
                    }

                    .dropdown-menu {
                        &.show {
                            left: 50% !important;
                            right: auto !important;
                            transform: translate(calc(-50% + 3px), 60px) !important;
                            max-height: rem(230);
                            overflow-y: auto;
                        }
                    }
                }
            }

            .select_area {
                border: 1px solid $form_border_color;
                border-radius: 10px;
                padding: rem(25);
                display: flex;
                align-items: center;
                margin-bottom: rem(25);

                .select_img {
                    width: 150px;
                    text-align: center;

                    .icon_img {
                        height: rem(20);
                        border-radius: 50%;

                        Image {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .name_area {
                        margin-top: rem(20);

                        h5 {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 26px;
                            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }

                        p {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                            color: $black_2;
                        }
                    }
                }
            }

            .btn_area {
                margin-bottom: rem(58);
            }
        }
    }
}








/* Above 1920 desktop */
@media (min-width: 1920px) {
    .assign_task_screen {
        .parent_area {
            .innerr_area {
                padding: rem(60) rem(230);
            }
        }
    }
}

/* 1680 desktop */
@media (min-width: 1680px) and (max-width: 1919px) {
    .assign_task_screen {
        .parent_area {
            .innerr_area {
                padding: rem(60) rem(230);
            }
        }
    }
}

/* 1600 desktop */
@media (min-width: 1600px) and (max-width: 1679px) {
    .assign_task_screen {
        .parent_area {
            .innerr_area {
                padding: rem(60) rem(230);
            }
        }
    }
}

/* Above 1400 and Below 1599 Desktop*/
@media (min-width: 1400px) and (max-width: 1599px) {
    .assign_task_screen {
        .parent_area {
            .innerr_area {
                padding: rem(60) rem(230);
            }
        }
    }
}

/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {
    .assign_task_screen {
        .parent_area {
            .innerr_area {
                padding: rem(60) rem(170);
            }
        }
    }
}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {
    .assign_task_screen {

        .parent_area {
            .innerr_area {
                padding: rem(60) rem(150);
            }
        }
    }
}

/* Landscape tablets and medium desktops */
@media (min-width: 1024px) and (max-width: 1199px) {
    .assign_task_screen {

        .parent_area {
            .innerr_area {
                padding: rem(60) rem(150);
            }
        }
    }
}

/* Landscape tablets and ipad */
@media (min-width: 768px) and (max-width: 1023px) {
    .assign_task_screen {
        margin-bottom: rem(0);

        .parent_area {

            //  padding-bottom: rem(70);
            .innerr_area {
                position: relative;
                // margin-bottom: rem(50);
                border-bottom: 1px solid $grey;
                background: $card_color;
                box-shadow: 0px 4px 25px rgba(55, 55, 128, 0.12);
                // border-radius: rem(25);
                padding: 0 rem(30);

                .bed_info_title {
                    text-align: center;
                    padding-top: rem(20);
                    padding-bottom: rem(10);

                    h5 {
                        color: #140745;
                        font-size: rem(26);
                        font-weight: 600;
                        border-bottom: 3px solid #140745;
                        display: inline-block;
                    }
                }

                .form-group {
                    position: relative;

                    input[type='date']::-webkit-calendar-picker-indicator {
                        background: transparent;
                        bottom: 0;
                        color: transparent;
                        cursor: pointer;
                        height: auto;
                        left: 0;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: auto;
                    }

                    span {
                        color: $critical_color;
                        // margin-left: rem(5);
                        font-size: rem(22);

                    }

                    textarea {
                        height: rem(138);
                    }

                    .assign_date {
                        &::-webkit-calendar-picker-indicator {
                            filter: invert(48%) sepia(2%) saturate(18%) hue-rotate(320deg) brightness(98%) contrast(82%);
                            cursor: pointer;
                        }
                    }

                    .icon {
                        position: absolute;
                        top: 0;
                        right: 0;
                        font-size: rem(28);
                        color: #7A7A7A;
                        margin-top: rem(51);
                        margin-right: rem(15);

                        Image {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .clock_icon {
                        position: absolute;
                        top: 0;
                        right: 0;
                        margin-top: 60px;
                        margin-right: 16px;
                        width: 21px;
                        height: 20px;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .form-select {
                        color: #7A7A7A;
                    }
                }

                .dropdown_box {
                    position: relative;

                    p {
                        &.text-danger {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            margin-bottom: rem(-20);
                        }
                    }

                    h6 {
                        font-size: rem(22);
                        font-weight: 600;
                        color: $form_label_color;

                        span {
                            color: $critical_color;
                        }
                    }

                    .dropdown_area {
                        margin-top: rem(15);
                        margin-right: rem(45);
                        background: transparent;
                        border: 1px solid transparent;
                        border-radius: rem(10);
                        color: $white;
                        width: 100%;
                        margin-bottom: rem(30);

                        button {
                            position: relative;

                            &.btn-primary {
                                border-radius: rem(10);
                                border: 1px solid $dashboard;
                                height: rem(60);
                                width: 100%;
                                font-size: rem(22);
                                font-weight: 400;
                                color: $designation;
                                text-align: start;
                                padding: 0 rem(20);
                                background-color: transparent;
                                overflow: hidden;

                                &::placeholder {
                                    opacity: 0.5;
                                }

                                &:hover {
                                    background-color: transparent;
                                    color: $designation;
                                }

                                &:focus {
                                    background: transparent;
                                    color: $designation;
                                }
                            }

                            .icon {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                right: 0;
                                margin-right: 20px;
                                margin-top: 15px;
                                @include transition(0.3s ease-in-out);
                            }

                            &.dropdown-toggle {
                                &:after {
                                    content: none;
                                }
                            }

                            &:focus {
                                box-shadow: none;

                                .down_icon {
                                    @include rotate(180deg);
                                    margin-bottom: rem(15);
                                }
                            }
                        }

                        .dropdown-menu {
                            &.show {
                                left: 50% !important;
                                right: auto !important;
                                transform: translate(calc(-50% + 3px), 60px) !important;
                                max-height: rem(230);
                                overflow-y: auto;
                            }
                        }
                    }
                }

                .select_area {
                    border: 1px solid $form_border_color;
                    border-radius: 10px;
                    padding: rem(25);
                    display: flex;
                    align-items: center;
                    margin-bottom: rem(25);

                    .select_img {
                        width: 150px;
                        text-align: center;

                        .icon_img {
                            height: rem(20);
                            border-radius: 50%;

                            Image {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .name_area {
                            margin-top: rem(20);

                            h5 {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 26px;
                                // color: $profile_info;
                                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }

                            p {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 17px;
                                color: $black_2;
                            }
                        }
                    }
                }

                .btn_area {
                    margin-bottom: rem(58);
                }
            }
        }
    }

}

/* Landscape phones and portrait tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .form-group {
        .form-control {
            height: rem(53);
            font-size: rem(20);
        }

        .form-select {
            height: rem(53);
            font-size: rem(20);
        }
    }

    .assign_task_screen {
        .parent_area {
            .innerr_area {
                padding: 0 rem(30);

                .form-group {
                    .icon {
                        margin-top: rem(50);
                    }

                    textarea {
                        height: rem(132);
                    }
                }
            }
        }
    }

}

/* Landscape phones and portrait tablets */
@media (min-width: 361px) and (max-width: 575px) {
    .form-group {
        .form-label {
            font-size: rem(20);

        }

        .form-control {
            font-size: rem(20);
            height: rem(53);
        }

    }

    .assign_task_screen {
        .parent_area {
            .innerr_area {
                padding: 0 rem(10);

                .form-group {
                    .icon {
                        margin-top: rem(48);
                    }

                    textarea {
                        height: rem(132);
                    }
                }
            }
        }
    }


}

/* Smaller phones */
@media (max-width: 360px) {
    .form-group {
        .form-label {
            font-size: rem(20);
        }

        .form-control {
            font-size: rem(20);
            height: rem(53);
        }

        .form-select {
            font-size: rem(20);
            height: rem(53);
        }
    }

    .assign_task_screen {
        .parent_area {
            .innerr_area {
                padding: 0 rem(10);

                .form-group {
                    .icon {
                        margin-top: rem(48);
                    }

                    textarea {
                        height: rem(132);
                    }
                }
            }
        }
    }


}

/* Smaller phones */
@media (max-width: 320px) {}




/* Add CSS styles for Portrait orientation */
@media screen and (orientation: portrait) {}

/* Add CSS styles for Landscape orientation */
@media screen and (orientation: landscape) {}