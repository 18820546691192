.custom_tabs_area {
    .custom_tabs {
        display: flex;
        padding: rem(12) rem(31);
        gap: rem(30);
        border-width: rem(1) 0;
        border-style: solid;
        border-color: #F0F0F0;
        overflow-x: scroll;
        &.staff_tab{
        justify-content: center;
        overflow-x: unset;
        }

        &::-webkit-scrollbar {
            // width: 3px;
            height: 3px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
        }

        .active_btn {
            padding: rem(10) rem(25);
            background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
            box-shadow: 0 rem(4) rem(18) rgba(227, 30, 36, 0.66);
            border-radius: rem(10);
            color: $white;
            font-weight: 600;
            font-size: rem(24);
            border: none;
            white-space: nowrap;

            &:hover {
                background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
            }
        }

        .inactive_btn {
            padding: rem(10) rem(20);
            display: flex;
            justify-content: center;
            align-items: center;
            color: $designation;
            font-weight: 600;
            font-size: rem(24);
            background-color: transparent;
            border: none;
            white-space: nowrap;
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .custom_tabs_area {
        .custom_tabs {
            gap: rem(15);
            
            .active_btn {
                padding: rem(10) rem(20);
                font-size: rem(18);
            }

            .inactive_btn {
                font-size: rem(18);
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .custom_tabs_area {
        .custom_tabs {
            gap: rem(10);

            .active_btn,
            .inactive_btn {
                padding: rem(10);
                font-size: rem(14);
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .custom_tabs_area {
        .custom_tabs {
            gap: rem(10);

            .active_btn,
            .inactive_btn {
                padding: rem(10);
                font-size: rem(14);
            }
        }
    }
}