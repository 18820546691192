.task_tab_screen {
    padding: rem(40) 0;

    .card {
        background: $card_color;
        border: rem(1) solid $grey;
        box-shadow: 0 rem(4) rem(25) rgba(55, 55, 128, 0.12);
        border-radius: rem(25);
        background-color: $accordin_body;
        // margin-bottom: 20px;

        .card-body {
            padding: rem(35) rem(40) rem(30) rem(40);
            display: flex;
            flex-wrap: wrap;
            gap: 2%;

            .card {
                background: $card_color;
                border: rem(1) solid $grey;
                box-shadow: 0 rem(4) rem(25) rgba(55, 55, 128, 0.12);
                border-radius: rem(10);
                margin-bottom: rem(15);
                width: 49%;

                .card-body {
                    padding: rem(22) rem(20);

                    .card_details {
                        @include clearfix();
                        display: flex;
                        align-items: center;

                        .left_area {
                            @include clearfix();
                            width: 650px;
                            // width: 350px;
                            float: left;
                            display: flex;

                            .inner_left_area {
                                width: 58px;
                                float: left;
                            }

                            .img_one {
                                width: rem(58);
                                height: rem(58);
                                overflow: hidden;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            .inner_right_area {
                                width: calc(100% - 58px);
                                float: right;

                                .text_area {
                                    padding-left: rem(20);

                                    h5 {
                                        color: $form_border_color;
                                        font-size: rem(20);
                                        font-weight: 400;
                                        line-height: rem(24);
                                    }

                                    p {
                                        font-weight: 400;
                                        font-size: rem(16);
                                        line-height: rem(26);
                                        color: $black_2;
                                        margin-top: rem(5);
                                    }
                                }
                            }

                        }

                        .right_area {
                            float: right;
                            width: calc(100% - 650px);
                            text-align: end;

                            .icons {
                                float: right;
                                margin-right: rem(0);

                                img {
                                    margin-left: rem(30);
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .report_details {
                        margin-top: rem(25);

                        .report_updated_by {
                            display: flex;
                            justify-content: space-between;
                            background: #FFEFEF;
                            border-radius: rem(40);
                            padding: rem(15) rem(18);

                            p {
                                font-weight: 400;
                                font-size: rem(16);
                                color: $cancel_btn_color;
                            }
                        }
                    }
                }
            }

        }

        .btn {
            width: rem(210);
            height: rem(45);
            margin-top: rem(15);
            font-weight: 600;
            font-size: rem(24);
            color: $white;
            background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
            box-shadow: 0 rem(4) rem(18) rgba(227, 30, 36, 0.66);
            // border-radius: rem(10);
            border-radius: rem(30);
            border: none;
            word-spacing: rem(3);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 40px;
            margin-bottom: 2%;

            &:hover,
            &:focus {
                background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                opacity: unset;
            }
        }
    }

    .center_btn {
        width: rem(210);
        height: rem(45);
        margin-top: rem(35);
        font-weight: 600;
        font-size: rem(24);
        color: $white;
        background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
        box-shadow: 0 rem(4) rem(18) rgba(227, 30, 36, 0.66);
        // border-radius: rem(10);
        border-radius: rem(30);
        border: none;
        word-spacing: rem(3);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        &:hover,
        &:focus {
            background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
            opacity: unset;
        }
    }

    .assign_task {
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: rem(50);
        margin-bottom: rem(38);

        .circle_area {
            display: flex;
            align-items: center;
            justify-content: center;
            height: rem(100);
            width: rem(100);
            text-align: center;
            border-radius: 50%;
            background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
            box-shadow: 0px 10px 27px rgba(227, 30, 36, 0.59);

            &:hover {
                background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
            }
        }

        .add_icon_area {
            color: $white;
            font-size: rem(20);

            p {
                font-weight: 500;
                font-size: 14px;
                color: $white;
            }

        }
    }
}



/* 1300 desktop only */
@media (min-width: 1300px) and (max-width: 1399px) {
    .task_tab_screen {
        .card {
            .card-body {
                .card {
                    .card-body {
                        .card_details {
                            .left_area {
                                width: 390px;
                            }

                            .right_area {
                                width: calc(100% - 390px);
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 1200 desktop only */
@media (min-width: 1200px) and (max-width: 1299px) {
    .task_tab_screen {
        .card {
            .card-body {
                .card {
                    .card-body {
                        .card_details {
                            .left_area {
                                width: 350px;
                            }

                            .right_area {
                                width: calc(100% - 350px);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .task_tab_screen {
        .card {
            .card-body {
                .card {
                    .card-body {
                        .card_details {
                            .left_area {
                                width: 230px;
                            }

                            .right_area {
                                width: calc(100% - 230px);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .task_tab_screen {
        padding: rem(40) 0;

        .card {
            background: $card_color;
            border: rem(1) solid $grey;
            box-shadow: 0 rem(4) rem(25) rgba(55, 55, 128, 0.12);
            border-radius: rem(25);
            background-color: $accordin_body;
            // margin-bottom: 20px;

            .card-body {
                padding: rem(35) rem(40) rem(30) rem(40);
                gap: 0;

                .card {
                    background: $card_color;
                    border: rem(1) solid $grey;
                    box-shadow: 0 rem(4) rem(25) rgba(55, 55, 128, 0.12);
                    border-radius: rem(10);
                    margin-bottom: rem(15);
                    width: 100%;

                    .card-body {
                        padding: rem(22) rem(20);

                        .card_details {
                            @include clearfix();
                            display: flex;
                            align-items: center;

                            .left_area {
                                @include clearfix();
                                width: 400px;
                                float: left;
                                display: flex;

                                .inner_left_area {
                                    width: 58px;
                                    float: left;
                                }

                                .img_one {
                                    width: rem(58);
                                    height: rem(58);
                                    overflow: hidden;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }

                                .inner_right_area {
                                    width: calc(100% - 58px);
                                    float: right;

                                    .text_area {
                                        padding-left: rem(20);

                                        h5 {
                                            color: $form_border_color;
                                            font-size: rem(20);
                                            font-weight: 400;
                                            line-height: rem(24);
                                        }

                                        p {
                                            font-weight: 400;
                                            font-size: rem(16);
                                            line-height: rem(26);
                                            color: $black_2;
                                            margin-top: rem(5);
                                        }
                                    }
                                }

                            }

                            .right_area {
                                float: right;
                                width: calc(100% - 400px);

                                .icons {
                                    float: right;
                                    margin-right: rem(20);

                                    img {
                                        margin-left: rem(30);
                                        cursor: pointer;
                                    }
                                }
                            }
                        }

                        .report_details {
                            margin-top: rem(25);

                            .report_updated_by {
                                display: flex;
                                justify-content: space-between;
                                background: #FFEFEF;
                                border-radius: rem(40);
                                padding: rem(15) rem(18);

                                p {
                                    font-weight: 400;
                                    font-size: rem(16);
                                    color: $cancel_btn_color;
                                }
                            }
                        }
                    }
                }

            }

            .btn {
                width: rem(210);
                height: rem(45);
                margin-top: rem(15);
                font-weight: 600;
                font-size: rem(24);
                color: $white;
                background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
                box-shadow: 0 rem(4) rem(18) rgba(227, 30, 36, 0.66);
                // border-radius: rem(10);
                border-radius: rem(30);
                border: none;
                word-spacing: rem(3);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 5%;

                &:hover,
                &:focus {
                    background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                    opacity: unset;
                }
            }
        }

        .center_btn {
            width: rem(210);
            height: rem(45);
            margin-top: rem(35);
            font-weight: 600;
            font-size: rem(24);
            color: $white;
            background: linear-gradient(135.83deg, $red_gradient_color 8.45%, $dark_red_gradient_color 115.01%);
            box-shadow: 0 rem(4) rem(18) rgba(227, 30, 36, 0.66);
            // border-radius: rem(10);
            border-radius: rem(30);
            border: none;
            word-spacing: rem(3);
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            &:hover,
            &:focus {
                background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                opacity: unset;
            }
        }

        .assign_task {
            position: absolute;
            z-index: 2;
            bottom: 0;
            right: rem(50);
            margin-bottom: rem(38);

            .circle_area {
                display: flex;
                align-items: center;
                justify-content: center;
                height: rem(100);
                width: rem(100);
                text-align: center;
                border-radius: 50%;
                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                box-shadow: 0px 10px 27px rgba(227, 30, 36, 0.59);

                &:hover {
                    background: linear-gradient(135.83deg, #E31E24 20%, #800020 100%);
                }
            }

            .add_icon_area {
                color: $white;
                font-size: rem(20);

                p {
                    font-weight: 500;
                    font-size: 14px;
                    color: $white;
                }

            }
        }
    }

}


/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .task_tab_screen {
        .card {
            .card-body {
                gap: 0;

                .card {
                    width: 100%;

                    .card-body {
                        .card_details {
                            .left_area {
                                width: rem(360);

                                .text_area {
                                    padding-left: rem(12);

                                    h5 {
                                        font-size: rem(19);
                                    }

                                    p {
                                        font-size: rem(15);
                                    }
                                }
                            }

                            .right_area {
                                width: calc(100% - 360px);

                                .icons {
                                    img {
                                        margin-left: rem(20);
                                    }
                                }
                            }
                        }

                        .report_details {
                            .report_updated_by {
                                p {
                                    font-size: rem(14);
                                }
                            }
                        }

                    }
                }

                .btn {
                    font-size: rem(20);
                    width: rem(180);
                    height: rem(34);
                }
            }

            .btn {
                margin-bottom: 7%;
                margin-top: 0;
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .task_tab_screen {
        padding: rem(25) 0;

        .card {
            // margin-bottom: 12px;

            .card-body {
                padding: rem(20);
                gap: 0;

                .card {
                    margin-bottom: rem(10);
                    width: 100%;

                    .card-body {
                        padding: rem(10);

                        .card_details {
                            .left_area {
                                width: rem(175);

                                .inner_left_area {
                                    width: rem(20);

                                    .img_one {
                                        width: rem(20);
                                        height: rem(30);
                                    }

                                }
                                .inner_right_area {
                                    width: calc(100% - rem(20));
                                    .text_area {
                                        padding-left: rem(10);
    
                                        h5 {
                                            font-size: rem(18);
                                        }
    
                                        p {
                                            font-size: rem(12);
                                            line-height: rem(10);
                                            margin-top: 0;
                                        }
                                    }
                                }


                            }

                            .right_area {
                                width: calc(100% - 175px);

                                .icons {
                                    img {
                                        width: rem(14);
                                        height: rem(13);
                                        margin-left: rem(15);
                                    }
                                }
                            }
                        }

                        .report_details {
                            margin-top: rem(25);
                            width: 100%;

                            .report_updated_by {
                                display: block;
                                text-align: center;
                                padding: rem(5);

                                p {
                                    font-size: rem(14);
                                    margin-bottom: rem(5);
                                }
                            }
                        }

                    }
                }

                .btn {
                    width: rem(140);
                    height: rem(40);
                    margin-top: rem(25);
                    font-size: rem(17);
                }
            }

            .btn {
                margin-bottom: 7%;
                margin-top: 0;
                margin-left: 20px;
            }
        }

        .assign_task {
            right: rem(0);

            .add_icon_area {
                font-size: rem(16);
            }

            .circle_area {
                height: 90px;
                width: 90px;
            }
        }
    }
}


/* Smaller phones */
@media (max-width: rem(360)) {
    .task_tab_screen {
        padding: rem(25) 0;

        .card {
            // margin-bottom: 12px;

            .card-body {
                padding: rem(20);
                gap: 0;

                .card {
                    margin-bottom: rem(10);
                    width: 100%;

                    .card-body {
                        padding: rem(10);

                        .card_details {
                            .left_area {
                                width: rem(165);

                                .inner_left_area {
                                    width: rem(20);

                                    .img_one {
                                        width: rem(20);
                                        height: rem(30);
                                    }

                                }
                                .inner_right_area {
                                    width: calc(100% - rem(20));
                                    .text_area {
                                        padding-left: rem(10);
    
                                        h5 {
                                            font-size: rem(18);
                                        }
    
                                        p {
                                            font-size: rem(12);
                                            line-height: rem(10);
                                            margin-top: 0;
                                        }
                                    }
                                }


                            }

                            .right_area {
                                width: calc(100% - 165px);

                                .icons {
                                    img {
                                        width: rem(14);
                                        height: rem(13);
                                        margin-left: rem(15);
                                    }
                                }
                            }
                        }

                        .report_details {
                            margin-top: rem(25);
                            width: 100%;

                            .report_updated_by {
                                display: block;
                                text-align: center;
                                padding: rem(5);

                                p {
                                    font-size: rem(14);
                                    margin-bottom: rem(5);
                                }
                            }
                        }

                    }
                }

                .btn {
                    width: rem(140);
                    height: rem(40);
                    margin-top: rem(25);
                    font-size: rem(17);
                }
            }

            .btn {
                margin-bottom: 7%;
                margin-top: 0;
                margin-left: 20px;
            }
        }

        .assign_task {
            right: rem(0);

            .add_icon_area {
                font-size: rem(16);
            }

            .circle_area {
                height: 90px;
                width: 90px;
            }
        }
    }
}