.patient_list_lab {
    .patient_list_area {
        margin: 0 rem(15);
        margin-top: rem(30);
        position: relative;
        z-index: 1;

        .search_filter_main {
            @include clearfix();

            .left_area {
                float: left;
                width: calc(100% - 125px);
                padding-right: rem(20);

                .form-group {
                    position: relative;

                    .form-control {
                        padding-left: rem(45);
                    }

                    .icon {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        font-size: rem(20);
                        color: $form_label_color;
                        margin-top: rem(18);
                        margin-left: rem(15);
                    }
                }
            }

            .right_area {
                float: right;
                width: rem(124);

                .inner_area {
                    @include clearfix();

                    .inner_left {
                        float: left;
                        width: rem(85);

                        .form-group {
                            .form-select {
                                &:focus {
                                    box-shadow: none;
                                }
                            }
                        }
                    }

                    .inner_right {
                        float: right;
                        width: calc(100% - 85px);

                        .filter_button {
                            position: relative;

                            .btn-primary {
                                float: right;
                                width: rem(124);
                                height: rem(60);
                                margin-left: rem(20);
                                font-size: rem(22);
                                font-weight: 400;
                                border-radius: rem(10);
                                background-color: $white;
                                border: rem(1.5) solid $form_border_color;
                                color: #140745;
                            }

                            .btn-border {
                                background: rgba(255, 0, 0, 0.15);
                                border: rem(1.5) solid rgba(255, 0, 0, 0.4);
                            }

                            .filter_icon {
                                position: absolute;
                                right: 0;
                                top: 0;
                                margin-top: 10px;
                                margin-right: 10px;
                                font-size: 7px;
                                color: $dark_red_2;
                            }

                            .image_area {
                                height: rem(22);
                                padding-right: rem(10);
                                overflow: hidden;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                    }
                }
            }
        }

        .text {
            p {
                font-size: rem(24);
                font-weight: 600;
                color: $form_label_color;
                margin-bottom: rem(15);
            }
        }

        .listing_main_area {
            .list_inner_area {
                background: $accordin_body;
                border: 1px solid $grey;
                border-radius: rem(25);
                display: flex;
                align-items: end;
                margin-bottom: rem(10);
                padding: rem(20);
                @include clearfix();
                position: relative;


                .left_area {
                    float: left;
                    width: calc(100% - 150px);

                    .img_con_area {
                        @include clearfix();
                        display: flex;
                        align-items: center;

                        .left {
                            float: left;
                            width: rem(143);

                            .img_area {
                                width: rem(143);
                                border-radius: rem(10);
                                overflow: hidden;
                                // width: rem(135);

                                img {
                                    height: 100%;
                                    width: 100%;
                                    object-fit: cover;
                                }
                            }
                        }

                        .right {
                            float: right;
                            width: calc(100% - 143px);
                            padding-left: rem(15);

                            h5 {
                                font-size: rem(22);
                                font-weight: 600;
                                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                                margin-bottom: rem(6);
                                cursor: pointer;
                            }

                            .top_list {
                                margin-bottom: rem(10);

                                ul {
                                    display: flex;
                                    align-items: center;

                                    li {
                                        font-size: rem(16);
                                        font-weight: 400;
                                        color: $designation;
                                        margin-right: rem(10);

                                        &:last-child {
                                            margin-right: 0;
                                        }

                                        .icon_area {
                                            font-size: rem(15);
                                            color: $dark_red_2;
                                            margin-right: rem(5);
                                        }

                                        .label {
                                            font-size: rem(16);
                                            font-weight: 400;
                                            color: $form_border_color;
                                            padding: rem(3) rem(10);
                                            background: rgba(55, 55, 128, 0.2);
                                            border-radius: rem(5);
                                            display: inline-block;
                                            text-transform: uppercase
                                        }
                                    }
                                }
                            }

                            .mid_list {
                                @extend .top_list;
                            }

                            .bottom_list {
                                @extend .top_list;
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .right_area {
                    float: right;
                    width: rem(150);
                    text-align: end;

                    .number_mr {
                        font-size: rem(14);
                        font-weight: 400;
                        color: $light_grey;
                        padding: rem(3) rem(10);
                        background: rgba(154, 154, 154, 0.2);
                        border-radius: rem(5);
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        margin-top: 20px;
                        margin-right: 20px;
                    }

                    .last_btn_area {
                        .number_txt {
                            font-size: rem(14);
                            font-weight: 400;
                            color: $light_grey;
                            padding: rem(3) rem(10);
                            background: rgba(154, 154, 154, 0.2);
                            border-radius: rem(5);
                            display: inline-block;
                        }

                        // .batch {
                        //     float: right;
                        //     background: $treatment_color;
                        //     width: rem(160);
                        //     height: rem(43);
                        //     line-height: rem(43);
                        //     border-radius: rem(22);
                        // margin-top: 10px;

                        //     &:hover {
                        //         background: darken($treatment_color, 4%);
                        //     }

                        //     p {
                        //         font-size: rem(16);
                        //         font-weight: 600;
                        //         color: $white;
                        //         text-align: center;
                        //     }
                        // }

                        .dropdown {
                            float: right;

                            .btn-primary {
                                height: unset;
                                width: unset;
                                border: none;
                                opacity: unset;
                            }

                            .btn.show {
                                background-color: transparent;
                            }

                            .dropdown-toggle {
                                padding: 0;

                                &::after {
                                    display: none;
                                }

                                &:focus,
                                &:hover {
                                    background-color: transparent;
                                }

                                .batch {
                                    float: right;
                                    width: rem(160);
                                    height: rem(43);
                                    line-height: rem(43);
                                    border-radius: rem(22);
                                    margin-top: rem(10);

                                    &.orange_bg {
                                        background: $treatment_color;

                                        &:hover {
                                            background: darken($treatment_color, 4%);
                                        }
                                    }

                                    &.danger {
                                        background: $critical_color;

                                        &:hover {
                                            background: darken($critical_color, 4%);
                                        }
                                    }

                                    &.success {
                                        background: $green;

                                        &:hover {
                                            background: darken($green, 4%);
                                        }
                                    }

                                    &.discharges {
                                        background: #FFEDAD;

                                        &:hover {
                                            background: darken(#FFEDAD, 4%);
                                        }
                                    }

                                    p {
                                        font-size: rem(16);
                                        font-weight: 600;
                                        color: $white;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .patient_list_lab {
        .patient_list_area {
            margin: 0 rem(15);
            margin-top: rem(-25);
            position: relative;
            z-index: 1;
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .patient_list_lab {
        .patient_list_area {
            .search_filter_main {
                .right_area {
                    .inner_area {
                        .inner_right {
                            .filter_button {
                                .btn-primary {
                                    height: rem(53);
                                    font-size: rem(20);
                                }

                                .image_area {
                                    height: rem(16);
                                    position: relative;
                                    top: rem(-2);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .patient_list_lab {
        .patient_list_area {
            margin: 0;
            margin-top: rem(-20);

            .search_filter_main {
                margin-bottom: 10px;

                .form-group {
                    margin-bottom: rem(15);
                }

                .left_area {
                    float: unset;
                    width: 100%;
                    padding-right: 0;

                    .form-group {
                        .icon {
                            margin-top: 14px;
                        }
                    }
                }

                .right_area {
                    float: unset;
                    width: 100%;

                    .inner_area {
                        .inner_right {
                            .filter_button {
                                .btn-primary {
                                    height: rem(53);
                                    font-size: rem(20);
                                }

                                .image_area {
                                    height: rem(16);
                                    position: relative;
                                    top: rem(-2);
                                }
                            }
                        }
                    }
                }
            }

            .text {
                p {
                    font-size: rem(20);
                    margin-bottom: rem(12);
                }
            }

            .listing_main_area {
                .list_inner_area {
                    width: 100%;
                    display: block;
                    padding: rem(12);
                    border-radius: rem(15);

                    .left_area {
                        width: 100%;
                        float: unset;

                        .img_con_area {
                            align-items: center;

                            .left {
                                width: rem(120);

                                .img_area {
                                    width: rem(120);
                                }
                            }

                            .right {
                                width: calc(100% - 120px);
                                padding-left: rem(10);

                                h5 {
                                    font-size: rem(18);
                                    margin-bottom: rem(6);
                                    margin-right: 60px;
                                }

                                .top_list {
                                    margin-bottom: rem(10);

                                    ul {
                                        li {
                                            font-size: rem(15);

                                            .label {
                                                font-size: rem(14);
                                            }

                                            .icon_area {
                                                font-size: rem(15);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .right_area {
                        width: 100%;
                        float: unset;

                        .number_mr {
                            font-size: 13px;
                            padding: 3px 8px;
                            margin-top: 12px;
                            margin-right: 12px;
                        }

                        .last_btn_area {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 5px;

                            .number_txt {
                                float: left;
                                font-size: rem(13);
                            }

                            // .btn_main {
                            //     .btn_text {
                            //         padding: rem(10);
                            //         font-size: rem(15);
                            //     }
                            // }

                            .dropdown {
                                .dropdown-toggle {
                                    .batch {
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .patient_list_lab {
        .patient_list_area {
            margin: 0;
            margin-top: rem(-20);

            .search_filter_main {
                margin-bottom: 10px;

                .form-group {
                    margin-bottom: rem(15);
                }

                .left_area {
                    float: unset;
                    width: 100%;
                    padding-right: 0;

                    .form-group {
                        .icon {
                            margin-top: 14px;
                        }
                    }
                }

                .right_area {
                    float: unset;
                    width: 100%;

                    .inner_area {
                        .inner_right {
                            .filter_button {
                                .btn-primary {
                                    height: rem(53);
                                    font-size: rem(20);
                                }

                                .image_area {
                                    height: rem(16);
                                    position: relative;
                                    top: rem(-2);
                                }
                            }
                        }
                    }
                }
            }

            .text {
                p {
                    font-size: rem(20);
                    margin-bottom: rem(12);
                }
            }

            .listing_main_area {
                .list_inner_area {
                    width: 100%;
                    display: block;
                    padding: rem(12);
                    border-radius: rem(15);

                    .left_area {
                        width: 100%;
                        float: unset;

                        .img_con_area {
                            align-items: center;

                            .left {
                                width: rem(120);

                                .img_area {
                                    width: rem(120);
                                }
                            }

                            .right {
                                width: calc(100% - 120px);
                                padding-left: rem(10);

                                h5 {
                                    font-size: rem(18);
                                    margin-bottom: rem(6);
                                    margin-right: 60px;
                                }

                                .top_list {
                                    margin-bottom: rem(10);

                                    ul {
                                        li {
                                            font-size: rem(15);

                                            .label {
                                                font-size: rem(14);
                                            }

                                            .icon_area {
                                                font-size: rem(15);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .right_area {
                        width: 100%;
                        float: unset;

                        .number_mr {
                            font-size: 13px;
                            padding: 3px 8px;
                            margin-top: 12px;
                            margin-right: 12px;
                        }

                        .last_btn_area {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 5px;

                            .number_txt {
                                float: left;
                                font-size: rem(13);
                            }

                            .dropdown {
                                .dropdown-toggle {
                                    .batch {
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}