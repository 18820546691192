.task_list_screen {

    .list_area {
        overflow-y: scroll;
        height: 550px;
        padding-bottom: 50px;

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
            -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
            background-color: #A7A7A7;
        }

        &::-webkit-scrollbar {
            width: 7px;
            background-color: #FFC2C2;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #FFC2C2;
            border: 1px solid #FFC2C2;
        }

        .item {
            padding-left: 76px;
            padding-top: 21px;
            padding-bottom: 21px;
            padding-right: 20px;
            background: transparent;
            border-width: 1px 0px;
            border-style: solid;
            border-color: #E7E7E7;
            border-radius: 0px;
            @include clearfix();

            &:active,
            &.active,
            &:hover {
                background: $light_red;
            }

            h4 {
                font-size: 24px;
                font-weight: 600;
                line-height: 29px;
                background: linear-gradient(135.83deg, #E31E24 8.45%, #800020 115.01%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                margin-bottom: 9px;
            }

            .left_area {
                width: 45%;
                float: left;
                display: flex;
                align-items: center;

                p {
                    font-size: 14px;
                    font-weight: 600;
                    color: $black_2;
                    margin-right: 10px;
                }

                .name_tag_area {
                    display: flex;
                    align-items: center;

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: $black_2;


                        &.tag {
                            width: auto;
                            height: auto;
                            background: $light_blue;
                            border-radius: 6px;
                            font-weight: 500;
                            color: $blue;
                            padding: 2px 8px;
                            display: inline-block;
                        }
                    }
                }
            }

            .right_area {
                width: calc(100% - 45%);
                float: right;
                display: flex;
                align-items: center;

                p {
                    font-size: 14px;
                    font-weight: 600;
                    color: $black_2;
                    margin-right: 10px;
                }

                .name_tag_area {
                    display: flex;
                    align-items: center;

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: $black_2;

                        &.tag {
                            width: auto;
                            height: auto;
                            background: $light_blue;
                            border-radius: 6px;
                            font-weight: 500;
                            color: $blue;
                            padding: 2px 8px;
                            cursor: pointer;
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .task_list_screen {
        .list_area {
            .item {
                padding: 20px;

                h4 {
                    font-size: 20px;
                    margin-bottom: 4px;
                }

                .left_area {
                    width: 100%;
                    float: left;
                    margin-bottom: 5px;
                }

                .right_area {
                    width: 100%;
                    float: right;
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .task_list_screen {
        .list_area {
            .item {
                padding: 15px;

                h4 {
                    font-size: 18px;
                    margin-bottom: 4px;
                }

                .left_area {
                    width: 100%;
                    float: left;
                    margin-bottom: 5px;
                }

                .right_area {
                    width: 100%;
                    float: right;
                }
            }
        }
    }
}

/* Smaller phones */
@media (max-width: rem(360)) {
    .task_list_screen {
        .list_area {
            .item {
                padding: 15px;

                h4 {
                    font-size: 18px;
                    margin-bottom: 4px;
                }

                .left_area {
                    width: 100%;
                    float: left;
                    margin-bottom: 5px;
                }

                .right_area {
                    width: 100%;
                    float: right;
                }
            }
        }
    }
}