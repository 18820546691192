.delete_modal {
    &.modal {
        .modal-dialog {
            &.modal-dialog-centered {
                .modal-content {

                    .modal-body {
                        padding: 0 rem(40);

                        .dlt_body_area {
                            .image_section {
                                text-align: center;
                                margin-top: rem(-23);
                                margin-bottom: rem(70);

                                .img_shape {
                                    width: rem(190);
                                    margin: 0 auto;
                                    position: relative;

                                    img {
                                        height: 100%;
                                        width: 100%;
                                        object-fit: cover;
                                    }

                                    .dlt_circle {
                                        height: rem(100);
                                        width: rem(100);
                                        border-radius: 50%;
                                        line-height: rem(100);
                                        background: linear-gradient(135.83deg, #E31E24 8.45%, #B5133B 115.01%);
                                        font-size: rem(30);
                                        color: $white;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        margin-left: rem(45);
                                        margin-top: rem(45);
                                    }

                                    .green_circle {
                                        height: rem(100);
                                        width: rem(100);
                                        border-radius: 50%;
                                        line-height: rem(100);
                                        background: $notification;
                                        font-size: rem(30);
                                        color: $white;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        margin-left: rem(45);
                                        margin-top: rem(45);

                                        .img_area {
                                            width: rem(42);
                                            margin: 0 auto;

                                            img {
                                                height: 100%;
                                                width: 100%;
                                                object-fit: cover;
                                            }
                                        }
                                    }
                                }
                            }

                            .text_line {
                                margin-bottom: rem(30);

                                p {
                                    font-size: rem(24);
                                    font-weight: 600;
                                    color: $black_2;
                                    text-align: center;
                                }
                            }

                            .btn_area {
                                margin-bottom: rem(60);
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(576)) and (max-width: rem(767)) {
    .delete_modal {
        &.modal {
            .modal-dialog {
                &.modal-dialog-centered {
                    .modal-content {
                        .modal-body {
                            .dlt_body_area {
                                .image_section {
                                    margin-top: rem(-21);
                                    margin-bottom: rem(50);

                                    .img_shape {
                                        width: rem(170);

                                        .dlt_circle {
                                            width: rem(80);
                                            height: rem(80);
                                            line-height: rem(80);
                                            font-size: rem(26);
                                        }

                                        .green_circle {
                                            width: rem(80);
                                            height: rem(80);
                                            line-height: rem(80);
                                            font-size: rem(26);

                                            .img_area {
                                                width: rem(35);
                                            }
                                        }
                                    }
                                }

                                .text_line {
                                    margin-bottom: rem(15);

                                    p {
                                        font-size: rem(20);
                                    }
                                }

                                .btn_area {
                                    margin-bottom: rem(40);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: rem(361)) and (max-width: rem(575)) {
    .delete_modal {
        &.modal {
            .modal-dialog {
                &.modal-dialog-centered {
                    .modal-content {
                        .modal-body {
                            padding: 0 rem(20);

                            .dlt_body_area {
                                .image_section {
                                    margin-top: rem(-18);
                                    margin-bottom: rem(30);

                                    .img_shape {
                                        width: rem(150);

                                        .dlt_circle {
                                            width: rem(70);
                                            height: rem(70);
                                            line-height: rem(70);
                                            font-size: rem(25);
                                            margin-left: rem(41);
                                        }

                                        .green_circle {
                                            width: rem(70);
                                            height: rem(70);
                                            line-height: rem(70);
                                            font-size: rem(25);
                                            margin-left: rem(41);

                                            .img_area {
                                                width: rem(30);
                                            }
                                        }
                                    }
                                }

                                .text_line {
                                    margin-bottom: rem(10);

                                    p {
                                        font-size: rem(18);
                                    }
                                }

                                .btn_area {
                                    margin-bottom: rem(30);

                                    .btn {
                                        font-size: rem(20);
                                        height: rem(55);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Smaller phones */
@media (max-width: rem(360)) {
    .delete_modal {
        &.modal {
            .modal-dialog {
                &.modal-dialog-centered {
                    .modal-content {
                        .modal-body {
                            padding: 0 rem(20);

                            .dlt_body_area {
                                .image_section {
                                    margin-top: rem(-18);
                                    margin-bottom: rem(30);

                                    .img_shape {
                                        width: rem(150);

                                        .dlt_circle {
                                            width: rem(70);
                                            height: rem(70);
                                            line-height: rem(70);
                                            font-size: rem(25);
                                            margin-left: rem(41);
                                        }

                                        .green_circle {
                                            width: rem(70);
                                            height: rem(70);
                                            line-height: rem(70);
                                            font-size: rem(25);
                                            margin-left: rem(41);

                                            .img_area {
                                                width: rem(30);
                                            }
                                        }
                                    }
                                }

                                .text_line {
                                    margin-bottom: rem(10);

                                    p {
                                        font-size: rem(18);
                                    }
                                }

                                .btn_area {
                                    margin-bottom: rem(30);

                                    .btn {
                                        font-size: rem(20);
                                        height: rem(55);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}